import { useMediaQuery } from '@chakra-ui/react';
import { Flex, Tabs, Form } from 'antd';
import Layout from 'components/layout';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import { useHistory, useParams } from 'react-router-dom';
import GeneralDataTab from './Tabs/GeneralDataTab';
import PedagogicalTeam from './Tabs/PedagogicalTeamTab';
import StudentsTab from './Tabs/StudentsTab';
import { useEffect, useState } from 'react';
import { useSaveClassV2 } from 'core/features/classes/hooks/useSaveClassV2';
import useAllClassesDetails from 'core/features/classes/hooks/useAllClassesDetails';
import ClassDetailsCard from '../../components/ClassDetailsCard';
import {
  notificateError,
  notificateSucess,
  openNotification,
} from 'infra/helpers/notifications';
import CustomizedRequiredMark from 'components/required-mark';
import BackButton from 'components/buttons/back-button';
import Button from 'components/buttons/button';
import { useQueryClient } from 'react-query';
import Title from 'components/typography/title';
import { NumberParam, useQueryParam } from 'use-query-params';

export interface IClassInfo {
  id?: string;
  year: number;
  serieId: string;
  period: string;
  name: string;
  collaborators: string[];
  students: string[];
  deletedStudents?: string[];
}

function ClassCreate() {
  const history = useHistory();
  const [step, setStep] = useQueryParam('tab', NumberParam);
  const { colorPrimary, colorGreyBorderAndFont } = useU4heroColors();
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const [form] = Form.useForm();
  const [isFirstPartComplete, setIsFirstPartComplete] =
    useState<boolean>(false);
  const { mutate: handleClassSave, isLoading } = useSaveClassV2();
  const { id, mode, tenantId } = useParams<{
    id: string;
    mode: string;
    tenantId: string;
  }>();
  const { data, refetch } = useAllClassesDetails(id);
  const [isEdited, setIsEdited] = useState(false);
  const queryClient = useQueryClient();

  if (!step) {
    if (mode || id) {
      setStep(3);
    } else {
      setStep(1);
    }
  }

  const name = Form.useWatch('name', form);
  const checkIsFirstPartComplete = () => {
    const isComplete =
      form.isFieldTouched('year') &&
      form.isFieldTouched('serieId') &&
      form.isFieldTouched('period') &&
      name !== '' &&
      name !== undefined &&
      form.isFieldTouched('initialAvaliation');

    if (isComplete) {
      setIsFirstPartComplete(true);
    } else {
      setIsFirstPartComplete(false);
    }
    return id ? true : isComplete;
  };

  function getStudents() {
    const students = [];
    if (data) {
      for (const classStudent of data?.classStudents) {
        const student = classStudent.student;
        const belongsToClass = data.students.some((s) => s.id === student.id);
        students.push({
          id: student.id,
          name: student.user.name,
          birthday: student.user.birthday,
          license: student.license,
          belongsToClass: belongsToClass,
          tenantName: data.tenant.name,
          classDetails: {
            id: data.id,
            name: data.name,
            period: data.period,
            serie: data.serie.name,
            year: data.year,
          },
        });
      }
    }

    return students;
  }

  const handleBack = () => {
    if (form.isFieldsTouched() && !isEdited) {
      openNotification({ onClose: handleOk, colorPrimary, isMobile });
    } else {
      handleOk();
    }
  };

  const actionButtons = (
    <Flex
      gap="16px"
      style={{
        width: '100%',
        alignItems: 'center',
        justifyContent: 'end',
        marginBottom: isMobile ? '8px' : '16px',
        marginTop: isMobile ? '8px' : '16px',
      }}
    >
      <Button
        variant="outline"
        size="sm"
        onClick={() => handleBack()}
        id="id-tour-create-class-button-cancel"
      >
        CANCELAR
      </Button>
      <Button
        loading={isLoading}
        size="sm"
        id="id-tour-create-class-button-save"
        htmlType="submit"
      >
        {step === 3 ? 'FINALIZAR' : 'SALVAR'}
      </Button>
    </Flex>
  );
  const items = [
    {
      key: '1',
      label: (
        <p
          style={{
            fontSize: isMobile ? '14px' : '24px',
            fontWeight: 700,
            color: 1 !== step ? colorGreyBorderAndFont : '',
          }}
        >
          DADOS GERAIS
        </p>
      ),
      children: (
        <Flex vertical>
          {!mode && actionButtons}
          <GeneralDataTab />
        </Flex>
      ),
      forceRender: true,
    },
    {
      key: '2',
      label: (
        <p
          style={{
            fontSize: isMobile ? '14px' : '24px',
            fontWeight: 700,
            color: 2 !== step ? colorGreyBorderAndFont : '',
          }}
        >
          {isMobile ? (
            <p style={{ textAlign: 'center' }}>
              EQUIPE <br /> PEDAGÓGICA
            </p>
          ) : (
            'EQUIPE PEDAGÓGICA'
          )}
        </p>
      ),
      children: (
        <Flex vertical>
          {!mode && actionButtons}
          <PedagogicalTeam
            collaborators={
              data?.teacherClasses.map((teacher) => ({
                id: teacher.user.id,
                name: teacher.user.name,
                active: teacher.user.active,
                profiles:
                  teacher.user?.profiles?.map((item) => item.name) ?? [],
              })) ?? []
            }
          />
        </Flex>
      ),
      forceRender: true,
      disabled: id ? false : !isFirstPartComplete,
    },
    {
      key: '3',
      label: (
        <p
          style={{
            fontSize: isMobile ? '14px' : '24px',
            fontWeight: 700,
            color: 3 !== step ? colorGreyBorderAndFont : '',
          }}
        >
          ESTUDANTES
        </p>
      ),
      children: (
        <Flex vertical>
          {!mode && actionButtons}
          <StudentsTab students={getStudents()} />
        </Flex>
      ),
      forceRender: true,
      disabled: id ? false : !isFirstPartComplete,
    },
  ];
  if (mode) {
    items.shift();
  }
  const getDeletedStudents = (values: IClassInfo) => {
    if (values.students) {
      const deletedStudents = data?.students
        .filter(
          (student) =>
            !values.students.some((studentSaved) => student.id === studentSaved)
        )
        .map((student) => student.id);
      return deletedStudents;
    }
  };

  const getDeletedCollaborators = (values: IClassInfo) => {
    if (values.collaborators) {
      const deletedCollaborators = data?.teacherClasses
        .filter(
          (collaborator) =>
            !values.collaborators.some(
              (collaboratorSaved) => collaborator.user.id === collaboratorSaved
            )
        )
        .map((collaborator) => collaborator.user.id);
      return deletedCollaborators;
    }
  };

  const onSubmit = (values: IClassInfo) => {
    handleClassSave(
      id
        ? {
            ...values,
            tenantId,
            id,
            deletedStudents: getDeletedStudents(values),
            deletedCollaborators: getDeletedCollaborators(values),
          }
        : { ...values, tenantId },
      {
        onSuccess: () => {
          notificateSucess(`Turma ${id ? 'editada' : 'criada'} com sucesso!`);
          if (!id) {
            handleOk();
            return;
          }
          setIsEdited(true);
          queryClient.invalidateQueries('/v2/users/class/');
          refetch();
        },
        onError: (err: any) => {
          notificateError(
            `Houve um problema ao ${
              id ? 'editar' : 'criar'
            } a turma. Verifique sua conexão e tente novamente.`
          );
        },
      }
    );
  };

  const handleOk = () => {
    if (tenantId) {
      history.push(`/schools/${tenantId}/classes`);
    } else {
      history.push('/my-school/classes');
    }
  };

  useEffect(() => {
    form.resetFields();
  }, [data, form]);

  return (
    <Layout.Container>
      <Flex vertical style={{ width: '100%', height: '100%' }}>
        <Flex gap={isMobile ? '12px' : '24px'} align="center">
          <BackButton onClick={handleBack} />
          <Title primary>Turmas</Title>
        </Flex>
        {id ? <ClassDetailsCard classData={data} /> : null}
        <Form
          style={{ height: '100%' }}
          layout="vertical"
          onFieldsChange={() => checkIsFirstPartComplete()}
          form={form}
          onFinish={onSubmit}
          initialValues={{
            name: data ? data.name : undefined,
            period: data ? data.period : undefined,
            year: data ? data.year : undefined,
            serieId: data ? data.serie.id : undefined,
            students: data?.students
              ? data.students.map((item) => item.id)
              : [],
            collaborators: data?.teacherClasses
              ? data?.teacherClasses.map((item) => item.user.id)
              : [],
            initialAvaliation: undefined,
          }}
          requiredMark={CustomizedRequiredMark}
        >
          <Tabs
            centered
            style={{ height: '100%' }}
            onTabClick={(event) => setStep(Number(event))}
            size="large"
            defaultActiveKey={mode || id ? '3' : '1'}
            items={items}
          />
        </Form>
      </Flex>
    </Layout.Container>
  );
}

export default ClassCreate;
