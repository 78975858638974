import { useMediaQuery } from '@chakra-ui/react';
import {  Col, Flex, Form, Row } from 'antd';
import Layout from 'components/layout';
import Title from 'components/typography/title';
import { useUpdateCurrentUser } from 'core/features/user/hooks/useUpdateCurrentUser';
import useUser from 'core/features/user/hooks/useUser';
import { useCurrentUser } from 'core/features/user/store';
import { UserForm } from 'core/features/user/typings/form';
import { useEffect, useState } from 'react';
import CustomizedRequiredMark from 'components/required-mark';
import PersonalInfo from './components/PersonalInfo';
import ContactInfo from './components/ContactInfo';
import AdressInfo from 'ui/pages/app/profile/components/AddressInfo';
import Safety from './components/Safety';
import ProfileProgress from './components/ProfileProgress';
import { notificateError, notificateSucess } from 'infra/helpers/notifications';
import Button from 'components/buttons/button';

function ProfilePage() {
  const { user, update: updateUser } = useCurrentUser();
  const [form] = Form.useForm();
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const { data, isLoading: isLoadingUser } = useUser(user?.id);
  const { mutate: handleSave, isLoading } = useUpdateCurrentUser();
  const _handleSave = (values: UserForm) => {
    handleSave(values, {
      onSuccess: () => {
        updateUser({ ...user, ...values } as any);
        notificateSucess('Perfil atualizado com sucesso!');
      },
      onError: (err: any) => {
        notificateError(
          'Houve um problema ao salvar as alterações. Por favor, verifique sua conexão e tente novamente.'
        );
      },
    });
  };
  useEffect(() => {
    form.resetFields();
  }, [data, form]);


  return (
    <Layout.Container>
      <Title primary style={{ marginBottom: '16px' }}>
        Meu Perfil
      </Title>
     
      <Form
        id="id-tour-my-profile"
        onFinish={(ev) => _handleSave(ev)}
        validateTrigger="onSubmit"
        layout="vertical"
        requiredMark={CustomizedRequiredMark}
        form={form}
        initialValues={data || undefined}
      >
        <Row
          gutter={isMobile ? [0, 24] : [28, 0]}
          style={{ marginBottom: '24px' }}
        >
          <Col span={isMobile ? 24 : 18} order={isMobile ? 2 : 1}>
            <Flex vertical gap="24px">
              <PersonalInfo />
              <ContactInfo />
              <AdressInfo />
              <Safety />
            </Flex>
          </Col>
          <Col span={isMobile ? 24 : 6} order={isMobile ? 1 : 2}>
            <Flex
              vertical
              gap="16px"
              justify="center"
              style={{ position: isMobile ? undefined : 'fixed' }}
            >
              <ProfileProgress />
              <Button
                size="md"
                id="id-tour-my-profile-button-save"
                style={{
                  fontWeight: 600,
                  fontSize: isMobile ? '12px' : '16px',
                  alignSelf: 'center',
                }}
                htmlType="submit"
                loading={isLoading}
              >
                SALVAR ALTERAÇÕES
              </Button>
            </Flex>
          </Col>
        </Row>
      </Form>
    </Layout.Container>
  );
}

export default ProfilePage;
