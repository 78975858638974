import { Flex, Row, Col, Radio, Form } from 'antd';
import SvgIcon from 'components/SvgIcon';
import { TagType } from 'core/features/competence/typings';
import useTagsCompetence from 'core/features/tags/hooks/useTagsCompetence';
import { useEffect, useState } from 'react';

function PickCompetence({
  value,
  onChange,
  setHasTwoCompetences,
  tagType,
}: {
  value: {
    id: string;
    name: string;
  };
  onChange: (v: { id: string; name: string }) => void;
  setHasTwoCompetences: (v: boolean) => void;
  tagType: TagType;
}) {
  const { data } = useTagsCompetence({
    competence: true,
    type: tagType,
    disableQuery: tagType === undefined,
  });

  const combined = [
    {
      label1: `Antibullying`,
      label2: `Segurança educacional`,
      value: 'combination 1',
      combination: true,
      id1: data?.find((item) => item.name['pt-br'] === 'Antibullying')?.id,
      icon1: data?.find((item) => item.name['pt-br'] === 'Antibullying')?.icon,
      id2: data?.find((item) => item.name['pt-br'] === 'Segurança educacional')
        ?.id,
      icon2: data?.find(
        (item) => item.name['pt-br'] === 'Segurança educacional'
      )?.icon,
    },
    {
      label1: `Autogestão`,
      label2: `Autoconsciência`,
      value: 'combination 2',
      combination: true,
      id1: data?.find((item) => item.name['pt-br'] === 'Autogestão')?.id,
      icon1: data?.find((item) => item.name['pt-br'] === 'Autogestão')?.icon,
      id2: data?.find((item) => item.name['pt-br'] === 'Autoconsciência')?.id,
      icon2: data?.find((item) => item.name['pt-br'] === 'Autoconsciência')
        ?.icon,
    },
    {
      label1: `Consciência social`,
      label2: `Relacionamento`,
      value: 'combination 3',
      combination: true,
      id1: data?.find((item) => item.name['pt-br'] === 'Consciência social')
        ?.id,
      icon1: data?.find((item) => item.name['pt-br'] === 'Consciência social')
        ?.icon,
      id2: data?.find((item) => item.name['pt-br'] === 'Relacionamento')?.id,
      icon2: data?.find((item) => item.name['pt-br'] === 'Relacionamento')
        ?.icon,
    },
  ];

  const [internalValue, setInternalValue] = useState<string>();
  const form = Form.useFormInstance();
  const skill = Form.useWatch('skill', form);

  useEffect(() => {
    if (value) {
      if (skill !== undefined && data?.find((item) => item.id === skill.id)) {
        const combination = combined.find(
          (item) => item.id2 === skill.id || item.id1 === skill.id
        );
        setInternalValue(combination?.value);
        setHasTwoCompetences(true);
      } else {
        setInternalValue(value.id);
      }
    }else{
      setInternalValue(undefined);
    }
  }, [value, skill]);
  return (
    <Radio.Group
      id="id-tour-question-creation-input-competence"
      onChange={(e) => {
        if (e.target.value.includes('combination')) {
          const selected = combined.find(
            (item) => item.value === e.target.value
          );
          setInternalValue(e.target.value);
          onChange({
            id: selected?.id1 as string,
            name: selected?.label1 as string,
          });
          form.setFieldValue('skill', {
            id: selected?.id2 as string,
            name: selected?.label2 as string,
          });
          form.setFieldValue('typeId', 1);
          setHasTwoCompetences(true);
        } else {
          const selected = data?.find((item) => item.id === e.target.value);
          onChange({
            id: selected?.id as string,
            name: selected?.name['pt-br'] as string,
          });
          setHasTwoCompetences(false);
          form.setFieldValue('typeId', 0);
          form.setFieldValue('skill', undefined);
        }
      }}
      value={internalValue}
    >
      <Row gutter={[16, 16]}>
        {tagType === TagType.Competências && combined.map((item) => (
          <Col span={12}>
            {item.combination ? (
              <Radio value={item.value} key={item.value}>
                <Flex gap="8px" align="center">
                  {item.icon1 ? (
                    <SvgIcon url={item.icon1} color="black" size={35} />
                  ) : null}
                  <p style={{ fontSize: '14px' }}>{item.label1}</p> +{' '}
                  {item.icon2 ? (
                    <SvgIcon url={item.icon2} color="black" size={35} />
                  ) : null}
                  <p style={{ fontSize: '14px' }}>{item.label2}</p>
                </Flex>
              </Radio>
            ) : null}
          </Col>
        ))}
        {data?.map((competence) => (
          <Col span={12}>
            <Radio value={competence.id} key={competence.id}>
              <Flex gap="8px" align="center">
                {competence.icon ? (
                  <SvgIcon url={competence.icon} color="black" size={35} />
                ) : null}
                <p style={{ fontSize: '14px' }}>{competence.name['pt-br']}</p>
              </Flex>
            </Radio>
          </Col>
        ))}
      </Row>
    </Radio.Group>
  );
}

export default PickCompetence;
