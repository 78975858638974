import { ArrowLeftOutlined } from '@ant-design/icons';
import { useMediaQuery } from '@chakra-ui/react';
import {
  Col,
  Drawer,
  Flex,
  Form,
  Input,
  Radio,
  Row,
  Select,
  Slider,
  SliderSingleProps,
} from 'antd';
import Layout from 'components/layout';
import CustomizedRequiredMark from 'components/required-mark';
import usePractice from 'core/features/practices/hooks/usePractice';
import { useSavePractice } from 'core/features/practices/hooks/useSavePractice';
import { PracticeForm } from 'core/features/practices/typings';
import { useCurrentUser } from 'core/features/user/store';
import {
  notificateError,
  notificateSucess,
  openNotification,
} from 'infra/helpers/notifications';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import { useEffect, useState } from 'react';
import { HiArrowRight } from 'react-icons/hi';
import { IoClose, IoSaveOutline } from 'react-icons/io5';
import { PiEyeFill } from 'react-icons/pi';
import { useHistory, useParams } from 'react-router';
import PracticePreview from './components/practicePreview';
import SelectSkill from './components/selectSkill';
import SelectCompetence from './components/selectCompetence';
import useEnvironments from 'core/features/questions/hooks/useEnvironments';
import { t } from 'core/resources/strings';
import CustomEditor from 'components/customEditor';
import CustomUpload from 'components/customUpload';
import BackButton from 'components/buttons/back-button';
import Button from 'components/buttons/button';
import Title from 'components/typography/title';

function CreatePractice() {
  const { colorPrimary, colorGreyBorderAndFont } = useU4heroColors();
  const history = useHistory();
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const { user } = useCurrentUser();
  const params = useParams<{
    id: string;
  }>();
  const { data } = usePractice(params.id);
  const { mutate: handleSave, isLoading } = useSavePractice();
  const environments = useEnvironments();
  const [form] = Form.useForm();
  const formValues = Form.useWatch([], form);
  const [published, setPublished] = useState<boolean>(true);
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const [competenceName, setCompetenceName] = useState<string>();
  const [skillName, setSkillName] = useState<string>();
  const competence1 = Form.useWatch('competence1', form);
  const competence2 = Form.useWatch('competence2', form);
  const [modality, setModality] = useState<string>();
  const [environment, setEnvironment] = useState<string>();
  const updateForm = (fieldName: string, value: any) => {
    form.setFieldValue(fieldName, value);
  };

  useEffect(() => {
    if (data) {
      setModality(t(`app.${data.practiceModality.name}`));
      setEnvironment(t(`app.${data.analysisEnvironment.name}`));
    }
    form.resetFields();
  }, [data, form]);

  const onSubmit = (values: PracticeForm) => {
    if (values.subject === null) {
      delete values.subject;
    }
    handleSave(
      { ...values, published: published, id: params.id },
      {
        onError: () => {
          notificateError(
            `Houve um problema ao ${
              params.id ? 'editar' : 'publicar'
            }. Verifique sua conexão e tente novamente.`
          );
        },
        onSuccess: () => {
          notificateSucess(
            `${
              params.id
                ? 'Inspiração pedagógica editada com sucesso!'
                : `Inspiração pedagógica ${
                    published ? 'publicada' : 'salva'
                  } com sucesso!`
            }`
          );
          setTimeout(() => {
            history.push('/practices/manager');
          }, 200);
        },
      }
    );
  };
  const modalityTypes = [
    { label: 'Presencial', value: 0 },
    { label: 'Remoto', value: 1 },
    { label: 'Múltiplos Contextos', value: 3 },
  ];

  const marks: SliderSingleProps['marks'] = {
    10: (
      <p
        style={{
          fontSize: isMobile ? '10px' : '16px',
          color: colorGreyBorderAndFont,
          fontWeight: 300,
        }}
      >
        10
      </p>
    ),
    20: (
      <p
        style={{
          fontSize: isMobile ? '10px' : '16px',
          color: colorGreyBorderAndFont,
          fontWeight: 300,
        }}
      >
        20
      </p>
    ),
    30: (
      <p
        style={{
          fontSize: isMobile ? '10px' : '16px',
          color: colorGreyBorderAndFont,
          fontWeight: 300,
        }}
      >
        30
      </p>
    ),
    40: (
      <p
        style={{
          fontSize: isMobile ? '10px' : '16px',
          color: colorGreyBorderAndFont,
          fontWeight: 300,
        }}
      >
        40
      </p>
    ),
    50: (
      <p
        style={{
          fontSize: isMobile ? '10px' : '16px',
          color: colorGreyBorderAndFont,
          fontWeight: 300,
        }}
      >
        50
      </p>
    ),
    60: (
      <p
        style={{
          fontSize: isMobile ? '10px' : '16px',
          color: colorGreyBorderAndFont,
          fontWeight: 300,
        }}
      >
        60
      </p>
    ),
  };

  const subjects = [
    'Arte',
    'Ciências',
    'Educação Física',
    'Geografia',
    'História',
    'Língua Inglesa',
    'Língua Portuguesa',
    'Matemática',
  ];
  const dificulties = ['Fácil', 'Médio', 'Difícil'];

  return (
    <Layout.Container>
      <Row gutter={[16, 0]}>
        <Col span={isPreviewOpen ? 12 : 24}>
          <Form
            layout="vertical"
            form={form}
            onFinish={onSubmit}
            requiredMark={CustomizedRequiredMark}
            validateTrigger="submit"
            initialValues={
              data
                ? {
                    ...data,
                    competence1: data?.competence1?.id,
                    competence2: data?.competence2?.id,
                    skill: data?.skill?.id,
                    practiceModalityId: data?.practiceModality.id,
                    analysisEnvironmentId: data?.analysisEnvironment.id,
                  }
                : undefined
            }
          >
            <Flex gap="23px" align="center">
              <BackButton
                onClick={() => {
                  if (form.isFieldsTouched()) {
                    openNotification({
                      colorPrimary,
                      isMobile,
                      onClose: () => history.push('/practices/manager'),
                    });
                  } else {
                    history.push('/practices/manager');
                  }
                }}
              />

              <Title primary>{params.id ? 'Editar' : 'Criar'} Inspiração</Title>
            </Flex>

            <Flex
              gap="16px"
              justify="end"
              align="center"
              style={{ marginBottom: isMobile ? '8px' : '16px' }}
            >
              <Button
                size="md"
                id="id-tour-practices-create-button-save"
                variant="outline"
                htmlType="submit"
                onClick={() => setPublished(false)}
                loading={isLoading}
              >
                <IoSaveOutline color={colorPrimary} size={24} />
              </Button>

              <Button
                size="md"
                id="id-tour-practices-create-button-preview"
                variant="outline"
                style={{
                  display: 'flex',
                  gap: isMobile ? '4px' : '16px',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                onClick={() => setIsPreviewOpen(true)}
              >
                <PiEyeFill size={isMobile ? 16 : 28} color={colorPrimary} />{' '}
                PRÉVIA
              </Button>

              <Button
                size="md"
                id="id-tour-practices-create-button-publish"
                htmlType="submit"
                loading={isLoading}
              >
                PUBLICAR
              </Button>
            </Flex>

            <Form.Item
              label="Título"
              rules={[
                {
                  required: true,
                  message: 'Por favor, crie um título para a publicação!',
                },
                {
                  max: 30,
                  message: 'O título pode ter no máximo 30 caracteres!',
                },
              ]}
              name="title"
            >
              <Input
                id="id-tour-create-practice-input-title"
                placeholder="Crie um título de até 30 caracteres"
                maxLength={30}
                size={isMobile ? 'middle' : 'large'}
              />
            </Form.Item>

            <Form.Item
              name="banner"
              label="Banner"
              rules={[
                {
                  required: true,
                  message: 'Por favor, carregue uma imagem para banner!',
                },
              ]}
            >
              {/* @ts-ignore */}
              <CustomUpload />
            </Form.Item>
            <Form.Item
              label="Objetivo"
              rules={[
                {
                  required: true,
                  message:
                    'Por favor, descreva o objetivo da Inspiração pedagógica!',
                },
              ]}
              name="goal"
            >
              <Input.TextArea
                id="id-tour-create-practice-input-goal"
                placeholder="Descreva brevemente o objetivo da Inspiração pedagógica"
                rows={2}
              />
            </Form.Item>
            <Form.Item hidden name="competence2" />
            <Row align="middle">
              <Col span={isMobile ? 24 : 11}>
                <Form.Item
                  label="Competência"
                  rules={[
                    {
                      required: true,
                      message: 'Por favor, selecione uma competência!',
                    },
                  ]}
                  name="competence1"
                >
                  {/* @ts-ignore */}
                  <SelectCompetence
                    competence2={competence2}
                    updateForm={updateForm}
                    setCompetenceName={setCompetenceName}
                  />
                </Form.Item>
              </Col>
              <Col span={isMobile ? 0 : 2}>
                <Flex justify="center">
                  <HiArrowRight color={colorGreyBorderAndFont} size={40} />
                </Flex>
              </Col>
              <Col span={isMobile ? 24 : 11}>
                <Form.Item
                  label="Habilidade"
                  rules={[
                    {
                      required:
                        competence1 !== undefined && competence2 === undefined,
                      message: 'Por favor, selecione uma habilidade!',
                    },
                  ]}
                  name="skill"
                >
                  {/* @ts-ignore */}
                  <SelectSkill
                    parentId={competence1}
                    disabled={
                      competence1 === undefined || competence2 !== undefined
                    }
                    setSkillName={setSkillName}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Form.Item
              label="Direcionamento da Inspiração"
              rules={[
                {
                  required: true,
                  message:
                    'Por favor, determine a o direcionamento da Inspiração!',
                },
              ]}
              name="target"
            >
              <Radio.Group id="id-tour-create-practice-input-target">
                <Row gutter={[isMobile ? 30 : 40, 0]}>
                  {['Individual', 'Coletivo', 'Universal'].map((item) => (
                    <Col key={item} span={8}>
                      <Radio
                        value={item}
                        style={{
                          whiteSpace: 'nowrap',
                        }}
                      >
                        {item}
                      </Radio>
                    </Col>
                  ))}
                </Row>
              </Radio.Group>
            </Form.Item>

            <Form.Item
              label="Ambiente de Análise"
              rules={[
                {
                  required: true,
                  message: 'Por favor, defina o ambiente de análise!',
                },
              ]}
              name="analysisEnvironmentId"
            >
              <Radio.Group id="id-tour-create-practice-input-analysisEnvironmentId">
                <Row gutter={[0, isMobile ? 8 : 0]}>
                  {environments.data?.map((item) => (
                    <Col key={item.id} span={isMobile ? 12 : 6}>
                      <Radio
                        onClick={() => setEnvironment(t(`app${item.name}`))}
                        value={item.id}
                        style={{
                          whiteSpace: 'nowrap',
                        }}
                      >
                        {t(`app.${item.name}`)}
                      </Radio>
                    </Col>
                  ))}
                </Row>
              </Radio.Group>
            </Form.Item>

            <Form.Item
              label="Faixa Etária"
              rules={[
                {
                  required: true,
                  message: 'Por favor, determine a faixa etária!',
                },
              ]}
              name="maxAge"
            >
              <Radio.Group id="id-tour-create-practice-input-max-age">
                <Row gutter={[0, isMobile ? 8 : 0]}>
                  {[3, 6, 12, 16].map((item) => (
                    <Col key={item} span={isMobile ? 12 : 6}>
                      <Radio
                        value={item}
                        style={{
                          whiteSpace: 'nowrap',
                        }}
                      >
                        {item} anos +
                      </Radio>
                    </Col>
                  ))}
                </Row>
              </Radio.Group>
            </Form.Item>

            <Form.Item
              label="Modalidade"
              rules={[
                {
                  required: true,
                  message: 'Por favor, determine a modalidade!',
                },
              ]}
              name="practiceModalityId"
            >
              <Radio.Group id="id-tour-create-practice-input-practiceModalityId">
                <Row gutter={[0, isMobile ? 8 : 0]}>
                  {modalityTypes.map((item) => (
                    <Col key={item.label} span={8}>
                      <Radio
                        value={item.value}
                        onClick={() => setModality(item.label)}
                        style={{
                          whiteSpace:
                            !isPreviewOpen && !isMobile ? 'nowrap' : 'initial',
                          margin: '0px',
                        }}
                      >
                        {item.label}
                      </Radio>
                    </Col>
                  ))}
                </Row>
              </Radio.Group>
            </Form.Item>

            <Form.Item
              label="Grau de Dificuldade"
              rules={[
                {
                  required: true,
                  message: 'Por favor, defina o ambiente de análise!',
                },
              ]}
              name="dificulty"
            >
              <Radio.Group id="id-tour-create-practice-input-dificulty">
                <Flex gap={isMobile ? '24px' : '40px'}>
                  {dificulties.map((item, index) => (
                    <Col key={item} span={8}>
                      <Radio
                        value={item}
                        style={{
                          whiteSpace: 'nowrap',
                        }}
                      >
                        {item}
                      </Radio>
                    </Col>
                  ))}
                </Flex>
              </Radio.Group>
            </Form.Item>

            <Form.Item
              label="Tempo de duração em minutos "
              rules={[
                {
                  required: true,
                  message:
                    'Por favor, determine o tempo de duração da inspiração!',
                },
              ]}
              name="duration"
              initialValue={10}
            >
              <Slider
                id="id-tour-create-practice-input-duration"
                min={10}
                max={60}
                marks={marks}
                step={null}
              />
            </Form.Item>

            <Form.Item
              label="Associe sua Inspiração pedagógica a uma disciplina"
              name="subject"
            >
              <Select
                options={subjects.map((item) => ({
                  label: item,
                  value: item,
                }))}
                style={{ width: isMobile ? '100%' : '35%' }}
                size={isMobile ? 'middle' : 'large'}
              />
            </Form.Item>

            <p style={{ fontSize: isMobile ? '14px' : '18px', color: 'black' }}>
              Clique no botão abaixo para utilizar a IA na geração de recursos e
              Inspirações pedagógicas.
            </p>

            <Button
              size="md"
              id="id-tour-practices-create-button-generate-with-ai"
              variant="outline"
              disabled
            >
              GERAR
            </Button>
            <Form.Item
              label="Recursos"
              rules={[
                {
                  required: true,
                  message:
                    'Por favor, descreva o objetivo da Inspiração pedagógica!',
                },
              ]}
              name="resources"
            >
              <Input.TextArea
                id="id-tour-create-practice-input-resources"
                placeholder="Cite os materiais que serão necessários para realizar a Inspiração pedagógica, caso não haja escreva “não há”...."
                rows={2}
              />
            </Form.Item>

            <Form.Item
              label="Conteúdo"
              rules={[
                {
                  required: true,
                  message: 'Por favor, escreva o passo a passo da inspiração!',
                },
              ]}
              name="content"
            >
              {/* @ts-ignore */}
              <CustomEditor folders={{images:'practices-images',files:'practices-files'}} acceptFiles />
            </Form.Item>
          </Form>
        </Col>
        {!isMobile ? (
          <Col
            span={isPreviewOpen ? 12 : 0}
            style={{ boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.40)' }}
          >
            <PracticePreview
              {...formValues}
              onClose={() => setIsPreviewOpen(false)}
              author={user?.name}
              competenceName={competenceName}
              skillName={skillName}
              modality={modality}
              environment={environment}
            />
          </Col>
        ) : null}
      </Row>

      <Drawer
        title={
          <Flex justify="space-between">
            <Title
              primary
              style={{
                marginBottom: '16px',
              }}
            >
              Prévia
            </Title>

            <IoClose
              style={{ cursor: 'pointer', justifySelf: 'end' }}
              size={24}
              color={colorGreyBorderAndFont}
              onClick={() => setIsPreviewOpen(false)}
            />
          </Flex>
        }
        placement={'right'}
        closable={false}
        open={isPreviewOpen && isMobile}
      >
        <PracticePreview
          {...formValues}
          onClose={() => setIsPreviewOpen(false)}
          author={user?.name}
          competenceName={competenceName}
          skillName={skillName}
          modality={modality}
          environment={environment}
        />
      </Drawer>
    </Layout.Container>
  );
}

export default CreatePractice;
