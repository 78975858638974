import { Select } from 'antd';
import { useEffect } from 'react';

const types = [
  {
    label: 'Bom comportamento',
    value: 0,
    behaviors: [
      {
        label: 'Ajudou a organizar a sala de aula.',
        relatedSkillId: '0df033fd-04c6-4a92-b937-74619252b04c',
        skill: 'Cooperação',
      },
      {
        label: 'Apresentou atitudes de liderança.',
        relatedSkillId: '6265a000-96d6-4a1b-95fd-90c0839cf0e1',
        skill: 'Protagonismo e autoria na vida pessoal e coletiva',
      },
      {
        label: 'Apresentou atitudes de responsabilidade ambiental.',
        relatedSkillId: '5857a396-0925-49c8-b291-da1b3eb0411e',
        skill: 'Consciência socioambiental',
      },
      {
        label: 'Colaborou bem em atividades de grupo.',
        relatedSkillId: '0df033fd-04c6-4a92-b937-74619252b04c',
        skill: 'Cooperação',
      },
      {
        label: 'Colaborou na manutenção da ordem na sala.',
        relatedSkillId: '0df033fd-04c6-4a92-b937-74619252b04c',
        skill: 'Cooperação',
      },
      {
        label: 'Completou as tarefas com qualidade.',
        relatedSkillId: '94174595-e5b5-4bf3-9cfe-707ddb6e68f0',
        skill: 'Determinação',
      },
      {
        label: 'Completou as tarefas com rapidez.',
        relatedSkillId: 'a00a5146-8399-4827-8959-4a7c43060c41',
        skill: 'Persistência',
      },
      {
        label: 'Contribuiu para a resolução de conflitos.',
        relatedSkillId: 'c50a58ef-ddfa-4693-9db9-3a3ae94ff93b',
        skill: 'Diálogo',
      },
      {
        label: 'Contribuiu para um ambiente positivo.',
        relatedSkillId: '50db7ec8-b6ec-48f3-9b4b-c745632dd03a',
        skill: 'Empatia',
      },
      {
        label: 'Demonstrou empatia pelos colegas.',
        relatedSkillId: '50db7ec8-b6ec-48f3-9b4b-c745632dd03a',
        skill: 'Empatia',
      },
      {
        label: 'Expressou entusiasmo pelo aprendizado.',
        relatedSkillId: '49863672-5727-48fe-8366-cd45479c71ee',
        skill: 'Diversidade de saberes e vivências culturais',
      },
      {
        label: 'Foi proativo em buscar ajuda.',
        relatedSkillId: '84dbf87f-51e1-492c-a7cf-d82236a7295f',
        skill: 'Autonomia',
      },
      {
        label: 'Foi proativo na resolução de problemas.',
        relatedSkillId: '1b52507a-bdb7-4b31-8dca-b88d49d949f4',
        skill: 'Formular e resolver problemas',
      },
      {
        label: 'Manifestou interesse na disciplina.',
        relatedSkillId: '49863672-5727-48fe-8366-cd45479c71ee',
        skill: 'Diversidade de saberes e vivências culturais',
      },
      {
        label: 'Mostrou respeito pelos colegas.',
        relatedSkillId: 'fb14afa9-ed10-4dd3-af01-1ca50db685ca',
        skill: 'Grupos sociais',
      },
      {
        label: 'Participou ativamente nas discussões em sala.',
        relatedSkillId: '5857a396-0925-49c8-b291-da1b3eb0411e',
        skill: 'Argumentação',
      },
      {
        label: 'Participou em atividades extracurriculares.',
        relatedSkillId: '41e23d16-6720-4b79-899c-a48e2eb87568',
        skill: 'Cidadania',
      },
      {
        label: 'Participou em eventos escolares.',
        relatedSkillId: '41e23d16-6720-4b79-899c-a48e2eb87568',
        skill: 'Cidadania',
      },
      {
        label: 'Teve bom relacionamento com os colegas.',
        relatedSkillId: 'fb14afa9-ed10-4dd3-af01-1ca50db685ca',
        skill: 'Grupos sociais',
      },
      {
        label: 'Teve interesse em aprender outros conteúdos.',
        relatedSkillId: '49863672-5727-48fe-8366-cd45479c71ee',
        skill: 'Diversidade de saberes e vivências culturais',
      },
      { label: 'Outro (Especifique abaixo).', relatedSkillId: '' },
    ],
  },
  {
    label: 'Mau comportamento',
    value: 1,
    behaviors: [
      {
        label: 'Agrediu um colega.',
        relatedSkillId: '6580501f-71f6-48f6-9660-bd4718cf6e75',
        skill: 'Influenciador',
      },
      {
        label: 'Apresentou comportamento desrespeitoso.',
        relatedSkillId: 'fb14afa9-ed10-4dd3-af01-1ca50db685ca',
        skill: 'Grupos sociais',
      },
      {
        label: 'Demonstrou falta de interesse na matéria.',
        relatedSkillId: '9c15a847-fda2-4572-99a9-f65803c66dd5',
        skill: 'Trabalho e projeto de vida',
      },
      {
        label: 'Esqueceu os materiais.',
        relatedSkillId: '1b52507a-bdb7-4b31-8dca-b88d49d949f4',
        skill: 'Formular e resolver problemas',
      },
      {
        label: 'Ficou distraído durante a aula.',
        relatedSkillId: 'a00a5146-8399-4827-8959-4a7c43060c41',
        skill: 'Persistência',
      },
      {
        label: 'Ficou em pé a aula toda.',
        relatedSkillId: '944a7ddb-9a35-4f78-9800-c40d360c9b1f',
        skill: 'Posicionamento ético',
      },
      {
        label: 'Manifestou falta de empatia pelos colegas.',
        relatedSkillId: '50db7ec8-b6ec-48f3-9b4b-c745632dd03a',
        skill: 'Empatia',
      },
      {
        label: 'Mostrou falta de organização.',
        relatedSkillId: '1b52507a-bdb7-4b31-8dca-b88d49d949f4',
        skill: 'Formular e resolver problemas',
      },
      {
        label: 'Mostrou-se ansioso durante testes ou avaliações.',
        relatedSkillId: 'bc18cb8a-cda5-4135-b886-a1dc777be2be',
        skill: 'Autoconfiança',
      },
      {
        label: 'Não aceitou as críticas.',
        relatedSkillId: 'cc34d823-a919-425c-bab6-0bdb49f88df7',
        skill: 'Autocrítica',
      },
      {
        label: 'Não concluiu as tarefas de casa.',
        relatedSkillId: '94174595-e5b5-4bf3-9cfe-707ddb6e68f0',
        skill: 'Determinação',
      },
      {
        label: 'Não parou de falar a aula toda.',
        relatedSkillId: 'c50a58ef-ddfa-4693-9db9-3a3ae94ff93b',
        skill: 'Diálogo',
      },
      {
        label: 'Não quis trabalhar em grupo.',
        relatedSkillId: '0df033fd-04c6-4a92-b937-74619252b04c',
        skill: 'Cooperação',
      },
      {
        label: 'Não respeitou as regras da sala de aula.',
        relatedSkillId: '944a7ddb-9a35-4f78-9800-c40d360c9b1f',
        skill: 'Posicionamento ético',
      },
      {
        label: 'Passou/Recebeu cola durante a avaliação.',
        relatedSkillId: '944a7ddb-9a35-4f78-9800-c40d360c9b1f',
        skill: 'Posicionamento ético',
      },
      {
        label: 'Teve dificuldade em aceitar a autoridade dos colegas.',
        relatedSkillId: 'bc18cb8a-cda5-4135-b886-a1dc777be2be',
        skill: 'Autoconfiança',
      },
      {
        label: 'Teve dificuldade em aceitar opiniões divergentes.',
        relatedSkillId: 'c50a58ef-ddfa-4693-9db9-3a3ae94ff93b',
        skill: 'Diálogo',
      },
      { label: 'Usou linguagem inadequada.', relatedSkillId: '' },
      {
        label: 'Usou o celular durante a aula.',
        relatedSkillId: '944a7ddb-9a35-4f78-9800-c40d360c9b1f',
        skill: 'Posicionamento ético',
      },
      {
        label: 'Usou roupas inadequadas para a escola.',
        relatedSkillId: '944a7ddb-9a35-4f78-9800-c40d360c9b1f',
        skill: 'Posicionamento ético',
      },
      { label: 'Outro (Especifique abaixo).', relatedSkillId: '' },
    ],
  },
  {
    label: 'É bom ficar de olho',
    value: 2,
    behaviors: [
      {
        label: 'Demonstrou falta de autoconfiança.',
        relatedSkillId: 'bc18cb8a-cda5-4135-b886-a1dc777be2be',
        skill: 'Autoconfiança',
      },
      {
        label: 'Dormiu durante a aula.',
        relatedSkillId: '638e1372-64a6-4cdc-af5b-c3f30c587fd6',
        skill: 'Saúde física',
      },
      {
        label: 'Ficou isolado dos colegas.',
        relatedSkillId: 'fb14afa9-ed10-4dd3-af01-1ca50db685ca',
        skill: 'Grupos sociais',
      },
      {
        label: 'Foi alvo de bullying.',
        relatedSkillId: 'd2b61649-64e2-4ecf-850a-2f8e45102432',
        skill: 'Vítima',
      },
      {
        label: 'Foi vítima de discriminação.',
        relatedSkillId: '75492ded-b768-4a3e-b4b8-c35e4499b874',
        skill: 'Preconceito',
      },
      {
        label: 'Teve dificuldade em controlar impulsos.',
        relatedSkillId: 'cf794d78-5959-4f2c-926c-532fc502b4a2',
        skill: 'Reconhecer suas emoções',
      },
      {
        label: 'Teve dificuldade em expressar emoções.',
        relatedSkillId: 'cf794d78-5959-4f2c-926c-532fc502b4a2',
        skill: 'Reconhecer suas emoções',
      },
      {
        label: 'Teve dificuldade em seguir instruções.',
        relatedSkillId: 'bda3296a-6f91-4011-9abd-7e8dc659fdab',
        skill: 'Flexibilidade',
      },
      { label: 'Outro (Especifique abaixo).', relatedSkillId: '' },
    ],
  },
];

interface BehaviorValue {
  value: string;
  relatedSkillId: string;
}

function SelectBehaviors({
  value,
  onChange,
  type,
}: {
  type?: number;
  value: BehaviorValue[] | undefined;
  onChange: (v: BehaviorValue[] | undefined) => void;
}) {
  const behaviors = types.find((item) => item.value === type)?.behaviors;

  return (
    <Select
      maxTagCount="responsive"
      style={{ width: '90%', height: '32px' }}
      disabled={type === undefined}
      mode="multiple"
      onChange={(v) => {
        const tags = behaviors
          ?.map((item) => {
            if (v.includes(item.label)) {
              return {
                value: item.label,
                relatedSkillId: item.relatedSkillId,
              };
            }
          })
          .filter((item) => item !== undefined);
        onChange(tags as BehaviorValue[]);
      }}
      value={value?.map((item)=> item.value)}
      placeholder="Selecione os comportamentos"
      options={behaviors?.map((item) => ({
        label: item.label,
        value: item.label,
      }))}
    />
  );
}

export default SelectBehaviors;
