import { translate } from '../polyglot';

export default translate('pt-br', {
  app: {
    idadeMinima: 'Idade Mínima',
    idadeMaxima: 'Idade Máxima',
    genero: 'Gênero',
    tempoMedio: 'Tempo médio',
    objetivoLudico: 'Objetivo Lúdico',
    objetivoPedagogico: 'Objetivo Pedagogico',
    competencias: 'Competências',
    questoes: 'Questões',
    conteudos: 'Conteúdos',
    conteudo: 'Conteúdo',
    remover: 'Remover',
    emocionometro: 'Emocionômetro',
    indicadores_gerais: 'Indicadores Gerais',
    indicadores_avancados: 'Indicadores avançados',
    rankJogadores: 'Rank de Jogadores',
    detalhes: 'Detalhes',
    modelo: 'Modelo',
    cancelar: 'Cancelar',
    importar: 'Importar',
    importacao: 'Importação',
    ver_mais: 'Ver mais',
    historico: 'Histórico',
    recomendacao: 'Recomendação',
    adicionar: 'Adicionar',
    maximo: 'Máximo',
    minimo: 'Mínimo',
    escola: 'Escola',
    escolas: 'Escolas',
    estudantes: 'estudantes',
    estudante: 'estudante',
    serie: 'Série',
    series: 'Séries',
    turma: 'Turma',
    turmas: 'Turmas',
    imagem: 'Imagem',
    filtro: 'Filtro',
    filtrar: 'Filtrar',
    imprimir: 'Imprimir',
    enviarEmail: 'Enviar email',
    comentarios: 'Comentários',
    comentar: 'Comentar',
    colaboradores: 'Colaboradores',
    colaborador: 'Colaborator',
    grafico: 'Gráficos',
    grafico_geral: 'Gráficos geral',
    sair: 'Sair',
    configuracoes: 'Configurações',
    nome: 'Nome',
    opcoes: 'Opções',
    editar: 'Editar',
    desativar: 'Desativar',
    ativar: 'Ativar',
    ativo: 'Ativo',
    inativo: 'Inativo',
    aviso: 'Aviso',
    sucesso: 'Sucesso',
    erro: 'Erro',
    portugues: 'Português',
    ingles: 'Inglês',
    espanhol: 'Espanhol',
    criar: 'Criar',
    entrar: 'Entrar',
    facaBusca: 'Faça uma busca',
    voltar: 'Voltar',
    salvar: 'Salvar',
    documento: 'Documento',
    telefone: 'Telefone',
    site: 'Site',
    emailAdmin: 'Email do diretor',
    nomeAdmin: 'Nome do diretor',
    pais: 'País',
    endereco: 'Endereço',
    adicionarTurma: 'Adicionar turma',
    cidade: 'Cidade',
    bairro: 'Bairro',
    estado: 'Estado',
    rua: 'Rua',
    complemento: 'Complemento',
    numero: 'Número',
    cep: 'CEP',
    matutino: 'Matutino',
    vespertino: 'Vespertino',
    noturno: 'Noturno',
    periodo: 'Período',
    excluir: 'Excluir',
    perfil: 'Perfil',
    usuario: 'Usuário',
    senha: 'Senha',
    acessoTurmas: 'Acesso às turmas',
    idade: 'Idade',
    anos: 'Anos',
    dataNascimento: 'Data de nascimento',
    nomePai: 'Nome do pai',
    nomeMae: 'Nome da mãe',
    limpar: 'Limpar',
    aplicar: 'Aplicar',
    relatorio: 'Relatório',
    relatorios: 'Relatórios',
    relatorioCompleto: 'Relatório completo',
    sexo: 'Sexo',
    feminino: 'Feminino',
    masculino: 'Masculino',
    esqueciSenha: 'Esqueci minha senha',
    voltarParaLogin: 'Voltar para o login',
    recuperarSenha: 'Recuperar senha',
    jogos: 'Jogos',
    jogo: 'Jogo',
    aventura: 'Aventura',
    exploracao: 'Exploração',
    miniquest: 'Miniquest',
    rpg: 'RPG',
    capitulo: 'Capítulo',
    capitulos: 'Capítulos',
    tipo: 'Tipo',
    perguntas: 'Perguntas',
    licenca: 'Licença',
    descricao: 'Descrição',
    hipotese: 'Hipótese',
    orientacao: 'Orientação',
    resposta: 'Resposta',
    pergunta: 'Pergunta',
    tag: 'Tag',
    tag_pai: 'Tag pai',
    jogoEmocoes: 'Jogo das emoções',
    graficoEmocoes: 'Gráfico das emoções',

    autoGestao: 'Autogestão',
    autoconsciencia: 'Autoconsciência',
    habilidadesRelacionamento: 'Habilidades de relacionamento',
    conscienciaSocial: 'Consciência Social',
    tomadaDecisaoResponsavel: 'Tomada de Decisão Responsável',
    graficoSocioemocional: 'Gráfico Socioemocional',
    socioemocional: 'Socioemocional',

    nivemCompreensao: 'Nível de compreensão',
    otimaCompreensao: 'Ótima compreensão',
    boaCompreensao: 'Boa compreensão',
    razoavelCompreensao: 'Razóavel compreensão',
    poucaCompreensao: 'Pouca compreensão',
    graficoBullying: 'Gráfico bullying',

    memoriaTrabalho: 'Memória de trabalho',
    flexibilidadeCognitiva: 'Flexibilidade cognitiva',
    controleInibitorio: 'Controle Inibitório',
    legenda: 'Legenda',
    baixoControleInibitorio: 'Baixo controle inibitório',
    medioControleInibitorio: 'Médio controle inibitório',
    altoControleInibitorio: 'Alto controle inibitório',

    entendimentoTurma: 'Entendimento da turma',
    entendimentoSerie: 'Entendimento da série',
    entendimentoestudante: 'Entendimento do estudante',

    graficoFuncoesExecutivas: 'Gráfico de Funções Executivas',
    mediaCliques10Segundos: 'Média de cliques a cada 10 segundos',

    acimaDe20: 'Acima de 20',
    de15a20: 'de 15 a 20',
    ate10: 'Até 10',

    graficoHumor: 'Gráfico de humor',
    nivelCompreensao: 'Nivel de compreensão',

    'Estou feliz': 'Feliz',
    'Estou com medo': 'Medo',
    'Estou triste': 'Triste',
    'Estou com raiva': 'Raiva',
    'Estou com nojo': 'Nojo',

    'Estou feliz.': 'Feliz',
    'Estou com medo.': 'Medo',
    'Estou triste.': 'Triste',
    'Estou com raiva.': 'Raiva',
    'Estou com nojo.': 'Nojo',

    configuracao: 'Configuração',
    editar_perfil: 'Editar perfil',
    inicial: 'Inicial',
    encerramento: 'Encerramento',
    investigacao: 'Investigação',

    Admin: 'Administrador(a)',
    Teacher: 'Professor(a)',
    u4hero: 'U4hero',

    morning: 'Matutino',
    evening: 'Vespertino',
    afternoon: 'Vespertino',
    fulltime: 'Integral',
    nocturnal: 'Noturno',
    organizacao: 'Organização',
    Organization: 'Organização',
    schoolEvasion: 'Evasão Escolar',
    reprovation: 'Reprovação',
    absenteeism: 'Absenteísmo',
    conflicts: 'Conflitos e Violência Escolar',
    performance: 'Desempenho Acadêmico',
    participation: 'Participação em Atividades Contraturno',
    familySupport: 'Apoio Familiar',
    socioeconomicLevel: 'Nível Socioeconômico',
    acessibility: 'Acessibilidade e Inclusão',
    mentalHealth: 'Saúde Mental',
    accessToTech: 'Acesso à Tecnologia',
    schoolEvasionInfo:
      'Este indicador avalia a probabilidade de um estudante abandonar os estudos com base em competências e habilidades socioemocionais essenciais. As competências e os temas analisados neste indicador são: Consciência Social, Relacionamento, Segurança, Autoconsciência, Autogestão, Tomada de Decisão Responsável e Anti-Bullying.',
    reprovationInfo:
      'Avalia o potencial risco de um estudante ser retido no mesmo ano ou nível de ensino devido à falta de competências socioemocionais críticas. As competências e os temas analisados incluem Consciência Social, Relacionamento, Segurança, Autoconsciência, Autogestão, Tomada de Decisão Responsável e Anti-Bullying. Esse indicador reflete a probabilidade de um estudante enfrentar dificuldades acadêmicas e emocionais que podem levá-lo à reprovação.',
    performanceInfo:
      'Indicador que está associado ao rendimento de um estudante em um ambiente educacional, com base em sua capacidade de atender aos padrões e expectativas estabelecidos para o aprendizado e o sucesso escolar. As competências e os temas analisados incluem Consciência Social, Relacionamento, Segurança, Autoconsciência, Autogestão, Tomada de Decisão Responsável e a capacidade de combater o bullying.',
    absenteeismInfo:
      'Mede o risco de ausências frequentes não justificadas. As competências e os temas analisados neste indicador são:  Autogestão, Autoconsciência, Tomada de Decisão Responsável, Segurança e Anti-Bullying.',
    conflictsInfo:
      'Avalia o risco de conflitos e violência no ambiente escolar baseado nas reflexões e descobertas dos próprios estudantes e educadores. Reflete a probabilidade de incidentes prejudiciais ao bem-estar e à segurança de estudantes e educadores. Os temas analisados neste indicador são: Segurança e Anti-Bullying.',
    participationInfo:
      'Indicador que avalia o envolvimento dos estudantes em atividades fora do horário escolar. Essas atividades, que incluem clubes escolares e grupos de interesse, promovem o aprendizado além da sala de aula e o desenvolvimento de habilidades socioemocionais. O indicador é composto por competências como Autoconsciência, Relacionamento, Autogestão,Tomada de Decisão Responsável e Consciência Social e o tema Segurança.',
    familySupportInfo:
      'Apoio Familiar é um indicador que avalia o envolvimento da família no processo educacional do estudante. O apoio da família é essencial para o desenvolvimento socioemocional do estudante e para a criação de um ambiente seguro e protegido. As competências avaliadas neste indicador incluem Relacionamento, Consciência Social e o tema Segurança.',
    socioeconomicLevelInfo:
      'Nível Socioeconômico é um indicador que avalia o nível socioeconômico dos estudantes no contexto escolar, familiar e social. Este indicador é construído com base no nível de escolaridade e renda familiar dos estudantes. A competência avaliada neste indicador é a Consciência Social e o tema Segurança.',
    acessibilityInfo:
      'Inclusão e Acessibilidade é um indicador que avalia a inclusão e o atendimento de estudantes com  demandas de acessibilidade em um ambiente escolar. Este indicador é composto por competências como Consciência Social e Relacionamento e o tema Segurança.',
    mentalHealthInfo:
      'Saúde Mental é um indicador que avalia o bem-estar emocional e psicológico dos estudantes e educadores no ambiente escolar. As competências e os temas analisados incluem Autogestão, Autoconsciência, Tomada de Decisão Responsável, Segurança e Anti-Bullying.',
    accessToTechInfo:
      'O indicador de “Acesso à Tecnologia” reflete a disponibilidade e uso de ferramentas tecnológicas pelos estudantes. Um baixo indicador pode sinalizar dificuldades no acesso à tecnologia, impactando o processo de ensino-aprendizagem. As competências analisadas incluem Tomada de Decisão Responsável, Relacionamento e Autogestão.',
    Games: 'Jogos',
    students: 'Estudantes',
    users: 'Usuários',
    customQuestion: 'Perguntas customizadas',
    tenant: 'Instituição',
    report: 'Relatório',
    collaborators: 'Colaboradores',
    collaborator: 'Colaboradores',
    class: 'Turmas',
    profile: 'Perfil',
    dashboard: 'Página inicial',
    activity: 'Atividade',
    indicators: 'Indicadores',
    settings: 'Configurações',
    create: 'Criar',
    edit: 'Editar Próprias',
    view: 'Visualizar Próprias',
    delete: 'Deletar',
    activate: 'Ativar/Desativar',
    viewall: 'Visualizar Todas',
    editall: 'Editar Todas',
    changePassword:'Alterar Senha',
    adapt:'Adaptar',
    import: 'Importar',
    practices:'Inspirações pedagógicas',
    Schools: 'Escolas',
    school: 'Escola',
    metrics: 'Métricas',
    games: 'Jogos',
    contacts: 'Contatos',
    competence: 'Competências',
    helpers: 'Ajudas',
    questions: 'Perguntas',
    locations: 'Traduções',
    home: 'Página inicial',
    org: 'Organização',
    safeSpace: 'Meu cantinho',
    selfAvaliation: 'Avaliação Inicial',
    avaliation: 'Avaliação',
    studentDetail:'Detalhes do estudante',
    symptoms:'Sintomas',
    macro:'Visão Macro',
    student:'Visão Estudante',
    geographic:'Visão Geográfica',
    viewName:'Ver nome',
    Comprehension: 'Conhecimento',
    'Spontaneous Decision': 'Comportamento',
    'Third Party': 'Percepção',
    serious: 'Grave',
    moderate: 'Moderado',
    light: 'Leve',
    no_manifest: 'Não manifesto',
    selfDeprecation: 'Auto-depreciação',
    somaticConcerns: 'Preocupações somáticas',
    changeInSelfImage: 'Mudança na auto-imagem',
    pessimism: 'Pessimismo',
    suicidalThoughtsOrDesires:'Pensamentos ou desejos suicidas',
    lossOfPleasure: 'Perda de prazer',
    guilt: 'Sentimento de culpa',
    sadness: 'Tristeza	473',
    difficultyWorking: 'Dificuldade de trabalhar',
    difficultyConcentrating: 'Dificuldade de concentração',
    irritability: 'Irritabilidade',
    pastFailures: 'Fracassos passados',
    feelingOfUselessness: 'Sentimento de inutilidade',
    agitation: 'Agitação',
    indecision: 'Indecisão',
    weightLoss: 'Perda de peso',
    tirednessOrFatigue: 'Cansaço ou fadiga',
    lossOfSexualInterest: 'Perda de interesse sexual',
    changeInSleepPattern: 'Alteração no padrão de sono',
    cry: 'Choro',
    selfCriticism: 'Auto-criticismo',
    changeInAppetite: 'Alteração no apetite',
    feelingOfPunishment: 'Sentimento de punição',
    lossOfInterest: 'Perda de interesse',
    lossOfenergy: 'Perda de energia',
    'In person':'Presencial',
    Remote:'Remoto',
    Hybrid:'Híbrido',
    Multiple:'Múltiplos Contextos',
    Familiar:'Familiar',
    School:'Escolar',
    Digital:'Digital',
    Social:'Social',
    posts:'Publicações',
    emotion:'Emocionômetro',
    icon:'Ícone',
    background:'Fundo',
    frame:'Moldura',
    activities:'Atividades',
    b2c:'B2C',
    groups:'Grupos',
    
  },
});
