import { useMediaQuery } from '@chakra-ui/react';
import { Col, Flex, Row } from 'antd';
import { GenericCard } from 'components/V2/generic-card-styled-component';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import useSafeSpaceAvaliations from 'core/features/safeSpace/hooks/useAvaliations';
import { CiHeart } from 'react-icons/ci';
import { LiaBombSolid } from 'react-icons/lia';
import { PiWarningDiamond } from 'react-icons/pi';
import moment from 'moment';
import { MdExpandMore } from 'react-icons/md';
import { Link } from 'react-router-dom';
import BrokenHeartIcon from 'components/icons/BrokenHeartIcon';
import EyesIcon from 'components/icons/EyesIcon';

function SafeSpaceAvaliations() {
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const { colorPrimary, colorGreyBorderAndFont } = useU4heroColors();
  const { data } = useSafeSpaceAvaliations({
    initialDate: moment().subtract(15, 'days').format('YYYY-MM-DD'),
  });
  const totalCount = data?.classifications.reduce(
    (total, item) => total + item.count,
    0
  );

  return (
    <GenericCard padding="8px" id='id-tour-section-safespace-avaliations'>
      <Flex vertical style={{ width: '100%' }}>
        <Flex vertical style={{ width: '100%' }}>
          <p
            style={{
              color: colorPrimary,
              fontSize: isMobile ? '18px' : '24px',
              fontWeight: 700,
            }}
          >
            Registros do meu cantinho
          </p>
          {totalCount !== 0 && (
            <p
              style={{
                color: colorGreyBorderAndFont,
                fontSize: isMobile ? '12px' : '14px',
                fontWeight: 500,
                alignSelf: 'end',
              }}
            >
              {data?.period}
            </p>
          )}
        </Flex>
        {totalCount === 0 ? (
          <Flex vertical align="center" justify="center" gap="16px">
            <BrokenHeartIcon color="#7B7B7B" size={60} />
            <p
              style={{
                fontSize: '14px',
                color: '#7B7B7B',
                textAlign: 'center',
                fontWeight: 400,
                maxWidth: isMobile ? '' : '267px',
              }}
            >
              Não há nenhum registro no meu cantinho, não há informações para
              serem exibidas!
            </p>
          </Flex>
        ) : (
          <Row>
            <Col xs={12} sm={6}>
              <Link to="/safe-space?flag=1" id='id-tour-link-to-safespace-filter-flag-1'>
                <Flex vertical gap={10} align="center">
                  <p
                    style={{
                      color: 'black',
                      fontSize: isMobile ? '14px' : '18px',
                      fontWeight: 400,
                      textAlign: 'center',
                      maxWidth: '111px',
                    }}
                  >
                    Coração Quentinho
                  </p>
                  <CiHeart color="black" size={isMobile ? '34px' : '30px'} />
                  <p
                    style={{
                      color: 'black',
                      fontSize: isMobile ? '18px' : '22px',
                      fontWeight: 700,
                    }}
                  >
                    {
                      data?.classifications.find(
                        (item: any) =>
                          item.safeSpaceClassification === '1'
                      )?.count
                    }
                  </p>
                </Flex>
              </Link>
            </Col>
            <Col xs={12} sm={6}>
              <Link to="/safe-space?flag=2" id='id-tour-link-to-safespace-filter-flag-2'>
                <Flex vertical gap={10} align="center">
                  <p
                    style={{
                      color: 'black',
                      fontSize: isMobile ? '14px' : '18px',
                      fontWeight: 400,
                      textAlign: 'center',
                      maxWidth: '111px',
                    }}
                  >
                    É bom ficar de olho
                  </p>
                  <EyesIcon size={isMobile ? 34 : 30} />
                  <p
                    style={{
                      color: 'black',
                      fontSize: isMobile ? '18px' : '22px',
                      fontWeight: 700,
                    }}
                  >
                    {
                      data?.classifications.find(
                        (item: any) =>
                          item.safeSpaceClassification === '2'
                      )?.count
                    }
                  </p>
                </Flex>
              </Link>
            </Col>
            <Col xs={12} sm={6} id='id-tour-link-to-safespace-filter-flag-3'>
              <Link to="/safe-space?flag=3">
                <Flex vertical gap={10} align="center">
                  <p
                    style={{
                      color: 'black',
                      fontSize: isMobile ? '14px' : '18px',
                      fontWeight: 400,
                      textAlign: 'center',
                      maxWidth: '111px',
                    }}
                  >
                    Redobre a atenção
                  </p>
                  <PiWarningDiamond
                    color="black"
                    size={isMobile ? '34px' : '30px'}
                  />
                  <p
                    style={{
                      color: 'black',
                      fontSize: isMobile ? '18px' : '22px',
                      fontWeight: 700,
                    }}
                  >
                    {
                      data?.classifications.find(
                        (item: any) =>
                          item.safeSpaceClassification === '3'
                      )?.count
                    }
                  </p>
                </Flex>
              </Link>
            </Col>
            <Col xs={12} sm={6}>
              <Link to="/safe-space?flag=4" id='id-tour-link-to-safespace-filter-flag-4'>
                <Flex vertical gap={10} align="center">
                  <p
                    style={{
                      color: 'black',
                      fontSize: isMobile ? '14px' : '18px',
                      fontWeight: 400,
                      textAlign: 'center',
                      maxWidth: '100px',
                    }}
                  >
                    Problema Sério
                  </p>
                  <LiaBombSolid
                    color="black"
                    size={isMobile ? '34px' : '30px'}
                  />
                  <p
                    style={{
                      color: 'black',
                      fontSize: isMobile ? '18px' : '22px',
                      fontWeight: 700,
                    }}
                  >
                    {
                      data?.classifications.find(
                        (item: any) =>
                          item.safeSpaceClassification === '4'
                      )?.count
                    }
                  </p>
                </Flex>
              </Link>
            </Col>
          </Row>
        )}

        {totalCount !== 0 && (
          <Link to="/safe-space" id='id-tour-link-to-safespace'>
            <Flex justify="center" align="center">
              <p
                style={{
                  color: colorGreyBorderAndFont,
                  fontSize: isMobile ? '12px' : '14px',
                  fontWeight: 500,
                }}
              >
                Veja versão completa
              </p>
              <MdExpandMore
                color={colorGreyBorderAndFont}
                style={{
                  transform: 'rotate(270deg)',
                }}
                size={20}
              />
            </Flex>
          </Link>
        )}
      </Flex>
    </GenericCard>
  );
}

export default SafeSpaceAvaliations;
