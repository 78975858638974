import { useMediaQuery } from '@chakra-ui/react';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import Text from 'components/typography/text';

function InstitutionHeader({image,tenantName}:{image?:string,tenantName?:string}) {
  const { colorPrimary } = useU4heroColors();
  const [isMobile] = useMediaQuery('(max-width: 768px)');

  return (
    <div
      style={{
        backgroundColor: colorPrimary,
        backgroundImage: "url('/estrelas_1.png')",
        fontFamily: 'Poppins',
        padding: isMobile ? '16px 10px' : '16px',
        height: isMobile ? '90px' : '74px',
        borderRadius: '20px',
        display: 'flex',
        alignItems:'center',
        width: '100%',
        fontWeight: 400,
        marginBottom: isMobile ? '18px' : '24px',
        marginTop:isMobile ? '20px' : '16px',
        position:'relative'
      }}
    >
      <Text size="lg" color='#FFFFFF' style={{maxWidth:isMobile ? '70%':undefined}}>
        Instituição : {tenantName}
      </Text>
      <img src={image} style={{position:'absolute',bottom:0,right:0,maxWidth:isMobile ? '100px':'152px'}}/>
    </div>
  );
}

export default InstitutionHeader;
