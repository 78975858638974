import Layout from "components/layout";
import {  Heading } from "@chakra-ui/react";
import { NumberParam, useQueryParam } from "use-query-params";
import TabHeader from "./components/TabHeader";
import TabAluno from "./components/TabAluno";
import TabAtividade from "./components/TabAtividade";
import TabTurma from "./components/TabTuma";


export interface ReportMainSteps {
  label: string;
  order: number;
  id:string;
}

export const ReportSteps: ReportMainSteps[] = [
  {
    label: "Turma",
    order: 0,
    id:'id-tour-report-header-tab-class'
  },
  {
    label: "Estudante",
    order: 1,
    id:'id-tour-report-header-tab-student'
  },
  {
    label: "Atividade",
    order: 2,
    id:'id-tour-report-header-tab-activity'
  },
]

function ReportsPage() {
  const [tab, setTab] = useQueryParam("tab", NumberParam);

  if (!tab && tab !== 0) {
    setTab(1);
  }

  return (
    <Layout.Container>
      <Heading mb="32px" fontSize="2xl">Relatórios</Heading>

      <TabHeader
        step={tab!}
        stepsLabel={ReportSteps}
        onChange={(v) => setTab(v)}
      />

      {tab === 0 ? <TabTurma /> : null}
      {tab === 1 ? <TabAluno /> : null}
      {tab === 2 ? <TabAtividade /> : null}
    </Layout.Container>
  );
}

export default ReportsPage;