import { useMediaQuery } from '@chakra-ui/react';
import { DatePicker, Form, Radio, Space } from 'antd';

function TimeInput({
  index,
  updateForm,
}: {
  index: number;
  updateForm: (
    fieldName: string | number | (string | number)[],
    value: any
  ) => void;
}) {
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const form = Form.useFormInstance();
  const graph = Form.useWatch(['graphs', index], form);
  if (graph?.graphView !== 'time') {
    return null;
  }
  return (
    <>
      <Form.Item
        rules={[
          {
            required: true,
            message: 'Por favor,  defina o periodo!',
          },
        ]}
        name={[index, 'timePeriod']}
        label="Período"
      >
        <Radio.Group id="id-tour-my-indicators-custom-panel-time-input">
          <Space direction={isMobile ? 'vertical' : 'horizontal'}>
            <Radio value={'month'}>Mês</Radio>
            <Radio value={'bimester'}>Bimestre</Radio>
            <Radio value={'trimester'}>Trimestre</Radio>
            <Radio value={'semester'}>Semestre</Radio>
          </Space>
        </Radio.Group>
      </Form.Item>
    </>
  );
}

export default TimeInput;
