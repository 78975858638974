import { Flex, Radio, Collapse, Space, Form } from 'antd';
import SvgIcon from 'components/SvgIcon';
import { TagType } from 'core/features/competence/typings';
import useTagsWithoutParent from 'core/features/tags/hooks/useTagsWithoutParent';
import { hex2rgba } from 'infra/helpers/hex2Rgba';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import { useState } from 'react';

function PickTag({
  value,
  onChange,
  setHasTwoCompetences,
  isEditing,
}: {
  value: { id: string; name: string };
  onChange: (v: { id: string; name: string }) => void;
  setHasTwoCompetences: (v: boolean) => void;
  isEditing: boolean;
}) {
  const [selected, setSelected] = useState<string>();
  const { colorPrimary } = useU4heroColors();
  const form = Form.useFormInstance();
  const competence = Form.useWatch('competence', form);
  const tagType = Form.useWatch('tagType', form);

  const { data } = useTagsWithoutParent({
    type: tagType,
    disabled: tagType === undefined,
  });

  const combined = [
    {
      label1: `Antibullying`,
      label2: `Segurança educacional`,
      value: 'combination 1',
      combination: true,
      id1: data?.find((item) => item.name === 'Antibullying')?.id,
      icon1: data?.find((item) => item.name === 'Antibullying')?.icon,
      id2: data?.find((item) => item.name === 'Segurança educacional')?.id,
      icon2: data?.find((item) => item.name === 'Segurança educacional')?.icon,
    },
    {
      label1: `Autogestão`,
      label2: `Autoconsciência`,
      value: 'combination 2',
      combination: true,
      id1: data?.find((item) => item.name === 'Autogestão')?.id,
      icon1: data?.find((item) => item.name === 'Autogestão')?.icon,
      id2: data?.find((item) => item.name === 'Autoconsciência')?.id,
      icon2: data?.find((item) => item.name === 'Autoconsciência')?.icon,
    },
    {
      label1: `Consciência social`,
      label2: `Relacionamento`,
      value: 'combination 3',
      combination: true,
      id1: data?.find((item) => item.name === 'Consciência social')?.id,
      icon1: data?.find((item) => item.name === 'Consciência social')?.icon,
      id2: data?.find((item) => item.name === 'Relacionamento')?.id,
      icon2: data?.find((item) => item.name === 'Relacionamento')?.icon,
    },
  ];

  const hasTwoCompentences = data?.find((item) => item.id === value?.id);
  const defaultActiveKey = hasTwoCompentences
    ? combined.find(
        (combination) =>
          combination.id2 === value?.id || combination.id1 === value?.id
      )?.value
    : competence?.id;

  return (
    <Flex vertical gap="8px" style={{ width: '100%' }}>
      {tagType === TagType.Competências &&
        combined?.map((combination) =>
          defaultActiveKey || !isEditing ? (
            <Collapse
              expandIconPosition="end"
              key={combination.value}
              defaultActiveKey={defaultActiveKey}
              style={{ width: '100%' }}
            >
              <Collapse.Panel
                header={
                  <Flex
                    vertical
                    gap="12px"
                    onClick={() => {
                      onChange({
                        id: combination?.id2 as string,
                        name: combination?.label2 as string,
                      });
                      form.setFieldValue('competence', {
                        id: combination.id1 as string,
                        name: combination.label1 as string,
                      });
                      form.setFieldValue('typeId', 1);
                      setSelected(combination.value);
                      setHasTwoCompetences(true);
                    }}
                    style={{ width: '100%' }}
                  >
                    <Flex gap="8px" align="center">
                      {combination.icon1 ? (
                        <SvgIcon
                          url={combination.icon1}
                          color="black"
                          size={28}
                        />
                      ) : null}
                      <p
                        style={{
                          fontSize: '14px',
                          color: 'black',
                          fontWeight: 400,
                        }}
                      >
                        {combination.label1}
                      </p>
                    </Flex>
                    <Flex gap="8px" align="center">
                      {combination.icon2 ? (
                        <SvgIcon
                          url={combination.icon2}
                          color="black"
                          size={28}
                        />
                      ) : null}
                      <p
                        style={{
                          fontSize: '14px',
                          color: 'black',
                          fontWeight: 400,
                        }}
                      >
                        {combination.label2}
                      </p>
                    </Flex>
                  </Flex>
                }
                style={{
                  background:
                    selected === combination.value ||
                    defaultActiveKey === combination.value
                      ? hex2rgba(colorPrimary, 0.5)
                      : undefined,
                }}
                key={combination.value}
              >
                <Flex style={{ padding: '8px' }}>
                  <p style={{ fontSize: '12px' }}>
                    Você já selecionou duas competências, não é necessário
                    selecionar uma habilidade!
                  </p>
                </Flex>
              </Collapse.Panel>
            </Collapse>
          ) : null
        )}
      {data?.map((item) =>
        defaultActiveKey || !isEditing ? (
          <Collapse
            defaultActiveKey={defaultActiveKey}
            style={{ width: '100%' }}
            items={[
              {
                key: item.id,
                label: (
                  <Flex gap="8px" align="center">
                    {item.icon ? (
                      <SvgIcon url={item.icon} color="black" size={28} />
                    ) : null}
                    <p
                      style={{
                        fontSize: '14px',
                        color: 'black',
                        fontWeight: 400,
                      }}
                    >
                      {item.name}
                    </p>
                  </Flex>
                ),
                children: (
                  <Radio.Group
                    id="id-tour-question-creation-input-skill"
                    style={{ width: '100%', marginTop: '5px' }}
                    onChange={(e) => {
                      const skill = item.children.find(
                        (item) => item.id === e.target.value
                      );
                      onChange({
                        id: skill?.id as string,
                        name: skill?.name as string,
                      });
                      form.setFieldValue('competence', {
                        id: item.id,
                        name: item.name,
                      });
                      form.setFieldValue('typeId', 0);
                      setSelected('');
                      setHasTwoCompetences(false);
                    }}
                    value={value?.id}
                  >
                    <Space direction="vertical" style={{ width: '100%' }}>
                      {item.children.map((child, index) => (
                        <Radio
                          style={{ paddingLeft: '8px', width: '100%' }}
                          value={child.id}
                          key={child.id}
                        >
                          <Flex gap="8px" align="center">
                            {child.icon ? (
                              <SvgIcon
                                url={child.icon}
                                color="black"
                                size={28}
                              />
                            ) : null}

                            {child.name}
                          </Flex>
                        </Radio>
                      ))}
                    </Space>
                  </Radio.Group>
                ),
              },
            ]}
            expandIconPosition="end"
          />
        ) : null
      )}
    </Flex>
  );
}

export default PickTag;
