import moment from 'moment';
import { SafeSpaceItem } from 'core/features/safeSpace/typings';
import { Flex } from 'antd';
import DynamicTable from 'components/V2/dinamic-table';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import { useMediaQuery } from '@chakra-ui/react';
import useSafespaces from 'core/features/safeSpace/hooks/useSafeSpaces';
import { useEffect, useState } from 'react';
import { MdExpandLess, MdExpandMore } from 'react-icons/md';
import Classification from '../Classification';
import Status from 'components/status';
import Dot from 'components/dot';
import SafeSpaceFilter from '../SafeSpaceFilter';
import { useHistory, useLocation } from 'react-router-dom';
import Tag from 'components/V2/tag';
import { useToggleMessage } from 'core/features/safeSpace/hooks/useToggleMessage';
import { FilterType, safeSpaceFlags } from 'infra/helpers/types';

function SafeSpaceTable() {
  const { colorPrimary } = useU4heroColors();
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState('');
  const history = useHistory();
  const { mutate: handleToggle } = useToggleMessage();
  const [filters, setFilters] = useState<FilterType>({
    types: [],
    series: [],
    classes: [],
    flags: [],
    status: [],
  });
  const { data, isLoading, isFetching } = useSafespaces({
    page,
    search,
    series: filters?.series?.map((item) => item.value) ?? undefined,
    classes: filters?.classes?.map((item) => item.value) ?? undefined,
    anonymous: filters?.types.map((item) => item.value).includes('anonymous'),
    disableConcat: true,
    flags: filters?.flags?.map((item) => item.value) ?? undefined,
    status: filters?.status?.map((item) => item.value) ?? undefined,
    unseen: filters?.types.map((item) => item.value).includes('unseen'),
    seen: filters?.types.map((item) => item.value).includes('seen'),
    limit: pageSize,
  });
  const isEachLoading = isLoading || isFetching;

  const tableHeaderStyle = {
    fontSize: isMobile ? '14px' : '18px',
    fontWeight: 400,
  };
  const tableRowStyle = {
    fontSize: isMobile ? '14px' : '16px',
    fontWeight: 400,
  };

  const columns = [
    {
      title: <p style={tableHeaderStyle}>Nome</p>,
      name: 'Nome',
      dataIndex: 'name',
      key: 'name',
      mobile: true,
      render: (name: string, record: SafeSpaceItem) => (
        <Flex gap={8} align="center" style={tableRowStyle}>
          {!record.viewed ? <Dot color={colorPrimary} size={8} /> : null}{' '}
          {record.anonymous ? 'Anônimo' : record.name}
        </Flex>
      ),
      sorter: (a: any, b: any) => {
        const nameA = a?.name || 'Anônimo';
        const nameB = b?.name || 'Anônimo';
        return nameA.localeCompare(nameB);
      },
      sortIcon: (sortOrder: any) =>
        sortOrder.sortOrder === 'descend' ? (
          <MdExpandLess size={25} />
        ) : (
          <MdExpandMore size={25} />
        ),
    },
    {
      title: <p style={tableHeaderStyle}>Classificação</p>,
      name: 'Classificação',
      dataIndex: 'classification',
      key: 'classification',
      mobile: true,
      render: (classification: string, record: SafeSpaceItem) => (
        <div>
          <p style={{ fontSize: '12px' }}>
            {record.teacherClassification !== 0 ? 'Educador' : 'Automática'}
          </p>
          {record.teacherClassification !== 0 ? (
            <Classification
              size={isMobile ? 'sm' : 'md'}
              type={record.teacherClassification}
            />
          ) : (
            <Classification
              size={isMobile ? 'sm' : 'md'}
              type={record.systemClassification}
            />
          )}
        </div>
      ),
    },
    {
      title: <p style={tableHeaderStyle}>Data</p>,
      name: 'Data',
      dataIndex: 'date',
      key: 'date',
      render: (date: string, record: SafeSpaceItem) => (
        <div style={tableRowStyle}>
          {moment.utc(record.date).format('DD/MM/YYYY')}
        </div>
      ),
      sorter: (a: any, b: any) => a.date.localeCompare(b.date),
      sortIcon: (sortOrder: any) =>
        sortOrder.sortOrder === 'descend' ? (
          <MdExpandLess size={25} />
        ) : (
          <MdExpandMore size={25} />
        ),
    },
    {
      title: <p style={tableHeaderStyle}>Série/Turma</p>,
      name: 'Série/Turma',
      dataIndex: 'class.name',
      key: 'class.name',
      render: (_: string, record: SafeSpaceItem) => (
        <div style={tableRowStyle}>
          <p>{record.class.name}</p>
          <Tag text={record.class.serie.name} />
        </div>
      ),
      sorter: (a: any, b: any) => {
        const nameA = a?.class.name || '';
        const nameB = b?.class.name || '';
        return nameA.localeCompare(nameB);
      },
      sortIcon: (sortOrder: any) =>
        sortOrder.sortOrder === 'descend' ? (
          <MdExpandLess size={25} />
        ) : (
          <MdExpandMore size={25} />
        ),
    },
    {
      title: <p style={tableHeaderStyle}>Status</p>,
      name: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (_: string, record: SafeSpaceItem) => (
        <Status
          size={isMobile ? 'sm' : 'md'}
          type={
            record.status === 0
              ? 'notStarted'
              : record.status === 1
              ? 'progress'
              : 'done'
          }
        />
      ),
      sorter: (a: any, b: any) => b.status - a.status,
      sortIcon: (sortOrder: any) =>
        sortOrder.sortOrder === 'descend' ? (
          <MdExpandLess size={25} />
        ) : (
          <MdExpandMore size={25} />
        ),
    },
  ];

  let location = useLocation();
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    if (location.search.includes('flag')) {
      const flag = queryParams.get('flag');
      const safeSpaceFlag = safeSpaceFlags.find(
        (item) => item.value === Number(flag)
      );
      if (safeSpaceFlag) {
        setFilters({
          ...filters,
          flags: [{ label: safeSpaceFlag?.label, value: safeSpaceFlag?.value }],
        });
      }
    }

    if (location.search.includes('classId')) {
      const id = queryParams.get('classId');
      if (id) {
        setFilters({
          ...filters,
          classes: [{ label: 'Turma', value: id }],
        });
      }
    }
    if (location.search.includes('search')) {
      const query = queryParams.get('search');
      if (query !== null) {
        setSearch(query);
      }
    }
  }, [location]);

  useEffect(() => {
    setPage(1);
  }, [search, filters]);

  return (
    <DynamicTable
      id="id-tour-safe-space-section-table"
      itemName="registro"
      data={data?.data ?? []}
      onChangePage={setPage}
      onChangePageSize={setPageSize}
      title=""
      total={data?.total ?? 0}
      page={page}
      pageSize={pageSize}
      isLoading={isEachLoading}
      onClickRow={(row) => {
        handleToggle([row.id]);
        history.push(`/safe-space/${row.id}`);
      }}
      defaultColumns={
        isMobile ? columns.filter((item) => item.mobile) : columns
      }
      allColumns={columns}
      onSearch={setSearch}
      search={search}
      appliedFilters={filters}
      onChangeFilters={setFilters}
      filters={
        <SafeSpaceFilter onChangeFilters={setFilters} filters={filters} />
      }
    />
  );
}

export default SafeSpaceTable;
