import React from 'react';

interface DotProps extends React.HTMLAttributes<HTMLDivElement> {
  color: string;
  size?: number;
}

const Dot: React.FC<DotProps> = ({ color, size = 8, ...props }) => {
  return (
    <div
      style={{
        width: size,
        height: size,
        borderRadius: '50%',
        background: color,
      }}
      {...props}
    ></div>
  );
}

export default Dot;
