import InstitutionIcon from 'assets/images/icons/InstitutionIcon';
import useTotalSchools from 'core/features/indicators/hooks/useTotalSchools';
import StatCard from '../statCard';

function TotalSchools() {
  const { data } = useTotalSchools();
  return (
    <StatCard
      title="Escola"
      total={data?.total ?? 0}
      Icon={InstitutionIcon}
    />
  );
}

export default TotalSchools;
