import { useMediaQuery } from '@chakra-ui/media-query';
import { Flex, Modal } from 'antd';
import Button from 'components/buttons/button';
import SubTitle from 'components/typography/subTitle';
import Text from 'components/typography/text';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import { MdExpandMore } from 'react-icons/md';
import stars from 'assets/images/estrelas_modal.png';
import cosmoWithTrophy from 'assets/images/cosmo-with-trophy.png';
import congrats from 'assets/images/congrats.png';

function ModalCompleteInitialTour({ onClose }: { onClose: () => void }) {
  const { colorPrimary, colorGreyBorderAndFont } = useU4heroColors();
  const [isMobile] = useMediaQuery('(max-width: 768px)');

  return (
    <>
      <Modal
        centered
        maskClosable={false}
        open={true}
        footer={null}
        closeIcon={null}
        wrapClassName="custom-modal-initial-tour"
      >
        <div
          style={{ height: '500px', overflow: 'hidden', position: 'relative' }}
        >
          <img src={stars} style={{ backgroundColor: colorPrimary }} />
          <div
            style={{
              width: '100%',
              height: '400px',
              borderRadius: '50% 50% 50% 49% / 42% 43% 0% 0%',
              background: 'white',
              marginTop: '-160px',
            }}
          ></div>

          <Flex
          vertical
            justify="center"
            align="center"
            style={{
              position: 'absolute',
              top: 0,
              zIndex: 200,
              height: '100%',
              width: '100%',
            }}
          >
            <img src={cosmoWithTrophy} />
            <img src={congrats} />
          </Flex>
        </div>
        <Flex vertical style={{ padding: '0px 16px 16px 16px' }}>
          <Text size="lg">
            Você explorou todos os recursos da nossa plataforma! Estamos felizes
            por tê-lo(a) conosco. Agora que conhece todas as funcionalidades,
            aproveite ao máximo da sua jornada profissional
          </Text>
          <Flex
            vertical
            justify="center"
            align="center"
            gap="8px"
            style={{ marginTop: '40px' }}
          >
            <Button size="md" onClick={onClose}>
              CONCLUIR
            </Button>
          </Flex>
        </Flex>
      </Modal>
    </>
  );
}

export default ModalCompleteInitialTour;
