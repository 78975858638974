import SelectBase, { SelectBaseProps } from "../select-base";
import useClasses from "core/features/classes/hooks/useClasses";
import { useState } from "react";

export interface SelectClassProps<T = any> extends SelectBaseProps<T> {
  value: any;
  serieId?: any;
  tenantId?:string | undefined;
  onChange: (v: any) => void;
}

function SelectClass({ value, onChange, serieId,tenantId, ...rest }: SelectClassProps) {
  const [search, setSearch] = useState<string>("");

  const { isLoading, isFetching, data } = useClasses({
    series: serieId ? [serieId] : undefined,
    search,
    disableConcat: true,
    page: 1,
    tenantId:tenantId
  });

  return (
    <SelectBase
      loading={isLoading || isFetching}
      placeholder="Selecione a turma"
      value={value}
      onChange={(ev) => {
        onChange(ev);
      }}
      searchValue={search}
      onSearch={(v) => setSearch(v)}
      options={
        data?.data?.map((item) => ({
          label: item.name,
          value: item.id,
        })) ?? []
      }
      {...rest}
    />
  );
}

export default SelectClass;
