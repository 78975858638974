import GenericFilterComponent from 'components/generic-filter';
import { FilterProps } from 'infra/helpers/types';

function FeedbackFilter({ filters, onChangeFilters }: FilterProps) {
    const ratings = [
        {
          label: 'Muito Ruim',
          value: 1,
        },
        {
          label: 'Ruim',
          value: 2,
        },
        {
          label: 'Regular',
          value: 3,
        },
        {
          label: 'Bom',
          value: 4,
        },
        {
          label: 'Muito Bom',
          value: 5,
        },
      ];

  return (
    <GenericFilterComponent
      filters={filters}
      onChangeFilters={onChangeFilters}
      filterGroups={[
        {
          title: 'Avaliação',
          filterName: 'ratings',
          type:'number',
          options: ratings?.map((item) => {
            return { label: item.label, value: item.value };
          }),
        },
        {
          title: 'A partir de',
          filterName: 'initialDate',
          options: [],
          type: 'date',
          open: true,
        },
      ]}
    />
  );
}

export default FeedbackFilter;
