export const Permissions = {
  Tenant: {
    View: 'tenant.view',
    Activate: 'tenant.activate',
    Delete: 'tenant.delete',
    Create: 'tenant.create',
    Edit: 'tenant.edit',
  },
  Students: {
    View: 'students.view',
    Activate: 'students.activate',
    Create: 'students.create',
    Edit: 'students.edit',
    Import: 'students.import',
  },
  Class: {
    View: 'class.view',
    Delete: 'class.delete',
    Create: 'class.create',
    Edit: 'class.edit',
  },
  Games: {
    View: 'games.view',
  },
  Collaborators: {
    View: 'collaborators.view',
    Activate: 'collaborators.activate',
    Create: 'collaborators.create',
    Edit: 'collaborators.edit',
    Import: 'collaborators.import',
  },
  Activity: {
    View: 'activity.view',
    Delete: 'activity.delete',
    Create: 'activity.create',
    Edit: 'activity.edit',
    EditAll: 'activity.editall',
    ViewAll: 'activity.viewall',
  },
  Practices: {
    View: 'practices.view',
    Adapt: 'practices.adapt',
  },
  Posts: {
    View: 'posts.view',
  },
  SelfAvaliation: {
    View: 'selfAvaliation.view',
    Delete: 'selfAvaliation.delete',
    Create: 'selfAvaliation.create',
    Edit: 'selfAvaliation.edit',
    EditAll: 'selfAvaliation.editall',
  },
  Avaliation: {
    View: 'avaliation.view',
    Delete: 'avaliation.delete',
    Create: 'avaliation.create',
    Edit: 'avaliation.edit',
    EditAll: 'avaliation.editall',
  },
  Indicators: {
    View: 'indicators.view',
    ViewAll: 'indicators.viewall',
  },
  Report: {
    View: 'report.view',
  },
  SafeSpace: {
    View: 'safeSpace.view',
    Edit: 'safeSpace.edit',
  },
  CustomQuestion: {
    View: 'customQuestion.view',
    ViewAll: 'customQuestion.viewall',
    Activate: 'customQuestion.activate',
    Create: 'customQuestion.create',
    Edit: 'customQuestion.edit',
    EditAll: 'customQuestion.editall',
  },
  Profile: {
    View: 'profile.view',
    Delete: 'profile.delete',
    Create: 'profile.create',
    Edit: 'profile.edit',
  },
  U4hero: {
    Metrics: {
      View: 'u4hero.metrics.view',
    },
    Questions: {
      View: 'u4hero.questions.view',
      Create: 'u4hero.questions.create',
      Edit: 'u4hero.questions.edit',
      Activate: 'u4hero.questions.activate',
    },
    Collaborators: {
      View: 'u4hero.collaborators.view',
      Activate: 'u4hero.collaborators.activate',
      Create: 'u4hero.collaborators.create',
      Edit: 'u4hero.collaborators.edit',
      Import: 'u4hero.collaborators.import',
      ChangePassword: 'u4hero.collaborators.changepassword',
    },
    Class: {
      View: 'u4hero.class.view',
      Activate: 'u4hero.class.activate',
      Delete: 'u4hero.class.delete',
      Create: 'u4hero.class.create',
      Edit: 'u4hero.class.edit',
    },
    Games: {
      View: 'u4hero.games.view',
      Delete: 'u4hero.games.delete',
      Create: 'u4hero.games.create',
      Edit: 'u4hero.games.edit',
    },
    Home: {
      View: 'u4hero.home.view',
    },
    Students: {
      ViewAll: 'u4hero.students.viewall',
      Activate: 'u4hero.students.activate',
      Create: 'u4hero.students.create',
      Edit: 'u4hero.students.edit',
      Import: 'u4hero.students.import',
    },
    Helpers: {
      View: 'u4hero.helpers.view',
      Create: 'u4hero.helpers.create',
      Edit: 'u4hero.helpers.edit',
    },
    Practices: {
      View: 'u4hero.practices.view',
      Create: 'u4hero.practices.create',
      Edit: 'u4hero.practices.edit',
      Delete: 'u4hero.practices.delete',
      Adapt: 'u4hero.practices.adapt',
    },
    Posts: {
      View: 'u4hero.posts.view',
      Create: 'u4hero.posts.create',
      Edit: 'u4hero.posts.edit',
      Delete: 'u4hero.posts.delete',
    },
    Competences: {
      View: 'u4hero.competence.view',
      Create: 'u4hero.competence.create',
      Edit: 'u4hero.competence.edit',
      Delete: 'u4hero.competence.delete',
    },
    Tenant: {
      View: 'u4hero.tenant.view',
      Create: 'u4hero.tenant.create',
      Activate: 'u4hero.tenant.activate',
      Edit: 'u4hero.tenant.edit',
    },
    Profile: {
      View: 'u4hero.profile.view',
      Delete: 'u4hero.profile.delete',
      Create: 'u4hero.profile.create',
      Edit: 'u4hero.profile.edit',
    },
    Dashboard: {
      View: 'u4hero.dashboard.view',
    },
  },
  Org: {
    Tenant: {
      View: 'org.tenant.view',
      Activate: 'org.tenant.activate',
      Delete: 'org.tenant.delete',
      Create: 'org.tenant.create',
      Edit: 'org.tenant.edit',
    },
    Profile: {
      View: 'org.profile.view',
      Delete: 'org.profile.delete',
      Create: 'org.profile.create',
      Edit: 'org.profile.edit',
    },
    Indicators: {
      View: 'org.indicators.view',
    },
    Dashboard: {
      View: 'org.dashboard.view',
    },
    Students: {
      ViewAll: 'org.students.viewall',
      Activate: 'org.students.activate',
      Create: 'org.students.create',
      Edit: 'org.students.edit',
      Import: 'u4hero.students.import',
    },
    Class: {
      View: 'org.class.view',
      Delete: 'org.class.delete',
      Create: 'org.class.create',
      Edit: 'org.class.edit',
    },
    Collaborators: {
      View: 'org.collaborators.view',
      Activate: 'org.collaborators.activate',
      Create: 'org.collaborators.create',
      Edit: 'org.collaborators.edit',
      Import: 'org.collaborators.import',
    },
  },
  Inkluzen: {
    Macro: {
      View: 'inkluzen.macro.view',
    },
    Geographic: {
      View: 'inkluzen.geographic.view',
    },
    Student: {
      Detail: 'inkluzen.studentDetail.view',
      ViewName: 'inkluzen.student.viewName',
      View: 'inkluzen.students.view',
    },
    Symptoms: {
      View: 'inkluzen.symptoms.view',
    },
  },
  Dashboard: 'dashboard.view',
};
