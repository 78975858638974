import { useMediaQuery } from '@chakra-ui/react';
import { Flex, Form, Image } from 'antd';
import { GenericCard } from 'components/V2/generic-card-styled-component';
import Table from 'components/V2/table';
import Status from 'components/status';
import { calcAge } from 'infra/helpers/date';
import { Pluralizar } from 'infra/helpers/plural';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import { useRef, useState } from 'react';
import { MdExpandLess, MdExpandMore } from 'react-icons/md';
import { useHistory, useParams } from 'react-router-dom';
import DoubleList from './DoubleList';
import Button from 'components/buttons/button';
import Tag from 'components/V2/tag';
import DownloadStudentKeys, {
  ClassBasic,
  StudentBasic,
} from 'components/DownloadStudentKeys';

interface Student {
  id: string;
  name: string;
  birthday?: string;
  belongsToClass: boolean;
  license: string;
  classe: ClassBasic;
}

function StudentsTab({ students }: { students: StudentBasic[] }) {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const { id, tenantId, mode } = useParams<{
    id: string;
    mode: string;
    tenantId: string;
  }>();
  const { colorPrimary } = useU4heroColors();
  const [isMobile] = useMediaQuery('(max-width: 768px)');

  const tableHeaderStyle = {
    fontSize: isMobile ? '14px' : '18px',
    fontWeight: 400,
  };

  const history = useHistory();
  const columns = [
    {
      title: <p style={tableHeaderStyle}>Nome/Chave</p>,
      dataIndex: 'name',
      key: 'name',
      render: (name: string, record: Student) => (
        <Flex vertical>
          <p style={{ fontSize: '16px' }}>{record.name}</p>
          <Tag type="filled" text={record.license} />
        </Flex>
      ),
      sorter: (a: any, b: any) => a.name.localeCompare(b.name),
      sortIcon: (sortOrder: any) =>
        sortOrder.sortOrder === 'descend' ? (
          <MdExpandLess size={25} />
        ) : (
          <MdExpandMore size={25} />
        ),
      mobile: true,
    },
    {
      title: <p style={tableHeaderStyle}>Idade</p>,
      dataIndex: 'birthday',
      key: 'birthday',
      render: (_: string, record: Student) => (
        <Flex align="center" justify="space-between" style={{ width: '95%' }}>
          <p style={{ fontSize: '16px' }}>
            {calcAge(record.birthday ?? '')} anos
          </p>
        </Flex>
      ),
      sorter: (a: any, b: any) => calcAge(b.birthday) - calcAge(a.birthday),
      sortIcon: (sortOrder: any) =>
        sortOrder.sortOrder === 'descend' ? (
          <MdExpandLess size={25} />
        ) : (
          <MdExpandMore size={25} />
        ),
      mobile: true,
    },
    {
      title: <p style={tableHeaderStyle}>Situação</p>,
      dataIndex: 'status',
      key: 'status',
      render: (_: string, record: Student) => (
        <Flex align="center" justify="space-between" style={{ width: '95%' }}>
          <p>
            {!record.belongsToClass ? (
              <Status type="exStudent" />
            ) : (
              <Status type="student" />
            )}
          </p>
        </Flex>
      ),
      sorter: (a: Student, b: Student) => (a.belongsToClass ? 1 : -1),
      sortIcon: (sortOrder: any) =>
        sortOrder.sortOrder === 'descend' ? (
          <MdExpandLess size={25} />
        ) : (
          <MdExpandMore size={25} />
        ),
    },
  ];

  return (
    <Flex vertical>
      {!mode && (
        <Form.Item name={'students'}>
          {/* @ts-ignore */}
          <DoubleList />
        </Form.Item>
      )}

      {id && (
        <GenericCard>
          <Flex gap={16} vertical style={{ width: '100%', height: '100%' }}>
            <Flex vertical>
              <h1
                style={{
                  fontWeight: 700,
                  fontSize: '24px',
                  color: colorPrimary,
                  margin: '0px',
                }}
              >
                Estudantes da Turma
              </h1>
              <Flex justify="flex-end" gap="16px">
                <DownloadStudentKeys
                  students={students.filter((item) => item.belongsToClass)}
                />
              </Flex>
            </Flex>

            <Table
              itemName={Pluralizar('estudante', students?.length > 1)}
              data={students ?? []}
              page={page}
              onChangePage={setPage}
              pageSize={pageSize}
              onChangePageSize={setPageSize}
              total={students.length}
              columns={
                isMobile ? columns.filter((column) => column.mobile) : columns
              }
            />

            {mode && (
              <Flex align="center" justify="center" style={{ width: '100%' }}>
                <Button
                  size="md"
                  onClick={() => {
                    if (tenantId) {
                      history.push(`/schools/${tenantId}/classes/edit/${id}`);
                    } else {
                      history.push(`/my-school/classes/${id}/edit`);
                    }
                  }}
                >
                  Editar
                </Button>
              </Flex>
            )}
          </Flex>
        </GenericCard>
      )}
    </Flex>
  );
}

export default StudentsTab;
