import GenericFilterComponent from 'components/generic-filter';
import { FilterProps } from 'infra/helpers/types';
function GameFilter({ filters, onChangeFilters }: FilterProps) {
  return (
    <GenericFilterComponent
      filters={filters}
      onChangeFilters={onChangeFilters}
      filterGroups={[
        {
          title: 'Classificação',
          filterName: 'classifications',
          options: [
            {
              label: 'Jogos já utilizados',
              value: 'used',
            },
            {
              label: 'Jogos não utilizados',
              value: 'not-used',
            },
          ],
        },
        {
          title: 'Duração do Jogo',
          filterName: 'durations',
          options: [10, 20, 30, 40, 50].map((item) => {
            return { label: `${item} minutos`, value: item };
          }),
          type: 'number',
        },
        {
          title: 'Faixa Etária',
          filterName: 'age',
          options: [3, 7, 12, 16].map((item) => {
            return { label: `Até ${item} anos`, value: item };
          }),
          type: 'radio',
        },
        {
          title: 'Gênero',
          filterName: 'genres',
          options: [
            {
              label: 'Aventura',
              value: 'Aventura',
            },
            {
              label: 'Estratégia',
              value: 'Estratégia',
            },
            {
              label: 'Puzzle',
              value: 'Puzzle',
            },
            {
              label: 'Simulação',
              value: 'Simulação',
            },
          ],
        },
        {
          title: 'Temas Extras',
          filterName: 'types',
          options: [
            {
              label: 'Socioemocional',
              value: 'socioEmocional',
            },
            {
              label: 'Geografia',
              value: 'geografia',
            },
            {
              label: 'História',
              value: 'historia',
            },
            {
              label: 'Português',
              value: 'portugues',
            },
            {
              label: 'Matemática',
              value: 'matematica',
            },
          ],
        },
        {
          title: 'Status',
          options: [
            { label: 'Ativo', value: 1 },
            { label: 'Inativo', value: 2 },
          ],
          filterName: 'status',
          type: 'radio',
        },
        {
          title: 'Limite de Perguntas',
          filterName: 'limits',
          options: [6, 8, 10].map((item) => {
            return { label: `Até ${item} perguntas`, value: item };
          }),
          type: 'number',
        },
        {
          title: 'Favoritos',
          filterName: 'favorite',
          options: [],
          type: 'switch',
        },
      ]}
    />
  );
}

export default GameFilter;
