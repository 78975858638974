import { Spinner, useMediaQuery } from '@chakra-ui/react';
import {
  Col,
  Flex,
  Form,
  Input,
  InputNumber,
  Modal,
  Radio,
  Row,
  Select,
  Space,
} from 'antd';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import { useEffect } from 'react';
import { IoClose } from 'react-icons/io5';
import {
  notificateError,
  notificateSucess,
  openNotification,
} from 'infra/helpers/notifications';
import { useQueryClient } from 'react-query';
import Button from 'components/buttons/button';
import SubTitle from 'components/typography/subTitle';
import { useSaveGame } from 'core/features/games/hooks/useSaveGame';
import useGamev1 from 'core/features/games/hooks/useGameV1';
import { GameForm } from 'core/features/games/typings';
import Text from 'components/typography/text';
import UploadImagem from 'components/inputs/upload-imagem';
import UploadMultipleImages from 'components/inputs/upload-multiple-images';
import CustomizedRequiredMark from 'components/required-mark';
import SelectTypeGame from 'components/inputs/select-type-game';
import SelectGenreGame from 'components/inputs/select-game-genre';
import LoadingDots from 'components/V2/loading-dots';
import { v4 as uuidv4 } from 'uuid';
const { TextArea } = Input;

function ModalGame({ onClose, id }: { id?: string; onClose: () => void }) {
  const { data, isLoading } = useGamev1(id);
  const queryClient = useQueryClient();
  const { mutate: handleSave, isLoading: isLoadingSave } = useSaveGame(id);
  const [form] = Form.useForm();
  const { colorPrimary } = useU4heroColors();
  const [isMobile] = useMediaQuery('(max-width: 768px)');

  const isOpenSource = Form.useWatch('isOpenSource', form);
  const versions = Form.useWatch('versions', form);
  const versionId = Form.useWatch('versionId', form);

  const engine = versions?.[versions?.length - 1]?.playDatas?.engine;

  useEffect(() => {
    form.resetFields();
  }, [data, form]);

  const _handleSave = (values: GameForm) => {
    handleSave(
      { ...values },
      {
        onSuccess: () => {
          notificateSucess(`Jogo ${id ? 'atualizado' : 'criado'} com sucesso!`);
          onClose();
          queryClient.invalidateQueries('/v1/game');
        },
        onError: (err: any) => {
          notificateError(
            `Houve um problema ao ${
              id ? 'editar' : 'criar'
            } o jogo. Verifique sua conexão e tente novamente.`
          );
        },
      }
    );
  };

  const newVersionId = uuidv4();
  const defaultValues = {
    active: true,
    versionId: newVersionId,
    versions: [
      { id: newVersionId, name: '', playDatas: { engine: undefined } },
    ],
  };

  return (
    <Modal
      title={<SubTitle primary>{id ? 'Editar' : 'Criar'} Jogo</SubTitle>}
      open={true}
      onCancel={() => {
        if (form.isFieldsTouched()) {
          openNotification({ onClose, colorPrimary, isMobile });
        } else {
          onClose();
        }
      }}
      footer={null}
      width={isMobile ? '95%' : 815}
      closeIcon={
        <div
          style={{
            background: colorPrimary,
            borderRadius: '50%',
            width: '20px',
            height: '20px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <IoClose size={16} color="white" />
        </div>
      }
    >
      <Form
        id="student-creation-modal"
        onFinish={(ev) => _handleSave(ev)}
        validateTrigger="onSubmit"
        layout="vertical"
        requiredMark={CustomizedRequiredMark}
        form={form}
        initialValues={
          data
            ? {
                ...data,
                versions: data?.versions.map((item) => ({
                  id: item.id,
                  gameId: item.gameId,
                  playDatas: item.playDatas,
                  name: item.name,
                })),
              }
            : { ...defaultValues }
        }
      >
        <Form.Item hidden name="active" />

        <Flex vertical gap={24}>
          <Flex
            className="box-shadow-generic-card"
            vertical
            style={{ padding: '16px' }}
          >
            <Text size="md" color={colorPrimary} bold>
              Informações do Jogo
            </Text>

            <Row>
              <Col md={12} xs={24}>
                <Form.Item
                  name="coverImageUrl"
                  label="Capa do Jogo"
                  rules={[
                    {
                      required: true,
                      message: 'Por favor, atribua uma imagem de capa!',
                    },
                  ]}
                >
                  {/* @ts-ignore */}
                  <UploadImagem
                    placeholderImg="gameIcon"
                    formats={['jpg', 'jpeg', 'png']}
                  />
                </Form.Item>
              </Col>
              <Col md={12} xs={24}>
                <Form.Item
                  name="screenShotsUrl"
                  label="Capturas de Tela do Jogo"
                  rules={[
                    {
                      required: true,
                      message: 'Por favor, atribua uma imagem de capa!',
                    },
                  ]}
                >
                  {/* @ts-ignore */}
                  <UploadMultipleImages />
                </Form.Item>
              </Col>
            </Row>

            <Form.Item
              label="Nome"
              name={['name', 'pt-br']}
              rules={[
                {
                  required: true,
                  message: 'Por favor, insira o nome do jogo!',
                },
              ]}
            >
              <Input
                id="id-tour-my-school-student-modal-input-name"
                placeholder="Insira o nome do Jogo"
                size={isMobile ? 'middle' : 'large'}
              />
            </Form.Item>
            <Form.Item
              label="Link do Vídeo"
              name="videoUrl"
              rules={[
                {
                  required: true,
                  message: 'Por favor, insira o link do vídeo do jogo!',
                },
              ]}
            >
              <Input
                id="id-tour-my-school-student-modal-input-name"
                placeholder="Insira o link do vídeo do youtube"
                size={isMobile ? 'middle' : 'large'}
                type="url"
              />
            </Form.Item>

            <Form.Item
              label="Gênero"
              name={'genre'}
              rules={[
                {
                  required: true,
                  message: 'Por favor, insira o nome do autor!',
                },
              ]}
            >
              {/* @ts-ignore */}
              <SelectGenreGame />
            </Form.Item>

            <Form.Item
              rules={[
                {
                  required: true,
                  message: 'Por favor, selecione a licença do jogo',
                },
              ]}
              name="isOpenSource"
              label="Tipo de Licença:"
            >
              <Radio.Group id="id-tour-my-school-student-modal-input-gender">
                <Space direction="horizontal" wrap>
                  <Radio value={true}>Open-Source</Radio>
                  <Radio value={false}>Desenvolvido pela U4hero</Radio>
                </Space>
              </Radio.Group>
            </Form.Item>

            <Row gutter={[24, 0]}>
              <Col md={12} xs={24}>
                <Form.Item
                  label="Nome do Autor"
                  name={'authorName'}
                  rules={[
                    {
                      required: isOpenSource,
                      message: 'Por favor, insira o nome do autor!',
                    },
                  ]}
                >
                  <Input
                    id="id-tour-my-school-student-modal-input-name"
                    placeholder="Insira o nome e sobrenome do autor"
                    size={isMobile ? 'middle' : 'large'}
                    disabled={!isOpenSource}
                  />
                </Form.Item>
              </Col>

              <Col md={12} xs={24}>
                <Form.Item
                  label="Github"
                  name={'authorGithub'}
                  rules={[
                    {
                      required: isOpenSource,
                      message: 'Por favor, insira o link do github',
                    },
                  ]}
                >
                  <Input
                    id="id-tour-my-school-student-modal-input-name"
                    placeholder="Insira o link do github do autor"
                    size={isMobile ? 'middle' : 'large'}
                    disabled={!isOpenSource}
                    type="url"
                  />
                </Form.Item>
              </Col>

              <Col md={12} xs={24}>
                <Form.Item label="Tema Extra Abordado" name={'type'}>
                  {/* @ts-ignore */}
                  <SelectTypeGame />
                </Form.Item>
              </Col>

              <Col md={12} xs={24}>
                <Form.Item
                  label="Faixa Etária"
                  name={['minAge']}
                  rules={[
                    {
                      required: true,
                      message: 'Por favor, defina a faixa etária',
                    },
                  ]}
                >
                  <Select
                    options={[
                      { label: 'Todas as idades', value: 0 },
                      { label: 'A partir de 3 anos', value: 3 },
                      { label: 'A partir de 7 anos', value: 7 },
                      { label: 'A partir de 12 anos', value: 12 },
                      { label: 'A partir de 16 anos', value: 16 },
                    ]}
                    size={isMobile ? 'middle' : 'large'}
                  />
                </Form.Item>
              </Col>

              <Col md={12} xs={24}>
                <Form.Item
                  label="Número Máximo de Perguntas"
                  name={'maxQuestions'}
                  rules={[
                    {
                      required: true,
                      message:
                        'Por favor, defina o número máximo de perguntas!',
                    },
                  ]}
                >
                  <InputNumber
                    style={{ width: '100%' }}
                    min={1}
                    type="number"
                    size={isMobile ? 'middle' : 'large'}
                    placeholder="Insira o número máximo de perguntas"
                  />
                </Form.Item>
              </Col>

              <Col md={12} xs={24}>
                <Form.Item
                  label="Duração do Jogo (min)"
                  name={'averageTime'}
                  rules={[
                    {
                      required: true,
                      message: 'Por favor, informe o tempo de duração do jogo!',
                    },
                  ]}
                >
                  <InputNumber
                    size={isMobile ? 'middle' : 'large'}
                    min={1}
                    type="number"
                    style={{ width: '100%' }}
                    placeholder="Insira o tempo médio de duração"
                  />
                </Form.Item>
              </Col>
            </Row>

            <Form.Item
              name={['descriptionU4Smile', 'pt-br']}
              label="Descrição para o Educador"
              rules={[
                {
                  required: true,
                  message: 'Por favor, crie uma descrição para o educador!',
                },
              ]}
            >
              <TextArea
                placeholder="Descreva de forma detalhada a importância do jogo, incluindo seu objetivo principal e as habilidades que são trabalhadas durante sua jogabilidade"
                id="id-tour-create-game-modal-input-educator-description"
                size={isMobile ? 'middle' : 'large'}
                rows={5}
              />
            </Form.Item>

            <Form.Item
              name={['descriptionU4Play', 'pt-br']}
              label="Descrição para o Estudante"
              rules={[
                {
                  required: true,
                  message: 'Por favor, crie uma descrição para o estudante!',
                },
              ]}
            >
              <TextArea
                placeholder="Descreva brevemente o objetivo do jogo."
                id="id-tour-create-game-modal-input-student-description"
                size={isMobile ? 'middle' : 'large'}
                rows={3}
              />
            </Form.Item>

            <Form.Item
              rules={[
                {
                  required: true,
                  message:
                    'Por favor, selecione a plataforma de desenvolvimento',
                },
              ]}
              name={['versions', versions?.length - 1, 'playDatas', 'engine']}
              label="Plataforma de Desenvolvimento:"
            >
              <Radio.Group id="id-tour-my-school-student-modal-input-gender">
                <Space direction="horizontal">
                  <Radio value={0}>Unity</Radio>
                  <Radio value={1}>Godot</Radio>
                </Space>
              </Radio.Group>
            </Form.Item>
          </Flex>

          <Flex
            className="box-shadow-generic-card"
            vertical
            style={{ padding: '16px', minHeight: 168 }}
          >
            <Flex justify="space-between">
              <Text size="md" color={colorPrimary} bold>
                Informações Técnicas
              </Text>

              <Form.Item name="versionId" hidden={engine === undefined}>
                <Select
                  options={versions?.map((item: any) => ({
                    label: item.name,
                    value: item.id,
                  }))}
                  size={isMobile ? 'middle' : 'large'}
                />
              </Form.Item>
            </Flex>
            <Form.List name="versions">
              {(fields, { add, remove }) => (
                <>
                  {fields.map((item, index) => (
                    <div
                      style={{
                        display:
                          versions?.[index]?.id === versionId ? 'flex' : 'none',
                        flexDirection: 'column',
                      }}
                      key={item.key}
                    >
                      <Form.Item hidden name={['id']} />
                      {engine !== undefined ? (
                        <>
                          <Form.Item
                            label="Nome da Versão"
                            name={[index, 'name']}
                            rules={[
                              {
                                required: true,
                                message: 'Campo Obrigatório!',
                              },
                            ]}
                          >
                            <Input
                              id="id-tour-my-school-student-modal-input-name"
                              size={isMobile ? 'middle' : 'large'}
                              disabled={data && index < data?.versions.length}
                            />
                          </Form.Item>
                          <Form.Item
                            label="Code Url"
                            name={[index, 'playDatas', 'codeUrl']}
                            rules={[
                              {
                                required: true,
                                message: 'Campo Obrigatório!',
                              },
                            ]}
                          >
                            <Input
                              id="id-tour-my-school-student-modal-input-name"
                              size={isMobile ? 'middle' : 'large'}
                              type="url"
                              disabled={data && index < data?.versions.length}
                            />
                          </Form.Item>
                          {engine === 1 ? (
                            <Form.Item
                              label="Pck Url"
                              name={[index, 'playDatas', 'pckUrl']}
                              rules={[
                                {
                                  required: true,
                                  message: 'Campo Obrigatório!',
                                },
                              ]}
                            >
                              <Input
                                id="id-tour-create-game-input-pck-url"
                                size={isMobile ? 'middle' : 'large'}
                                type="url"
                                disabled={data && index < data?.versions.length}
                              />
                            </Form.Item>
                          ) : (
                            <>
                              <Form.Item
                                label="Data Url"
                                name={[index, 'playDatas', 'dataUrl']}
                                rules={[
                                  {
                                    required: true,
                                    message: 'Campo Obrigatório!',
                                  },
                                ]}
                              >
                                <Input
                                  id="id-tour-create-game-input-data-url"
                                  size={isMobile ? 'middle' : 'large'}
                                  type="url"
                                  disabled={
                                    data && index < data?.versions.length
                                  }
                                />
                              </Form.Item>
                              <Form.Item
                                label="Loader Url"
                                name={[index, 'playDatas', 'loaderUrl']}
                                rules={[
                                  {
                                    required: true,
                                    message: 'Campo Obrigatório!',
                                  },
                                ]}
                              >
                                <Input
                                  id="id-tour-create-game-input-pck-url"
                                  size={isMobile ? 'middle' : 'large'}
                                  type="url"
                                  disabled={
                                    data && index < data?.versions.length
                                  }
                                />
                              </Form.Item>

                              <Form.Item
                                label="StreamingAssets Url "
                                name={[
                                  index,
                                  'playDatas',
                                  'streamingAssetsUrl',
                                ]}
                              >
                                <Input
                                  id="id-tour-create-game-input-streaming-assets-url"
                                  size={isMobile ? 'middle' : 'large'}
                                  type="url"
                                  disabled={
                                    data && index < data?.versions.length
                                  }
                                />
                              </Form.Item>
                            </>
                          )}

                          <Form.Item
                            label="Framework Url"
                            name={[index, 'playDatas', 'frameworkUrl']}
                            rules={[
                              {
                                required: true,
                                message: 'Campo Obrigatório!',
                              },
                            ]}
                          >
                            <Input
                              id="id-tour-create-game-input-framework-url"
                              size={isMobile ? 'middle' : 'large'}
                              type="url"
                              disabled={data && index < data?.versions.length}
                            />
                          </Form.Item>
                        </>
                      ) : (
                        <Flex
                          vertical
                          justify="center"
                          align="center"
                          style={{ marginTop: '40px' }}
                          gap={'8px'}
                        >
                          <LoadingDots />
                          <Text size="md" color={colorPrimary}>
                            Selecione a plataforma de desenvolvimento para
                            preencher a ficha técnica
                          </Text>
                        </Flex>
                      )}

                      <Button
                        size="md"
                        style={{ alignSelf: 'center' }}
                        onClick={() => {
                          const newVersionId = uuidv4();
                          add({
                            id: newVersionId,
                            name: '',
                            playDatas: {
                              engine: versions?.[0]?.playDatas?.engine,
                            },
                          });
                          form.setFieldValue('versionId', newVersionId);
                        }}
                      >
                        CRIAR NOVA VERSÃO
                      </Button>
                    </div>
                  ))}
                </>
              )}
            </Form.List>
          </Flex>

          <Flex justify="center" gap={isMobile ? '12px' : '24px'}>
            <Button
              variant="outline"
              size="md"
              disabled={isLoading || isLoadingSave}
              onClick={() => {
                if (form.isFieldsTouched()) {
                  openNotification({ onClose, colorPrimary, isMobile });
                } else {
                  onClose();
                }
              }}
              id="id-tour-my-create-game-modal-button-cancel"
            >
              CANCELAR
            </Button>

            <Button
              htmlType="submit"
              size="md"
              loading={isLoading || isLoadingSave}
              id="id-tour-my-create-game-modal-button-save"
            >
              SALVAR
            </Button>
          </Flex>
        </Flex>
      </Form>
    </Modal>
  );
}

export default ModalGame;
