import { Divider, Flex, Rate } from 'antd';
import { PiHeart, PiHeartFill, PiStarFill } from 'react-icons/pi';
import Text from 'components/typography/text';
import Button from 'components/buttons/button';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import { useGameFavorite } from 'core/features/games/hooks/useGameFavorite';
import { useMediaQuery } from '@chakra-ui/react';
import envelope from 'assets/images/envelope.png';
import { GameDetails } from 'core/features/games/typings';
import { useQueryClient } from 'react-query';
import CustomCarousel from 'components/custom-carousel';
import { useState } from 'react';
import ModalGamePreview from 'components/modals/modal-game-preview';
import './style.css';
import YouTube from 'react-youtube';

function GameInfo(game: GameDetails) {
  const { colorPrimary } = useU4heroColors();
  const { mutate: handleToggleFavorite, isLoading: isLoadingFavorite } =
    useGameFavorite();
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const [isOpenPreview, setIsOpenPreview] = useState(false);
  const queryClient = useQueryClient();

  const {
    id,
    averageTime,
    minAge,
    type,
    isFavorite,
    descriptionU4Smile,
    isOpenSource,
    authorGithub,
    authorName,
    videoUrl,
    screenShotsUrl,
    averageRating,
  } = game;

  const gameTypes: { [key: string]: string } = {
    socioEmocional: 'Socioemocional',
    geografia: 'Geografia',
    historia: 'História',
    portugues: 'Português',
    matematica: 'Matemática',
  };

  const re =
    /(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/\s]{11})/gi;

  const carouselItems: any = screenShotsUrl ? [...screenShotsUrl] : [];
  const videoId = videoUrl ? re.exec(videoUrl) : undefined;

  if (videoId) {
    carouselItems.splice(1, 0, {
      url: videoId?.[1],
      type: 'video',
    });
  }

  return (
    <Flex vertical gap={isMobile ? 48 : 24} style={{ marginBottom: '24px' }}>
      <ModalGamePreview
        {...game}
        isOpen={isOpenPreview}
        onClose={() => setIsOpenPreview(false)}
      />

      <CustomCarousel
        centerMode={true}
        slidesToShow={isMobile ? 1 : 3}
        slidesToScroll={1}
        showTotal={false}
        infinite={true}
        initialSlide={1}
        className={'center-carousel special-carousel'}
      >
        {carouselItems?.map((item: any, index: number) => (
          <div key={index} style={{ height: '100%', borderRadius: '20px' }}>
            {item.type === 'video' ? (
              <YouTube
                style={{ width: '100%', height: '100%', borderRadius: '20px' }}
                videoId={item.url}
                opts={{ width: '100%', height: '100%', borderRadius: '20px' }}
              />
            ) : (
              <img
                src={item.url}
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                  borderRadius: '20px',
                }}
              />
            )}
          </div>
        ))}
      </CustomCarousel>
      <Flex vertical>
        <Flex
          style={{
            padding: isMobile ? '8px' : '25px 0px',
            background: '#F4F4F4',
            borderRadius: '20px 20px 0px 0px',
            paddingLeft: isMobile ? '8px' : undefined,
            position: 'relative',
          }}
          gap={isMobile ? 10 : 28}
          align={isMobile ? 'start' : 'center'}
          justify={'center'}
          vertical={isMobile}
        >
          <img
            src={envelope}
            alt="envelope"
            style={{ position: 'absolute', top: -60, right: -10 }}
          />

          <Rate disabled defaultValue={averageRating} allowHalf />

          {!isMobile && (
            <Divider
              type="vertical"
              style={{ height: '35px', borderColor: 'rgba(38, 38, 38, 0.30)' }}
            />
          )}
          <Text size="md">
            <strong>Duração:</strong> {averageTime} min
          </Text>
          {!isMobile && (
            <Divider
              type="vertical"
              style={{ height: '35px', borderColor: 'rgba(38, 38, 38, 0.30)' }}
            />
          )}
          <Text size="md">
            <strong>Tema Extra Abordado:</strong> {type ? gameTypes[type] : '-'}
          </Text>
          {!isMobile && (
            <Divider
              type="vertical"
              style={{ height: '35px', borderColor: 'rgba(38, 38, 38, 0.30)' }}
            />
          )}
          <Text size="md">
            <strong>Faixa Etária:</strong> {minAge} anos +
          </Text>
        </Flex>
        <Flex
          style={{
            padding: '16px',
            borderRadius: '0px 0px 20px 20px',
            boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.10)',
          }}
          vertical
        >
          <Flex justify="flex-end" gap={16}>
            <Button
              size="md"
              variant="outline"
              loading={isLoadingFavorite}
              icon={
                isFavorite ? (
                  <PiHeartFill color={colorPrimary} size={20} />
                ) : (
                  <PiHeart color={colorPrimary} size={20} />
                )
              }
              onClick={() =>
                handleToggleFavorite(id, {
                  onSuccess: () => {
                    queryClient.invalidateQueries(`/v2/game/${id}`);
                  },
                })
              }
            >
              {isFavorite ? 'DESFAVORITAR' : 'Favoritar'}
            </Button>
            <Button size="md" onClick={() => setIsOpenPreview(true)}>
              Testar jogo
            </Button>
          </Flex>
          <Text size="md">
            <strong>Objetivo:</strong> {descriptionU4Smile['pt-br']}
          </Text>
          <Text size="md">
            <strong>Autor: </strong>
            {isOpenSource
              ? `Este jogo open source foi desenvolvido por ${authorName} (${authorGithub}). Agradecemos ao autor por seu trabalho!`
              : 'Desenvolvido pela equipe U4Hero, este jogo é uma criação exclusiva.'}
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
}

export default GameInfo;
