import { useMediaQuery } from '@chakra-ui/react';
import { Flex } from 'antd';
import Layout from 'components/layout';
import useCheckPermissions from 'core/features/user/hooks/useCheckPermissions';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Permissions } from 'core/resources/enums/permissions';
import AutomationsTable from '../Activities/components/AutomationsTable';
import ModalAutomated from 'components/modals/modal-automated-activity';
import { useQueryClient } from 'react-query';
import BackButton from 'components/buttons/back-button';
import Button from 'components/buttons/button';
import Title from 'components/typography/title';

function Automations() {
  const history = useHistory();
  const [check] = useCheckPermissions();
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const { colorPrimary } = useU4heroColors();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [id, setId] = useState<string>();
  const queryClient = useQueryClient();
  return (
    <Layout.Container>
      {isOpenModal ? (
        <ModalAutomated
          id={id}
          onClose={() => {
            queryClient.invalidateQueries(`get-automated`);
            setIsOpenModal(false);
            setId(undefined);
          }}
        />
      ) : null}
      <Flex gap="23px">
        <BackButton onClick={() => history.push('/activities')} />
        <Title primary>Automação</Title>
      </Flex>
      <Flex
        gap="24px"
        justify="end"
        style={{ marginBottom: isMobile ? '8px' : '16px' }}
      >
        {check([Permissions.Activity.Create]) ? (
          <Button
            size="md"
            onClick={() => setIsOpenModal(true)}
            id="id-tour-activities-button-add"
          >
            CRIAR AUTOMAÇÃO
          </Button>
        ) : null}
      </Flex>

      <AutomationsTable
        onEdit={(v) => {
          setIsOpenModal(true);
          setId(v._id);
        }}
      />
    </Layout.Container>
  );
}

export default Automations;
