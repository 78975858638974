import { useMediaQuery } from "@chakra-ui/react";
import {
  Col,
  Divider,
  Flex,
  Row,
  Button,
  Spin,
} from "antd";
import SearchBar from "components/V2/SearchBar";
import { GenericCard } from "components/V2/generic-card-styled-component";
import useUsers from "core/features/user/hooks/useUsers";
import { UserDetails } from "core/features/user/typings";
import useU4heroColors from "infra/packages/antd/u4heroTokens";
import {  useEffect, useState } from "react";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import {
  MdKeyboardDoubleArrowLeft,
  MdKeyboardDoubleArrowRight,
} from "react-icons/md";
import { useParams } from "react-router-dom";


function DoubleList({
  value,
  onChange,
}: {
  value: string[];
  onChange: (v: string[]) => void;
}) {
  const [search, setSearch] = useState<string>();
  const [selectedSearch, setSelectedSearch] = useState<string>();
  const [isMobile] = useMediaQuery("(max-width: 768px)");
  const { colorPrimary } = useU4heroColors();
  const { tenantId} = useParams<{ tenantId: string }>();
  const { data, isLoading } = useUsers({ page: 1, search, tenantId });
  const [selected, setSelected] = useState<UserDetails[]>([]);
  const [allCollaborators, setAllCollaborators] = useState<UserDetails[]>([]);

  useEffect(() => {
    if (data) {
      setAllCollaborators(data.data.filter((item) => !value.includes(item.id)));
      setSelected(data?.data.filter((item) => value.includes(item.id)));
    }
  }, [data]);

  const handleAddToSelected = (item: UserDetails) => {
    setAllCollaborators((prevCollaborators) =>
      prevCollaborators?.filter((q) => q.id !== item.id)
    );
    const added = [...selected, item];
    setSelected(added);
    onChange(added.map((item) => item.id));
  };

  const handleRemoveFromSelected = (item: UserDetails) => {
    const filtered = selected.filter((q) => q.id !== item.id);
    setSelected(filtered);
    onChange(filtered.map((item) => item.id));

    setAllCollaborators((prevAllCollaborators) => [
      ...prevAllCollaborators,
      item,
    ]);
  };

  const moveAllToSelected = () => {
    setSelected([...selected, ...allCollaborators]);
    onChange([...selected, ...allCollaborators].map((item) => item.id));
    setAllCollaborators([]);
  };

  const moveAllToQuestionBank = () => {
    setAllCollaborators((prevAllCollaborators) => [
      ...prevAllCollaborators,
      ...selected,
    ]);
    setSelected([]);
    onChange([]);
  };

  return (
    <Row gutter={[24, isMobile ? 8 : 0]} align="stretch">
      <Col span={isMobile ? 24 : 11}>
        <Flex vertical gap="8px" style={{ height: "100%" }}>
          <p
            style={{
              fontSize: isMobile ? "18px" : "24px",
              color: colorPrimary,
              fontWeight: 700,
            }}
          >
            Todos os Colaboradores
          </p>
          <GenericCard height="100%">
            <Flex vertical gap="16px" style={{ width: "100%" }}>
              <Flex justify="space-between">
                <SearchBar
                  id="id-tour-reenroll-class-search-all-collaborators-list"
                  onSearch={setSearch}
                />
              </Flex>
              {allCollaborators.length ? (
                <Flex
                  vertical
                  style={{
                    height: isMobile ? "40vh" : "60vh",
                    overflowY: "scroll",
                    paddingRight: "16px",
                  }}
                  id="id-tour-reenroll-class-all-collaborators-list"
                >
                  {allCollaborators?.map((item) => (
                    <Flex vertical>
                      <Flex justify="space-between" align="center">
                        <p
                          style={{
                            color: "black",
                            fontSize: isMobile ? "14px" : "18px",
                            fontWeight: 400,
                          }}
                        >
                          {item.name}
                        </p>
                        <Button
                          type="primary"
                          size="small"
                          style={{ borderRadius: "8px" }}
                          onClick={() => handleAddToSelected(item)}
                          disabled={selected?.length >= 6}
                        >
                          <IoIosArrowForward
                            style={{
                              transform: isMobile ? "rotate(90deg)" : "",
                            }}
                            size={16}
                          />
                        </Button>
                      </Flex>
                      <Divider />
                    </Flex>
                  ))}
                </Flex>
              ) : isLoading ? (
                <Spin />
              ) : (
                <Flex
                  vertical
                  justify="center"
                  align="center"
                  style={{ width: "100%" }}
                >
                  <p
                    style={{
                      fontSize: "14px",
                      color: "#7B7B7B",
                      textAlign: "center",
                      fontWeight: 400,
                      maxWidth: "313px",
                    }}
                  >
                    Não existem mais colaboradores para exibição. Todos os
                    colaboradores ja foram escolhidos.
                  </p>
                </Flex>
              )}
            </Flex>
          </GenericCard>
        </Flex>
      </Col>
      <Col span={isMobile ? 24 : 2} style={{ alignSelf: "center" }}>
        <Flex
          vertical={!isMobile}
          align="center"
          justify="center"
          gap={isMobile ? "32px" : "48px"}
        >
          <Button
            type="default"
            style={{
              borderRadius: "8px",
              border: `2px solid ${colorPrimary}`,
              padding: "8px",
              height: "100%",
            }}
            onClick={moveAllToSelected}
            disabled={allCollaborators?.length === 0}
            id="id-tour-reenroll-class-move-all-to-selected-button"
          >
            <MdKeyboardDoubleArrowRight
              style={{ transform: isMobile ? "rotate(90deg)" : "" }}
              color={colorPrimary}
              size={20}
            />
          </Button>
          <Button
            type="default"
            style={{
              borderRadius: "8px",
              border: `2px solid ${colorPrimary}`,
              padding: "8px",
              height: "100%",
            }}
            disabled={selected?.length === 0}
            onClick={moveAllToQuestionBank}
            id="id-tour-reenroll-class-move-all-to-unselected-button"
          >
            <MdKeyboardDoubleArrowLeft
              style={{ transform: isMobile ? "rotate(90deg)" : "" }}
              color={colorPrimary}
              size={20}
            />
          </Button>
        </Flex>
      </Col>
      <Col span={isMobile ? 24 : 11}>
        <Flex vertical gap="8px" style={{ height: "100%" }}>
          <p
            style={{
              fontSize: isMobile ? "18px" : "24px",
              color: colorPrimary,
              fontWeight: 700,
            }}
          >
            Selecionados
          </p>
          <GenericCard height="100%">
            <Flex vertical gap="16px" style={{ width: "100%" }}>
              <Flex justify="space-between">
                <SearchBar
                  id="id-tour-reenroll-class-search-selected-collaborators-list"
                  onSearch={setSelectedSearch}
                />
              </Flex>
              {selected?.length ? (
                <Flex
                  vertical
                  style={{
                    height: isMobile ? "" : "60vh",
                    overflowY: "scroll",
                    paddingRight: "16px",
                  }}
                  id="id-tour-reenroll-class-selected-collaborators-list"
                >
                  {selectedSearch
                    ? selected
                        ?.filter((item) =>
                          item.name
                            .toLowerCase()
                            .includes(selectedSearch.toLowerCase())
                        )
                        .map((item: any) => (
                          <>
                            <Flex
                              justify="space-between"
                              align="center"
                              gap="35px"
                            >
                              <Button
                                type="primary"
                                size="small"
                                style={{ borderRadius: "8px" }}
                                onClick={() => handleRemoveFromSelected(item)}
                              >
                                <IoIosArrowBack
                                  style={{
                                    transform: isMobile ? "rotate(90deg)" : "",
                                  }}
                                  size={16}
                                />
                              </Button>
                              <p
                                style={{
                                  color: "black",
                                  fontSize: isMobile ? "14px" : "18px",
                                  fontWeight: 400,
                                }}
                              >
                                {item.name}
                              </p>
                            </Flex>
                            <Divider />
                          </>
                        ))
                    : selected?.map((item: any) => (
                        <>
                          <Flex
                            justify="space-between"
                            align="center"
                            gap="35px"
                          >
                            <Button
                              type="primary"
                              size="small"
                              style={{ borderRadius: "8px" }}
                              onClick={() => handleRemoveFromSelected(item)}
                            >
                              <IoIosArrowBack
                                style={{
                                  transform: isMobile ? "rotate(90deg)" : "",
                                }}
                                size={16}
                              />
                            </Button>
                            <p
                              style={{
                                color: "black",
                                fontSize: isMobile ? "14px" : "18px",
                                fontWeight: 400,
                              }}
                            >
                              {item.name}
                            </p>
                          </Flex>
                          <Divider />
                        </>
                      ))}
                </Flex>
              ) : (
                <Flex
                  vertical
                  justify="center"
                  align="center"
                  style={{ width: "100%" }}
                >
                  <p
                    style={{
                      fontSize: "14px",
                      color: "#7B7B7B",
                      textAlign: "center",
                      fontWeight: 400,
                      maxWidth: "313px",
                    }}
                  >
                    Até agora, nenhum colaborador foi escolhido. Por favor,
                    selecione os colaboradores para visualizá-los aqui.
                  </p>
                </Flex>
              )}
            </Flex>
          </GenericCard>
        </Flex>
      </Col>
    </Row>
  );
}
export default DoubleList