import { Select } from 'antd';
import { useMediaQuery } from '@chakra-ui/react';
import useTagsCompetence from 'core/features/tags/hooks/useTagsCompetence';

interface Tag {
  id: string;
  parentId: string | null;
}
interface SelectIndicatorsProps {
  value: Tag[] | undefined;
  onChange: (v: Tag[] | undefined) => void;
  updateForm?: (field: string, value: any) => void;
}

function SelectIndicators({
  value,
  onChange,
  updateForm,
}: SelectIndicatorsProps) {
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const { data, isLoading } = useTagsCompetence({});

  const options = data?.map((item) => ({
    label: item.name['pt-br'],
    value: item.id,
  }));

  return (
    <Select
      id="id-tour-indicators-custom-panel-indicators-input"
      placeholder="Escolha os indicadores"
      options={options}
      mode="multiple"
      optionFilterProp="label"
      filterSort={(optionA, optionB) =>
        (optionA?.label ?? '')
          .toLowerCase()
          .localeCompare((optionB?.label ?? '').toLowerCase())
      }
      onChange={(v) => {
        const tags = data
          ?.map((item) => {
            if (v.includes(item.id)) {
              return {
                id: item.id,
                parentId: item.parentId,
              };
            }
          })
          .filter((item) => item !== undefined);
        onChange(tags as Tag[]);
      }}
      value={value !== undefined ? value?.map((item) => item.id): undefined}
      size={isMobile ? 'middle' : 'large'}
    />
  );
}

export default SelectIndicators;
