import { Flex, Form, Radio, Collapse } from 'antd';
import Text from 'components/typography/text';
import { GenericCard } from 'components/V2/generic-card-styled-component';
import '../style.css';
import likert from 'assets/images/likert.png';
import likertMobile from 'assets/images/likert-mobile.png';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import { useMediaQuery } from '@chakra-ui/react';

function AnswerFormat({ onChangeCustom }: { onChangeCustom: () => void }) {
  const { colorPrimary } = useU4heroColors();
  const [isMobile] = useMediaQuery('(max-width: 768px)');

  return (
    <GenericCard>
      <Collapse
        ghost
        expandIconPosition="end"
        style={{ width: '100%' }}
        defaultActiveKey={'1'}
      >
        <Collapse.Panel
          key={'1'}
          className="remove-padding"
          header={
            <Text size="md" color={colorPrimary} bold style={{ width: '100%' }}>
              Formato da Resposta
            </Text>
          }
        >
          <Form.Item
            name="methodology"
            rules={[
              {
                required: true,
                message: 'Por favor, selecione o formato da resposta!',
              },
            ]}
            style={{ marginTop: '8px' }}
          >
            <Radio.Group
              id="id-tour-question-creation-input-analysisType"
              style={{ width: '100%' }}
            >
              <Flex
                style={{ width: '100%' }}
                gap={isMobile ? 24 : 88}
                vertical={isMobile}
              >
                <Flex
                  vertical
                  gap={8}
                  style={{ width: isMobile ? '100%' : '50%' }}
                >
                  <Radio value={1}>Escala Likert</Radio>
                  <Flex
                    style={{
                      background: '#F1F1F1',
                      padding: '8px',
                      width: '100%',
                    }}
                  >
                    <img
                      src={isMobile ? likertMobile : likert}
                      draggable={false}
                    />
                  </Flex>
                </Flex>
                <Flex
                  vertical
                  gap={8}
                  style={{ width: isMobile ? '100%' : '50%' }}
                >
                  <Radio
                    value={0}
                    onChange={(e) => {
                      if (e.target.checked) {
                        onChangeCustom();
                      }
                    }}
                  >
                    Alternativas Personalizadas
                  </Radio>
                  <Flex vertical gap={6} style={{ width: '100%' }}>
                    <Flex
                      style={{
                        background: '#F1F1F1',
                        borderRadius: '5px',
                        width: '100%',
                      }}
                    >
                      <Text>A - </Text>
                    </Flex>
                    <Flex
                      style={{
                        background: '#F1F1F1',
                        borderRadius: '5px',
                        width: '100%',
                      }}
                    >
                      <Text>B - </Text>
                    </Flex>
                    <Flex
                      style={{
                        background: '#F1F1F1',
                        borderRadius: '5px',
                        width: '100%',
                      }}
                    >
                      <Text>C - </Text>
                    </Flex>
                  </Flex>
                </Flex>
              </Flex>
            </Radio.Group>
          </Form.Item>
        </Collapse.Panel>
      </Collapse>
    </GenericCard>
  );
}

export default AnswerFormat;
