import { useMediaQuery } from '@chakra-ui/react';
import { Flex, Tabs } from 'antd';
import Layout from 'components/layout';
import Title from 'components/typography/title';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import { NumberParam, useQueryParam } from 'use-query-params';
import CompetencesTab from './components/tabs/CompetenceTab';
import SkillTab from './components/tabs/SkillTab';
import cosmo from 'assets/images/cosmo-with-lightbulb.png';
import Text from 'components/typography/text';

function Competences() {
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const [step, setStep] = useQueryParam('tab', NumberParam);
  const { colorGreyBorderAndFont, colorPrimary } = useU4heroColors();
  if (!step) {
    setStep(1);
  }

  const items = [
    {
      key: '1',
      label: (
        <p
          style={{
            fontSize: isMobile ? '14px' : '24px',
            fontWeight: 700,
            color: 1 !== step ? colorGreyBorderAndFont : '',
          }}
        >
          COMPETÊNCIAS
        </p>
      ),
      children: <CompetencesTab />,
    },
    {
      key: '2',
      label: (
        <p
          style={{
            fontSize: isMobile ? '14px' : '24px',
            fontWeight: 700,
            color: 2 !== step ? colorGreyBorderAndFont : '',
          }}
        >
          HABILIDADES
        </p>
      ),
      children: <SkillTab />,
    },
  ];

  return (
    <Layout.Container>
      <Title primary>Competências e {isMobile ? <br/>:null} Habilidades</Title>
      <div
        style={{
          backgroundImage: "url('/estrelas_1.png')",
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundColor: colorPrimary,
          borderRadius: '20px',
          position: 'relative',
          padding: '16px',
          marginBottom: '24px',
          marginTop: '12px',
        }}
      >
        <Flex
          vertical
          gap={isMobile ? '16px' : '8px'}
          style={{ maxWidth: 509 }}
        >
          {isMobile ? (
            <Flex justify="space-between">
              <img
                src={cosmo}
                style={{
                  position: 'absolute',
                  top: -54,
                  right: 0,
                  overflow: 'visible',
                }}
                width={'70px'}
              />
            </Flex>
          ) : null}
          <Text size="lg" color="white" align='justify'>
            Aqui, você tem o controle total para adicionar novas competências e
            habilidades, editar as existentes e garantir que os dados estejam
            completos e atualizados.
          </Text>
        </Flex>
        {!isMobile && (
          <img
            src={cosmo}
            style={{
              position: 'absolute',
              bottom: 0,
              top: undefined,
              right: 54,
              overflow: 'visible',
            }}
          />
        )}
      </div>
      <Tabs
        centered
        onTabClick={(event) => setStep(Number(event))}
        size="large"
        defaultActiveKey={step?.toString()}
        items={items}
      />
    </Layout.Container>
  );
}

export default Competences;
