import { useMediaQuery } from '@chakra-ui/react';
import { Collapse, Flex, Form, Input } from 'antd';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import Button from 'components/buttons/button';
import Text from 'components/typography/text';
import LoadingDots from 'components/V2/loading-dots';
import { hex2rgba } from 'infra/helpers/hex2Rgba';
import SelectIndicators from 'components/V2/selects/select-indicators';
import SelectYear from 'components/V2/selects/select-year';
import CategoryInput from './components/categoryInput';
import SourceInput from './components/sourceInput';
import InnerSourceInput from './components/innerSourceInput';
import AnalysisTypeInput from './components/analysisTypeInput';
import AnalysisEnvironmentInput from './components/analysisEnvironmentInput';
import GraphViewInput from './components/graphViewInput';
import ComparativeInput from './components/comparativeInput';
import TimeInput from './components/timeInput';
import SelectGraphType from './components/selectGraphType';
import ComparisonModeInput from './components/comparisonMode';
import { v4 as uuidv4 } from 'uuid';
import { RightOutlined } from '@ant-design/icons';

function CustomizeGraphs({ graphIndex }: { graphIndex?: number }) {
  const form = Form.useFormInstance();
  const { colorPrimary, colorGreyBorderAndFont } = useU4heroColors();
  const [isMobile] = useMediaQuery('(max-width: 768px)');

  const graphs = Form.useWatch('graphs', form);

  const updateForm = (
    fieldName: string | number | (string | number)[],
    value: any
  ) => {
    form.setFieldValue(fieldName, value);
  };

  return (
    <Collapse
      style={{ width: '100%' }}
      expandIconPosition="end"
      defaultActiveKey={graphIndex !== undefined ? '2' : undefined}
    >
      <Collapse.Panel
        style={{
          background: '#F5F5F5',
          borderRadius: '10px',
          width: '100%',
        }}
        header={<Text>Personalização dos Gráficos</Text>}
        key={'2'}
        forceRender
      >
        <Form.List name="graphs">
          {(fields, { add, remove }) => (
            <Flex vertical gap={16} style={{ marginTop: '16px' }}>
              {fields.map((field, index) => (
                <Collapse
                  key={field.key}
                  style={{ width: '100%' }}
                  expandIconPosition="end"
                  expandIcon={({ isActive }) =>
                    isActive ? (
                      <RightOutlined
                        style={{
                          fontSize: '14px',
                          color: 'white',
                          transform: 'rotate(90deg)',
                        }}
                      />
                    ) : (
                      <RightOutlined
                        style={{ fontSize: '14px', color: 'white' }}
                      />
                    )
                  }
                  defaultActiveKey={graphIndex}
                >
                  <Collapse.Panel
                    style={{
                      background: hex2rgba(colorPrimary, 0.6),
                      borderRadius: '10px 10px 10px 0px',
                      width: '100%',
                    }}
                    header={
                      <Text size="lg" color="white">
                        Gráfico {index + 1}
                      </Text>
                    }
                    key={index}
                    forceRender
                  >
                    <Flex
                      vertical
                      style={{
                        marginTop: '8px',
                        padding: '16px',
                        borderRadius: '10px',
                        border: `0.5px solid ${colorGreyBorderAndFont}`,
                      }}
                    >
                      <Form.Item hidden name="id" />
                      <CategoryInput index={index} updateForm={updateForm} />
                      <SourceInput index={index} updateForm={updateForm} />
                      <InnerSourceInput index={index} updateForm={updateForm} />

                      {graphs?.[index]?.category !== undefined &&
                      graphs?.[index]?.source !== undefined ? (
                        <>
                          {' '}
                          <Form.Item
                            label="Ano de Análise"
                            rules={[
                              {
                                required: true,
                                message:
                                  'Por favor,  defina o ano à ser ser levado em consideração!',
                              },
                            ]}
                            name={[index, 'yearOfAnalysis']}
                          >
                            {/* @ts-ignore */}
                            <SelectYear />
                          </Form.Item>
                          {graphs[index]?.category !== 'emotion' && (
                            <Form.Item
                              label="Indicadores"
                              rules={[
                                {
                                  required: true,
                                  message: 'Por favor,  defina os indicadores!',
                                },
                              ]}
                              name={[index, 'tags']}
                            >
                              {/* @ts-ignore */}
                              <SelectIndicators />
                            </Form.Item>
                          )}
                          <AnalysisTypeInput index={index} />
                          <AnalysisEnvironmentInput index={index} />
                          <GraphViewInput
                            index={index}
                            updateForm={updateForm}
                          />
                          <ComparisonModeInput
                            index={index}
                            updateForm={updateForm}
                          />
                          <ComparativeInput
                            index={index}
                            updateForm={updateForm}
                          />
                          <TimeInput index={index} updateForm={updateForm} />
                          <Form.Item
                            label="Estilo do Gráfico"
                            rules={[
                              {
                                required: true,
                                message:
                                  'Por favor,  escolha o tipo do gráfico!',
                              },
                            ]}
                            name={[index, 'type']}
                          >
                            {/* @ts-ignore */}
                            <SelectGraphType
                              id="id-tour-my-indicators-custom-panel-graph-type"
                              index={index}
                            />
                          </Form.Item>
                          <Form.Item
                            label="Nome do Gráfico"
                            rules={[
                              {
                                required: true,
                                message:
                                  'Por favor, crie um nome para esse gráfico!',
                              },
                            ]}
                            name={[index, 'name']}
                          >
                            <Input
                              id="id-tour-my-indicators-custom-panel-graph-name"
                              placeholder="Crie um nome para seu gráfico"
                              size={isMobile ? 'middle' : 'large'}
                            />
                          </Form.Item>
                        </>
                      ) : (
                        <Flex
                          vertical
                          align="center"
                          justify="center"
                          gap="16px"
                        >
                          <LoadingDots />
                          <Text size="sm" color={colorPrimary}>
                            Preencha os campos para continuar criando o gráfico
                          </Text>
                        </Flex>
                      )}
                    </Flex>
                  </Collapse.Panel>
                </Collapse>
              ))}
              <Flex justify="center">
                {' '}
                <Button
                  size="md"
                  onClick={() =>
                    add({
                      id: uuidv4(),
                      tags: [],
                    })
                  }
                >
                  + GRÁFICOS
                </Button>
              </Flex>
            </Flex>
          )}
        </Form.List>
      </Collapse.Panel>
    </Collapse>
  );
}

export default CustomizeGraphs;
