import { Flex } from 'antd';
import Text from 'components/typography/text';
import lumiWithLogo from 'assets/images/lumi-with-logo.png';
import element1 from 'assets/images/elemento_01.png';
import element2 from 'assets/images/elemento_02.png';

function StudenKeyItem({
  school,
  name,
  license,
}: {
  school: string;
  name: string;
  license: string;
}) {
  return (
    <Flex vertical gap={8} style={{ position: 'relative' }}>
      <Flex justify="space-between">
        <img src={lumiWithLogo} style={{ filter: 'grayscale(100%)' }} />
        <Text size="sm">
          <strong>Escola: </strong>
          {school}
        </Text>
        <img src={element2} style={{ filter: 'grayscale(100%)'}} />
      </Flex>
      <img
        src={element1}
        style={{
          filter: 'grayscale(100%)',
          position: 'absolute',
          bottom: 0,
          left: 0,
        }}
      />
      <div style={{ paddingLeft: 12, paddingRight: 16, paddingBottom: 10 }}>
        <Text style={{ fontSize: 12 }}>
          Olá, bem-vindo(a) à U4Hero! Você está a apenas 2 passos de iniciar sua
          jornada de diversão e aprendizagem:
          <ol style={{ paddingLeft: 12 }}>
            <li>
              Faça o download do nosso app nas lojas (Play Store, Windows Store
              ou App Store) ou acesse diretamente pelo navegador <p style={{textDecoration:'underline'}}>play.u4hero.com.</p>
            </li>
            <li>Insira sua chave de acesso e clique em REGISTRAR.</li>
          </ol>
        </Text>
        <Flex justify="space-between">
          <Text size="sm">
            <strong>Nome: </strong>
            {name}
          </Text>
          <Text size="sm">
            <strong>Chave: </strong>
            {license.toUpperCase()}
          </Text>
        </Flex>
        <Text style={{ fontSize: 12 }}>
          Prepare-se para uma experiência incrível de desenvolvimento e
          crescimento com a <strong>U4Hero!</strong>
        </Text>
        <Text style={{ fontSize: 10 }} align="end">
          Qualquer dúvida mande um e-mail para:
        </Text>
        <Text style={{ fontSize: 10 }} align="end">
         atendimento@u4hero.com
        </Text>
      </div>
    </Flex>
  );
}

export default StudenKeyItem;
