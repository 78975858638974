import { useMediaQuery } from '@chakra-ui/react';
import { Tabs } from 'antd';
import Layout from 'components/layout';
import Title from 'components/typography/title';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import { NumberParam, useQueryParam } from 'use-query-params';
import Catalog from './components/tabs/catalog';
import Overview from './components/tabs/overview';
import Rankings from './components/tabs/rankings';

function GamesPage() {
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const [step, setStep] = useQueryParam('tab', NumberParam);
  const { colorGreyBorderAndFont } = useU4heroColors();

  if (!step) {
    setStep(1);
  }

  const items = [
    {
      key: '1',
      label: (
        <p
          style={{
            fontSize: isMobile ? '14px' : '24px',
            fontWeight: 700,
            color: 1 !== step ? colorGreyBorderAndFont : '',
          }}
        >
          VISÃO GERAL
        </p>
      ),
      children: <Overview />,
    },
    {
      key: '2',
      label: (
        <p
          style={{
            fontSize: isMobile ? '14px' : '24px',
            fontWeight: 700,
            color: 2 !== step ? colorGreyBorderAndFont : '',
          }}
        >
          CATÁLOGO
        </p>
      ),
      children: <Catalog />,
    },
    // {
    //   key: '3',
    //   label: (
    //     <p
    //       style={{
    //         fontSize: isMobile ? '14px' : '24px',
    //         fontWeight: 700,
    //         color: 3 !== step ? colorGreyBorderAndFont : '',
    //       }}
    //     >
    //       RANKINGS
    //     </p>
    //   ),
    //   children: <Rankings />,
    // },
  ];

  return (
    <Layout.Container>
      <Title primary>Jogos</Title>

      <Tabs
        centered
        onTabClick={(event) => setStep(Number(event))}
        size="large"
        defaultActiveKey={step?.toString()}
        items={items}
      />
    </Layout.Container>
  );
}

export default GamesPage;
