import { useMediaQuery } from '@chakra-ui/react';
import { Flex, Modal, Spin } from 'antd';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import { IoClose } from 'react-icons/io5';
import Button from 'components/buttons/button';
import mobileBg from 'assets/images/background-age-range-mobile.png';
import bg from 'assets/images/background-age-range.png';

export const series = [
  { name: '1º Ano (EF)', min: 5, max: 7 },
  { name: '2º Ano (EF)', min: 6, max: 8 },
  { name: '3º Ano (EF)', min: 7, max: 9 },
  { name: '4º Ano (EF)', min: 8, max: 10 },
  { name: '5º Ano (EF)', min: 9, max: 11 },
  { name: '6º Ano (EF)', min: 10, max: 12 },
  { name: '7º Ano (EF)', min: 11, max: 13 },
  { name: '8º Ano (EF)', min: 12, max: 14 },
  { name: '9º Ano (EF)', min: 13, max: 15 },
  { name: '1º Ano (E.M)', min: 14, max: 16 },
  { name: '2º Ano (E.M)', min: 15, max: 17 },
  { name: '3º Ano (E.M)', min: 16, max: 18 }
];

function ModalAges({ onClose }: { onClose: () => void }) {
  const { colorPrimary } = useU4heroColors();
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  return (
    <Modal
      title={
        <p
          style={{
            fontSize: isMobile ? '18px' : '24px',
            color: colorPrimary,
            fontWeight: 700,
          }}
        >
          Saiba mais!
        </p>
      }
      open={true}
      onCancel={onClose}
      footer={null}
      width={isMobile ? '100%' : 478}
      closeIcon={
        <div
          style={{
            background: colorPrimary,
            borderRadius: '50%',
            width: '20px',
            height: '20px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <IoClose size={16} color="white" />
        </div>
      }
    >
      <div
        style={{
          backgroundImage: `url(${isMobile ? mobileBg : bg})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundColor: colorPrimary,
          borderRadius: '20px',
          display: 'flex',
          flexDirection: 'column',
          padding: '8px',
        }}
      >
        <p
          style={{
            fontSize: isMobile ? '14px' : '18px',
            color: 'white',
            textAlign: 'justify',
          }}
        >
          Ao criar uma pergunta, é crucial selecionar a faixa etária adequada
          para garantir a adequação do conteúdo à idade e ao desenvolvimento dos
          estudantes.
          {isMobile
            ? ' Além disso, é importante observar as regras específicas para direcionar a pergunta à turma correta.'
            : ''}
        </p>
        {!isMobile && (
          <p
            style={{
              fontSize: isMobile ? '14px' : '18px',
              color: 'white',
              maxWidth: '312px',
              textAlign: 'justify',
            }}
          >
            Além disso, é importante observar as regras específicas para
            direcionar a pergunta à turma correta.
          </p>
        )}
      </div>
  
      <Flex vertical gap="8px" style={{ marginTop: '21px' }}>
        {series.map((item) => (
          <div
            style={{
              borderRadius: '5px',
              boxShadow:
                '0px -0.5px 4px 0px rgba(38, 38, 38, 0.10), 0px 3px 4px 0px rgba(38, 38, 38, 0.10)',
              background: 'white',
              padding: '6px 16px',
              fontSize: isMobile ? '14px' : '18px',
            }}
          >
            <strong>{item.name}</strong> - {item.min} à {item.max} anos
          </div>
        ))}
        <Button size='md' style={{alignSelf:'center',marginTop:'8px'}} onClick={onClose}>Concluir</Button>
      </Flex>

    </Modal>
  );
}

export default ModalAges;
