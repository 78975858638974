import { useMediaQuery } from '@chakra-ui/react';
import { Dropdown, Menu } from 'antd';
import useCheckPermissions from 'core/features/user/hooks/useCheckPermissions';
import { useCurrentUser } from 'core/features/user/store';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import { useEffect, useState } from 'react';
import { Permissions } from 'core/resources/enums/permissions';
import moment from 'moment';
import { MdExpandLess, MdExpandMore } from 'react-icons/md';
import DynamicTable from 'components/V2/dinamic-table';
import { FaEllipsisVertical } from 'react-icons/fa6';
import useAutomatedActivities from 'core/features/activity/hooks/useAutomatedActivities';
import { AutomatedDetails } from 'core/features/activity/types';
import { useDeleteAutomatedActivity } from 'core/features/activity/hooks/useDeleteAutomatedActivity';
import AutomationFilter from '../AutomationFilter';
import {
  notificateError,
  notificateSucess,
  showConfirm,
} from 'infra/helpers/notifications';
import { FilterType } from 'infra/helpers/types';

function AutomationsTable({
  onEdit,
}: {
  onEdit: (item: AutomatedDetails) => void;
}) {
  const [check] = useCheckPermissions();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState('');
  const [filters, setFilters] = useState<FilterType>({
    series: [],
    classes: [],
    initialDate: [],
    endDate: [],
  });
  const { user } = useCurrentUser();
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const { colorPrimary } = useU4heroColors();
  const { data, isLoading, refetch, isFetching } = useAutomatedActivities({
    page,
    search,
    tenantId: user?.tenantId,
    responsibleId: user?.resources.includes(Permissions.Activity.ViewAll)
      ? undefined
      : user?.id,
    startSchedule:
      filters?.initialDate.map((item) => item.value)[0] ?? undefined,
    endSchedule: filters?.endDate.map((item) => item.value)[0] ?? undefined,
    series: filters?.series?.map((item) => item.value) ?? undefined,
    classes: filters?.classes?.map((item) => item.value) ?? undefined,
    limit: pageSize,
  });

  const { mutate: handleDelete } = useDeleteAutomatedActivity();
  const isEachLoading = isLoading || isFetching;

  const tableHeaderStyle = {
    fontSize: isMobile ? '14px' : '18px',
    fontWeight: 400,
  };
  const tableRowStyle = {
    fontSize: isMobile ? '14px' : '16px',
    fontWeight: 400,
  };
  const columns = [
    {
      title: <p style={tableHeaderStyle}>Responsável</p>,
      name: 'Responsável',
      dataIndex: 'responsibleName',
      key: 'responsibleName',
      render: (_: string, record: AutomatedDetails) => (
        <p style={tableRowStyle}>{record.responsibleName}</p>
      ),
      sorter: (a: any, b: any) =>
        a.responsibleName.localeCompare(b.responsibleName),
      sortIcon: (sortOrder: any) =>
        sortOrder.sortOrder === 'descend' ? (
          <MdExpandLess size={25} />
        ) : (
          <MdExpandMore size={25} />
        ),
    },
    {
      title: <p style={tableHeaderStyle}>Turma</p>,
      dataIndex: 'className',
      key: 'className',
      name: 'Turma',
      render: (_: string, record: AutomatedDetails) => (
        <p style={tableRowStyle}>{record.className}</p>
      ),
      sorter: (a: any, b: any) => a.className.localCompare(b.className),
      sortIcon: (sortOrder: any) =>
        sortOrder.sortOrder === 'descend' ? (
          <MdExpandLess size={25} />
        ) : (
          <MdExpandMore size={25} />
        ),
    },
    {
      title: <p style={tableHeaderStyle}>Frequência</p>,
      dataIndex: 'period',
      key: 'period',
      name: 'Frequência',
      render: (_: string, record: AutomatedDetails) => (
        <p style={tableRowStyle}>{record.period} dia(s)</p>
      ),
      sorter: (a: any, b: any) => b.period - a.period,
      sortIcon: (sortOrder: any) =>
        sortOrder.sortOrder === 'descend' ? (
          <MdExpandLess size={25} />
        ) : (
          <MdExpandMore size={25} />
        ),
    },
    {
      title: <p style={tableHeaderStyle}>Início</p>,
      key: 'startSchedule',
      dataIndex: 'startSchedule',
      name: 'Início',
      render: (_: string, record: AutomatedDetails) => (
        <p style={tableRowStyle}>
          {moment.utc(record.startSchedule).format('DD/MM/YYYY')}
        </p>
      ),
      sorter: (a: any, b: any) =>
        a.startSchedule.localeCompare(b.startSchedule),
      sortIcon: (sortOrder: any) =>
        sortOrder.sortOrder === 'descend' ? (
          <MdExpandLess size={25} />
        ) : (
          <MdExpandMore size={25} />
        ),
    },
    {
      title: <p style={tableHeaderStyle}>Término</p>,
      key: 'endSchedule',
      dataIndex: 'endSchedule',
      name: 'Término',
      render: (_: string, record: AutomatedDetails) => (
        <p style={tableRowStyle}>
          {moment.utc(record.endSchedule).format('DD/MM/YYYY')}
        </p>
      ),
      sorter: (a: any, b: any) => a.endSchedule.localeCompare(b.endSchedule),
      sortIcon: (sortOrder: any) =>
        sortOrder.sortOrder === 'descend' ? (
          <MdExpandLess size={25} />
        ) : (
          <MdExpandMore size={25} />
        ),
    },
    {
      title: <p style={tableHeaderStyle}>Ações</p> ,
      key: 'action',
      mobile: true,
      dataIndex: 'action',
      name: 'Ações',
      render: (_: string, record: AutomatedDetails) => (
        <Dropdown
          overlay={
            <Menu
              onClick={(e) => {
                if (e.key === 'edit') {
                  onEdit(record);
                }
                if (e.key === 'delete') {
                  if (user?.id !== record.responsibleId) {
                    notificateError(`Você não é o responsável pela automação!`);
                  } else {
                    showConfirm({
                      message: 'Tem certeza que deseja excluir a automação?',
                      colorPrimary,
                      isMobile,
                    }).then(({ isConfirmed }) => {
                      if (isConfirmed) {
                        handleDelete(record._id, {
                          onSuccess: () => {
                            notificateSucess(
                              `A automação foi excluída com sucesso!`
                            );
                            refetch();
                          },
                          onError: () => {
                            notificateError(
                              `Houve um problema ao excluir a automação. Verifique sua conexão e tente novamente.`
                            );
                          },
                        });
                      }
                    });
                  }
                }
              }}
            >
              {check([Permissions.Activity.Edit]) ? (
                <Menu.Item key={'edit'}>Editar</Menu.Item>
              ) : null}

              {check([Permissions.Activity.Delete]) ? (
                <Menu.Item key={'delete'}>Deletar</Menu.Item>
              ) : null}
            </Menu>
          }
          trigger={['click']}
        >
          <FaEllipsisVertical size={24} style={{ cursor: 'pointer' }} />
        </Dropdown>
      ),
    },
  ];

  useEffect(() => {
    setPage(1);
  }, [search, filters]);
  return (
    <DynamicTable
      id="id-tour-my-school-section-automations-table"
      itemName="automação"
      data={data?.data ?? []}
      onChangePage={setPage}
      onChangePageSize={setPageSize}
      title=""
      total={data?.total ?? 0}
      page={page}
      pageSize={pageSize}
      isLoading={isEachLoading}
      defaultColumns={
        isMobile ? columns.filter((item) => item.mobile) : columns
      }
      allColumns={columns}
      onSearch={setSearch}
      appliedFilters={filters}
      onChangeFilters={setFilters}
      filters={
        <AutomationFilter filters={filters} onChangeFilters={setFilters} />
      }
    />
  );
}

export default AutomationsTable;
