import { useMediaQuery } from '@chakra-ui/react';
import { Form, Radio, Space } from 'antd';

function SourceInput({
  index,
  updateForm,
}: {
  index: number;
  updateForm: (
    fieldName: string | number | (string | number)[],
    value: any
  ) => void;
}) {
  const [isMobile] = useMediaQuery('(max-width: 768px)');

  return (
    <Form.Item
    rules={[
      {
        required: true,
        message:
          'Por favor,  defina os dados à serem exibidos!',
      },
    ]}
    name={[index,'source']}
    label="Dados a serem exibidos, referente à:"
  >
    <Radio.Group
      id="id-tour-my-indicators-custom-panel-source-input"
      onChange={()=>{
        updateForm(['graphs', index, 'serieId'], undefined);
        updateForm(['graphs', index, 'classId'], undefined);
        updateForm(['graphs', index, 'studentId'], undefined);
      }}
    >
      <Space direction={isMobile ? 'vertical' : 'horizontal'}>
        <Radio value={'school'}>Escola</Radio>
        <Radio value={'serie'}>Série</Radio>
        <Radio value={'class'}>Turma</Radio>
        <Radio value={'student'}>Estudante</Radio>
      </Space>
    </Radio.Group>
  </Form.Item>
  );
}

export default SourceInput;
