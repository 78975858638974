import { Flex, Spin, Tooltip } from 'antd';
import SubTitle from 'components/typography/subTitle';
import { GenericCard } from 'components/V2/generic-card-styled-component';
import { Graph } from 'core/features/indicators-profile/typings';
import useIndicatorsData from 'core/features/indicators/hooks/useIndicatorsData';
import { t } from 'core/resources/strings';
import { FiTrash2 } from 'react-icons/fi';
import { IoDuplicateOutline } from 'react-icons/io5';
import { TfiPencil } from 'react-icons/tfi';
import AreaGraph from './graphs/areaGraph';
import BarGraph from './graphs/barGraph';
import ColumnGraph from './graphs/columnGraph';
import DonutGraph from './graphs/donutGraph';
import LineGraph from './graphs/lineGraph';
import PieGraph from './graphs/pieGraph';
import RadarGraph from './graphs/radarGraph';
import StackedGraph from './graphs/stackedGraph';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';

const graphsTypes = [
  {
    value: 'line-graph',
    label: 'Gráfico de linha',
    time: true,
    graphComponent: LineGraph,
  },
  {
    value: 'area-graph',
    label: 'Gráfico de área',
    time: true,
    graphComponent: AreaGraph,
  },
  {
    value: 'bar-graph',
    label: 'Gráfico de barra',
    comparative: true,
    graphComponent: BarGraph,
  },

  {
    value: 'radar-graph',
    label: 'Gráfico de radar',
    comparative: true,
    emotion: true,
    graphComponent: RadarGraph,
  },
  {
    value: 'pizza-graph',
    label: 'Gráfico de pizza',
    emotion: true,
    graphComponent: PieGraph,
  },
  {
    value: 'donut-graph',
    label: 'Gráfico de donut',
    emotion: true,
    graphComponent: DonutGraph,
  },
  {
    value: 'column-graph',
    label: 'Gráfico de coluna',
    time: true,
    comparative: true,
    graphComponent: ColumnGraph,
  },
  {
    value: 'stacked-graph',
    label: 'Gráfico de coluna empilhada',
    comparative: true,
    graphComponent: StackedGraph,
  },
];

function CustomGraph({
  graph,
  onRemove,
  onEdit,
  onDuplicate,
}: {
  graph: Graph;
  onRemove?: () => void;
  onEdit?: () => void;
  onDuplicate?: () => void;
}) {
  const graphType = graphsTypes.find((g) => g.value === graph.type);
  const { data, isLoading } = useIndicatorsData({ ...graph });
  const GraphComponent = graphType?.graphComponent;

  // const { colorPrimary } = useU4heroColors();

  const sources = {
    school: 'Escola',
    serie: 'Série',
    class: 'Turma',
    student: 'Estudante',
  };

  const views = {
    time: 'Temporal',
    year: 'Ano',
    comparative: 'Comparativo',
  };
  const analysisTypes = ['Conhecimento', 'Comportamento', 'Percepção', 'Todos'];
  const environments = ['Familiar', 'Escolar', 'Digital', 'Social', 'Todos'];
  return (
    <GenericCard height="100%">
      <Flex vertical style={{ width: '100%' }}>
        <Flex vertical style={{ width: '100%' }}>
          <Flex justify="space-between">
            <SubTitle primary>{graph.name}</SubTitle>
            <Flex gap={16} align="center">
              {onEdit && (
                <Tooltip title="Editar">
                  <TfiPencil
                    onClick={onEdit}
                    style={{
                      fontSize: 16,
                      color: 'black',
                      cursor: 'pointer',
                    }}
                  />
                </Tooltip>
              )}

              {onDuplicate && (
                <Tooltip title="Copiar">
                  <IoDuplicateOutline
                    onClick={onDuplicate}
                    style={{
                      fontSize: 16,
                      color: 'black',
                      cursor: 'pointer',
                    }}
                  />
                </Tooltip>
              )}

              {onRemove && (
                <Tooltip title="Deletar">
                  <FiTrash2
                    onClick={onRemove}
                    style={{
                      fontSize: 16,
                      color: 'black',
                      cursor: 'pointer',
                    }}
                  />
                </Tooltip>
              )}
            </Flex>
          </Flex>
          <Flex gap={4} justify="flex-end">
            <GraphTag text={t(`app.${graph.category}`)} />
            <GraphTag text={sources[graph.source]} />
            {graph.analysisTypeId !== undefined && (
              <GraphTag text={analysisTypes[graph.analysisTypeId]} />
            )}
            {graph.analysisEnvironmentId !== undefined && (
              <GraphTag text={environments[graph.analysisEnvironmentId]} />
            )}
            <GraphTag text={views[graph.graphView]} />
          </Flex>
        </Flex>
        {isLoading ? (
          <Spin size="large" />
        ) : (
          <>
            {GraphComponent && (
              <GraphComponent
                categories={data?.data.categories}
                series={data?.data.series}
              />
            )}
          </>
        )}
      </Flex>
    </GenericCard>
  );
}

export default CustomGraph;

const GraphTag = ({ text }: { text: string }) => {
  return (
    <div
      style={{
        padding: '4px',
        background: '#E9E9E9',
        color: 'black',
        fontSize: '12px',
      }}
    >
      {text}
    </div>
  );
};
