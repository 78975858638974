import Polyglot from "node-polyglot";
import moment from "moment";

//const locale = useCurrentUser.getState().user?.locale;

export const currentLocale = moment.locale(); //TODO: locale esta fixo pt-br

const polyglot = new Polyglot({
  locale: currentLocale,
});

let { t, extend } = polyglot;

t = t.bind(polyglot);
extend = extend.bind(polyglot);

export const translate = (locale: string, strings: any) => {
  if (locale === currentLocale) {
    extend(strings);
  }
};

export { t };
