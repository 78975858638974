import { useMediaQuery } from '@chakra-ui/react';
import { Flex, Modal } from 'antd';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import { IoClose } from 'react-icons/io5';
import Text from 'components/typography/text';
import AuthCode, { AuthCodeRef } from 'react-auth-code-input';
import { useRef, useState } from 'react';
import SubTitle from 'components/typography/subTitle';
import { useValidate2FaPrivate } from 'core/features/auth/hooks/useValidate2FaPrivate';
import { notificateError, notificateSucess } from 'infra/helpers/notifications';
import useToggle2Fa from 'core/features/auth/hooks/useToggle2Fa';
import Button from 'components/buttons/button';
import { useCurrentUser } from 'core/features/user/store';

function Modal2Fa({
  activate,
  onClose,
  onSucess
}: {
  activate: boolean;
  onClose: () => void;
  onSucess: () => void;
}) {
  const AuthInputRef = useRef<AuthCodeRef>(null);
  const {user} = useCurrentUser();
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const { colorPrimary } = useU4heroColors();
  const { mutate: handle2Fa,isLoading } = useValidate2FaPrivate();
  const [authCode, setAuthCode] = useState('');
  const [isInvalid, setIsInvalid] = useState(false);
  const { mutate: toggle2Fa } = useToggle2Fa();
  const _handle2Fa = () => {
    handle2Fa(
      {
        code: authCode,
      },
      {
        onSuccess: (data: any) => {
          if (data.data.success) {
            setAuthCode('');
            setIsInvalid(false);
            notificateSucess(
              `A autenticação de dois fatores foi ${
                activate ? 'ativada' : 'desativada'
              } com sucesso!`
            );
            onSucess();
            onClose();

          }else{
            setAuthCode('');
            setIsInvalid(true);
            notificateError(
              `O código autenticação está inválido ou expirado!`
            );
          }
        
        },
        onError: () => {
          notificateError(
            `A ${
              activate ? 'ativação' : 'desativação'
            } da autenticação de dois fatores falhou. Por favor, insira o código enviado por e-mail para concluir o processo de desativação.`
          );
        },
      }
    );
  };
  const _handleToggle = (isActive: boolean) => {
    toggle2Fa(isActive, {
      onSuccess: (data: any) => {
        notificateSucess('Te enviamos um novo código, cheque seu email!');
      },
      onError: () => {
        notificateError(
          'Ocorreu um problema ao enviar o codigo. Tente novamente.'
        );
      },
    });
  };
  return (
    <Modal
      title={
        <SubTitle primary>
          {activate ? 'Segurança Reforçada!' : 'Desativação da Autenticação!'}
        </SubTitle>
      }
      closeIcon={
        <div
          style={{
            background: colorPrimary,
            borderRadius: '50%',
            width: '20px',
            height: '20px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <IoClose size={16} color="white" />
        </div>
      }
      open={true}
      centered
      onCancel={onClose}
      footer={null}
      width={450}
    >
      <Flex vertical gap="8px">
        <Text>
          {activate
            ? ' Você ativou a autenticação de dois fatores. Um e-mail com o código de segurança foi enviado para você.'
            : 'Você desativou a autenticação de dois fatores. Verifique seu e-mail para inserir o código de segurança e concluir o processo.'}
        </Text>
        <Text>Digite aqui o código que te enviamos pelo email.</Text>
        <AuthCode
          inputClassName="input-auth"
          containerClassName="container-auth"
          allowedCharacters="numeric"
          onChange={(res) => setAuthCode(res)}
          ref={AuthInputRef}
        />
        {isInvalid ? (
          <Text color="red">Código inválido ou expirado.</Text>
        ) : null}
        <Button
          size="md"
          style={{ justifySelf: 'center', alignSelf: 'center' }}
          onClick={_handle2Fa}
          disabled={authCode.length < 6}
          loading={isLoading}
        >
          Verificar
        </Button>
        <Text
          style={{
            fontSize: isMobile ? '14px' : '18px',
            fontWeight: 400,
            color: 'black',
          }}
        >
          O recebimento do seu código pode levar até um minuto. Se não o
          recebeu,
          <button
            style={{ textDecoration: 'underline', color: 'black' }}
            onClick={() => {
              _handleToggle(activate);
              setIsInvalid(false);
            }}
          >
            clique aqui para solicitar um novo código
          </button>
        </Text>
      </Flex>
    </Modal>
  );
}

export default Modal2Fa;
