import { useMediaQuery } from '@chakra-ui/react';
import { Flex } from 'antd';
import BackButton from 'components/buttons/back-button';
import Button from 'components/buttons/button';
import InstitutionHeader from 'components/institution-header';
import Layout from 'components/layout';
import Title from 'components/typography/title';
import { useHistory, useParams } from 'react-router-dom';
import ClassesTable from './components/ClassesTable';
import HeaderImage from 'assets/images/classes-header-image.png';
import { useTenant } from 'core/features/tenants/hooks/useTenant';

function Classes() {
  const history = useHistory();
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const { tenantId } = useParams<{ tenantId: string }>();
  const { data: tenant } = useTenant(tenantId);
  
  return (
    <Layout.Container>
      <Flex gap={isMobile ? '12px' : '24px'} align="center">
        <BackButton
            onClick={() => {
              if (tenant) {
                history.push(`/schools?tab=${tenant?.master ? 1 : 2}`);
              } else {
                history.push('/my-school');
              }
            }}
        />
        <Title primary>Turmas</Title>
      </Flex>
      {tenant ? <InstitutionHeader image={HeaderImage} tenantName={tenant.name} /> : null}
      <Flex style={{ marginTop: '16px', marginBottom: '17px' }} justify="end">
        <Button
          id="id-tour-create-class-button-add-class"
          size="md"
          onClick={() => {
            if (tenantId) {
              history.push(`/schools/${tenantId}/classes/create`);
            } else {
              history.push('/my-school/classes/create');
            }
          }}
        >
          Criar Turma
        </Button>
      </Flex>

      <ClassesTable />
    </Layout.Container>
  );
}

export default Classes;
