import { Select } from 'antd';
import { useMediaQuery } from '@chakra-ui/react';
import useSeries from 'core/features/series/hooks/useSeries';

interface SelectSerieProps {
  value: string;
  onChange: (v: string) => void;
  cleanDependentFields?: () => void;
  id?: string;
}

function SelectSerie({ value, onChange, cleanDependentFields, id }: SelectSerieProps) {
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const { data } = useSeries();

  return (
    <Select
      id={id}
      placeholder="Selecione a série"
      options={data?.map((serie) => ({ label: serie.name, value: serie.id }))}
      onChange={(v) => {
        onChange(v);
        cleanDependentFields && cleanDependentFields();
      }}
      value={value}
      size={isMobile ? 'middle' : 'large'}
    />
  );
}

export default SelectSerie;
