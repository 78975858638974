import { Flex } from 'antd';
import Dot from 'components/dot';

const sizes = {
  sm:{
    padding:'4px 8px',
    fontSize:'12px',
    dot:6
  },
  md:{
    padding:'4px 8px',
    fontSize:'14px',
    dot:8
  },
  lg:{
    padding:'8px 8px',
    fontSize:'16px',
    dot:10
  },
}
const types = {
  active:{
    bg:'#CEEACF',
    color:'#336732',
    text:'ATIVO'
  },
  inactive:{
    bg:'#FFCDD3',
    color:'#BB191E',
    text:'INATIVO'
  },
  progress:{
    bg:'#B2EBFE',
    color:'#0067AA',
    text:'EM ANDAMENTO'
  },
  notStarted:{
    bg:'#FFE4A9',
    color:'#8A4C00',
    text:'NÃO INICIADA'
  },
  done:{
    bg:'#CEEACF',
    color:'#336732',
    text:'CONCLUÍDO'
  },
  expired:{
    bg:'#FFCDD3',
    color:'#BB191E',
    text:'EXPIRADA'
  },
  student: {
    bg:'#CEEACF',
    color:'#336732',
    text:'ESTUDANTE'
  },
  exStudent: {
    bg:'#FFCDD3',
    color:'#BB191E',
    text:'EX-ESTUDANTE'
  }
}
function Status({
  type,
  size = 'lg',
}: {
  type: 'active' | 'inactive' | 'progress' | 'notStarted' | 'done' | 'expired' | 'exStudent' | 'student';
  size?: 'sm' | 'md' | 'lg';
}) {

  return (
    <Flex
      align="center"
      style={{
        fontWeight: 'bold',
        background: types[type].bg ,
        padding: sizes[size].padding,
        borderRadius: '4px',
        width: 'fit-content',
        gap: 8,
      }}
    >
      <Dot size={sizes[size].dot} color={types[type].color} />
      <p
        style={{
          fontSize: sizes[size].fontSize,
          fontWeight: 600,
          color: types[type].color,
        }}
      >
        {types[type].text}
      </p>
    </Flex>
  );
}

export default Status;
