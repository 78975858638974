import { useMediaQuery } from '@chakra-ui/media-query';
import { Col, Flex, Row, Collapse, Switch } from 'antd';
import Text from 'components/typography/text';
import { t } from 'core/resources/strings';

function ResourcesTab({
  groups,
  setResources,
  setUpdate,
  resources,
}: {
  groups: {
    name: string;
    children: {
      id: string;
      name: string;
    }[];
  }[];
  setResources: React.Dispatch<React.SetStateAction<string[]>>;
  setUpdate?: React.Dispatch<React.SetStateAction<boolean>>;
  resources: string[];
}) {
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const removeResources = (values: string[]) => {
    setResources((prev) =>
      prev.filter((resource) => !values.includes(resource))
    );
    setUpdate && setUpdate(true);
  };

  const addResources = (values: string[]) => {
    setResources((prev) => [...prev, ...values]);
    setUpdate && setUpdate(true);
  };


  return (
    <Row gutter={[24, 32]}>
      <Col span={24}>
        <Flex gap="24px" align="center" justify="flex-end">
          <Text size="lg">Acesso Completo</Text>
          <Switch
            onChange={(checked) =>
              checked
                ? addResources(
                    groups.flatMap((group) =>
                      group.children.map((child) => child.id)
                    )
                  )
                : removeResources(
                    groups.flatMap((group) =>
                      group.children.map((child) => child.id)
                    )
                  )
            }
            checked={groups
              .flatMap((group) => group.children.map((child) => child.id))
              .every((element) => resources.includes(element))}
          />
        </Flex>
      </Col>

      {groups.map((item) => (
        <Col span={isMobile ? 24:8} key={item.name}>
          <Collapse
            expandIconPosition="end"
            items={[
              {
                key: '1',
                label: (
                  <Flex gap="24px" align="center">
                    <Switch
                      onClick={(checked, e) => e.stopPropagation()}
                      onChange={(checked) =>
                        checked
                          ? addResources(item.children.map((item) => item.id))
                          : removeResources(
                              item.children.map((item) => item.id)
                            )
                      }
                      checked={item.children
                        .map((item) => item.id)
                        .every((element) => resources.includes(element))}
                    />
                    <Text size="lg">{t(`app.${item.name}`)}</Text>
                  </Flex>
                ),
                children: (
                  <Flex gap={5} style={{ padding: '10px' }} vertical>
                    {item.children.map((child) => (
                      <Flex gap="24px" align="center" key={child.id}>
                        <Switch
                          onChange={(checked) =>
                            checked
                              ? addResources([child.id])
                              : removeResources([child.id])
                          }
                          checked={resources.includes(child.id)}
                        />
                        <Text size="lg"> {t(`app.${child.name}`)} </Text>
                      </Flex>
                    ))}
                  </Flex>
                ),
              },
            ]}
          />
        </Col>
      ))}
    </Row>
  );
}

export default ResourcesTab;
