import { useMediaQuery } from '@chakra-ui/react';
import {  Col, Empty, Flex, Pagination, Row, Spin } from 'antd';
import BackButton from 'components/buttons/back-button';
import Layout from 'components/layout';
import Title from 'components/typography/title';
import SearchBar from 'components/V2/SearchBar';
import usePosts from 'core/features/posts/hooks/usePosts';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import PostCard from './components/postCard';

function Posts() {
  const history = useHistory();
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const [search,setSearch] = useState<string>();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(8);
  const { data, isLoading } = usePosts({ page: page, limit: pageSize, search });
  const { colorPrimary } = useU4heroColors();

  useEffect(()=>{
    setPage(1);
  },[search])
  return (
    <Layout.Container>
      <Flex vertical gap={isMobile ? '12px' : '24px'}>
        <Flex align="center" gap={isMobile ? '12px' : '40px'}>
          <BackButton onClick={() => history.goBack()}/>
          <Title primary>Blog de Novidades</Title>
        </Flex>
        <div
          style={{
            backgroundImage: "url('/estrelas_1.png')",
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundColor: colorPrimary,
            height: '154px',
            borderRadius: '20px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position:'relative',
            marginBottom:'24px'
          }}
        >
          <Title
            style={{ color: 'white', fontSize: isMobile ? '30px' : '40px' }}
          >
            Novidades
          </Title>
          <div style={{position:'absolute',bottom:-20}}>
          <SearchBar value={search} onSearch={setSearch}/>
          </div>
        </div>
        {isLoading ? (
          <Spin size="large" />
        ) : (
          <Flex vertical gap="16px">
            <Row gutter={[24, 24]} align="stretch">
              {data?.data.map((item) => (
                <Col span={isMobile ? 24 : 6} key={item.id}>
                  <PostCard post={item} />
                </Col>
              ))}
            </Row>
            {!isLoading && !data?.data.length ? <Empty /> : null}
            <Flex justify="center" style={{ marginBottom: '18px' }}>
              <Pagination
                defaultCurrent={1}
                showSizeChanger
                current={page}
                pageSize={pageSize}
                total={data?.total}
                onChange={(v, size) => {
                  setPage(v);
                  setPageSize(size);
                }}
              />
            </Flex>
          </Flex>
        )}
      </Flex>
    </Layout.Container>
  );
}

export default Posts;
