import { useMediaQuery } from '@chakra-ui/react';
import DynamicTable from 'components/V2/dinamic-table';
import useCheckPermissions from 'core/features/user/hooks/useCheckPermissions';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Permissions } from 'core/resources/enums/permissions';
import { useDeleteClass } from 'core/features/classes/hooks/useDeleteClass';
import { Button, Dropdown, Flex, Menu } from 'antd';
import { ClassDetails } from 'core/features/classes/types';
import { MdExpandLess, MdExpandMore } from 'react-icons/md';
import { FaEllipsisVertical } from 'react-icons/fa6';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import useClasses from 'core/features/classes/hooks/useClasses';
import { t } from 'core/resources/strings';
import Tag from 'components/V2/tag';
import {
  notificateError,
  notificateSucess,
  showConfirm,
} from 'infra/helpers/notifications';
import { FilterType } from 'infra/helpers/types';
import ClassesFilter from '../ClassesFilter';
import { SiGoogleclassroom } from 'react-icons/si';

function ClassesTable({
  onSelect,
  value,
  callback,
}: {
  onSelect?: (rowKeys: string[]) => void;
  value?: string[];
  callback?: (rows: ClassDetails[]) => void;
}) {
  const history = useHistory();

  const [check] = useCheckPermissions();
  if (
    !check([
      Permissions.Class.View,
      Permissions.U4hero.Tenant.Edit,
      Permissions.Org.Tenant.Edit,
    ])
  ) {
    history.push('/errors/no-permission');
  }

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [filters, setFilters] = useState<FilterType>({
    years: [],
    series: [],
  });

  const [search, setSearch] = useState('');
  const { tenantId } = useParams<{ tenantId: string }>();
  const { colorPrimary } = useU4heroColors();

  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const { mutate: handleDelete, isLoading: isLoadingDelete } = useDeleteClass();
  const { data, isLoading, isFetching, refetch } = useClasses({
    page,
    years: filters?.years?.map((item: any) => item.value) ?? undefined,
    series: filters?.series?.map((item: any) => item.value) ?? undefined,
    search,
    limit: pageSize,
    tenantId,
  });
  const isEachLoading = isLoading || isFetching || isLoadingDelete;
  const tableHeaderStyle = {
    fontSize: isMobile ? '14px' : '18px',
    fontWeight: 400,
  };
  const tableRowStyle = {
    fontSize: isMobile ? '14px' : '16px',
    fontWeight: 400,
  };
  const [selectedClasses, setSelectedClasses] = useState<ClassDetails[]>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>(value ?? []);

  const redirectToViewClass = (classId: string) => {
    if (tenantId) {
      history.push(`/schools/${tenantId}/classes/${classId}/view`);
    } else {
      history.push(`/my-school/classes/${classId}/view`);
    }
  };

  const redirectToDashboard = (classId: string) => {
    if (tenantId) {
      history.push(`/schools/${tenantId}/class/dashboard/${classId}`);
    } else {
      history.push(`/my-school/class/dashboard/${classId}`);
    }
  };

  const columns = [
    {
      title: (
        <p style={tableHeaderStyle}>
          {onSelect ? 'Turma/Série' : 'Nome/Série'}
        </p>
      ),
      name: onSelect ? 'Turma/Série' : 'Nome/Série',
      dataIndex: 'name',
      key: 'name',
      mobile: true,
      render: (_: string, record: ClassDetails) => (
        <Flex vertical style={tableRowStyle}>
          <p>{record.name}</p>
          <Tag text={record.serie.name} />
        </Flex>
      ),
      sorter: (a: any, b: any) => a.name.localeCompare(b.name),
      sortIcon: (sortOrder: any) =>
        sortOrder.sortOrder === 'descend' ? (
          <MdExpandLess size={25} />
        ) : (
          <MdExpandMore size={25} />
        ),
    },
    {
      title: <p style={tableHeaderStyle}>Chave</p>,
      name: 'Chave',
      dataIndex: 'user.email',
      key: 'user.email',
      mobile: true,
      render: (_: string, record: ClassDetails) => (
        <Flex>
          <p style={tableRowStyle}>{record?.user?.email}</p>
        </Flex>
      ),
    },
    {
      title: <p style={tableHeaderStyle}>Quantidade de Estudantes</p>,
      name: 'Qtd de Estudantes',
      dataIndex: 'countStudents',
      key: 'countStudents',
      render: (_: string, record: ClassDetails) => (
        <p style={tableRowStyle}>{record.countStudents}</p>
      ),
      sorter: (a: any, b: any) => a.countStudents - b.countStudents,
      sortIcon: (sortOrder: any) =>
        sortOrder.sortOrder === 'descend' ? (
          <MdExpandLess size={25} />
        ) : (
          <MdExpandMore size={25} />
        ),
    },
    {
      title: <p style={tableHeaderStyle}>Ano Letivo</p>,
      name: 'Ano Letivo',
      dataIndex: 'year',
      key: 'year',
      render: (_: string, record: ClassDetails) => (
        <p style={tableRowStyle}>{record.year}</p>
      ),
      sorter: (a: any, b: any) => a.countStudents - b.countStudents,
      sortIcon: (sortOrder: any) =>
        sortOrder.sortOrder === 'descend' ? (
          <MdExpandLess size={25} />
        ) : (
          <MdExpandMore size={25} />
        ),
    },
    {
      title: <p style={tableHeaderStyle}>Painel da Turma</p>,
      key: 'classPanel',
      dataIndex: 'classPanel',
      name: 'Painel da Turma',
      render: (_: string, record: ClassDetails) => (
        <Button
          type="primary"
          size="large"
          onClick={(e) => {
            e.stopPropagation();
            redirectToDashboard(record.id);
          }}
          style={{ padding: '8px' }}
        >
          <Flex>
            <SiGoogleclassroom size={20} color="white" />
          </Flex>
        </Button>
      ),
    },
    {
      title: <p style={tableHeaderStyle}>Ações</p>,
      key: 'action',
      mobile: true,
      dataIndex: 'action',
      name: 'Ações',
      render: (_: string, record: ClassDetails) => (
        <Dropdown
          overlay={
            <Menu
              onClick={(event) => {
                event.domEvent.stopPropagation();
                if (event.key === 'edit') {
                  if (tenantId) {
                    history.push(
                      `/schools/${tenantId}/classes/edit/${record.id}`
                    );
                  } else {
                    history.push(`/my-school/classes/${record.id}/edit`);
                  }
                }
                if (event.key === 'panel') {
                  redirectToDashboard(record.id);
                }
                if (event.key === 'delete') {
                  showConfirm({
                    message:
                      'Tem certeza que deseja excluir o registro da turma?',
                    colorPrimary,
                    isMobile,
                  }).then(({ isConfirmed }) => {
                    if (isConfirmed) {
                      handleDelete([record.id], {
                        onSuccess: () => {
                          notificateSucess('A turma foi excluída com sucesso!');
                          refetch();
                        },
                        onError: () => {
                          notificateError(
                            'Houve um problema ao excluir a turma. Verifique sua conexão e tente novamente.'
                          );
                        },
                      });
                    }
                  });
                }
                if (event.key === 're-enroll') {
                  if (tenantId) {
                    history.push(
                      `/schools/${tenantId}/classes/reenroll/${record.id}`
                    );
                  } else {
                    history.push(`/my-school/classes/${record.id}/reenroll`);
                  }
                }
                if (event.key === 'view') {
                  redirectToViewClass(record.id);
                }
              }}
            >
              <Menu.Item key="edit">
                <p>Editar</p>
              </Menu.Item>
              <Menu.Item key="delete">
                <p>Deletar</p>
              </Menu.Item>
              <Menu.Item key="re-enroll">
                <p>Rematrícula</p>
              </Menu.Item>
              <Menu.Item key="view">
                <p>Visualizar</p>
              </Menu.Item>
              {isMobile ? (
                <Menu.Item key={'panel'}>Ver painel</Menu.Item>
              ) : null}
            </Menu>
          }
          trigger={['click']}
        >
          <FaEllipsisVertical
            onClick={(e) => e.stopPropagation()}
            size={24}
            style={{ cursor: 'pointer' }}
            id="id-tour-action-class-table"
          />
        </Dropdown>
      ),
    },
  ];
  const periodAndYear: any = isMobile
    ? {
        title: (
          <p style={tableHeaderStyle}>
            Turno/
            <br />
            Ano Letivo
          </p>
        ),
        name: 'Turno/Ano Letivo',
        dataIndex: 'period',
        key: 'period',
        mobile: true,
        render: (_: string, record: ClassDetails) => (
          <Flex vertical>
            <p style={tableRowStyle}>{t(`app.${record.period}`)}</p>
            <div
              style={{
                color: colorPrimary,
                fontWeight: 700,
                border: `1px solid ${colorPrimary}`,
                fontSize: '10px',
                padding: '4px',
                borderRadius: '5px',
                width: 'fit-content',
                alignSelf: 'center',
              }}
            >
              {record.year}
            </div>
          </Flex>
        ),
      }
    : {
        title: <p style={tableHeaderStyle}>Turno</p>,
        name: 'Turno',
        dataIndex: 'period',
        key: 'period',
        render: (_: string, record: ClassDetails) => (
          <p style={tableRowStyle}>{t(`app.${record.period}`)}</p>
        ),
      };

  if (onSelect) {
    columns[1] = periodAndYear;
    columns.pop();
    columns.pop();
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys: string[], selectedRows: ClassDetails[]) => {
      setSelectedRowKeys(selectedRowKeys);
      setSelectedClasses(selectedRows);
      onSelect && onSelect(selectedRowKeys);
    },
  };

  const onClickRow = (key: string) => {
    if (selectedRowKeys.includes(key)) {
      const rowKeys = [...selectedRowKeys.filter((item) => item !== key)];
      setSelectedClasses((prev) => [...prev.filter((item) => item.id !== key)]);
      setSelectedRowKeys(rowKeys);
      onSelect && onSelect(rowKeys);
    } else {
      const rowKeys = [...selectedRowKeys, key];
      setSelectedRowKeys(rowKeys);
      onSelect && onSelect(rowKeys);
      const classe = data?.data.find((item) => item.id === key);
      if (classe) {
        setSelectedClasses((prev) => [...prev, classe]);
      }
    }
  };
  const onDelete = () => {
    showConfirm({
      message: 'Tem certeza que deseja excluir o registro das turmas?',
      colorPrimary,
      isMobile,
    }).then(({ isConfirmed }) => {
      if (isConfirmed) {
        handleDelete(
          selectedClasses?.map((classe: ClassDetails) => classe.id) ?? [],
          {
            onSuccess: () => {
              notificateSucess('A turmas foram excluídas com sucesso!');
              refetch();
            },
            onError: () => {
              notificateError(
                'Houve um problema ao excluir a turma. Verifique sua conexão e tente novamente.'
              );
            },
          }
        );
      }
    });
  };

  useEffect(() => {
    if (value && data) {
      setSelectedClasses((prev) =>
        prev.length
          ? [
              ...prev,
              ...data?.data
                .filter((v) => !prev.map((item) => item.id).includes(v.id))
                .filter((item) => value.includes(item.id)),
            ]
          : data?.data.filter((item) => value.includes(item.id))
      );
    }
  }, [data]);

  useEffect(() => {
    if (selectedClasses.length && callback) {
      callback(selectedClasses.filter((item) => item !== undefined));
    }
  }, [selectedClasses]);

  useEffect(() => {
    setPage(1);
  }, [search, filters]);
  return (
    <DynamicTable
      id="id-tour-my-school-selection-classes-table"
      itemName="turma"
      data={data?.data ?? []}
      onChangePage={setPage}
      onChangePageSize={setPageSize}
      title=""
      total={data?.total ?? 0}
      page={page}
      pageSize={pageSize}
      isLoading={isEachLoading}
      defaultColumns={
        isMobile ? columns.filter((column) => column.mobile) : columns
      }
      allColumns={columns}
      appliedFilters={filters}
      onChangeFilters={setFilters}
      filters={<ClassesFilter filters={filters} onChangeFilters={setFilters} />}
      onSearch={setSearch}
      rowSelection={
        onSelect
          ? { preserveSelectedRowKeys: true, ...rowSelection }
          : undefined
      }
      onClickRow={
        onSelect ? (v) => onClickRow(v.id) : (v) => redirectToViewClass(v.id)
      }
      selected={selectedClasses && selectedClasses?.length > 0}
      onDelete={onSelect ? undefined : onDelete}
    ></DynamicTable>
  );
}

export default ClassesTable;
