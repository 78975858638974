import { useQuery } from "react-query";
import axios from "infra/http";

export interface UseActivitiesParams {
  page: number;
  classId?: string;
  disableConcat?: boolean;
  initialDate?: string;
  endDate?: string;
  userId?: string;
  tenantId?: string;
  optionIds?: string[];
}
 export interface Emotional {
  optionId: string;
  name:string;
  createdAt:string;
  image:string;
}
function useEmotions(params: UseActivitiesParams) {

  const fetchActivities = async (
    params: UseActivitiesParams
  ): Promise<{ data: Emotional[]; total: number }> => {
    const { data, headers } = await axios.get<Emotional[]>(`/v2/graphs/emotional/list`, {
      params: {
        page: params.page,
        classId: params.classId,
        initialDate: params.initialDate,
        endDate: params.endDate,
        userId: params.userId,
        optionIds: params.optionIds,
        tenantId:params.tenantId
      },
    });

    let returnData: Emotional[] = [];

    returnData = [...data];

    return { data: returnData, total: Number(headers["total"]) };
  };

  return useQuery(
    [`/v2/graphs/emotional/list`, params],
    () => fetchActivities(params),
  );
}

export default useEmotions;
