import Layout from 'components/layout';
import { useMediaQuery } from '@chakra-ui/react';
import WelcomeMessage from './components/Welcome';
import { Col, Flex, Row } from 'antd';
import SafeSpaceAvaliations from './components/SafeSpaceAvaliations';
import Emotion from './components/Emotion';
import AttentionPoints from './components/AttentionPoints';
import CharacterLore from './components/CharacterLore';
import StudentPanel from './components/StudentPanel';
import News from './components/News';
import AttentionPointsPreview from './components/AttentionPointsPreview';
import { Element } from 'react-scroll';
import Qedu from './components/qedu';
import { useCurrentUser } from 'core/features/user/store';

function HomePage() {
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const { user } = useCurrentUser();
  return (
    <Layout.Container>
      <div>
        <Row
          style={{ width: '100%', marginBottom: '32px' }}
          align="stretch"
          gutter={[0, 32]}
        >
          <Col
            span={isMobile ? 24 : 12}
            order={1}
            style={{
              width: '100%',
              height: '100%',
              paddingRight: isMobile ? undefined : '16px',
            }}
          >
            <WelcomeMessage />
          </Col>

          <Col
            span={isMobile ? 24 : 5}
            order={isMobile ? 6 : 2}
            style={{
              height: '100%',
              width: '100%',
              paddingLeft: isMobile ? undefined : '16px',
            }}
          >
            <StudentPanel />
          </Col>
          <Col
            span={isMobile ? 24 : 7}
            order={isMobile ? 3 : 3}
            style={{
              height: '100%',
              width: '100%',
              paddingLeft: isMobile ? undefined : '16px',
            }}
          >
            <CharacterLore />
          </Col>
          <Col
            span={isMobile ? 24 : 12}
            order={isMobile ? 2 : 4}
            style={{
              minHeight: '100%',
              width: '100%',
              paddingRight: isMobile ? undefined : '16px',
            }}
          >
            <Flex
              vertical
              gap={24}
              justify="space-between"
              style={{ minHeight: '100%' }}
            >
              <News />
              {isMobile ? <AttentionPointsPreview /> : null}
              <SafeSpaceAvaliations />
            </Flex>
          </Col>
          <Col
            sm={isMobile ? 24 : 12}
            order={isMobile ? 4 : 5}
            style={{
              paddingLeft: isMobile ? undefined : '16px',
            }}
          >
            <Emotion />
          </Col>

          <Col sm={24} order={6}>
            <Element name="attentionPoints" className="element">
              <AttentionPoints />
            </Element>
          </Col>
          {user?.inep_id && (
            <Col sm={24} order={7}>
              <Qedu />
            </Col>
          )}
        </Row>
      </div>
    </Layout.Container>
  );
}

export default HomePage;
