import { Flex, Form, Radio, Space } from 'antd';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import Text from 'components/typography/text';
import LoadingDots from 'components/V2/loading-dots';
import SelectActivityTag from './selectActivityTag';
import SelectTagType from 'components/select-tag-type';

function StepTag() {
  const { colorPrimary } = useU4heroColors();
  const form = Form.useFormInstance();
  const tagType = Form.useWatch('tagType', form);

  return (
    <Flex vertical gap="8px">
      <SelectTagType id="id-tour-create-activity-input-tag-type" />

      {tagType === undefined ? (
        <Flex
          vertical
          justify="center"
          align="center"
          style={{ height: '100%' }}
          gap={'8px'}
        >
          <LoadingDots />
          <Text size="sm" color={colorPrimary}>
            Defina a área de análise!
          </Text>
        </Flex>
      ) : (
        <Form.Item
          label={
            tagType === 0
              ? 'Competências e Habilidades:'
              : tagType === 1
              ? 'Foco de Análise do Clima Escolar:'
              : 'Selecione o que será abordado:'
          }
          rules={[
            {
              required: true,
            },
          ]}
          name="tags"
        >
          {/* @ts-ignore */}
          <SelectActivityTag />
        </Form.Item>
      )}
    </Flex>
  );
}

export default StepTag;
