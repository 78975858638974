import React, { useRef, useState, ReactNode } from 'react';
import { Carousel as AntdCarousel, CarouselProps, Flex } from 'antd';
import { CarouselRef } from 'antd/lib/carousel';
import Dot from 'components/dot';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
interface CustomCarouselProps extends CarouselProps {
  children?: ReactNode;
  showTotal?: boolean;
}

const CustomCarousel: React.FC<CustomCarouselProps> = ({
  children,
  showTotal = true,
  ...props
}) => {
  const [currentSlide, setCurrentSlide] = useState(props.initialSlide ?? 0);
  const carouselRef = useRef<CarouselRef>(null);
  const { colorPrimary } = useU4heroColors();

  const handleDotClick = (index: number) => {
    carouselRef.current?.goTo(index * (props.slidesToScroll || 1));
    setCurrentSlide(index * (props.slidesToScroll || 1));
  };

  const handleBeforeChange = (current: number, next: number) => {
    setCurrentSlide(next);
  };
  const totalSlides = React.Children.count(children);
  const totalDots = Math.ceil(totalSlides / (props.slidesToScroll || 1));

  return (
    <div>
      <AntdCarousel
        ref={carouselRef}
        beforeChange={handleBeforeChange}
        {...props}
        dots={false}
      >
        {children}
      </AntdCarousel>
      <Flex
        align="center"
        justify="center"
        style={{ marginTop: '20px', width: '100%' }}
      >
        <Flex justify="center" gap="12px">
          {[...Array(totalDots)].map((_, index) => (
            <Dot
              key={index}
              onClick={() => handleDotClick(index)}
              color={
                index * (props.slidesToScroll || 1) === currentSlide
                  ? colorPrimary
                  : '#E9E9E9'
              }
              size={12}
            />
          ))}
        </Flex>
        {showTotal && (
          <p
            style={{
              fontSize: '12px',
              color: colorPrimary,
              fontWeight: 700,
              position: 'absolute',
              right: 16,
            }}
          >
            {Math.ceil((currentSlide + 1) / (props.slidesToScroll || 1))}
            <span style={{ fontSize: '10px', color: 'black', fontWeight: 400 }}>
              /{totalDots}
            </span>
          </p>
        )}
      </Flex>
    </div>
  );
};

export default CustomCarousel;
