import {translate} from '../polyglot';

export default translate('en', {
  app: {
    idadeMinima: 'Minimum age',
    idadeMaxima: 'Maximum age',
    genero: 'Genre',
    tempoMedio: 'Average time',
    objetivoLudico: 'Playful purpose',
    objetivoPedagogico: 'Pedagogical objective',
    competencias: 'Skills',
    questoes: 'Questions',
    conteudos: 'Contents',
    conteudo: 'Content',
    remover: 'Remove',
    emocionometro: 'Emotionometer',
    indicadores_gerais: 'Gerais indicators',
    indicadores_avancados: 'Advanced indicators',
    rankJogadores: 'Player rank',
    detalhes: 'Details',
    modelo: 'Model',
    cancelar: 'Cancel',
    importar: 'Import',
    importacao: 'Import',
    ver_mais: 'View more',
    historico: 'History',
    recomendacao: 'Recommendation',
    adicionar: 'To add',
    maximo: 'Maximum',
    minimo: 'Minimum',
    escola: 'School',
    escolas: 'Schools',
    alunos: 'Students',
    aluno: 'Student',
    serie: 'Grade',
    series: 'Grades',
    turma: 'Class',
    turmas: 'Classes',
    imagem: 'Image',
    filtro: 'Filter',
    filtrar: 'Filter',
    imprimir: 'Print',
    enviarEmail: 'Send',
    comentarios: 'Comments',
    comentar: 'Comment',
    colaboradores: 'Collaborators',
    colaborador: 'Collaborator',
    grafico: 'Graphics',
    grafico_geral: 'General Graphics',
    sair: 'Go out',
    configuracoes: 'Settings',
    nome: 'Name',
    opcoes: 'Options',
    editar: 'Edit',
    desativar: 'Deactivate',
    ativar: 'Activate',
    ativo: 'Active',
    inativo: 'Inactive',
    aviso: 'Warning',
    sucesso: 'Success',
    erro: 'Error',
    portugues: 'Portuguese',
    ingles: 'English',
    espanhol: 'Spanish',
    criar: 'Create',
    entrar: 'Log in',
    facaBusca: 'Search',
    voltar: 'Back',
    salvar: 'Save',
    documento: 'Document',
    telefone: 'Phone',
    site: 'Site',
    emailAdmin: "Director's email",
    nomeAdmin: "Director's name",
    pais: 'Country',
    endereco: 'Address',
    adicionarTurma: 'Add class',
    cidade: 'City',
    bairro: 'District',
    estado: 'State',
    rua: 'Street',
    complemento: 'Complement',
    numero: 'Number',
    cep: 'Postal Code',
    matutino: 'Morning',
    afternoon: 'Afternoon',
    vespertino: 'Evening',
    noturno: 'Nocturnal',
    periodo: 'Period',
    excluir: 'Delete',
    perfil: 'Profile',
    usuario: 'User',
    senha: 'Password',
    acessoTurmas: 'Access to classes',
    idade: 'Age',
    anos: 'Years',
    dataNascimento: 'Date of birth',
    nomePai: 'Father Name',
    nomeMae: 'Mother Name',
    limpar: 'Clear',
    aplicar: 'Apply',
    relatorio: 'Report',
    relatorios: 'Reports',
    relatorioCompleto: 'Full report',
    sexo: 'Sex',
    feminino: 'Feminine',
    masculino: 'Male',
    esqueciSenha: 'I forgot my password',
    voltarParaLogin: 'Back to login',
    recuperarSenha: 'Recover Password',
    jogos: 'Games',
    jogo: 'Game',
    aventura: 'Adventure',
    exploracao: 'Explorer',
    miniquest: 'Miniquest',
    rpg: 'RPG',
    capitulo: 'Chapter',
    capitulos: 'Chapters',
    tipo: 'Type',
    perguntas: 'Questions',
    licenca: 'License',
    descricao: 'Description',
    hipotese: 'Hypothesis',
    orientacao: 'Orientation',
    resposta: 'Answer',
    pergunta: 'Question',
    tag: 'Tag',
    tag_pai: 'Parent Tag',
    jogoEmocoes: 'Emotions game',
    graficoEmocoes: 'Graph of emotions',

    autoGestao: 'Self-managenment',
    autoconsciencia: 'Self-conscience',
    habilidadesRelacionamento: 'Relationship skills',
    conscienciaSocial: 'Social conscience',
    tomadaDecisaoResponsavel: 'Responsible Decision',
    graficoSocioemocional: 'Socioemotional graph',
    socioemocional: 'Socioemotional',

    nivemCompreensao: 'Level of understanding',
    otimaCompreensao: 'Great understanding',
    boaCompreensao: 'Good understanding',
    razoavelCompreensao: 'Reasonable understanding',
    poucaCompreensao: 'Little understanding',
    graficoBullying: 'Graphic bullying',

    memoriaTrabalho: 'Working memory',
    flexibilidadeCognitiva: 'Cognitive flexibility',
    controleInibitorio: 'Inhibitory control',
    legenda: 'Legend',
    baixoControleInibitorio: 'Low inhibitory control',
    medioControleInibitorio: 'Medium inhibitory control',
    altoControleInibitorio: 'High inhibitory control',

    entendimentoTurma: 'Class understanding',
    entendimentoSerie: 'Understanding the series',
    entendimentoAluno: 'Student understanding',

    graficoFuncoesExecutivas: 'Executive Functions Graph',
    mediaCliques10Segundos: 'Average clicks every 10 seconds',

    acimaDe20: 'Above 20',
    de15a20: 'from 15 to 20',
    ate10: 'To 10',

    graficoHumor: 'Mood chart',
    nivelCompreensao: 'Level of understanding',

    'Estou feliz': "I'm happy",
    'Estou com medo': "I'm afraid",
    'Estou triste': "I'm sad",
    'Estou com raiva': "I'm angry",
    'Estou com nojo': "I'm disgusted",

    configuracao: 'Settings',
    editar_perfil: 'Edit profile',
    inicial: 'Home',
    encerramento: 'Closing',
    investigacao: 'Investigation',

    Admin: 'Admin',
    Teacher: 'Teacher',

    morning: 'Morning',
    evening: 'Evening',
    nocturnal: 'Nocturnal',
    organizacao: 'Organization',
  },
});
