import moment from 'moment';
import {  Header } from 'semantic-ui-react';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import { useMediaQuery } from '@chakra-ui/media-query';
import { Flex,Typography } from 'antd';
import Avatar from 'components/avatar';


const { Text } = Typography;

function CommentBox({
  comments,
  withOutHeader,
}: {
  comments: any[];
  withOutHeader?: true;
}){
  const { colorPrimary } = useU4heroColors();
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  return (
    <div>
      {withOutHeader ? null : (
        <Header
          as="h3"
          style={{ fontSize: isMobile ? '18px' :'24px', fontFamily: 'Poppins', color: colorPrimary }}
        >
          Comentários
        </Header>
      )}
      <div
        style={{
          backgroundColor: 'rgba(255,255,255,.8)',
        }}
      >
        {comments.map((item: any) => (
          <Flex>
            <Flex vertical>
              <Flex gap={isMobile ? '4px':'12px'}>
              <Avatar url={item?.user?.imageUrl} name={item?.user?.name} size='md' type='secondary' />

            <Flex vertical>
            <Text style={{color:'black',fontSize:isMobile ? '14px':'18px',fontWeight:600}}>{item?.user?.name}</Text>
              <Text style={{color:'#9D9D9D',fontSize:isMobile ? '14px':'18px',fontWeight:400}}>{moment(item?.createdAt).format('DD/MM/YYYY HH:MM')}</Text>
            </Flex>
              </Flex>
            <p style={{color:'black',fontSize:isMobile ? '14px':'18px',fontWeight:400}}>{item?.comment}</p>
            </Flex>
          </Flex>
        ))}
      </div>
    </div>
  );  
}


export default CommentBox;
