import {  Col, Flex, Row} from 'antd';
import Button from 'components/buttons/button';
import { GenericCard } from 'components/V2/generic-card-styled-component';
import { useHistory } from 'react-router';

function StudentPanel() {
  const history = useHistory();
  return (
    <GenericCard padding="2px" width="100%" height="100%" id='id-tour-section-check-student-panel'>
      <Row justify='space-between' style={{width:'100%'}}>
        <Col span={12}>
          <img
            alt='lumi lendo'
            src="/lumi-lendo.png"
            style={{
              transform: 'scaleX(-1)',
              height: '140px',
              borderRadius: '20px',
              objectFit: 'cover',
              aspectRatio: '16/9',
            }}
          />
        </Col>
        <Col span={12}>
          <Flex
            vertical
            gap="4px"
            style={{ height: '100%' }}
            justify="center"
            align="center"
          >
            <p
              style={{
                fontSize: '18px',
                color: 'black',
                textAlign: 'center',
                maxWidth: '97px',
                fontWeight: 400,
              }}
            >
              Confira o Painel do Estudante
            </p>

            <Button
              size='sm'
              id='id-tour-button-check-student-panel'
              onClick={() => {
                history.push('/my-school/students');
              }}
             
            >
              CONFERIR
            </Button>
          </Flex>
        </Col>
      </Row>
    </GenericCard>
  );
}

export default StudentPanel;
