import React, { forwardRef, useEffect, useRef, useState } from 'react';
import {
  ContainerButton,
  SubTitle,
  OptionContainer,
  PageBreak,
} from './styles';
import logo from 'assets/images/logo.png';
import QuestionNormal from './QuestionNormal';
import Swal from 'sweetalert2';
import { Box, Flex, Text } from '@chakra-ui/layout';
import CommentBox from './CommentBox';
import useReport from 'core/features/report/hooks/useReport';
import { Button, Dropdown, Image, Menu, Modal, Checkbox, Row, Col } from 'antd';
import { Skeleton } from '@chakra-ui/skeleton';
import { useReactToPrint } from 'react-to-print';
import QuestionPercentage from './QuestionPercentage';
import { reportTypes } from 'core/features/report/types';
import { Empty } from 'antd';
import { useCurrentUser } from 'core/features/user/store';
import { useMediaQuery } from '@chakra-ui/react';
import { t } from 'core/resources/strings';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import Dot from 'components/dot';
import { hex2rgba } from 'infra/helpers/hex2Rgba';
import {
  BsChat,
  BsEmojiFrown,
  BsEmojiFrownFill,
  BsEmojiHeartEyes,
  BsEmojiHeartEyesFill,
  BsEmojiNeutral,
  BsEmojiNeutralFill,
} from 'react-icons/bs';
import { FiStar } from 'react-icons/fi';
import { Input } from 'antd';
import { useSaveQuestionFeedback } from 'core/features/questions/hooks/useSaveQuestionFeedback';
import { TfiPrinter } from 'react-icons/tfi';
import { FaInstagram, FaSquareFacebook, FaYoutube } from 'react-icons/fa6';
import { IoIosHelpCircleOutline, IoIosSend } from 'react-icons/io';
import SvgIcon from 'components/SvgIcon';
import { notificateError, notificateSucess } from 'infra/helpers/notifications';

const { TextArea } = Input;

function Report({
  onClose,
  classId,
  studentId,
  initialDate,
  finalDate,
  activityId,
  tenantId,
  type,
}: {
  studentId?: string;
  classId?: string;
  onClose: () => void;
  initialDate?: string;
  finalDate?: string;
  activityId?: string;
  tenantId?: string;
  type: reportTypes;
}) {
  const [commentsInvisibles, setCommentsInvisibles] = useState<string[]>([]);
  const [isInvisibleAll, setIsInvisibleAll] = useState(false);
  const { colorPrimary } = useU4heroColors();
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const { data, onComentar, onEnviarEmail, isLoading, isLoadingComment } =
    useReport({
      classId,
      userId: studentId,
      initialDate,
      finalDate,
      activityId,
      tenantId,
      type,
    });

  const { data: data2 } = useReport(
    type === reportTypes.Student
      ? {
          classId,
          userId: studentId,
          initialDate,
          finalDate,
          activityId,
          type: reportTypes.Class,
        }
      : {
          classId: '',
          userId: '',
          initialDate,
          finalDate,
          activityId,
          type: reportTypes.Class,
        }
  );

  const componentRef = useRef<any>();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: data?.user
      ? data?.user
      : data?.class?.name
      ? data?.class?.name
      : data?.tenant,
  });

  const handleEnviar = () => {
    Swal.fire({
      title: 'Informe o e-mail',
      input: 'text',
      inputAttributes: {
        autocapitalize: 'off',
      },
      showCancelButton: true,
      confirmButtonText: 'Enviar',
      showLoaderOnConfirm: true,
      preConfirm: (login: any): any => {
        return onEnviarEmail({
          email: login,
          invisibleComments: commentsInvisibles,
        })
          .then(() => {
            notificateSucess();
          })
          .catch(() => {
            notificateError();
          });
      },
      allowOutsideClick: () => !Swal.isLoading(),
    });
  };

  const handleInvisibleAllComments = () => {
    if (isInvisibleAll) {
      setIsInvisibleAll(false);
      setCommentsInvisibles([]);
      return;
    }

    let result: string[] = [];

    for (const question of data?.items) {
      result = [...result, ...question?.comments.map((item: any) => item.id)];
    }

    setCommentsInvisibles(result);
    setIsInvisibleAll(true);
  };

  return (
    <>
      <Modal width="900px" footer={null} onCancel={onClose} visible title={' '}>
        <Box mb="32px" mt="8px">
          <ContainerButton>
            <Checkbox
              onChange={() =>
                type !== reportTypes.Student
                  ? null
                  : handleInvisibleAllComments()
              }
              checked={isInvisibleAll}
              disabled={type !== reportTypes.Student}
              style={{ color: 'black', fontSize: isMobile ? '12px' : '16px' }}
            >
              Ocultar todos os comentários
            </Checkbox>
            <Flex gridGap="16px" justifySelf="end" marginRight="20px">
              <Button
                onClick={handleEnviar}
                // disabled={type !== reportTypes.Student}
                disabled={true}
                style={{ border: `solid 1px ${colorPrimary}` }}
                size={isMobile ? 'small' : 'middle'}
              >
                <IoIosSend size={isMobile ? 14 : 20} color={colorPrimary} />
              </Button>
              <Button
                onClick={handlePrint}
                style={{ border: `solid 1px ${colorPrimary}` }}
                size={isMobile ? 'small' : 'middle'}
              >
                <TfiPrinter size={isMobile ? 14 : 20} color={colorPrimary} />
              </Button>
            </Flex>
          </ContainerButton>
        </Box>

        {isLoading ? (
          <Skeleton height={250} />
        ) : data.items.length ? (
          <DocumentPdf
            ref={componentRef}
            type={type}
            data={data}
            dataClass={data2}
            onComentar={onComentar}
            isLoading={isLoading}
            isLoadingComment={isLoadingComment}
            isInvisibleAll={isInvisibleAll}
          />
        ) : (
          <Empty />
        )}
      </Modal>
    </>
  );
}
interface Props {
  isLoading: any;
  data: any;
  dataClass: any;
  isLoadingComment: any;
  onComentar: any;
  isInvisibleAll: boolean;
  type: reportTypes;
}
export const DocumentPdf = forwardRef((props: Props, ref: any) => {
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const questionConteiner = useRef<any>();
  const { colorPrimary } = useU4heroColors();

  const analysisTypes: { [key: number]: string } ={
    0:"Conhecimento",
    1:"Comportamento",
    2:"Percepção"
  }


  useEffect(() => {
    const conteiner = questionConteiner.current.children;
    const conteinerArray: any = Array.from(conteiner);

    // for (let i = 0; i <= conteinerArray.length; i += 1) {
    //   // var questionPageHeigth = conteinerArray[i].offsetHeight - 150;
    //   // console.log(questionPageHeigth)
    //   // var pageCount = Math.floor(questionPageHeigth / 1250 + 1);
    //   // conteinerArray[i].style.height = `${1100 * pageCount}px`;
    // }
  }, []);
  return (
    <div id="report" ref={ref}>
      <Image src="/capaRelatorio.png" preview={false} />
      <Flex
        style={{ minHeight: '500px' }}
        direction="column"
        justify="center"
        align="center"
      >
        {props.data.tenantImageUrl ? (
          <img
            src={props.data.tenantImageUrl}
            alt="icon"
            style={{
              width: isMobile ? '80px' : '122px',
              height: isMobile ? '40px' : '64px',
            }}
          />
        ) : null}

        <h1
          style={{
            color: 'black',
            fontWeight: 700,
            marginBottom: '16px',
            fontSize: isMobile ? '20px' : '28px',
            textAlign: 'center',
            padding: '0px',
            fontFamily: 'Poppins',
          }}
        >
          Relatório Socioemocional{' '}
          {props.type === reportTypes.Activity ? (
            <strong>da Atividade</strong>
          ) : null}
          {props.type === reportTypes.Student ? (
            <strong>do Estudante</strong>
          ) : null}
          {props.type === reportTypes.Class ? <strong>da Turma</strong> : null}
          {props.type === reportTypes.School ? (
            <strong>da Escola</strong>
          ) : null}
        </h1>

        <div
          style={{
            textAlign: 'center',
            fontWeight: 400,
            fontSize: isMobile ? '14px' : '20px',
          }}
        >
          <p>{props.data.tenant}</p>
          <p>{props.data.user}</p>
          <p>
            {props.data.class?.name
              ? `${props.data.class.serie.name} ${props.data.class?.name} - ${t(
                  `app.${props.data.class.period}`
                )}`
              : null}
          </p>
        </div>
        <img
          src={logo}
          alt="icon"
          style={{
            width: isMobile ? '50px' : '122px',
            height: isMobile ? '37px' : '90px',
            marginBottom: '25px',
            marginTop: isMobile ? '8px' : '4px',
          }}
        />
      </Flex>
      <div
        style={{
          minHeight: '1050px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <h1
          style={{
            color: colorPrimary,
            fontSize: isMobile ? '20px' : '28px',
            textAlign: 'center',
            fontWeight: 700,
            fontFamily: 'Poppins',
          }}
        >
          O que você encontra nesse Relatório?
        </h1>
        <p
          style={{
            color: 'black',
            fontSize: isMobile ? '14px' : '18px',
            textAlign:'justify',
            fontWeight: 400,
          }}
        >
          O Relatório Socioemocional é a funcionalidade onde se consegue
          visualizar com mais detalhes e informações os resultados qualitativos
          da investigação socioemocional criada pelo educador. Ele apresenta
          resultados específicos de um estudante ou de uma turma, mostrando as
          decisões que foram tomadas durante as situações-problemas vivenciadas
          nas trilhas de aprendizagem socioemocionais dos jogos! Oferecendo
          dados concretos e informações obtidas pelo viés lúdico da ferramenta,
          assim como hipóteses comportamentais e orientações de intervenção ao
          educador, e ao corpo docente, para que consigam trilhar um percurso
          socioemocional, servindo como complemento para elaborar projetos,
          embasar decisões e realizar abordagens pedagógicas mais assertivas,
          que aproximam os estudantes. Sabe-se o quanto o educador tem mais
          propriedade e experiência para lidar com seus estudantes, por isso o
          Relatório Socioemocional vem como uma ferramenta de ajuda e auxilio,
          para potencializar e complementar a prática profissional do educador.
        </p>
        <p
          style={{
            textAlign: 'end',
            fontSize: isMobile ? '12px' : '18px',
            fontWeight: 400,
            marginTop: isMobile ? '12px' : '16px',
            marginBottom: isMobile ? '14px' : '0px',
          }}
        >
          Equipe Socioemocional
        </p>
        <Image
          style={{ alignSelf: 'end' }}
          src="/background-report.png"
          preview={false}
          width="100%"
        />
      </div>

      {!props.isLoading ? (
        <div ref={questionConteiner}>
          {props.data?.items.map((item: any, index: any) => (
            <div>
              <div
                style={{
                  backgroundImage: isMobile
                    ? "url('/background-question-report-mobile.png')"
                    : "url('/background-question-report.png')",
                  fontFamily: 'Poppins',
                  padding: '16px',
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat',
                  height: '169px',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                }}
              >
                <h1
                  style={{
                    textAlign: 'center',
                    color: 'white',
                    fontSize: isMobile ? '20px' : '28px',
                  }}
                >
                  QUESTÕES
                </h1>
              </div>
              <Flex direction="column" align="end">
                <Flex gridGap={'8px'} align="center">

                <IoIosHelpCircleOutline size={isMobile ? 20:24}/>
                  <Text
                    style={{
                      fontWeight: 400,
                      fontSize: isMobile ? '12px' : '16px',
                    }}
                  >
                    {analysisTypes[item.analysisTypeId]}
                  </Text>{' '}
                </Flex>
                <Flex gridGap={'8px'} align="center">
                  <SvgIcon
                    url={item?.competenceIcon}
                    size={isMobile ? 20 : 24}
                    color="black"
                  />
                  <Text
                    style={{
                      fontWeight: 400,
                      fontSize: isMobile ? '12px' : '16px',
                    }}
                  >
                    {item.competence}
                  </Text>{' '}
                </Flex>
                <Flex gridGap={'8px'} align="center">
                  <SvgIcon
                    url={item?.skillIcon}
                    size={isMobile ? 20 : 24}
                    color="black"
                  />
                  <Text
                    style={{
                      fontWeight: 400,
                      fontSize: isMobile ? '12px' : '16px',
                    }}
                  >
                    {item.skill}
                  </Text>{' '}
                </Flex>
              </Flex>
              <QuestionItem
                ref={questionConteiner}
                isInvisibleAll={props.isInvisibleAll}
                isLoading={props.isLoadingComment}
                onComentar={props.onComentar}
                item={item}
                itemClass={props?.dataClass?.items?.find(
                  (itemClass: any) => itemClass.id === item.id
                )}
                key={index}
                index={index}
                type={props.type}
              />
            </div>
          ))}
        </div>
      ) : null}

      <div
        style={{
          backgroundImage: isMobile
            ? "url('/mobile-capa-relatorio.png')"
            : "url('/desktop-capa-relatorio.png')",
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          display: 'flex',
          height: '100vh',
        }}
      >
        <div
          style={{
            background: '#7743B9',
            borderRadius: '20px',
            width: '100%',
            height: isMobile ? '133px' : '116px',
            padding: isMobile ? '4px 7px' : '16px',
            alignSelf: 'end',
            color: 'white',
          }}
        >
          <Text
            style={{
              color: 'white',
              fontSize: isMobile ? '14px' : '20px',
              marginBottom: '12px',
            }}
          >
            Vem conhecer um pouco mais sobre nós!
          </Text>

          <Row>
            <Col span={isMobile ? 12 : 6}>
              <a
                style={{
                  display: 'flex',
                  gap: isMobile ? '2px' : '4px',
                  alignItems: 'center',
                }}
                href={'https://u4hero.com'}
              >
                <img alt="lumi" src="/lumi-head.png" width="30px" />
                <Text>u4hero.com</Text>
              </a>
            </Col>
            <Col span={isMobile ? 12 : 6}>
              {' '}
              <a
                style={{
                  display: 'flex',
                  gap: isMobile ? '2px' : '4px',
                  alignItems: 'center',
                }}
                href={'https://www.instagram.com/u4hero/'}
              >
                <FaInstagram color="white" size={30} />
                <Text>@u4hero</Text>
              </a>
            </Col>
            <Col span={isMobile ? 12 : 6}>
              {' '}
              <a
                style={{
                  display: 'flex',
                  gap: isMobile ? '2px' : '4px',
                  alignItems: 'center',
                }}
                href={'https://www.facebook.com/u4hero.ofc/'}
              >
                <FaSquareFacebook color="white" size={30} />
                <Text>/u4hero.ofc</Text>
              </a>
            </Col>
            <Col span={isMobile ? 12 : 6}>
              <a
                style={{
                  display: 'flex',
                  gap: isMobile ? '2px' : '4px',
                  alignItems: 'center',
                }}
                href={'https://www.youtube.com/@u4hero'}
              >
                <FaYoutube color="white" size={30} />
                <Text>/u4hero.ofc</Text>
              </a>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
});

interface QuestionItemProps {
  item: any;
  itemClass: any;
  index: number;
  isLoading: boolean;
  onComentar: (v: any, v2: any) => void;
  isInvisibleAll: boolean;
  type: reportTypes;
}

const QuestionItem = forwardRef(
  (props: QuestionItemProps, ref: React.Ref<any>) => {
    const [comentar, setComentar] = useState<boolean>(false);
    const [comentario, setComentario] = useState<any>('');
    const [feedback, setFeedback] = useState(false);
    const { user } = useCurrentUser();
    const { colorPrimary, colorSecondary } = useU4heroColors();
    const [isMobile] = useMediaQuery('(max-width: 768px)');
    const { mutate: handleSave } = useSaveQuestionFeedback();

    const itemConteiner = useRef<any>();

    useEffect(() => {
      const conteiner = itemConteiner.current;

      if (conteiner) {
      }
    }, []);

    const handleFeedback = (feedbackId: number, questionId: string) => {
      props.item.feedback = feedbackId;
      handleSave(
        {
          questionId: questionId,
          feedback: '',
          rating: feedbackId,
        },
        {
          onError: () => {
            notificateError('Ops, houve algum erro, tente novamente!');
          },
          onSuccess: () => {
            notificateSucess('Obrigado pelo seu feedback!');
          },
        }
      );
      setFeedback(false);
    };

    useEffect(() => {
      if (!props.isLoading) {
        setComentario('');
        setComentar(false);
      }
    }, [props.isLoading]);

  


    return (
      <PageBreak>
        <div>
          {props.type === reportTypes.Student ? (
            <Flex direction="column">
              <Flex>
                <SubTitle isMobile={isMobile}>
                  {props.index < 9 ? `0${props.index + 1}` : props.index + 1} - {props.item.name}
                </SubTitle>

              </Flex>
              <Flex justify="space-between">
                <div
                  style={{
                    padding: '4px',
                    borderRadius: '5px',
                    background: '#D5C7EA',
                    width: 'fit-content',
                    fontSize: isMobile ? '12px' : '16px',
                    color: colorPrimary,
                    fontFamily: 'Lato',
                  }}
                >
                  {props.itemClass?.countAnswers} Respostas
                </div>
                <Flex gridGap={'7px'} align="center">
                  <Dot
                    color={hex2rgba(colorSecondary, 0.5)}
                    size={isMobile ? 18 : 22}
                  />{' '}
                  <Text
                    style={{
                      fontSize: isMobile ? '12px' : '16px',
                      fontWeight: 400,
                    }}
                  >
                    Resposta do estudante
                  </Text>{' '}
                </Flex>
              </Flex>
            </Flex>
          ) : (
            <SubTitle>{`${props.item.name} (${props.item.options.reduce(
              (acc: number, curr: any) => acc + curr.countAnswers,
              0
            )} estudantes)`}</SubTitle>
          )}
        </div>

        <OptionContainer ref={itemConteiner}>
          <div
            style={{
              display: 'flex',
              flexDirection: props.item.humor ? 'row' : 'column',
            }}
          >
            {props.item.options.map((option: any, key: number) =>
              !props.item.percentage ? (
                <QuestionNormal
                  optionClass={props.itemClass?.options.find(
                    (optionsClass: any) => optionsClass.id === option.id
                  )}
                  index={key}
                  key={key}
                  option={option}
                  answer={props.item.answer}
                />
              ) : (
                <QuestionPercentage
                  key={key}
                  index={key}
                  option={option}
                  answer={props.item.answer}
                />
              )
            )}
          </div>
          {props.item.answer && props.type === reportTypes.Student ? (
            <div>
              <div
                style={{
                  display: 'inline-block',
                  color: colorPrimary,
                  fontWeight: 'bold',
                  fontSize: isMobile ? '18px' : '24px',
                }}
              >
                Hipóteses
              </div>

              <Text
                style={{
                  color: '#000000',
                  whiteSpace: 'pre-wrap',
                  fontSize: isMobile ? '14px' : '18px',
                  fontWeight: 400,
                }}
              >
                {props.item.answer.hypothesis}
              </Text>

              <br />

              <div
                style={{
                  display: 'inline-block',
                  color: colorPrimary,
                  fontWeight: 'bold',
                  fontSize: isMobile ? '18px' : '24px',
                }}
              >
                Sugestões
              </div>

              <Text
                style={{
                  color: '#000000',
                  whiteSpace: 'pre-wrap',
                  fontSize: isMobile ? '14px' : '18px',
                  fontWeight: 400,
                }}
              >
                {props.item.answer.orientation}
              </Text>

              <br />

              <div>
                {!props.isInvisibleAll && props.item.comments?.length ? (
                  <CommentBox comments={props.item.comments} />
                ) : null}
              </div>
            </div>
          ) : null}

          <div>
            <Flex
              align="center"
              gridGap={isMobile ? '24px' : '40px'}
              padding={'8px'}
              className="box-shadow-generic-card"
              width="fit-content"
              borderRadius="10px"
              marginBottom={isMobile ? '8px' : '16px'}
              marginTop={isMobile ? '18px' : '22px'}
            >
              {!props.isInvisibleAll && props.type === reportTypes.Student ? (
                <Flex
                  color="black"
                  gridGap="8px"
                  onClick={() => setComentar(true)}
                  justify="center"
                  align="center"
                  cursor="pointer"
                >
                  <BsChat size={isMobile ? 20 : 28} />
                  <span style={{ fontSize: isMobile ? '14px' : '18px' }}>
                    Comentar
                  </span>
                </Flex>
              ) : null}
              {!props.isInvisibleAll ? (
                <Dropdown
                  open={feedback}
                  onOpenChange={(visible) => {
                    setFeedback(visible);
                  }}
                  overlay={
                    <Menu
                      onClick={(e) =>
                        handleFeedback(Number(e.key), props.item.id)
                      }
                      className="box-shadow-generic-card"
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '16px',
                        position: 'relative',
                        bottom: '10px',
                        borderRadius: '10px',
                      }}
                    >
                      <Menu.Item key={2} style={{ padding: '4px' }}>
                        <BsEmojiHeartEyes size={28} />
                      </Menu.Item>
                      <Menu.Item key={1} style={{ padding: '4px' }}>
                        <BsEmojiNeutral size={28} />
                      </Menu.Item>
                      <Menu.Item key={0} style={{ padding: '4px' }}>
                        <BsEmojiFrown size={28} />
                      </Menu.Item>
                    </Menu>
                  }
                  trigger={['click']}
                  placement="topRight"
                >
                  {props.item.feedback !== undefined ? (
                    <Flex
                      color="black"
                      gridGap="8px"
                      justify="center"
                      align="center"
                      cursor="pointer"
                    >
                      {props.item.feedback === 0 ? (
                        <BsEmojiFrownFill size={28} />
                      ) : props.item.feedback === 1 ? (
                        <BsEmojiNeutralFill size={28} />
                      ) : (
                        <BsEmojiHeartEyesFill size={28} />
                      )}
                      <span style={{ fontSize: isMobile ? '14px' : '18px' }}>
                        {props.item.feedback === 0
                          ? 'Não gostei'
                          : props.item.feedback === 1
                          ? 'Neutro'
                          : 'Gostei'}
                      </span>
                    </Flex>
                  ) : (
                    <Flex
                      color="black"
                      gridGap="8px"
                      justify="center"
                      align="center"
                      cursor="pointer"
                    >
                      <FiStar size={isMobile ? 20 : 28} />
                      <span style={{ fontSize: isMobile ? '14px' : '18px' }}>
                        Feedback
                      </span>
                    </Flex>
                  )}
                </Dropdown>
              ) : null}
            </Flex>
            {comentar ? (
              <div>
                <TextArea
                  rows={5}
                  placeholder="Escreva seu comentário."
                  style={{ borderRadius: '12px' }}
                  value={comentario}
                  onChange={(v) => setComentario(v.target.value)}
                />
                <Flex
                  justify={isMobile ? 'center' : 'end'}
                  gridGap={isMobile ? '16px' : '24px'}
                  style={{ marginTop: '8px' }}
                >
                  <Button
                    loading={props.isLoading}
                    type="primary"
                    onClick={() => {
                      if (comentario !== '') {
                        props.onComentar(
                          props.item.answer.answerId,
                          comentario
                        );
                        props.item.comments.push({
                          comment: comentario,
                          user: {
                            name: user?.name,
                            imageUrl: user?.imageUrl,
                          },
                        });
                        notificateSucess('Comentário publicado!');
                      } else {
                        notificateError('O comentário não pode ser vazio.');
                      }
                    }}
                    style={{
                      fontSize: '14px',
                      padding: '8px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    SALVAR
                  </Button>
                  <Button
                    loading={props.isLoading}
                    color={colorPrimary}
                    onClick={() => setComentar(false)}
                    style={{
                      border: `3px solid ${colorPrimary}`,
                      fontSize: '14px',
                      padding: '8px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    CANCELAR
                  </Button>
                </Flex>
              </div>
            ) : null}
          </div>
        </OptionContainer>
      </PageBreak>
    );
  }
);

export default Report;
