import { useMediaQuery } from '@chakra-ui/react';
import { Col, Flex, Form, Input, Radio, Row, Space } from 'antd';
import InputMask from 'components/inputs/input-mask';
import UploadImagem from 'components/inputs/upload-imagem';
import SubTitle from 'components/typography/subTitle';
import { GenericCard } from 'components/V2/generic-card-styled-component';
import { validateCnpj } from 'infra/helpers/validateCnpj';

function SchoolInfo() {
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  return (
    <GenericCard>
      <Flex vertical style={{ width: '100%' }}>
        <SubTitle primary style={{ marginBottom: '8px' }}>
          Informações Da Instituição
        </SubTitle>
        <Form.Item
          name="imageUrl"
          rules={[
            {
              message: '',
            },
          ]}
        >
          {/* @ts-ignore */}
          <UploadImagem placeholderImg='instituition' />
        </Form.Item>
        <Form.Item
          label="Razão Social"
          name="name"
          rules={[
            {
              required: true,
              message: 'Por favor, informe seu nome completo!',
            },
          ]}
        >
          <Input id="id-tour-my-school-settings-input-name" />
        </Form.Item>
        <Row gutter={[52, 0]}>
          <Col span={isMobile ? 24 : 12}>
            <Form.Item
              label="CNPJ"
              rules={[
                {
                  message: 'Por favor, informe o número do cnpj!',
                },
                {
                  validator: async (rule, value) => {
                    if (value) {
                      if (!validateCnpj(value)) {
                        throw new Error('CNPJ invalido!');
                      }
                    }
                  },
                },
              ]}
              name="document"
            >
              <InputMask mask="99.999.999/9999-99">
                {() => <Input id="id-tour-my-school-settings-input-document" />}
              </InputMask>
            </Form.Item>
          </Col>
          <Col span={isMobile ? 24 : 12}>
            <Form.Item
              label="Telefone"
              name="phone"
              rules={[
                {
                  validator: async (rule, value) => {
                    if (value) {
                      const formattedValue = value.replace(/\D/g, '');
                      if (formattedValue.length < 10) {
                        throw new Error(
                          'O telefone deve ter pelo menos 10 números!'
                        );
                      }
                    }
                  },
                },
                {
                  required: true,
                  message: 'Por favor, informe o telefone da instituição!',
                },
              ]}
            >
              <InputMask mask="(99) 99999-9999" autoComplete="off">
                {() => (
                  <Input
                    id="id-tour-my-school-settings-input-phone"
                  />
                )}
              </InputMask>
            </Form.Item>
          </Col>
          <Col span={isMobile ? 24 : 12}>
          <Form.Item
          label="Nome Fantasia"
          name="fantasyName"
        >
          <Input id="id-tour-my-school-settings-input-fantasy-name" />
        </Form.Item>
          </Col>
          <Col span={isMobile ? 24 : 12}>
          <Form.Item
          label="Site"
          name="site"
        >
          <Input id="id-tour-my-school-settings-input-site" />
        </Form.Item>
          </Col>
        </Row>
  
      </Flex>
    </GenericCard>
  );
}

export default SchoolInfo;
