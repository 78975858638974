import { Dropdown, Flex, Menu } from 'antd';
import Text from 'components/typography/text';
import { t } from 'core/resources/strings';
import { calcAge } from 'infra/helpers/date';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import { useEffect, useRef, useState } from 'react';
import { useDownloadExcel } from 'react-export-table-to-excel';
import { FaChevronRight } from 'react-icons/fa6';
import { useReactToPrint } from 'react-to-print';
import StudentKeys from '../StudentKeys';
import StudentKeysNotes from '../StudentKeysNotes';

export interface StudentBasic {
  id: string;
  name: string;
  birthday?: string;
  license: string;
  classDetails: ClassBasic;
  tenantName: string;
  belongsToClass?: boolean;
}

export interface ClassBasic {
  id: string;
  name: string;
  period: string;
  serie: string;
  year: number;
}
export interface ClassWithStudents {
  classDetails: ClassBasic;
  students: StudentBasic[];
}

function DownloadStudentKeys({ students }: { students: StudentBasic[] }) {
  const componentRef = useRef(null);
  const { colorPrimary } = useU4heroColors();
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [selected, setSelected] = useState<string>();

  function groupStudentsByClass(students: StudentBasic[]): ClassWithStudents[] {
    const classMap = students.reduce((acc, student) => {
      const classId = student.classDetails.id;

      if (!acc[classId]) {
        acc[classId] = {
          classDetails: student.classDetails,
          students: [],
        };
      }

      acc[classId].students.push(student);

      return acc;
    }, {} as Record<string, ClassWithStudents>);

    return Object.values(classMap);
  }

  const classes = groupStudentsByClass(students);

  const tableRef = useRef(null);

  const docTitle =
    classes.length > 1
      ? `Lista de Chaves de Acessos dos Estudantes - ${students?.[0].tenantName}`
      : 'Lista de Chaves de Acessos dos Estudantes - ' +
        `${students?.[0]?.classDetails?.name} - ${
          students?.[0]?.classDetails?.serie
        } - ${t(`app.${students?.[0]?.classDetails?.period}`)} - ${
          students?.[0]?.classDetails?.year
        }`;

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: docTitle,
    sheet: docTitle,
  });

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: docTitle,
  });

  const header = ['Nome', 'Chave', 'Idade'];

  useEffect(() => {
    if (selected) {
      if (selected === 'download-table') {
        onDownload();
      }
      setTimeout(() => {
        handlePrint();
      }, 500);
    }
  }, [selected]);

  return (
    <>
      <Dropdown
        overlay={
          <Menu
            onClick={(e) => {
              setSelected(e.key);
            }}
            style={{ border: `3px solid ${colorPrimary}` }}
          >
            <Menu.Item
              key={'colorful'}
              id="id-tour-download-student-keys-colorful-mode"
            >
              <Text color={colorPrimary} size="md" bold>
                Versão Colorida
              </Text>
            </Menu.Item>
            <Menu.Item
              key={'color-grey'}
              id="id-tour-download-student-keys-color-grey-mode"
            >
              <Text color={colorPrimary} size="md" bold>
                Versão escalas de cinza
              </Text>
            </Menu.Item>
            <Menu.Item
              key={'notes'}
              id="id-tour-download-student-keys-notes-mode"
            >
              <Text color={colorPrimary} size="md" bold>
                Modo Bilhete
              </Text>
            </Menu.Item>
            <Menu.Item
              key={'download-table'}
              id="id-tour-download-student-table"
            >
              <Text color={colorPrimary} size="md" bold>
                Baixar tabela
              </Text>
            </Menu.Item>
          </Menu>
        }
        open={dropdownVisible}
        onOpenChange={(visible) => setDropdownVisible(visible)}
        trigger={['click']}
      >
        <Flex
          style={{
            boxShadow: `inset 0 0 0 3px ${colorPrimary}`,
            padding: '8px',
            borderRadius: '10px',
            cursor: 'pointer',
          }}
          align="center"
        >
          <Text color={colorPrimary} size={'lg'} bold>
            BAIXAR CHAVE DOS ESTUDANTES
          </Text>
          <FaChevronRight
            style={{
              transform: dropdownVisible ? 'rotate(90deg)' : undefined,
            }}
            color={colorPrimary}
            size={20}
          />
        </Flex>
      </Dropdown>
      {selected === 'notes' ? (
        <StudentKeysNotes ref={componentRef} students={students} />
      ) : null}
      {selected === 'colorful' ? (
        <StudentKeys mode="colorful" ref={componentRef} classes={classes} />
      ) : null}
      {selected === 'color-grey' ? (
        <StudentKeys mode="color-grey" ref={componentRef} classes={classes} />
      ) : null}

      <table ref={tableRef} hidden={true}>
        <tbody>
          <tr>
            <th>U4Hero! Tecnologia Socioemocional</th>
          </tr>
          <tr>
            <th>Nome da Escola:</th>
            <td>{students?.[0]?.tenantName}</td>
          </tr>

          {classes.map((item, index) => (
            <div key={index}>
              <tr>
                <th>Turma:</th>
                <td>{item.classDetails?.name}</td>
              </tr>
              <tr>
                <th>Série:</th>
                <td>{item.classDetails?.serie}</td>
              </tr>
              <tr>
                <th>Turno:</th>
                <td>{t(`app.${item.classDetails?.period}`)}</td>
              </tr>
              <tr>
                <th>Ano letivo:</th>
                <td>{item.classDetails?.year}</td>
              </tr>
              <tr>
                {header.map((item) => (
                  <th>{item}</th>
                ))}
              </tr>
              {item.students.map((student) => (
                <tr>
                  <td>{student.name}</td>
                  <td>{student.license}</td>
                  <td>{calcAge(student.birthday ?? '')}</td>
                </tr>
              ))}
            </div>
          ))}
        </tbody>
      </table>
    </>
  );
}

export default DownloadStudentKeys;
