import { Select } from 'antd';
import { useMediaQuery } from '@chakra-ui/react';
import { ComparativePeriod } from 'core/features/indicators-profile/typings';

interface SelectCompareProps {
  value: string;
  onChange: (v: string) => void;
  period: ComparativePeriod;
  updateForm?: (field: string, value: any) => void;
}

function SelectCompare({
  value,
  onChange,
  updateForm,
  period,
}: SelectCompareProps) {
  const [isMobile] = useMediaQuery('(max-width: 768px)');

  const interval = {
    year: 12,
    bimester: 2,
    trimester: 3,
    quadrimester: 4,
    semester: 6,
  };
  let options = [];
  for (let i = 0; i < 12 / interval[period]; i++) {
    const group: any = {
      'bimester': 'Bimestre',
      'trimester': 'Trimestre',
      'quadrimester': 'Quadrimestre',
      'semester': 'Semestre',
    };
    if (interval[period] === 12) {
      const year = new Date().getFullYear();
      for (let i = year; i > 2019; i--) {
        options.push({
          value: i,
          label: i.toString(),
        });
      }
    } else {
      options.push({
        value: i,
        label: `${i + 1}° ${group[period]}`,
      });
    }
  }

  return (
    <Select
      id="id-tour-indicators-custom-panel-compare-input"
      placeholder="Selecione"
      options={options}
      style={{ width: '100%' }}
      onChange={(v) => {
        onChange(v);
        // updateForm('subClassification', undefined);
      }}
      value={value}
      size={isMobile ? 'middle' : 'large'}
    />
  );
}

export default SelectCompare;
