import { ApexOptions } from 'apexcharts';
import ReactApexChart from 'react-apexcharts';

const colors = [
  '#FF0000',
  '#00FF00',
  '#0000FF',
  '#FFFF00',
  '#00FFFF',
  '#FF00FF',
  '#C0C0C0',
  '#808080',
  '#800000',
  '#808000',
  '#008000',
  '#800080',
  '#008080',
  '#000080',
  '#FFA500',
  '#FFFFE0',
  '#FFD700',
  '#ADFF2F',
  '#7FFF00',
  '#32CD32',
  '#00FF7F',
  '#3CB371',
  '#40E0D0',
  '#00BFFF',
  '#1E90FF',
  '#4169E1',
  '#FF00FF',
  '#FF1493',
  '#FF69B4',
  '#FFC0CB',
  '#FFA07A',
  '#FF8C00',
  '#FF4500',
  '#B22222',
  '#8B0000',
  '#800080',
  '#4B0082',
  '#191970',
  '#0000CD',
  '#006400',
  '#2E8B57',
  '#228B22',
  '#808000',
  '#556B2F',
];
function BarGraph({
  categories,
  series,
}: {
  categories: string[];
  series: ApexAxisChartSeries;
}) {
  const options: ApexOptions = {
    chart: {
      type: 'bar',
      height: 350,
      toolbar:{
        show:false
      }
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        borderRadiusApplication: 'end',
        horizontal: true,
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: categories,
    },
  };

  return (
    <ReactApexChart options={options} series={series} type="bar" height={350} />
  );
}

export default BarGraph;
