import { useMediaQuery } from '@chakra-ui/react';
import { Col, Flex, Form, Input, Radio, Row, Space } from 'antd';
import InputMask from 'components/inputs/input-mask';
import SelectOrganization from 'components/inputs/select-organization';
import UploadImagem from 'components/inputs/upload-imagem';
import Text from 'components/typography/text';
import { GenericCard } from 'components/V2/generic-card-styled-component';
import useCheckPermission from 'core/features/user/hooks/useCheckPermission';
import { validateCnpj } from 'infra/helpers/validateCnpj';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import { Permissions } from 'core/resources/enums/permissions';
import { TfiInfo } from 'react-icons/tfi';
import { Link } from 'react-router-dom';

function SchoolInfoSection() {
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const { colorPrimary } = useU4heroColors();
  const [check] = useCheckPermission();
  const isU4Hero = check(Permissions.U4hero.Tenant.Create);
  return (
    <GenericCard>
      <Flex vertical style={{ width: '100%' }}>
        <Text
          size="lg"
          color={colorPrimary}
          style={{ marginBottom: '16px', fontWeight: 700 }}
        >
          Dados da Escola
        </Text>
        <Form.Item
          name="imageUrl"
          rules={[
            {
              message: '',
            },
          ]}
        >
          {/* @ts-ignore */}
          <UploadImagem placeholderImg="instituition" />
        </Form.Item>

        <Row gutter={[24, 0]}>
          <Col span={isMobile ? 24 : 14}>
            {' '}
            <Form.Item
              label="Nome"
              name="name"
              rules={[
                {
                  required: true,
                  message: 'Por favor, informe seu nome completo!',
                },
              ]}
            >
              <Input
                id="id-tour-institutions-create-input-name"
                placeholder="Insira o nome da Organização"
              />
            </Form.Item>
          </Col>
          <Col span={isMobile ? 24 : 10}>
            <Form.Item
              label="CNPJ"
              rules={[
                {
                  message: 'Por favor, informe o número do cnpj!',
                },
                {
                  validator: async (rule, value) => {
                    if (value) {
                      if (!validateCnpj(value)) {
                        throw new Error('CNPJ invalido!');
                      }
                    }
                  },
                },
              ]}
              name="document"
            >
              <InputMask mask="99.999.999/9999-99">
                {() => (
                  <Input
                    id="id-tour-institutions-create-input-document"
                    placeholder="Insira o CNPJ"
                  />
                )}
              </InputMask>
            </Form.Item>
          </Col>
          <Col span={isMobile ? 24 : 14}>
            <Form.Item label="Site" name="site">
              <Input
                id="id-tour-institutions-create-input-site"
                placeholder="Site institucional"
              />
            </Form.Item>
          </Col>
          <Col span={isMobile ? 24 : 10}>
            <Form.Item
              label="Telefone"
              name="phone"
              rules={[
                {
                  validator: async (rule, value) => {
                    if (value) {
                      const formattedValue = value.replace(/\D/g, '');
                      if (formattedValue.length < 10) {
                        throw new Error(
                          'O telefone deve ter pelo menos 10 números!'
                        );
                      }
                    }
                  },
                },
              ]}
            >
              <InputMask mask="(99) 99999-9999" autoComplete="off">
                {() => (
                  <Input
                    id="id-tour-institutions-create-input-phone"
                    placeholder="( * * ) * * * * * - * * * *"
                  />
                )}
              </InputMask>
            </Form.Item>
          </Col>
          {isU4Hero ? (
            <Col span={isMobile ? 24 : 14}>
              <Form.Item name="parentId" label="Organização">
                {/* @ts-ignore */}
                <SelectOrganization id="id-tour-institutions-create-input-organization" />
              </Form.Item>
            </Col>
          ) : null}

          <Col span={isMobile ? 24 : 10}>
            <Form.Item
              label="Código INEP"
              name="inep_id"
              tooltip={{
                icon: (
                  <Flex
                    align="center"
                    justify="center"
                    style={{
                      backgroundColor: colorPrimary,
                      borderRadius: '50px',
                      width: '18px',
                      height: '18px',
                      padding: '2px',
                    }}
                  >
                    <TfiInfo style={{ color: 'white', fontWeight: 900 }} />
                  </Flex>
                ),
                title: (
                  <Text size="md" color="white">
                    Não sabe o código INEP da sua escola?{' '}
                    <Link
                      to={{
                        pathname:
                          'https://inepdata.inep.gov.br/analytics/saw.dll?Dashboard&NQUser=inepdata&NQPassword=Inep2014&PortalPath=%2Fshared%2FCenso%20da%20Educa%C3%A7%C3%A3o%20B%C3%A1sica%2F_portal%2FCat%C3%A1logo%20de%20Escolas&Page=Pr%C3%A9-Lista%20das%20Escolas',
                      }}
                      target="_blank"
                      style={{ textDecoration: 'underline' }}
                    >
                      Clique aqui
                    </Link>{' '}
                    para encontrá-lo!
                  </Text>
                ),
                color: colorPrimary,
              }}
              rules={[
                {
                  required: true,
                  message: 'Por favor, informe o código INEP!',
                },
              ]}
            >
              <Input
                id="id-tour-institutions-create-input-inep-id"
                type="number"
                placeholder="Código INEP"
              />
            </Form.Item>
          </Col>

          <Col span={isMobile ? 24 : 12} style={{ alignSelf: 'end' }}>
            <Form.Item name="sector">
              <Radio.Group
                id="id-tour-institutions-create-input-sector"
                style={{ width: '70%' }}
              >
                <span style={{ fontSize: isMobile ? '14px' : '18px' }}>
                  Setor:&nbsp;
                </span>
                <Space direction="horizontal">
                  <Radio value={'private'}>Privado</Radio>
                  <Radio value={'public'}>Público</Radio>
                </Space>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
      </Flex>
    </GenericCard>
  );
}

export default SchoolInfoSection;
