import { useMediaQuery } from '@chakra-ui/react';
import { Form, Radio, Space } from 'antd';

function CategoryInput({
  index,
  updateForm,
}: {
  index: number;
  updateForm: (
    fieldName: string | number | (string | number)[],
    value: any
  ) => void;
}) {
  const [isMobile] = useMediaQuery('(max-width: 768px)');

  return (
    <Form.Item
      rules={[
        {
          required: true,
          message: 'Por favor,  defina a categoria à ser analisada!',
        },
      ]}
      name={[index, 'category']}
      label="Categoria a ser analisada"
    >
      <Radio.Group
        id="id-tour-indicators-custom-panel-analysis-category-input"
        onChange={() => {
          updateForm(['graphs', index, 'graphView'], undefined);
        }}
      >
        <Space direction={isMobile ? 'vertical' : 'horizontal'}>
          <Radio value={'activity'}>Atividades</Radio>
          <Radio value={'avaliation'}>Avaliações</Radio>
          <Radio value={'selfAvaliation'}>Avaliações Iniciais</Radio>
          <Radio value={'emotion'}>Emocionômetro</Radio>
        </Space>
      </Radio.Group>
    </Form.Item>
  );
}

export default CategoryInput;
