import { useReset } from 'core/features/auth/hooks/useReset';
import { useState } from 'react';
import { useCurrentUser } from 'core/features/user/store';
import { useHistory } from 'react-router-dom';
import bgMobile from 'assets/images/bg-reset-mobile.png';
import bg from 'assets/images/bg-reset.png';
import sucess from 'assets/images/password-reset-sucess.png';
import logo from 'assets/images/logo.png';
import { showError } from 'infra/helpers/alerta';
import { useMediaQuery } from '@chakra-ui/media-query';
import { Flex, Form, Input, Modal, Typography } from 'antd';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import PasswordRequirements from 'components/password-requirements';
import { IoClose } from 'react-icons/io5';
import Button from 'components/buttons/button';

const { Title } = Typography;
function ResetPage() {
  const { mutate: handleReset, isLoading } = useReset();
  const { colorPrimary } = useU4heroColors();
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const history = useHistory();
  const { user } = useCurrentUser();
  const [form] = Form.useForm();
  const password = Form.useWatch('password', form);
  const [isOpenModal, setIsOpenModal] = useState(false);
  if (user) {
    history.push('/');
  }

  const _handleReset = (values: {
    password: string;
    passwordConfirmation: string;
  }) => {
    handleReset(values, {
      onSuccess: () => {
        setIsOpenModal(true);
      },
      onError: (err: any) => {
        showError(err?.response?.data?.error);
      },
    });
  };

  return (
    <Flex
      style={{
        width: '100%',
        height: '100vh',
        backgroundImage: isMobile ? '' : `url(${bg})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'bottom',
      }}
    >
      {isOpenModal ? (
        <Modal
          title={
            <p
              style={{
                fontSize: isMobile ? '18px' : '24px',
                color: colorPrimary,
                fontWeight: 700,
              }}
            >
              Senha Redefinida!
            </p>
          }
          open={true}
          centered
          onCancel={() => {
            setIsOpenModal(false);
          }}
          closeIcon={
            <div
              style={{
                background: colorPrimary,
                borderRadius: '50%',
                width: '20px',
                height: '20px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <IoClose size={16} color="white" />
            </div>
          }
          footer={null}
          width={450}
        >
          <Flex vertical justify="center" align="center">
            <img alt="sucesso" src={sucess} width="167px" />
            <p
              style={{
                fontSize: isMobile ? '14px' : '18px',
                textAlign: 'justify',
                fontWeight: 400,
                marginBottom: '16px',
              }}
            >
              Ótimo! Sua senha foi redefinida com sucesso. Agora você pode
              acessar sua conta com sua nova senha.
            </p>
            <Button size="md" onClick={() => history.push('/auth/login')}>
              CONCLUIR
            </Button>
          </Flex>
        </Modal>
      ) : null}
      <Form
        onFinish={(ev) => _handleReset(ev)}
        validateTrigger="onSubmit"
        layout="vertical"
        form={form}
        requiredMark="optional"
      >
        {isMobile ? (
          <img alt="background-mobile" src={bgMobile} width="536px" />
        ) : null}
        <Flex
          style={{
            borderRadius: isMobile ? ' 20px 20px 0px 0px' : '0px 20px 20px 0px',
            background: 'white',
            width: isMobile ? '100vw' : '50vw',
            height: isMobile ? '' : '100vh',
            position: isMobile ? 'relative' : '-moz-initial',
            top: isMobile ? -20 : undefined,
          }}
          vertical
          justify="center"
          align="center"
        >
          <Flex
            vertical
            justify="center"
            style={{
              width: isMobile ? '100%' : '508px',
              padding: isMobile ? '0px 8px' : '',
            }}
          >
            <img
              alt="logo"
              src={logo}
              width={isMobile ? '100px' : '212px'}
              style={{ alignSelf: 'center' }}
            />
            <Title
              level={2}
              style={{
                maxWidth: '508px',
                color: colorPrimary,
                fontSize: isMobile ? '18px' : '28px',
                fontWeight: 700,
                marginTop: isMobile ? '' : '16px',
                textAlign: 'center',
                marginBottom: '0px',
              }}
            >
              Redefina sua senha!
            </Title>
            <p
              style={{
                fontSize: isMobile ? '14px' : '18px',
                textAlign: 'center',
                fontWeight: 400,
                marginBottom: isMobile ? '' : '24px',
              }}
            >
              Por favor, crie uma nova senha seguindo as diretrizes fornecidas
              abaixo
            </p>
            <Form.Item
              label="Nova Senha"
              rules={[
                {
                  required: true,
                  message: 'Por favor, digite sua senha!',
                },
                {
                  min: 12,
                  message: 'Senha deve ter pelo menos 12 caracteres',
                },
                {
                  pattern:
                    /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*()])[\w!@#$%^&*()]+$/,
                  message:
                    'Senha deve conter pelo menos 1 letra maiúscula, 1 letra minúscula, 1 número e 1 caractere especial',
                },
              ]}
              name="password"
              initialValue=""
            >
              <Input.Password
                size="large"
                style={{ width: '100%', height: isMobile ? '40px' : '60px' }}
              />
            </Form.Item>
            <PasswordRequirements password={password} />
            <Form.Item
              label="Confirmar senha"
              rules={[
                {
                  required: true,
                  message: 'Por favor, confirme sua senha!',
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error('Ops, as senhas não são iguais!')
                    );
                  },
                }),
              ]}
              name="passwordConfirmation"
            >
              <Input.Password
                size="large"
                style={{ width: '100%', height: isMobile ? '40px' : '60px' }}
              />
            </Form.Item>

            <Button
              size="lg"
              htmlType="submit"
              style={{
                alignSelf: 'center',
                marginTop: isMobile ? '16px' : '',
              }}
              loading={isLoading}
            >
              ALTERAR SENHA
            </Button>
          </Flex>
        </Flex>
      </Form>
    </Flex>
  );
}

export default ResetPage;
