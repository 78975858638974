import { useQuery } from 'react-query';
import axios from 'infra/http';
import { QuestionHumorDetails } from '../types/QuestionsDetails';

function useQuestionHumor() {
  const fetchData = async (): Promise<QuestionHumorDetails> => {
    const { data } = await axios.get<QuestionHumorDetails>(`/v2/questions/humor`);

    return data;
  };

  return useQuery([`/v2/questions/humor`], () => fetchData());
}

export default useQuestionHumor;
