import TeacherIcon from 'assets/images/icons/TeacherIcon';
import useTotalEducators from 'core/features/indicators/hooks/useTotalEducators';
import StatCard from '../statCard';

function TotalEducators({onRemove}:{onRemove?:()=> void}) {
  const { data } = useTotalEducators();
  return (
    <StatCard title="Educador" total={data?.total ?? 0} Icon={TeacherIcon} onRemove={onRemove} />
  );
}

export default TotalEducators;
