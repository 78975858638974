import { useMediaQuery } from "@chakra-ui/react";
import { Flex, Spin } from "antd";
import { IClass } from "core/features/classes/types";
import useU4heroColors from "infra/packages/antd/u4heroTokens";

function ClassDetailsCard({ classData }: { classData: IClass | undefined }) {

  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const { colorPrimary } = useU4heroColors();

  return (
    <Flex
      style={{
        justifyContent: 'space-between',
        marginTop: isMobile ? '8px' : '24px',
        borderRadius: '10px',
        width: '100%',
        height: 'fit-content',
        background: colorPrimary,
        position: 'relative',
      }}>
        <div
    style={{
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      background: "url('https://u4hero-files.s3.sa-east-1.amazonaws.com/helpers/faa8a4a9-76fe-4211-8c68-5c3744f5cd5b.png')",
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      borderRadius: '10px',
      zIndex: 1,
    }}
  ></div>
      {classData ?
        <Flex style={{width: '100%'}}>
          <Flex
            style={{
              width: '100%',
              paddingLeft: '18px',
              paddingBottom: '16px',
              paddingTop: '16px',
              padding: '10px',
            }}
            justify="center"
            gap='4px'
            vertical
          >
            <p style={{ color: 'white', lineHeight: '21px', fontSize: isMobile ? '14px':'18px', fontWeight: 400 }}><span style={{ fontWeight: 600 }}>Escola: </span>{classData.tenant.name}</p>
            <p style={{ color: 'white', lineHeight: '21px', fontSize: isMobile ? '14px':'18px', fontWeight: 400 }}><span style={{ fontWeight: 600 }}>Série/Turma: </span>{classData?.serie.name} - {classData.name}</p>
            <p style={{ color: 'white', lineHeight: '21px', fontSize: isMobile ? '14px':'18px', fontWeight: 400 }}><span style={{ fontWeight: 600 }}>Ano Letivo: </span>{classData?.year}</p>
            <p style={{ color: 'white', lineHeight: '21px', fontSize: isMobile ? '14px':'18px', fontWeight: 400 }}><span style={{ fontWeight: 600 }}>Turno: </span>{classData?.period === 'morning' ? 'Matutino' : 'Vespertino'}</p>
            <p style={{ color: 'white', lineHeight: '21px', fontSize: isMobile ? '14px':'18px', fontWeight: 400 }}><span style={{ fontWeight: 600 }}>Chave: </span>{classData?.user.email}</p>

          </Flex>
          <Flex style={{ height: '100%' }} align="end">
            <img width={'81px'} height={'79px'} src="https://u4hero-files.s3.amazonaws.com/helpers/3de055ed-6250-49bb-b273-ec689d744627.png" alt="Lumi sorrindo para o leitor" />
          </Flex>
        </Flex> : <Spin />}

    </Flex>
  )
}

export default ClassDetailsCard;