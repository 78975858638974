import { Flex, Form, Input } from 'antd';
import Button from 'components/buttons/button';
import SubTitle from 'components/typography/subTitle';
import { GenericCard } from 'components/V2/generic-card-styled-component';
import { useState } from 'react';
import ChangeDirectorModal from '../ChangeDiretorModal';

function ContactInfo() {
  const [isOpenModal, setIsOpenModal] = useState(false);

  return (
    <>
      {isOpenModal ? (
        <ChangeDirectorModal onClose={() => setIsOpenModal(false)} />
      ) : null}
      <GenericCard style={{ position: 'relative' }}>
        <Button
          size="sm"
          style={{ position: 'absolute', right: 16, top: 16 }}
          variant="outline"
          onClick={() => setIsOpenModal(true)}
        >
          TROCAR DIREÇÃO
        </Button>
        <Flex vertical style={{ width: '100%' }}>
          <SubTitle primary>Direção e Contato</SubTitle>
          <Form.Item label="Nome do Diretor" name="adminName">
            <Input id="id-tour-my-school-settings-input-admin-name" disabled />
          </Form.Item>
          <Form.Item
            label="E-mail"
            rules={[
              {
                required: true,
                message: 'Por favor, informe seu e-mail!',
              },
            ]}
            name="adminEmail"
          >
            <Input
              type="email"
              disabled={true}
              style={{
                width: '100%',
              }}
              id="id-tour-my-school-settings-input-admin-email"
            />
          </Form.Item>
        </Flex>
      </GenericCard>
    </>
  );
}

export default ContactInfo;
