import usePracticesAuthors from 'core/features/practices/hooks/usePracticesAuthors';
import useEnvironments from 'core/features/questions/hooks/useEnvironments';
import { t } from 'core/resources/strings';
import useTagsCompetence from 'core/features/tags/hooks/useTagsCompetence';
import { FilterProps } from 'infra/helpers/types';
import GenericFilterComponent from 'components/generic-filter';

function PracticeFilter({ filters, onChangeFilters }: FilterProps) {
  const { data: authors } = usePracticesAuthors();
  const environments = useEnvironments();
  const { data: dataCompetences } = useTagsCompetence({ competence: true });
  const { data: dataSkills } = useTagsCompetence({ skill: true });


  return (
    <GenericFilterComponent
      filters={filters}
      onChangeFilters={onChangeFilters}
      filterGroups={[
        {
          title: 'Ambiente de Análise',
          filterName: 'environments',
          options:
            environments?.data?.map((item) => {
              return { label: t(`app.${item.name}`), value: item.id };
            }) ?? [],
          type: 'number',
        },
        {
          title: 'Competência/Habilidade',
          filterName: 'tags',
          options: [],
          children: dataCompetences?.map((competence) => {
            return {
              title: competence.name['pt-br'],
              options: dataSkills?.filter((item) => item.parentId === competence.id).map((skill) => {
                return { label: skill.name['pt-br'], value: skill.id }
              }) ?? []
            }
          }) ?? []
        },
        {
          title: 'Dificuldade',
          filterName: 'dificulties',
          options: ['Fácil', 'Médio', 'Difícil'].map((item) => {
            return { label: item, value: item };
          }),
        },
        {
          title: 'Disciplina Relacionada',
          filterName: 'subjects',
          options: [
            'Arte',
            'Ciências',
            'Educação Física',
            'Geografia',
            'História',
            'Língua Inglesa',
            'Língua Portuguesa',
            'Matemática',
          ].map((item) => {
            return { label: item, value: item };
          }),
        },
        {
          title: 'Duração',
          filterName: 'durations',
          type: 'number',
          options: [10, 20, 30, 40, 50].map((item) => {
            return { label: `${item} minutos`, value: item };
          }),
        },
        {
          title: 'Faixa Etária',
          filterName: 'age',
          options: [3, 6, 12, 16].map((item) => {
            return { label: `Até ${item} anos`, value: item };
          }),
          type: 'radio',
        },
        {
          title: 'Modalidade',
          filterName: 'modalities',
          options: [
            { label: 'Presencial', value: 0 },
            { label: 'Remoto', value: 1 },
            { label: 'Múltiplos Contextos', value: 3 },
          ],
          type: 'number',
        },
        {
          title: 'Autor',
          filterName: 'authors',
          options:
            authors?.map((item) => {
              return { label: item.name, value: item.id };
            }) ?? [],
        },
        {
          title: 'Tipo',
          filterName: 'published',
          options: [
            {
              label: 'Publicado',
              value: 1,
            },
            {
              label: 'Rascunho',
              value: 2,
            },
          ],
          type: 'radio',
        },
        {
          title: 'Data de publicação',
          filterName: 'publishDate',
          options: [],
          type: 'date',
          open: true,
        },
      ]}
    />
  );
}

export default PracticeFilter;
