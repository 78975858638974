import { useMediaQuery } from '@chakra-ui/react';
import { Col, Flex, Form, Input, Radio, Row, Space } from 'antd';
import InputMask from 'components/inputs/input-mask';
import UploadImagem from 'components/inputs/upload-imagem';
import SubTitle from 'components/typography/subTitle';
import { GenericCard } from 'components/V2/generic-card-styled-component';
import { validateCpf } from 'infra/helpers/validateCpf';

function PersonalInfo() {
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  return (
    <GenericCard>
      <Flex vertical style={{ width: '100%' }}>
        <SubTitle primary style={{ marginBottom: '8px' }}>
          Informações Pessoais
        </SubTitle>
        <Form.Item
          name="imageUrl"
          rules={[
            {
              message: '',
            },
          ]}
        >
          {/* @ts-ignore */}
          <UploadImagem />
        </Form.Item>
        <Form.Item
          label="Nome Completo"
          name="name"
          rules={[
            {
              required: true,
              message: 'Por favor, informe seu nome completo!',
            },
          ]}
        >
          <Input id="id-tour-my-profile-input-name" />
        </Form.Item>
        <Row gutter={[52, 0]}>
          <Col span={isMobile ? 24 : 12}>
            <Form.Item
              label="CPF"
              rules={[
                {
                  message: 'Por favor, informe o número do cpf!',
                },
                {
                  validator: async (rule, value) => {
                    if (value) {
                      if (!validateCpf(value)) {
                        throw new Error('Cpf invalido!');
                      }
                    }
                  },
                },
              ]}
              name="document"
            >
              <InputMask mask="999.999.999-99">
                {() => <Input id="id-tour-my-profile-input-document" />}
              </InputMask>
            </Form.Item>
          </Col>
          <Col span={isMobile ? 24 : 12}>
            <Form.Item
              label="Data de Nascimento"
              rules={[
                {
                  required: true,
                  message: 'Por favor, informe a data de nascimento!',
                },
              ]}
              name="birthday"
            >
              <Input id="id-tour-my-profile-input-date-of-birth" type="date" />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item name="gender">
          <Radio.Group id="id-tour-my-profile-input-gender">
            <span style={{ fontSize: isMobile ? '14px' : '18px' }}>
              Sexo:&nbsp;
            </span>
            <Space direction="horizontal">
              <Radio
                value={'F'}
              >
                Feminino
              </Radio>
              <Radio
                value={'M'}
              >
                Masculino
              </Radio>
            </Space>
          </Radio.Group>
        </Form.Item>
      </Flex>
    </GenericCard>
  );
}

export default PersonalInfo;
