import { useMediaQuery } from '@chakra-ui/media-query';
import { Flex } from 'antd';
import Button from 'components/buttons/button';
import { GenericCard } from 'components/V2/generic-card-styled-component';
import { PostDetails } from 'core/features/posts/typings';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import moment from 'moment';
import { useHistory } from 'react-router';

function PostCard({
  post,
  minimalist = false,
}: {
  post: PostDetails;
  minimalist?: boolean;
}) {
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const history = useHistory();
  const { colorPrimary, colorGreyBorderAndFont } = useU4heroColors();

  return (
    <GenericCard height="100%" className="post-card">
      <Flex
        vertical
        justify="space-between"
        style={{ height: '100%', position: 'relative' }}
      >
        <div>
          <img
            alt={`banner ${post.title}`}
            src={post.banner}
            style={{ borderRadius: '10px' }}
          />

          {!minimalist && (
            <p
              style={{
                fontSize: isMobile ? '10px' : '14px',
                color: colorGreyBorderAndFont,
                textAlign: 'end',
                marginBottom: '4px',
              }}
            >
              {moment(post.createdAt).format('DD/MM/YYYY')}
            </p>
          )}
        </div>
        <p
          style={{
            fontSize: isMobile ? '16px' : '18px',
            color: colorPrimary,
            textAlign: 'center',
            fontWeight: 700,
            marginTop: '8px',
            marginBottom: '4px',
          }}
        >
          {post.title}
        </p>
        {!minimalist && (
          <p
            style={{
              fontSize: isMobile ? '14px' : '16px',
              color: 'black',
              textAlign: 'justify',
              fontWeight: 400,
              marginBottom: '8px',
            }}
          >
            {post.description}
          </p>
        )}

        <Button
          size="sm"
          style={{
            justifySelf: 'center',
            alignSelf: 'center',
            marginTop: '10px',
          }}
          onClick={() => history.push(`/posts/${post.id}`)}
        >
          LEIA MAIS
        </Button>
      </Flex>
    </GenericCard>
  );
}

export default PostCard;
