import { Flex, Form } from 'antd';
import { GenericCard } from 'components/V2/generic-card-styled-component';
import SubTitle from 'components/typography/subTitle';
import Text from 'components/typography/text';
import { ApexOptions } from 'apexcharts';
import ReactApexChart from 'react-apexcharts';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import { MdDone, MdExpandMore } from 'react-icons/md';
import { IoMdClose } from 'react-icons/io';
import { useMediaQuery } from '@chakra-ui/react';
import { useState } from 'react';
function ProfileProgress() {
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const { colorPrimary, colorGreyBorderAndFont } = useU4heroColors();
  const [showDetails, setShowDetails] = useState(false);
  const form = Form.useFormInstance();
  const formValues = Form.useWatch([], form);

  const options: ApexOptions = {
    chart: {
      type: 'radialBar',
    },
    plotOptions: {
      radialBar: {
        hollow: {
          size: '60%',
        },
        dataLabels: {
          show: true,
          value: {
            show: true,
            fontSize: '2vh',
            fontWeight: 800,
            formatter: function (val: any) {
              return Math.trunc(val) + '%';
            },
          },
        },
      },
    },
    labels: [''],
    colors: [colorPrimary],
  };
  const items = [
    {
      label: 'Informações da Instituição',
      value: 40,
      complete: false,
      checkFields: ['name', 'document', 'phone', 'fantasyName', 'site'],
    },
    {
      label: 'Direção e Contato',
      value: 30,
      complete: false,
      checkFields: ['adminName', 'adminEmail'],
    },
    {
      label: 'Endereço',
      value: 30,
      complete: false,
      checkFields: [
        'addressZipCode',
        'addressStreet',
        'addressNumber',
        'addressDistrict',
        'addressState',
        'addressCity',
        'addressComplement',
        'addressNumber',
      ],
    },
  ];

  const checkCompletion = (fields: string[]) => {
    for (const field of fields) {
      if (formValues?.hasOwnProperty(field)) {
        if (!formValues[field]) {
          return false;
        }
      } else {
        return false;
      }
    }
    return true;
  };

  const updatedItems = items.map((item) => {
    const allFieldsFilled = checkCompletion(item.checkFields);
    return {
      ...item,
      complete: allFieldsFilled,
    };
  });

  return (
    <GenericCard>
      <Flex vertical justify="center" style={{ width: '100%' }}>
        <SubTitle primary>Progresso do Perfil</SubTitle>
        <ReactApexChart
          width="100%"
          height="290px"
          options={options}
          series={[
            updatedItems.reduce((total, item) => {
              if (item.complete) {
                return total + item.value;
              }
              return total;
            }, 0),
          ]}
          type="radialBar"
        />
        {isMobile && !showDetails ? (
          <Flex justify="center" onClick={() => setShowDetails(true)}>
            <p
              style={{
                color: colorGreyBorderAndFont,
                fontSize: '12px',
                fontWeight: 500,
              }}
            >
              Mais detalhes
            </p>
            <MdExpandMore color={colorGreyBorderAndFont} size={15} />
          </Flex>
        ) : null}
        {!isMobile || showDetails ? (
          <>
            {updatedItems.map((item) => (
              <Flex key={item.label} gap="8px" align="center">
                {item.complete ? (
                  <MdDone size={24} color="black" />
                ) : (
                  <IoMdClose size={24} color={colorGreyBorderAndFont} />
                )}

                <Text color={item.complete ? 'black' : colorGreyBorderAndFont}>
                  {item.label}
                </Text>
                <Text color={item.complete ? 'black' : colorPrimary}>{`${
                  item.complete ? `${item.value}%` : `+ ${item.value}%`
                }`}</Text>
              </Flex>
            ))}
            {isMobile ? (
              <Flex justify="center" onClick={() => setShowDetails(false)}>
                <p
                  style={{
                    color: colorGreyBorderAndFont,
                    fontSize: '12px',
                    fontWeight: 500,
                  }}
                >
                  Menos Detalhes
                </p>
                <MdExpandMore color={colorGreyBorderAndFont} size={15} />
              </Flex>
            ) : null}
          </>
        ) : null}
      </Flex>
    </GenericCard>
  );
}

export default ProfileProgress;
