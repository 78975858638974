import { Form, Radio, Space } from 'antd';
import { TagType } from 'core/features/competence/typings';

function SelectTagType({
  id,
  removeLabel,
  onChange,
}: {
  id?: string;
  removeLabel?: boolean;
  onChange?: () => void;
}) {
  const options = [
    {
      label: 'Competências',
      value: TagType.Competências,
    },
    {
      label: 'Clima Escolar',
      value: TagType['Clima Escolar'],
    },
    {
      label: 'Treinamento',
      value: TagType.Treinamento,
    },
    {
      label: 'Pesquisa',
      value: TagType.Pesquisa,
    },
  ];
  return (
    <Form.Item
      rules={[
        {
          required: true,
          message: 'Por favor, defina a área de análise desejada',
        },
      ]}
      name="tagType"
      label={removeLabel ? '' : 'Selecione a área de análise desejada:'}
      style={{ marginTop: removeLabel ? '8px' : undefined }}
    >
      <Radio.Group id={id} onChange={onChange}>
        <Space direction="horizontal" wrap>
          {options.map((item, index) => (
            <Radio value={item.value} key={index}>
              {item.label}
            </Radio>
          ))}
        </Space>
      </Radio.Group>
    </Form.Item>
  );
}

export default SelectTagType;
