import { DatePicker, Flex, Spin } from 'antd';
import { ApexOptions } from 'apexcharts';
import SubTitle from 'components/typography/subTitle';
import { GenericCard } from 'components/V2/generic-card-styled-component';
import useStudentFrequencyGraph from 'core/features/indicators/hooks/useStudentFrequencyGraph';
import dayjs from 'dayjs';
import moment from 'moment';
import { useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { AiOutlineCalendar } from 'react-icons/ai';
const { RangePicker } = DatePicker;


function StudentFrequencyGraph() {
  const [date,setDate] = useState<any>();
  const { data, isLoading } = useStudentFrequencyGraph({
    startDate:date ? dayjs(date[0]).format('YYYY-MM-DD') : undefined,
    endDate:date ? dayjs(date[1]).format('YYYY-MM-DD') : undefined
  });
  const counts = data?.data.map((entry) => [new Date(entry.date).getTime(),entry.distinctUserCount]);

  const options: ApexOptions = {
    chart: {
      height: 350,
      type: 'area',
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth',
    },
    xaxis: {
      type: 'datetime',
      labels: {
        formatter: (value) => dayjs(value).utc().format('DD/MM/YYYY')
      },
    },
  };

  const series = [
    {
      name: 'Frequência',
      data: counts ?? [],
    },
  ];

  return (
    <GenericCard height="100%" width='100%'>
      <Flex vertical style={{ width: '100%' }}>
        <SubTitle primary>Frequência dos Estudantes</SubTitle>
        <RangePicker
            style={{ width: '50%',alignSelf:'flex-end' }}
            format="DD/MM/YYYY"
            suffixIcon={<AiOutlineCalendar color="black" />}
            picker={'date'}
            onChange={(dates)=> setDate(dates)}
            value={date}
          />
        {isLoading ? (
          <Spin size={'large'} />
        ) : (
          <ReactApexChart
            width="100%"
            options={options}
            series={series}
            type="area"
            id="id-tour-student-frequency-graph"
          />
        )}
      </Flex>
    </GenericCard>
  );
}

export default StudentFrequencyGraph;
