import { useMediaQuery } from '@chakra-ui/react';
import { Flex, Form, Input, Switch } from 'antd';
import Button from 'components/buttons/button';
import Modal2Fa from 'components/modals/modal-activate-2-fa';
import SubTitle from 'components/typography/subTitle';
import { GenericCard } from 'components/V2/generic-card-styled-component';
import useToggle2Fa from 'core/features/auth/hooks/useToggle2Fa';
import { notificateError } from 'infra/helpers/notifications';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import { useState } from 'react';
import ChangePassword from '../ChangePassword';

function Safety() {
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const { colorPrimary } = useU4heroColors();
  const [isChangePasswordOpen, setIsChangePasswordOpen] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const { mutate: toggle2Fa } = useToggle2Fa();
  const [activate, setActivate] = useState(false);
  const form = Form.useFormInstance();
  const isActivated = form.getFieldValue('twofactor');
  const _handleToggle = (activate: boolean) => {
    toggle2Fa(activate, {
      onSuccess: () => {
        setIsOpenModal(true);
      },
      onError: () => {
        notificateError(
          'Ocorreu um problema ao enviar o codigo. Tente novamente.'
        );
      },
    });
  };

  return (
    <GenericCard>
      {isOpenModal ? (
        <Modal2Fa
          onSucess={() => form.setFieldValue('twofactor', activate)}
          activate={activate}
          onClose={() => setIsOpenModal(false)}
        />
      ) : null}
      <Flex vertical style={{ width: '100%' }}>
        <SubTitle primary>Segurança</SubTitle>
        <Form.Item name="twofactor" label="Autenticação de dois fatores">
          <span style={{ display: 'none' }}></span>
          <Switch
            onChange={(v, e) => {
              e.preventDefault();
              setActivate(v);
              _handleToggle(v);
            }}
            checked={isActivated}
          />
        </Form.Item>
        {isChangePasswordOpen ? <ChangePassword /> : null}
        <Button
          variant="outline"
          size="md"
          id="id-tour-my-profile-change-password-button"
          style={{
            alignSelf: isMobile ? 'center' : 'end',
            display: !isChangePasswordOpen ? 'block' : 'none',
          }}
          onClick={() => setIsChangePasswordOpen(true)}
        >
          ALTERAR SENHA
        </Button>
      </Flex>
    </GenericCard>
  );
}

export default Safety;
