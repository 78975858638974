import SvgIcon from 'components/SvgIcon';

function ResourcesIcon({
  size = 30,
  color = 'black',
}: {
  size?: number | string;
  color?: string;
}) {
  return (
    <SvgIcon
      url="https://u4hero-files.s3.sa-east-1.amazonaws.com/svg-site/backpack_rucksack_school_bag_bag_icon_209216.svg"
      color={color}
      size={size}
    />
  );
}

export default ResourcesIcon;
