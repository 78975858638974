import useSeries from 'core/features/series/hooks/useSeries';
import useAllClasses from 'core/features/classes/hooks/useAllClasses';
import GenericFilterComponent from 'components/generic-filter';
import { FilterProps } from 'infra/helpers/types';
import { useParams } from 'react-router-dom';

const studentTypes = [
  { value: 'deleted', label: 'Estudantes desativados' },
  { value: 'active', label: 'Estudantes com chave ativa' },
  { value: 'without-class', label: 'Estudantes sem turma' },
];

const progressOptions = [
  { value: 'not_started', label: 'Não Iniciado (0%)' },
  { value: 'in_progress', label: 'Em Andamento (1% - 79%)' },
  { value: 'completed', label: 'Concluído (100%)' },
];

function StudentFilter({ filters, onChangeFilters }: FilterProps) {
  const { data } = useSeries();
  const { tenantId } = useParams<{ tenantId: string }>();
  const { data: dataClasses } = useAllClasses({ tenantId });

  return (
    <GenericFilterComponent
      filters={filters}
      onChangeFilters={onChangeFilters}
      filterGroups={[
        {
          title: 'Tipo',
          filterName: 'types',
          options: studentTypes,
        },
        {
          title: 'Série',
          filterName: 'series',
          options:
            data?.map((item) => {
              return { label: item.name, value: item.id };
            }) ?? [],
        },
        {
          title: 'Turma',
          filterName: 'classes',
          options:
            dataClasses?.data.map((item) => {
              return {
                label:
                  item.name.length > 20
                    ? item.name.substr(0, 17) + '...'
                    : item.name,
                value: item.id,
              };
            }) ?? [],
        },
        {
          title: 'Progresso',
          filterName: 'progress',
          options: progressOptions,
        },
        {
          title: 'Último acesso a partir de',
          filterName: 'lastAccess',
          options: [],
          type: 'date',
          open: true,
        },
      ]}
    />
  );
}

export default StudentFilter;
