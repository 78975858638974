import { useMediaQuery } from '@chakra-ui/media-query';
import { Col, Flex, Form, Row, Spin } from 'antd';
import AppliedFilters from 'components/applied-filters';
import SearchBar from 'components/V2/SearchBar';
import UseCountGamesByResponsibleId from 'core/features/games/hooks/useCountGamesByResponsibleId';
import useGames from 'core/features/games/hooks/useGames';
import { useCurrentUser } from 'core/features/user/store';
import { FilterType } from 'infra/helpers/types';
import { useEffect, useMemo, useState } from 'react';
import GameCard from './GameCard';
import GameFilter from './GameFilter';

function StepGame({
  value,
  onChange,
  setMaxQuestions,
}: {
  value: string | null;
  onChange: (v: string | null) => void;
  setMaxQuestions: (v: number | undefined) => void;
}) {
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const [search, setSearch] = useState('');
  const [filters, setFilters] = useState<FilterType>({
    limits: [],
    classifications: [],
    age: [],
    durations: [],
    favorite: [],
  });
  const { data, isLoading } = useGames({
    page: 1,
    search,
    ageClassification: filters?.age?.map((item) => item.value)[0] ?? undefined,
    durations: filters?.durations?.map((item) => item.value) ?? undefined,
    questionLimits: filters?.limits?.map((item) => item.value) ?? undefined,
    favorites: filters.favorite.map((item) => item.value)[0] ?? undefined,
  });
  const user = useCurrentUser();

  const form = Form.useFormInstance();
  const classes = Form.useWatch('classes', form);
  const students = Form.useWatch('students', form);
 
  const { data: countGames } = UseCountGamesByResponsibleId({
    responsibleId: user?.user?.id,
    classes,
    students,
    enabled:
      (classes !== undefined && classes?.length !== 0) ||
      (students !== undefined && students.length !== 0),
  });

  useEffect(() => {
    if (data?.data && value) {
      setMaxQuestions(
        data?.data?.find((item) => item.id === value)?.maxQuestions
      );
    }
  }, [data]);

  if (data) {
    for (const game of data?.data) {
      game.count = countGames?.find((t) => t.gameId === game.id)?.count ?? 0;
    }
  }

  const filteredGames = useMemo(() => {
    if (!data || !data.data) return [];

    if (
      filters.classifications.map((item) => item.value).includes('used') &&
      filters.classifications.map((item) => item.value).includes('not-used')
    ) {
      return data.data;
    }

    return data.data.filter((game) => {
      if (filters.classifications.map((item) => item.value).includes('used')) {
        return game?.count && game.count > 0;
      } else if (
        filters.classifications.map((item) => item.value).includes('not-used')
      ) {
        return game.count === 0;
      }
      return true;
    });
  }, [data, filters.classifications]);

  return (
    <Flex vertical gap="8px">
      <p
        style={{
          fontSize: isMobile ? '14px' : '18px',
          fontWeight: 400,
          color: 'black',
        }}
      >
        Selecione um jogo para a atividade:
      </p>
      <Flex
        align="center"
        gap="16px"
        style={{ marginBottom: '16px', width: '100%' }}
      >
        <SearchBar width="100%" value={search} onSearch={setSearch} />

        <GameFilter filters={filters} onChangeFilters={setFilters} />
      </Flex>
      <AppliedFilters appliedFilters={filters} onChangeFilters={setFilters} />
      {isLoading ? (
        <Spin size="large" />
      ) : (
        <Row id="id-tour-game-step-row" gutter={[65, 16]} align="stretch">
          {filteredGames.map((item) => (
            <Col key={item.id} style={{ width: isMobile ? '100%' : '20%' }}>
              <GameCard
                item={item}
                isSelected={value === item.id}
                onSelect={() => {
                  if (value === item.id) {
                    onChange(null);
                    setMaxQuestions(0);
                  } else {
                    onChange(item.id);
                    setMaxQuestions(item.maxQuestions);
                  }
                }}
              />
            </Col>
          ))}
        </Row>
      )}
    </Flex>
  );
}

export default StepGame;
