import { useMediaQuery } from '@chakra-ui/media-query';
import { Flex, Form, Steps, Input } from 'antd';
import Layout from 'components/layout';
import useActivity from 'core/features/activity/hooks/useActivity';
import { useSaveActivity } from 'core/features/activity/hooks/useSaveActivity';
import { ActivityForm } from 'core/features/activity/types';
import {
  notificateError,
  notificateSucess,
  openNotification,
} from 'infra/helpers/notifications';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { FaCheckCircle } from 'react-icons/fa';
import {
  PiBrainThin,
  PiCalendarThin,
  PiGameControllerThin,
  PiSealQuestionThin,
  PiUsersFourThin,
} from 'react-icons/pi';
import { Permissions } from 'core/resources/enums/permissions';
import { useHistory, useLocation } from 'react-router';
import { useParams } from 'react-router-dom';
import StepClass from './components/StepClass';
import StepGame from './components/StepGame';
import StepQuestion from './components/StepQuestion';
import StepStudents from './components/StepStudents';
import StepTag from './components/StepTag';
import useCheckPermissions from 'core/features/user/hooks/useCheckPermissions';
import CloseButton from 'components/buttons/close-button';
import Button from 'components/buttons/button';
import Title from 'components/typography/title';
import moment from 'moment';
import CustomizedRequiredMark from 'components/required-mark';

function CreateActivity() {
  const history = useHistory();
  const { colorPrimary, colorGreyBorderAndFont } = useU4heroColors();
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const [current, setCurrent] = useState(0);
  const [form] = Form.useForm();
  const [minAge, setMinAge] = useState<number>();
  const [maxAge, setMaxAge] = useState<number>();
  const [countStudents, setCountStudents] = useState<number>(0);
  const [maxQuestions, setMaxQuestions] = useState<number | undefined>(0);
  const params = useParams<{
    id: string;
  }>();
  const { data } = useActivity(params.id);

  let location = useLocation();

  const actions = ['edit', 'create'];

  let typeName = '';

  for (const action of actions) {
    if (location.pathname.includes(`/${action}/`)) {
      const parts = location.pathname.split(`/${action}/`);

      if (parts.length > 1) {
        typeName = parts[1].split('/')[0];
        break;
      }
    }
  }

  const types: {
    [key: string]: {
      label: string;
      permissions: {
        edit: string;
        delete: string;
        create: string;
      };
      type: number;
      link: string;
    };
  } = {
    individual: {
      label: 'Atividade individual',
      permissions: {
        edit: Permissions.Activity.Edit,
        delete: Permissions.Activity.Delete,
        create: Permissions.Activity.Create,
      },
      type: 3,
      link: '/activities/individual',
    },
    class: {
      label: 'Atividade da turma',
      permissions: {
        edit: Permissions.Activity.Edit,
        delete: Permissions.Activity.Delete,
        create: Permissions.Activity.Create,
      },
      type: 0,
      link: '/activities/class',
    },
    'self-avaliation': {
      label: 'Avaliação inicial',
      permissions: {
        edit: Permissions.SelfAvaliation.Edit,
        delete: Permissions.SelfAvaliation.Delete,
        create: Permissions.SelfAvaliation.Create,
      },
      type: 1,
      link: '/activities/self-avaliation',
    },
    avaliation: {
      label: 'Avaliação da turma',
      permissions: {
        edit: Permissions.Avaliation.Edit,
        delete: Permissions.Avaliation.Delete,
        create: Permissions.Avaliation.Create,
      },
      type: 2,
      link: '/activities/avaliation',
    },
    'individual-avaliation': {
      label: 'Avaliação individual',
      permissions: {
        edit: Permissions.Avaliation.Edit,
        delete: Permissions.Avaliation.Delete,
        create: Permissions.Avaliation.Create,
      },
      type: 4,
      link: '/activities/individual-avaliation',
    },
  };

  const type = types[typeName].type;

  const [check] = useCheckPermissions();
  if (!check([types[typeName].permissions.create])) {
    history.push('/errors/no-permission');
  }
  const { mutate: handleSave, isLoading: isLoadingSave } = useSaveActivity();
  const classes = Form.useWatch('classes', form);
  const students = Form.useWatch('students', form);
  const gameId = Form.useWatch('gameId', form);
  const tags = Form.useWatch('tags', form);
  const endDate = Form.useWatch('endDate', form);
  const publishDate = Form.useWatch('publishDate', form);
  const questions = Form.useWatch('questions', form);

  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    if (current === 0) {
      onClose();
    } else {
      setCurrent(current - 1);
    }
  };

  const steps = [
    {
      title: 'Turma',
      content: (
        <Form.Item name="classes">
          {/* @ts-ignore */}
          <StepClass
            setMinAge={setMinAge}
            setMaxAge={setMaxAge}
            setCountStudents={setCountStudents}
          />
        </Form.Item>
      ),
      icon: <PiUsersFourThin size={32} />,
    },
    {
      title: 'Jogo',
      content: (
        <Form.Item name="gameId">
          {/* @ts-ignore */}
          <StepGame setMaxQuestions={setMaxQuestions} />
        </Form.Item>
      ),
      icon: <PiGameControllerThin size={32} />,
    },
    {
      title: 'Habilidades',
      content: <StepTag />,
      icon: <PiBrainThin size={32} />,
    },
    {
      title: 'Perguntas',
      content: (
        <Form.Item name="questions">
          {/* @ts-ignore */}
          <StepQuestion
            minAge={minAge}
            maxAge={maxAge}
            typeId={type.toString()}
            maxQuestions={maxQuestions}
          />
        </Form.Item>
      ),
      icon: <PiSealQuestionThin size={32} />,
    },
    {
      title: 'Prazo',
      content: (
        <Flex id="id-tour-term-step" vertical gap="8px">
          <p
            style={{
              fontSize: isMobile ? '14px' : '18px',
              fontWeight: 400,
              color: 'black',
            }}
          >
            Defina o prazo da atividade:
          </p>

          <Flex gap="48px">
            <Form.Item label="Data de Inicio" name="publishDate">
              <Input
                id="id-tour-publish-date-term-step"
                type="date"
                min={params.id ? publishDate : moment().format('YYYY-MM-DD')}
                size={isMobile ? 'middle' : 'large'}
              />
            </Form.Item>
            <Form.Item label="Data de Término" name="endDate">
              <Input
                id="id-tour-end-date-term-step"
                type="date"
                min={publishDate}
                size={isMobile ? 'middle' : 'large'}
              />
            </Form.Item>
          </Flex>
        </Flex>
      ),
      icon: <PiCalendarThin size={32} />,
    },
  ];

  if (type !== 0 && type !== 3) {
    steps.splice(1, 2);
  }
  if (type === 3 || type === 4) {
    steps[0] = {
      title: 'Estudantes',
      content: (
        <Form.Item name="students">
          {/* @ts-ignore */}
          <StepStudents
            setMinAge={setMinAge}
            setMaxAge={setMaxAge}
            setCountStudents={setCountStudents}
          />
        </Form.Item>
      ),
      icon: <PiUsersFourThin size={32} />,
    };
  }

  const close = () => {
    history.push(types[typeName].link);
  };
  const onClose = () => {
    if (form.isFieldsTouched()) {
      openNotification({ onClose: close, colorPrimary, isMobile });
    } else {
      close();
    }
  };
  const onSubmit = (values: ActivityForm) => {
    handleSave(
      { ...values, typeId: type, countStudents: countStudents },
      {
        onError: () => {
          notificateError(
            `Houve um problema ao ${params.id ? 'editar' : 'criar'} a ${
              type === 0 || type === 3 ? 'Atividade' : 'Avaliação'
            }. Verifique sua conexão e tente novamente.`
          );
        },
        onSuccess: () => {
          notificateSucess(
            `${
              params.id
                ? `${
                    type === 0 || type === 3 ? 'Atividade' : 'Avaliação'
                  } editada com sucesso!`
                : `${
                    type === 0 || type === 3 ? 'Atividade' : 'Avaliação'
                  } criada com sucesso!`
            }`
          );
          setTimeout(() => {
            close();
          }, 200);
        },
      }
    );
  };

  const disabled =
    type === 4
      ? (!students?.length && current === 0) ||
        (!questions?.length && current === 1) ||
        ((!endDate || !publishDate) && current === 2)
      : type === 0 || type === 3
      ? (!classes?.length && !students?.length && current === 0) ||
        (!gameId && current === 1) ||
        (!tags?.length && current === 2) ||
        (!questions?.length && current === 3) ||
        ((!endDate || !publishDate) && current === 4)
      : (!classes?.length && current === 0) ||
        (!questions?.length && current === 1) ||
        ((!endDate || !publishDate) && current === 2);

  const isStepEnabled = (current: number, stepIndex: number) => {
    if (stepIndex === current && stepIndex === 1) {
      return type === 0 || type === 3
        ? !!classes?.length || !!students?.length
        : !!classes?.length;
    }
    if (stepIndex === current && stepIndex === 2) {
      return !(type === 0 || type === 3 ? !gameId : !questions?.length);
    }
    if (stepIndex === current && stepIndex === 3) {
      return !(type === 0 || type === 3
        ? !tags?.length
        : !endDate || !publishDate);
    }

    if (stepIndex === current && stepIndex === 4) {
      return !!questions?.length;
    }

    return false;
  };
  const items = steps.map((item, index) => ({
    key: item.title,
    title: isMobile ? undefined : (
      <span
        id={`id-tour-activity-create-step-${item.title.toLowerCase()}`}
        style={{
          fontSize: '16px',
          fontWeight: 700,
          color: current >= index ? colorPrimary : colorGreyBorderAndFont,
        }}
      >
        {item.title}
      </span>
    ),
    icon:
      current > index ? (
        <FaCheckCircle
          size={32}
          color={colorPrimary}
          onClick={() => {
            setCurrent(index);
          }}
          cursor="pointer"
        />
      ) : (
        React.cloneElement(item.icon, {
          color: current === index ? colorPrimary : colorGreyBorderAndFont,
          onClick: () => {
            if (params.id) {
              setCurrent(index);
            } else {
              if (isStepEnabled(current + 1, index)) {
                setCurrent(index);
              }
            }
          },
          cursor: params.id
            ? 'pointer'
            : isStepEnabled(current + 1, index)
            ? 'pointer'
            : 'not-allowed',
        })
      ),
  }));

  useEffect(() => {
    if (data) {
      form.resetFields();
      setCountStudents(data.countStudents);
    }
  }, [data, form]);

  return (
    <Layout.Container>
      <Form
        form={form}
        onFinish={onSubmit}
        layout="vertical"
        initialValues={
          data || {
            tags: [],
            classes: [],
            students: [],
            questions: [],
            endDate: null,
            publishDate: moment().format('YYYY-MM-DD'),
            name: '',
            gameId: null,
            tagType: undefined,
            methodology: undefined,
          }
        }
        requiredMark={CustomizedRequiredMark}
      >
        <Flex
          gap={isMobile ? '12px' : '24px'}
          align="center"
          style={{ marginBottom: '12px' }}
        >
          <CloseButton onClick={onClose} />
          <Title primary>
            {params.id ? 'Editar' : 'Criar'}{' '}
            {type === 3 || type === 0 ? 'atividade' : 'avaliação'}
          </Title>
        </Flex>
        <Steps
          responsive={false}
          current={current}
          items={items}
          style={{ marginBottom: '16px' }}
        />
        <Flex
          justify="end"
          gap={'16px'}
          style={{ marginBottom: isMobile ? '8px' : '' }}
        >
          <Button
            variant="outline"
            size="md"
            id="id-tour-create-activity-button-cancel"
            onClick={() => prev()}
          >
            {current === 0 ? 'CANCELAR' : 'ANTERIOR'}
          </Button>

          {current < steps.length - 1 ? (
            <Button
              size="md"
              id="id-tour-create-activity-button-next"
              disabled={disabled}
              loading={isLoadingSave}
              onClick={next}
            >
              PRÓXIMO
            </Button>
          ) : null}
          {current === steps.length - 1 ? (
            <Button
              htmlType="submit"
              size="md"
              id="id-tour-create-activity-button-next"
              disabled={disabled}
              loading={isLoadingSave}
            >
              FINALIZAR
            </Button>
          ) : null}
        </Flex>
        <Form.Item name="id" hidden />
        {steps.map((item, index) => (
          <div
            style={{
              marginBottom: '16px',
              display: current === index ? '' : 'none',
            }}
          >
            {item.content}
          </div>
        ))}
      </Form>
    </Layout.Container>
  );
}

export default CreateActivity;
