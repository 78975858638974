import { Flex } from 'antd';
import { forwardRef } from 'react';
import element3 from 'assets/images/elemento_03.png';
import element4 from 'assets/images/elemento_04.png';
import element5 from 'assets/images/elemento_05.png';
import lumiWithLogo from 'assets/images/lumi-with-logo.png';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import Text from 'components/typography/text';
import './style.css';
import { t } from 'core/resources/strings';
import { ClassWithStudents, StudentBasic } from '../DownloadStudentKeys';
import React from 'react';

interface StudentKeysProps {
  mode: 'colorful' | 'color-grey';
  classes: ClassWithStudents[];
}

const StudentKeys = forwardRef<HTMLDivElement, StudentKeysProps>(
  ({ mode, classes }, ref) => {
    const { colorPrimary } = useU4heroColors();
    const color = mode === 'colorful' ? '#452392' : 'black';

    const chunkArray = (array: StudentBasic[], size: number) => {
      const chunkedArr = [];
      for (let i = 0; i < array.length; i += size) {
        chunkedArr.push(array.slice(i, i + size));
      }
      return chunkedArr;
    };

    const classItems = classes.map((classe) => ({
      classDetails: classe.classDetails,
      chunks: chunkArray(classe.students, 12),
    }));
    // const studentChunks = chunkArray(students || [], 12);

    return (
      <div ref={ref} style={{ height: '100%' }}>
        {classItems.map((classe, index) => (
          <React.Fragment key={index}>
            <Flex
              key={index}
              vertical
              style={{ position: 'relative', width: '100%', height: '100%' }}
              className="student-keys-container"
            >
              {classe.chunks.map((students, chunkIndex) => (
                <div
                  key={chunkIndex}
                  className="student-key-page"
                  style={{ position: 'relative', minHeight: '100%' }}
                >
                  <Flex
                    justify="space-between"
                    align="self-start"
                    style={{ position: 'absolute', top: 0, width: '100%' }}
                  >
                    <img
                      src={element3}
                      style={{
                        filter:
                          mode === 'color-grey' ? 'grayscale(100%)' : undefined,
                      }}
                    />
                    <img
                      src={lumiWithLogo}
                      style={{
                        filter:
                          mode === 'color-grey' ? 'grayscale(100%)' : undefined,
                      }}
                      width="fit-content"
                      height="fit-content"
                    />
                  </Flex>
                  <Flex
                    vertical
                    gap={8}
                    align="center"
                    style={{ alignSelf: 'center', marginTop: '80px' }}
                  >
                    <Flex
                      style={{
                        background:
                          mode === 'colorful' ? colorPrimary : '#EFEFEF',
                        color: mode === 'colorful' ? 'white' : 'black',
                        fontSize: '34px',
                        padding: '8px',
                        borderRadius: '15px',
                        width: 'fit-content',
                      }}
                      justify="center"
                      align="center"
                    >
                      CHAVES DE ACESSO
                    </Flex>
                    <Text style={{ fontSize: '22px' }}>
                      <strong
                        style={{
                          color: mode === 'colorful' ? colorPrimary : 'black',
                        }}
                      >
                        Escola:{' '}
                      </strong>
                      {students[0].tenantName}
                    </Text>
                    <Flex
                      style={{
                        borderRadius: '15px',
                        borderTop: `2px solid ${color}`,
                        borderRight: `3px solid ${color}`,
                        borderBottom: `4px solid ${color}`,
                        borderLeft: `2px solid ${color}`,
                        background: '#FFF',
                        width: '524px',
                        alignSelf: 'center',
                      }}
                      vertical
                    >
                      <Text
                        size="lg"
                        align="center"
                        style={{
                          padding: '8px',
                          width: '100%',
                          color: color,
                          fontWeight: 700,
                          background:
                            mode === 'colorful' ? '#EFE8F6' : '#F1F1F1',
                          borderRadius: '15px 15px 0px 0px',
                          borderBottom: `0.5px solid ${color}`,
                        }}
                      >
                        {classe.classDetails.serie} - {classe.classDetails.name}{' '}
                        - {t(`app.${classe.classDetails.period}`)} -{' '}
                        {classe.classDetails.year}
                      </Text>
                      <Flex>
                        <Text
                          size="lg"
                          align="center"
                          style={{
                            padding: '8px',
                            borderBottom: `0.5px solid ${color}`,
                            borderRight: `0.5px solid ${color}`,
                            width: '60%',
                            color: color,
                            fontWeight: 700,
                            background:
                              mode === 'colorful' ? '#EFE8F6' : '#F1F1F1',
                          }}
                        >
                          Nome do Estudante
                        </Text>
                        <Text
                          size="lg"
                          align="center"
                          style={{
                            padding: '8px',
                            borderBottom: `0.5px solid ${color}`,
                            width: '40%',
                            color: color,
                            fontWeight: 700,
                            background:
                              mode === 'colorful' ? '#EFE8F6' : '#F1F1F1',
                          }}
                        >
                          Chave
                        </Text>
                      </Flex>
                      {students.map((item, index) => (
                        <Flex key={item.id}>
                          <Text
                            size="md"
                            align="center"
                            style={{
                              padding: '8px',
                              borderBottom:
                                students.length - 1 === index
                                  ? undefined
                                  : `0.5px solid ${color}`,
                              borderRight: `0.5px solid ${color}`,
                              width: '60%',
                              color: 'black',
                            }}
                          >
                            {item.name}
                          </Text>
                          <Text
                            size="md"
                            align="center"
                            style={{
                              padding: '8px',
                              borderBottom:
                                students.length - 1 === index
                                  ? undefined
                                  : `0.5px solid ${color}`,
                              width: '40%',
                              color: 'black',
                            }}
                          >
                            {item.license}
                          </Text>
                        </Flex>
                      ))}
                    </Flex>
                  </Flex>
                  <Flex
                    justify="space-between"
                    style={{ position: 'absolute', bottom: 0, width: '100%' }}
                  >
                    <img
                      src={element5}
                      style={{
                        filter:
                          mode === 'color-grey' ? 'grayscale(100%)' : undefined,
                      }}
                    />
                    <img
                      src={element4}
                      style={{
                        filter:
                          mode === 'color-grey' ? 'grayscale(100%)' : undefined,
                      }}
                    />
                  </Flex>
                  {chunkIndex < classe.chunks.length - 1 && (
                    <div className="page-break" />
                  )}
                </div>
              ))}
            </Flex>
            <div className="page-break" />
          </React.Fragment>
        ))}
      </div>
    );
  }
);

export default StudentKeys;
