import { useEffect, useState } from 'react';
import useTagsCompetence from 'core/features/tags/hooks/useTagsCompetence';
import {
  Button,
  Flex,
  Menu,
  Dropdown,
  Collapse,
  Checkbox,
  CollapseProps,
} from 'antd';
import { FiSettings } from 'react-icons/fi';
import { MdExpandMore } from 'react-icons/md';
import Text from 'components/typography/text';
import { TagType } from 'core/features/competence/typings';

function CompetencesFilter({
  value,
  onChange,
  tagType,
}: {
  value: string[];
  onChange: React.Dispatch<React.SetStateAction<string[]>>;
  tagType: TagType;
}) {
  const { data: dataCompetences } = useTagsCompetence({
    competence: true,
    type: tagType,
  });
  const { data: dataSkills } = useTagsCompetence({
    skill: true,
    parentType: tagType,
  });
  const skillIds = dataSkills?.map((item) => item.id) || [];
  const competenceIds = dataCompetences?.map((item) => item.id) || [];
  const [isAllSkillsSelected, setIsAllSkillsSelected] = useState(false);
  const [isAllCompetencesSelected, setIsAllCompetencesSelected] =
    useState(false);

  useEffect(() => {
    if (dataCompetences && dataCompetences.length > 0) {
      const competenceIds = dataCompetences.map((item) => item.id);

      onChange(competenceIds);
    }
  }, [dataCompetences]);

  useEffect(() => {
    const allSkillsSelected = skillIds.every((id) => value.includes(id));
    const allCompetencesSelected = competenceIds.every((id) =>
      value.includes(id)
    );
    setIsAllSkillsSelected(allSkillsSelected);
    setIsAllCompetencesSelected(allCompetencesSelected);
  }, [value]);
  const handleMenuClick = (e: any) => {
    const key = e.key;
    toggleTag(key);
  };

  const toggleTag = (tagId: string) => {
    if (value.includes(tagId)) {
      onChange((prevTags) => prevTags.filter((id) => id !== tagId));
    } else {
      onChange((prevTags) => [...prevTags, tagId]);
    }
  };

  const toggleAllSkills = () => {
    if (isAllSkillsSelected) {
      onChange((prevTags) => prevTags.filter((tag) => !skillIds.includes(tag)));
    } else {
      onChange((prevTags) => [
        ...prevTags,
        ...skillIds.filter((id) => !prevTags.includes(id)),
      ]);
    }
  };

  const toggleAllCompetences = () => {
    if (isAllCompetencesSelected) {
      onChange((prevTags) =>
        prevTags.filter((tag) => !competenceIds.includes(tag))
      );
    } else {
      onChange((prevTags) => [
        ...prevTags,
        ...competenceIds.filter((id) => !prevTags.includes(id)),
      ]);
    }
  };

  const handleSelectAll = () => {
    const allTags = dataCompetences
      ?.map((competence) => competence.id)
      .concat(dataSkills ? dataSkills?.map((skill) => skill.id) : []);
    if (allTags) {
      onChange(allTags.length === value.length ? [] : allTags);
    }
  };
  const items: CollapseProps['items'] = [
    {
      key: '1',
      label: (
        <Text size="md" bold>
          {tagType === TagType.Competências ? 'Competências' : 'Área de foco'}
        </Text>
      ),
      children: (
        <Menu onClick={handleMenuClick}>
          <Flex justify="end">
            <Button
              onClick={() => toggleAllCompetences()}
              style={{ fontSize: '16px', fontWeight: 700, color: 'black' }}
              type="link"
            >
              {isAllCompetencesSelected ? 'Remover' : 'Selecionar'} todos
            </Button>
          </Flex>
          {dataCompetences?.map((competence) => (
            <Menu.Item key={competence.id}>
              <Checkbox checked={value.includes(competence.id)}>
                <label>{competence.name['pt-br']}</label>
              </Checkbox>
            </Menu.Item>
          ))}
        </Menu>
      ),
    },
    {
      key: '2',
      label: (
        <Text size="md" bold>
          Habilidades
        </Text>
      ),
      children: (
        <>
          <Flex justify="end">
            <Button
              onClick={() => toggleAllSkills()}
              style={{ fontSize: '16px', fontWeight: 700, color: 'black' }}
              type="link"
            >
              {isAllSkillsSelected ? 'Remover' : 'Selecionar'} todos
            </Button>
          </Flex>
          <Collapse
            style={{ paddingLeft: '8px' }}
            ghost={true}
            expandIconPosition="end"
          >
            {dataCompetences?.map((competence) => (
              <Collapse.Panel
                header={<Text size="md">{competence.name['pt-br']}</Text>}
                key={competence.id}
              >
                <Menu onClick={handleMenuClick}>
                  {dataSkills
                    ?.filter((skill) => skill.parentId === competence.id)
                    .map((skill) => (
                      <Menu.Item key={skill.id}>
                        <Checkbox checked={value.includes(skill.id)}>
                          <label>
                            {skill.name['pt-br'].length > 26
                              ? skill.name['pt-br'].substr(0, 23) + '...'
                              : skill.name['pt-br']}{' '}
                          </label>
                        </Checkbox>
                      </Menu.Item>
                    ))}
                </Menu>
              </Collapse.Panel>
            ))}
          </Collapse>
        </>
      ),
    },
  ];

  return (
    <Dropdown
      overlay={
        <Menu style={{ maxHeight: '515px', width: '284px', overflowY: 'auto' }}>
          <Flex justify="end">
            <Button
              onClick={() => handleSelectAll()}
              style={{ fontSize: '16px', fontWeight: 700 }}
              type="link"
            >
              Selecionar todos
            </Button>
          </Flex>

          <Collapse ghost={true} items={items} expandIconPosition="end" />
        </Menu>
      }
      trigger={['click']}
    >
      <Flex
        gap="8px"
        align="center"
        style={{
          background: '#F5F5F5',
          padding: '8px',
          borderRadius: '10px',
          cursor: 'pointer',
        }}
      >
        <FiSettings size={26} />
        <MdExpandMore
          style={{
            transform: 'rotate(270deg)',
          }}
          color="black"
          size={16}
        />
      </Flex>
    </Dropdown>
  );
}

export default CompetencesFilter;
