import { useMediaQuery } from '@chakra-ui/react';
import { Col, Divider, Flex, Row, Typography } from 'antd';
import SelectBase from 'components/inputs/select-base';
import { useClassification } from 'core/features/safeSpace/hooks/useClassification';
import useSafeSpace from 'core/features/safeSpace/hooks/useSafeSpace';
import { useStatus } from 'core/features/safeSpace/hooks/useStatus';
import { t } from 'core/resources/strings';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Permissions } from 'core/resources/enums/permissions';
import useCheckPermission from 'core/features/user/hooks/useCheckPermission';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import { GenericCard } from 'components/V2/generic-card-styled-component';
import profilePlaceholder from 'assets/images/ellipse.png';
import background from 'assets/images/background-safe-space.png';
import Status from 'components/status';
import Classification from 'ui/pages/app/safeSpace/components/Classification';
import {
  notificateError,
  notificateSucess,
  openNotification,
} from 'infra/helpers/notifications';
import { useHistory, useParams } from 'react-router-dom';
import Layout from 'components/layout';
import SafeSpaceChat from '../SafeSpaceChat';
import SafespaceComment from '../SafeSpaceComment';
import SafeSpaceAvaliation from '../SafeSpaceAvaliation';
import BackButton from 'components/buttons/back-button';
import Button from 'components/buttons/button';
import acessDenied from 'assets/images/lumi-acess-denied.png';
import Title from 'components/typography/title';
import Text from 'components/typography/text';

function SafeSpaceAnalysis() {
  const params = useParams<{ id: string }>();
  const { data, error, refetch } = useSafeSpace(params.id);
  const history = useHistory();
  const { mutate: handleClassification } = useClassification();
  const { mutate: handleStatus } = useStatus();
  const [flag, setFlag] = useState<number>();
  const [status, setStatus] = useState<number>();
  const { colorPrimary, colorGreyBorderAndFont } = useU4heroColors();
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const [check] = useCheckPermission();

  const checkProgress = () => {
    if (
      (status !== data?.status && status !== undefined) ||
      (flag !== data?.teacherClassification &&
        flag !== undefined &&
        flag !== null)
    ) {
      return true;
    }
    return false;
  };

  const handleSaveInfo = () => {
    if (
      status === data?.status &&
      (flag === data?.teacherClassification ||
        flag === null ||
        flag === undefined)
    ) {
      notificateError('Faça alguma alteração antes de salvar!');
    } else {
      if (status !== data?.status) {
        handleStatus(
          { safeSpaceId: data?.id, status: status },
          {
            onError: () => {
              notificateError(
                'Ops, aconteceu algum problema ao tentar salvar suas alterações. Verifique sua conexão e tente novamente!'
              );
              return;
            },
            onSuccess: () => {
              refetch();
            },
          }
        );
      }
      if (flag !== undefined && data?.teacherClassification !== flag) {
        handleClassification(
          { safeSpaceId: data?.id, flag: flag },
          {
            onError: () => {
              notificateError(
                'Ops, aconteceu algum problema ao tentar salvar suas alterações. Verifique sua conexão e tente novamente!'
              );
              return;
            },
            onSuccess: () => {
              refetch();
            },
          }
        );
      }
      notificateSucess('Informações salvas com sucesso!');
    }
  };
  useEffect(() => {
    if (data) {
      if (data?.teacherClassification !== 0) {
        setFlag(data?.teacherClassification);
      }
      if (data?.status !== null) {
        setStatus(data.status);
      }
    }
  }, [data]);

  if (error) {
    return (
      <Layout.Container>
        <Flex vertical align="center" justify="center" style={{height:'100%',}} gap={16}>
          <img src={acessDenied} alt="acesso proibido" />
          <Title>Acesso Negado!</Title>
          <Text style={{ maxWidth: 873 }} align="justify" size="lg">
            Você recebeu uma nova mensagem no “Meu Cantinho”. No entanto, você
            não está mais vinculado a essa turma e não pode acessar a mensagem.
          </Text>
          <Button size='md' onClick={()=> history.push('/')}>VOLTAR PARA PAGINA INICIAL</Button>
        </Flex>
      </Layout.Container>
    );
  }
  return (
    <Layout.Container>
      <Flex
        gap={isMobile ? '12px' : '24px'}
        align="center"
        style={{ marginBottom: isMobile ? '14px' : '24px' }}
      >
        <BackButton
          onClick={() => {
            if (checkProgress()) {
              openNotification({
                onClose: () => history.goBack(),
                colorPrimary,
                isMobile,
              });
            } else {
              history.goBack();
            }
          }}
        />
        <Title primary>Cantinho do Estudante</Title>
      </Flex>

      <Flex vertical gap={'24px'} style={{ marginBottom: '16px' }}>
        <Flex
          gap="16px"
          align="center"
          style={{
            width: '100%',
            background: 'rgba(244, 244, 244, 0.80)',
            backgroundImage: `url(${background})`,
            backgroundSize: 'contain',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            padding: '8px 16px',
            borderRadius: isMobile ? '20px' : '10px',
          }}
        >
          <img
            src={profilePlaceholder}
            alt="Perfil do estudante"
            style={{
              width: isMobile ? '56px' : '80px',
              height: isMobile ? '56px' : '80px',
              borderRadius: '50%',
              background: 'gray',
            }}
          />
          <Flex
            vertical={isMobile}
            justify="space-around"
            style={{
              width: '100%',
              fontSize: isMobile ? '14px' : '18px',
              fontWeight: 400,
              color: 'black',
            }}
          >
            <p>
              <strong style={{ color: colorPrimary }}>Nome: </strong>
              {!isMobile ? <br /> : null}
              {data?.anonymous === false ? data?.name : 'Anônimo'}
            </p>
            {!isMobile ? (
              <Divider
                type="vertical"
                style={{ color: 'black', height: '56px' }}
              />
            ) : null}
            <p>
              <strong style={{ color: colorPrimary }}>Turma/Série: </strong>
              {!isMobile ? <br /> : null}
              {data?.class.name} - {data?.class.serie.name}
            </p>
            {!isMobile ? (
              <Divider
                type="vertical"
                style={{ color: 'black', height: '56px' }}
              />
            ) : null}
            <p>
              <strong style={{ color: colorPrimary }}>Turno: </strong>
              {!isMobile ? <br /> : null}
              {data ? t(`app.${data?.class?.period}`) : null}
            </p>
          </Flex>
        </Flex>

        <Row align="stretch" gutter={isMobile ? [0, 24] : [24, 0]}>
          <Col span={isMobile ? 24 : 8}>
            <GenericCard
              width="100%"
              height="100%"
              minHeight={isMobile ? '386px' : '492px'}
            >
              <Flex vertical justify="space-between" style={{ width: '100%' }}>
                <p
                  style={{
                    fontSize: isMobile ? '18px' : '24px',
                    color: colorPrimary,
                    fontWeight: 700,
                  }}
                >
                  Registro Meu Cantinho
                </p>
                <GenericCard padding="8px" width="100%">
                  <Flex vertical gap="4px" style={{ width: '100%' }}>
                    <p
                      style={{
                        fontSize: isMobile ? '14px' : '16px',
                        fontWeight: 400,
                        color: 'black',
                      }}
                    >
                      {data?.message}
                    </p>
                    <p
                      style={{
                        textAlign: 'end',
                        color: colorGreyBorderAndFont,
                        fontWeight: 500,
                        fontSize: isMobile ? '12px' : '14px',
                      }}
                    >
                      {moment.utc(data?.date).format('DD/MM/YYYY')}
                    </p>
                  </Flex>
                </GenericCard>
                <div>
                  <p
                    style={{
                      fontSize: isMobile ? '14px' : '18px',
                      fontWeight: 400,
                      color: 'black',
                    }}
                  >
                    Status do Acolhimento
                  </p>
                  <SelectBase
                    id="id-tour-safe-space-input-status"
                    disabled={!check(Permissions.SafeSpace.Edit)}
                    tooltip="Você não tem permissão para editar este acolhimento!"
                    options={[
                      {
                        label: (
                          <Status
                            size={isMobile ? 'sm' : 'md'}
                            type={'notStarted'}
                          />
                        ),
                        value: 0,
                      },
                      {
                        label: (
                          <Status
                            size={isMobile ? 'sm' : 'md'}
                            type={'progress'}
                          />
                        ),
                        value: 1,
                      },
                      {
                        label: (
                          <Status size={isMobile ? 'sm' : 'md'} type={'done'} />
                        ),
                        value: 2,
                      },
                    ]}
                    value={status}
                    onChange={(e) => setStatus(e)}
                  />
                </div>
                <div>
                  <p
                    style={{
                      fontSize: isMobile ? '14px' : '18px',
                      fontWeight: 400,
                      color: 'black',
                    }}
                  >
                    Classificação do Professor
                  </p>
                  <SelectBase
                    id="id-tour-safe-space-input-teacher-classification"
                    disabled={!check(Permissions.SafeSpace.Edit)}
                    tooltip="Você não tem permissão para editar este acolhimento!"
                    options={[
                      {
                        label: (
                          <Classification
                            size={isMobile ? 'sm' : 'md'}
                            type={1}
                          />
                        ),
                        value: 1,
                      },
                      {
                        label: (
                          <Classification
                            size={isMobile ? 'sm' : 'md'}
                            type={2}
                          />
                        ),
                        value: 2,
                      },
                      {
                        label: (
                          <Classification
                            size={isMobile ? 'sm' : 'md'}
                            type={3}
                          />
                        ),
                        value: 3,
                      },
                      {
                        label: (
                          <Classification
                            size={isMobile ? 'sm' : 'md'}
                            type={4}
                          />
                        ),
                        value: 4,
                      },
                    ]}
                    value={flag}
                    onChange={(e) => setFlag(e)}
                  />
                </div>

                <div>
                  <p
                    style={{
                      fontSize: isMobile ? '14px' : '18px',
                      fontWeight: 400,
                      color: 'black',
                    }}
                  >
                    Classificação Automática
                  </p>
                  <div
                    style={{
                      borderRadius: '8px',
                      paddingLeft: isMobile ? '8px' : '18px',
                      border: '0.5px solid #262626',
                    }}
                  >
                    <Classification
                      size={isMobile ? 'sm' : 'md'}
                      type={data?.systemClassification ?? 0}
                    />
                  </div>
                </div>

                <Flex justify="center">
                  <Button
                    size="md"
                    id="id-tour-safe-space-button-save"
                    onClick={handleSaveInfo}
                    disabled={!checkProgress()}
                  >
                    SALVAR
                  </Button>
                </Flex>
              </Flex>
            </GenericCard>
          </Col>
          <Col span={isMobile ? 24 : 16}>
            <SafeSpaceAvaliation />
          </Col>
        </Row>

        <Row align="stretch" gutter={isMobile ? [0, 24] : [24, 0]}>
          <Col span={isMobile ? 24 : 12}>
            <SafespaceComment comments={data?.comments ?? []} />
          </Col>
          <Col span={isMobile ? 24 : 12}>
            <SafeSpaceChat />
          </Col>
        </Row>
      </Flex>
    </Layout.Container>
  );
}

export default SafeSpaceAnalysis;
