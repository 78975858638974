import { useMediaQuery } from '@chakra-ui/media-query';
import { Flex, Form, Input } from 'antd';
import Button from 'components/buttons/button';
import PasswordRequirements from 'components/password-requirements';
import CustomizedRequiredMark from 'components/required-mark';
import { useLogout } from 'core/features/auth/hooks/useLogout';
import { useUpdatePassword } from 'core/features/user/hooks/useUpdatePassword';
import { notificateError, notificateSucess } from 'infra/helpers/notifications';
import { useEffect, useState } from 'react';

function ChangePassword() {
  const [form] = Form.useForm();
  const { mutate: handleSave } = useUpdatePassword();
  const { handleLogout } = useLogout();
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const password = Form.useWatch('newPassword', form);

  const [submittable, setSubmittable] = useState<boolean>(false);

  const values = Form.useWatch([], form);

  useEffect(() => {
    form
      .validateFields({ validateOnly: true })
      .then(() => setSubmittable(true))
      .catch(() => setSubmittable(false));
  }, [form, values]);

  const _handleSave = (values: { password: string; newPassword: string }) => {
    handleSave(
      { password: values.password, newPassword: values.newPassword },
      {
        onSuccess: () => {
          notificateSucess(
            'Sua senha foi alterada com sucesso. Por favor, faça o login novamente para continuar acessando sua conta com a nova senha.'
          );
          handleLogout();
        },
        onError: (err: any) => {
          if (
            err?.response?.data?.error ===
            'A senha atual inserida não corresponde à senha correta.'
          ) {
            form.setFields([
              {
                name: 'password',
                errors: [
                  'A senha atual inserida não corresponde à senha correta.',
                ],
              },
            ]);
          } else {
            notificateError(
              'Houve um problema ao alterar a senha. Por favor, verifique sua conexão e tente novamente.'
            );
          }
        },
      }
    );
  };

  return (
    <Form
      onSubmitCapture={(e) => {
        e.preventDefault();
        form.validateFields().then(() => _handleSave(values));
      }}
      layout="vertical"
      requiredMark={CustomizedRequiredMark}
      form={form}
    >
      <Form.Item
        label="Senha atual"
        rules={[
          {
            required: true,
            message: 'Por favor, digite sua senha atual!',
          },
        ]}
        name="password"
        initialValue=""
      >
        <Input.Password id="id-tour-my-profile-current-password" size="large" />
      </Form.Item>
      <Form.Item
        label="Nova senha"
        rules={[
          {
            required: true,
            message: 'Por favor, digite sua nova senha!',
          },
          {
            min: 12,
            message: 'Senha deve ter pelo menos 12 caracteres',
          },
          {
            pattern:
              /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*()])[\w!@#$%^&*()]+$/,
            message:
              'Senha deve conter pelo menos 1 letra maiúscula, 1 letra minúscula, 1 número e 1 caractere especial',
          },
        ]}
        name="newPassword"
        initialValue=""
      >
        <Input.Password id="id-tour-my-profile-new-password" size="large" />
      </Form.Item>
      <PasswordRequirements password={password} />
      <Form.Item
        label="Confirmar senha"
        rules={[
          {
            required: true,
            message: 'Por favor, confirme sua senha!',
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('newPassword') === value) {
                return Promise.resolve();
              }
              return Promise.reject(
                new Error('Ops, as senhas não são iguais!')
              );
            },
          }),
        ]}
        name="confirmPassword"
        validateDebounce={1000}
      >
        <Input.Password id="id-tour-my-profile-confirm-password" size="large" />
      </Form.Item>
      <Flex justify="center">
        <Button
          size='md'
          id="id-tour-my-profile-button-save"
          disabled={!submittable}
          htmlType={'submit'}
        >
          CONFIRMAR ALTERAÇÃO
        </Button>
      </Flex>
    </Form>
  );
}

export default ChangePassword;
