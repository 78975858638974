import ClassRoomIcon from 'assets/images/icons/ClassRoomIcon';
import useTotalClasses from 'core/features/indicators/hooks/useTotalClasses';
import StatCard from '../statCard';

function TotalClasses({onRemove}:{onRemove?:()=> void}) {
  const { data } = useTotalClasses();
  return (
    <StatCard title="Turma" total={data?.total ?? 0} Icon={ClassRoomIcon} onRemove={onRemove} />
  );
}

export default TotalClasses;
