import { Dropdown, Flex, Form, Input, Menu, Select } from 'antd';
import { useMediaQuery } from '@chakra-ui/react';
import lineGraph from 'assets/images/line-graph.png';
import barGraph from 'assets/images/bar-graph.png';
import columnGraph from 'assets/images/column-graph.png';
import areaGraph from 'assets/images/area-graph.png';
import radarGraph from 'assets/images/radar-graph.png';
import pizzaGraph from 'assets/images/pizza-graph.png';
import donutGraph from 'assets/images/donut-graph.png';
import stackedGraph from 'assets/images/stacked-graph.png';
import Text from 'components/typography/text';
import { DownOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';

const graphsTypes = [
  {
    value: 'line-graph',
    image: lineGraph,
    label: 'Gráfico de linha',
    time: true,
  },
  {
    value: 'area-graph',
    image: areaGraph,
    label: 'Gráfico de área',
    time: true,
  },
  {
    value: 'bar-graph',
    image: barGraph,
    label: 'Gráfico de barra',
    comparative: true,
  },

  {
    value: 'radar-graph',
    image: radarGraph,
    label: 'Gráfico de radar',
    comparative: true,
    emotion: true,
  },
  {
    value: 'pizza-graph',
    image: pizzaGraph,
    label: 'Gráfico de pizza',
    emotion: true,
  },
  {
    value: 'donut-graph',
    image: donutGraph,
    label: 'Gráfico de donut',
    emotion: true,
  },
  {
    value: 'column-graph',
    image: columnGraph,
    label: 'Gráfico de coluna',
    time: true,
    comparative: true,
  },
  {
    value: 'stacked-graph',
    image: stackedGraph,
    label: 'Gráfico de coluna empilhada',
    comparative: true,
  },
];

interface SelectGraphTypeProps {
  value: string;
  onChange: (v: string) => void;
  id: string;
  index: number;
}

function SelectGraphType({ value, onChange, id, index }: SelectGraphTypeProps) {
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const [isOpen, setIsOpen] = useState(false);
  const { colorPrimary } = useU4heroColors();
  const [label, setLabel] = useState<string>(
    graphsTypes.find((item) => item.value === value)?.label ?? ''
  );
  const form = Form.useFormInstance();
  const graph = Form.useWatch(['graphs', index], form);
  let options: any = [];

  if (graph?.graphView === 'comparative') {
    options = graphsTypes.filter((item) => item.comparative);
  }
  if (graph?.graphView === 'year' && graph?.category === 'emotion') {
    options = graphsTypes.filter((item) => item.time || item.emotion);
  }
  if (graph?.graphView === 'time') {
    options = graphsTypes.filter((item) => item.time);
  }
  if (graph?.graphView === 'year' && graph?.category !== 'emotion') {
    options = graphsTypes.filter((item) => item.time);
  }

  useEffect(() => {
    const name = graphsTypes?.find((item: any) => item.value === value)?.label;
    setLabel(name as string);
  }, [value]);
	
  return (
    <Dropdown
      open={isOpen}
      overlay={
        <Menu>
          <Flex
            style={{
              borderRadius: 8,
              background: 'white',
              padding: 16,
            }}
            vertical
            gap={12}
          >
            <Text size="md">Sugestões</Text>
            <Flex gap={16}>
              {options.map((item: any) => (
                <GraphItem
                  key={item.value}
                  onClick={() => {
                    onChange(item.value);
                    setIsOpen(false);
                  }}
                  selected={value === item.value}
                  hover={`1px solid ${colorPrimary}`}
                >
                  <img src={item.image} />
                </GraphItem>
              ))}
            </Flex>
          </Flex>
        </Menu>
      }
      trigger={['click']}
    >
      <Input
        id={id}
        placeholder="Escolha o tipo do gráfico"
        size={isMobile ? 'middle' : 'large'}
        suffix={
          <DownOutlined
            style={{ color: 'rgba(0, 0, 0, 0.25)', fontSize: 12 }}
          />
        }
        value={label}
        // disabled
        readOnly
        onClick={() => {
          if (options.length) {
            setIsOpen(true);
          }
        }}
      />
    </Dropdown>
  );
}

export default SelectGraphType;

const GraphItem = styled.div<any>`
  cursor: pointer;
  border-radius: 5px;
  width: 64px;
  height: 64px;
  box-shadow: 0px -0.5px 4px 0px rgba(0, 0, 0, 0.1),
    0px 4px 4px 0px rgba(0, 0, 0, 0.1);
  background: ${({ background }) => (background ? background : '#fff')};
  border: ${({ hover, selected }) => (selected && hover ? hover : '')};
  &:hover {
    border: ${({ hover }) => (hover ? hover : '')};
  }
`;
