import { useMediaQuery } from '@chakra-ui/react';
import { Col, Flex, Row, Spin } from 'antd';
import { GenericCard } from 'components/V2/generic-card-styled-component';
import useEmotionalStats from 'core/features/graphs/hooks/useEmotionalGraphV2';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import moment from 'moment';
import { t } from 'core/resources/strings';
import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import bad from 'assets/images/sad.png';
import good from 'assets/images/happy.png';
import fear from 'assets/images/fear.png';
import disgusted from 'assets/images/disgusted.png';
import verybad from 'assets/images/angry.png';
import SadIcon from 'components/icons/SadIcon';

const colors = [
  { color: '#FFBC00', name: 'Feliz', img: good },
  { color: '#8C6B84', name: 'Triste', img: bad },
  { color: '#216EB4', name: 'Medo', img: fear },
  { color: '#00B8B8', name: 'Nojo', img: disgusted },
  { color: '#E35809', name: 'Raiva', img: verybad },
];

function Emotion() {
  const { colorPrimary, colorGreyBorderAndFont } = useU4heroColors();
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const { data, isLoading: isLoadingStats } = useEmotionalStats({
    initialDate: moment().subtract(7, 'days').format('YYYY-MM-DD'),
    endDate: moment().add(1, 'day').format('YYYY-MM-DD'),
  });

  const totalCount = data?.data?.reduce(
    (total, item) => total + item?.percent,
    0
  );

  const options: ApexOptions = {
    chart: {
      type: 'donut',
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: 'bottom',
          },
        },
      },
    ],
    labels: data?.data?.map((item) => t(`app.${item.name}`)),
    colors: colors.map((item) => item.color),
    legend: {
      show: false,
    },
  };
  return (
    <GenericCard padding="8px" height="100%" id='id-tour-section-emotionometer'>
      <Flex vertical style={{ width: '100%',height:'100%' }}>
        <p
          style={{
            color: colorPrimary,
            fontSize: isMobile ? '18px' : '24px',
            fontWeight: 700,
          }}
        >
          Emocionômetro
        </p>
        {totalCount !== 0 && (
          <p
            style={{
              color: colorGreyBorderAndFont,
              fontSize: isMobile ? '12px' : '14px',
              fontWeight: 500,
              alignSelf: 'end',
            }}
          >
            {data?.period}
          </p>
        )}

        {isLoadingStats ? (
          <Spin size="large" />
        ) : (
          <div>
            {totalCount === 0 ? (
              <Flex
                vertical
                align="center"
                justify="center"
                gap="16px"
                style={{ height: '100%' }}
              >
                <SadIcon color="#7B7B7B" size={60} />
                <p
                  style={{
                    fontSize: '14px',
                    color: '#7B7B7B',
                    textAlign: 'center',
                    fontWeight: 400,
                    maxWidth: '267px',
                  }}
                >
                  O emocionômetro não foi preenchido nenhuma vez, não há nenhuma
                  informação para ser exibida!
                </p>
              </Flex>
            ) : (
              <Row align="middle" justify="center" style={{ width: '100%' }}>
                <Col span={isMobile ? 24 : 14}>
                  <ReactApexChart
                    width="100%"
                    height={isMobile ? '235px' : '250px'}
                    options={options}
                    series={data?.data.map((item) =>
                      parseFloat(item.percent.toFixed(1))
                    )}
                    type="donut"
                    style={{ display: 'flex', justifyContent: 'center' }}
                    id='id-tour-emotionometer-graph'
                  />
                </Col>
                <Col span={isMobile ? 24 : 10}>
                  <Flex vertical={!isMobile} wrap={'wrap'} gap="16px">
                    {colors.map((item, index) => (
                      <Flex gap="8px" key={index} align="center" id={`id-tour-legend-item-${index}`}>
                        <img
                          alt={item.name}
                          src={item.img}
                          width={isMobile ? '24px' : '30px'}
                        />
                        <p
                          style={{
                            fontSize: isMobile ? '14px' : '16px',
                            fontWeight: 400,
                            color: 'black',
                            textAlign: 'right',
                          }}
                        >
                          {item.name}
                        </p>
                      </Flex>
                    ))}
                  </Flex>
                </Col>
              </Row>
            )}
          </div>
        )}
      </Flex>
    </GenericCard>
  );
}

export default Emotion;
