import { useHistory } from 'react-router-dom';
import bg from 'assets/images/bg.png';
import bgMobile from 'assets/images/bg-mobile.png';
import logo from 'assets/images/logo.png';
import { useLogin } from 'core/features/auth/hooks/useLogin';
import { LoginForm } from 'core/features/auth/typings';
import { useCurrentUser } from 'core/features/user/store';
import jwt_decode from 'jwt-decode';
import { CurrentUser } from 'core/features/user/typings';
import { useRef, useState } from 'react';
import { useValidate2FA } from 'core/features/auth/hooks/useValidate2Fa';
import { useMediaQuery } from '@chakra-ui/media-query';
import { Flex, Form, Input, Modal, Typography } from 'antd';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import { notificateError, notificateSucess } from 'infra/helpers/notifications';
import AuthCode, { AuthCodeRef } from 'react-auth-code-input';
import './styles.css';
import { IoClose } from 'react-icons/io5';
import Button from 'components/buttons/button';
import { useUpdateCurrentUser } from 'core/features/user/hooks/useUpdateCurrentUser';
import SubTitle from 'components/typography/subTitle';
import Text from 'components/typography/text';

function LoginPage() {
  const { mutate: handleLogin, isLoading } = useLogin();
  const {
    update: updateUser,
    updateToken,
    updateRefreshToken,
  } = useCurrentUser();
  const { mutate: handleSave } = useUpdateCurrentUser();
  const [authCode, setAuthCode] = useState('');
  const [isOpenModal, setIsOpenModal] = useState(false);
  const { mutate: handle2Fa, isLoading: isLoading2Fa } = useValidate2FA();
  const [isInvalid, setIsInvalid] = useState(false);
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const history = useHistory();
  const { user } = useCurrentUser();
  const { colorPrimary } = useU4heroColors();
  const [form] = Form.useForm();
  if (user) {
    history.push('/');
  }
  const AuthInputRef = useRef<AuthCodeRef>(null);

  const _handleLogin = (values: LoginForm) => {
    handleLogin(values, {
      onSuccess: (data: any) => {
        if (data && data.data.message === 'User needs to validate 2FA') {
          setIsOpenModal(true);
        } else {
          const decoded: CurrentUser = jwt_decode(data.data.accessToken);
          login(data, decoded);
        }
      },
      onError: () => {
        form.setFields([
          {
            name: 'password',
            errors: [
              'Ops! Parece que o e-mail e/ou a senha inseridos estão incorretos.',
            ],
          },
        ]);
        notificateError(
          'Ops! Parece que o e-mail e/ou a senha inseridos estão incorretos.'
        );
      },
    });
  };
  const _handle2Fa = () => {
    handle2Fa(
      {
        code: authCode,
        username: form.getFieldValue('username'),
        password: form.getFieldValue('password'),
      },
      {
        onSuccess: (data: any) => {
          if (!data) {
            setAuthCode('');
            setIsInvalid(true);
          } else {
            const decoded: CurrentUser = jwt_decode(data.data.accessToken);
            login(data, decoded);
          }
        },
        onError: () => {
          notificateError('Código inválido');
        },
      }
    );
  };
  function login(data: any, decoded: any) {
    updateUser(decoded);
    updateToken(data.data.accessToken);
    updateRefreshToken(data.data.refreshToken);
    if (!decoded.startedInitialTour) {
      handleSave(
        { ...decoded, startedInitialTour: true },
        {
          onSuccess: () => {
            history.push('/?initialtour');
          },
        }
      );
    } else {
      history.push('/');
    }
  }

  return (
    <Flex
      style={{
        width: '100%',
        height: '100vh',
        backgroundImage: isMobile ? '' : `url(${bg})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      }}
    >
      {isOpenModal ? (
        <Modal
          title={
            <p
              style={{
                fontSize: isMobile ? '18px' : '24px',
                color: colorPrimary,
                fontWeight: 700,
              }}
            >
              Verificação de segurança
            </p>
          }
          closeIcon={
            <div
              style={{
                background: colorPrimary,
                borderRadius: '50%',
                width: '20px',
                height: '20px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <IoClose size={16} color="white" />
            </div>
          }
          open={true}
          centered
          onCancel={() => {
            setIsOpenModal(false);
            setAuthCode('');
            setIsInvalid(false);
          }}
          footer={null}
          width={450}
        >
          <Flex vertical gap="8px">
            <Text>Digite aqui o código que te enviamos pelo email.</Text>

            <AuthCode
              inputClassName="input-auth"
              containerClassName="container-auth"
              allowedCharacters="numeric"
              onChange={(res) => setAuthCode(res)}
              ref={AuthInputRef}
            />
            {isInvalid ? (
              <Text color="red">Código inválido ou expirado.</Text>
            ) : null}
            <Button
              size="md"
              style={{ justifySelf: 'center', alignSelf: 'center' }}
              onClick={_handle2Fa}
              disabled={authCode.length < 6}
              loading={isLoading2Fa}
            >
              Verificar
            </Button>
            <Text>
              O recebimento do seu código pode levar até um minuto. Se não o
              recebeu,
              <button
                style={{ textDecoration: 'underline', color: 'black' }}
                onClick={() => {
                  handleLogin({
                    username: form.getFieldValue('username'),
                    password: form.getFieldValue('password'),
                  });
                  notificateSucess(
                    'Enviamos um novo código de segurança para o seu email.'
                  );
                  setIsInvalid(false);
                }}
              >
                clique aqui para enviar um novo código.
              </button>
            </Text>
          </Flex>
        </Modal>
      ) : null}
      <Form
        onFinish={(ev) => _handleLogin(ev)}
        validateTrigger="onSubmit"
        layout="vertical"
        form={form}
        requiredMark="optional"
      >
        {isMobile ? (
          <img alt="background-mobile" src={bgMobile} width="536px" />
        ) : null}
        <Flex
          style={{
            borderRadius: isMobile ? ' 20px 20px 0px 0px' : '0px 20px 20px 0px',
            background: 'white',
            width: isMobile ? '100vw' : '50vw',
            height: isMobile ? '' : '100vh',
            position: isMobile ? 'relative' : '-moz-initial',
            top: isMobile ? -20 : undefined,
          }}
          vertical
          justify="center"
          align="center"
        >
          <Flex
            gap={isMobile ? '0px' : '16px'}
            vertical
            justify="center"
            style={{
              width: isMobile ? '100%' : '508px',
              padding: isMobile ? '0px 8px' : '',
            }}
          >
            <img
              alt="logo"
              src={logo}
              width={isMobile ? '100px' : '212px'}
              style={{ alignSelf: 'center' }}
            />
            <SubTitle
              primary
              style={{
                color: colorPrimary,
                fontSize: isMobile ? '18px' : '28px',
                fontWeight: 700,
                marginTop: '8px',
                textAlign: 'center',
              }}
            >
              Seja o protagonista da sua história, conecte-se com U4hero!
            </SubTitle>
            <Form.Item
              label="E-mail"
              rules={[
                {
                  required: true,
                  message: 'Por favor, informe seu e-mail!',
                },
              ]}
              name="username"
            >
              <Input
                size="large"
                type="email"
                style={{ width: '100%', height: isMobile ? '40px' : '60px' }}
              />
            </Form.Item>
            <Form.Item
              label="Senha"
              rules={[
                {
                  required: true,
                  message: 'Por favor, digite sua senha!',
                },
              ]}
              name="password"
            >
              <Input.Password
                size="large"
                style={{ width: '100%', height: isMobile ? '40px' : '60px' }}
              />
            </Form.Item>
            <a
              onClick={() => history.push('/auth/recover')}
              style={{
                fontSize: isMobile ? '12px' : '16px',
                fontWeight: 400,
                alignSelf: 'flex-end',
              }}
              onMouseEnter={(e) => {
                e.currentTarget.style.textDecoration = 'underline'; // Add underline on hover
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.textDecoration = 'none'; // Remove underline when not hovering
              }}
            >
              Esqueceu sua senha? Clique aqui!
            </a>

            <Button
              size="lg"
              htmlType="submit"
              style={{ alignSelf: 'center', marginTop: isMobile ? '16px' : '' }}
              loading={isLoading}
            >
              ENTRAR
            </Button>
          </Flex>
        </Flex>
      </Form>
    </Flex>
  );
}

export default LoginPage;
