import { useMediaQuery } from '@chakra-ui/react';
import { Divider, Flex, Input } from 'antd';
import useMessages from 'core/features/chat/hooks/useMessages';
import { useSaveMessage } from 'core/features/chat/hooks/useSaveMessage';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { IoIosSend } from 'react-icons/io';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import { GenericCard } from 'components/V2/generic-card-styled-component';
import startChat from 'assets/images/start-chat.png';
import Conversation from '../Conversation';
import { notificateError, notificateSucess } from 'infra/helpers/notifications';
import { useParams } from 'react-router-dom';
import Button from 'components/buttons/button';

const { TextArea } = Input;

function SafeSpaceChat() {
  const params = useParams<{ id: string }>();
  const { data: messages, refetch: refetchMessages } = useMessages(params.id);
  const [message, setMessage] = useState('');
  const { mutate: handleSave, isLoading } = useSaveMessage();
  const { colorPrimary } = useU4heroColors();
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const messagesEndRef = useRef<HTMLDivElement | null>(null);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (
      event.key === 'Enter' &&
      !event.shiftKey &&
      message !== undefined &&
      message !== ''
    ) {
      event.preventDefault();
      onSubmit();
    }
  };

  const onSubmit = () => {
    if (message === '') {
      notificateError('Preencha a mensagem antes de enviar!');
    } else {
      handleSave(
        { id: params.id ?? '', message: message },
        {
          onError: () => {
            notificateError(
              'Ops, aconteceu algum problema ao tentar sua mensagem. Verifique sua conexão e tente novamente!'
            );
          },
          onSuccess: () => {
            notificateSucess('Mensagem enviada com sucesso!');
            setMessage('');
            refetchMessages();
          },
        }
      );
    }
  }

  useEffect(() => {
    if (messages) {
      setTimeout(() => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
      }, (200));
    }
  }, [messages]);

  return (
    <GenericCard padding="8px" height="100%">
      <Flex
        vertical
        gap="8px"
        justify="space-between"
        style={{ width: '100%' }}
      >
        <p
          style={{
            fontSize: isMobile ? '18px' : '24px',
            color: colorPrimary,
            fontWeight: 700,
          }}
        >
          Chat (Assíncrono)
        </p>
        <div
          style={{
            maxHeight: isMobile ? '484px' : '520px',
            overflow: 'auto',
            display: 'flex',
            flexDirection: 'column',
          }}
   
        >
          {messages?.length ? (
            messages.map((item) => (
              <div>
                <Divider
                  style={{
                    fontSize: isMobile ? '14px' : '18px',
                    fontWeight: 500,
                  }}
                >
                  {moment(item.date).format('dddd, DD/MM/YYYY')}
                </Divider>
                <Conversation messages={item.messages ?? []} />
              </div>
            ))
          ) : (
            <>
              <p
                style={{
                  fontSize: isMobile ? '14px' : '16px',
                  color: '#7B7B7B',
                  textAlign: 'center',
                  fontWeight: 400,
                }}
              >
                Envie uma mensagem de acolhimento para este estudante!
              </p>
              <img
                alt='Comece um chat'
                src={startChat}
                width={isMobile ? '189px' : '226px'}
                style={{ alignSelf: 'center' }}
              />
            </>
          )}
           <div ref={messagesEndRef} />
        </div>

        <TextArea
          id="id-tour-safe-space-input-chat-message"
          onKeyDown={handleKeyDown}
          autoSize={{ minRows: 2 }}
          style={{
            boxShadow:
              '0px -0.5px 4px 0px rgba(0, 0, 0, 0.25), 0px 3px 4px 0px rgba(0, 0, 0, 0.25)',
          }}
          placeholder="Insira sua mensagem"
          value={message}
          onChange={(v) => setMessage(v.target.value)}
        />
        <Button
          disabled={message === ''}
          onClick={onSubmit}
          size='md'
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            alignSelf: 'center',
            gap: '8px',
          }}
          id="id-tour-safe-space-button-send-chat-message"
          loading={isLoading}
        >
          <IoIosSend color="white" size={20} />
          ENVIAR
        </Button>
      </Flex>
    </GenericCard>
  );
}

export default SafeSpaceChat;
