import { useMediaQuery } from '@chakra-ui/media-query';
import {  Col, Flex, Row } from 'antd';
import Button from 'components/buttons/button';
import { GenericCard } from 'components/V2/generic-card-styled-component';
import Tag from 'components/V2/tag';
import { PracticeDetails } from 'core/features/practices/typings';
import { t } from 'core/resources/strings';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import moment from 'moment';
import { useHistory } from 'react-router';

function PracticeCard({ practice }: { practice: PracticeDetails }) {
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const history = useHistory();
  const { colorPrimary, colorSecondary,colorGreyBorderAndFont } = useU4heroColors();
  const isPracticeNew = moment(practice.createdAt).isAfter(
    moment().subtract(7, 'days')
  );

  return (
    <GenericCard height="100%" className="practice-card">
      <Flex vertical justify="space-between" style={{ height: '100%',position:'relative' }}>
        <div>
          <img alt={`banner ${practice.title}`} src={practice.banner} style={{ borderRadius: '10px' }} />
          <div
            style={{
              borderRadius: '10px 5px 5px 0px',
              fontSize: isMobile ? '12px' : '16px',
              position: 'absolute',
              paddingLeft: isMobile ? '14px' : '16px',
              paddingRight: isMobile ? '14px' : '16px',
              background: colorPrimary,
              color: 'white',
              top: 0,
            }}
          >
            {t(`app.${practice.analysisEnvironment.name}`)}
          </div>
          <Flex style={{ width: '100%',position:'relative',bottom:isMobile ? 18:24 }} justify="center">
            <div
              style={{
                borderRadius: '5px 5px 0px 0px',
                fontSize: isMobile ? '12px' : '16px',
                paddingLeft: isMobile ? '14px' : '16px',
                paddingRight: isMobile ? '14px' : '16px',
                background: colorPrimary,
                color: 'white',
                textTransform:'uppercase'
              }}
            >
              {practice.target} - {t(`app.${practice.practiceModality.name}`)}
            </div>
          </Flex>

          <p style={{ fontSize:isMobile ? '10px':'14px',color:colorGreyBorderAndFont,textAlign: 'end', marginBottom: '4px' }}>
            {moment(practice.createdAt).format('DD/MM/YYYY')}
          </p>
        </div>
        <Flex gap="8px" justify="flex-end" align="center">
          <Tag color="primary" text={practice.competence1.name['pt-br']} />
          {practice?.competence2 ? (
            <Tag color="primary" text={practice.competence2.name['pt-br']} />
          ) : null}
          {practice?.skill ? (
            <Tag color="primary" text={practice.skill.name['pt-br']} />
          ) : null}
        </Flex>
        <p
          style={{
            fontSize: isMobile ? '16px' : '18px',
            color: colorPrimary,
            textAlign: 'center',
            fontWeight: 700,
            marginTop: '8px',
            marginBottom: '4px',
          }}
        >
          {practice.title}
        </p>
        <p
          style={{
            fontSize: isMobile ? '14px' : '16px',
            color: 'black',
            textAlign: 'justify',
            fontWeight: 400,
            marginBottom: '8px',
          }}
        >
          {practice.goal}
        </p>
        <Row>
          <Col span={8}>
            <p
              style={{
                fontSize: isMobile ? '14px' : '16px',
                color: 'black',
                fontWeight: 400,
              }}
            >
              Faixa Etária:
              <br />
              <span
                style={{
                  color: colorPrimary,
                  textAlign: 'center',
                  fontWeight: 600,
                }}
              >
                {practice.maxAge} anos +{' '}
              </span>
            </p>
          </Col>
          <Col span={8}>
            <p
              style={{
                fontSize: isMobile ? '14px' : '16px',
                color: 'black',
                fontWeight: 400,
              }}
            >
              Duração
              <br />
              <span
                style={{
                  color: colorPrimary,
                  textAlign: 'center',
                  fontWeight: 600,
                }}
              >
                {practice.duration} min
              </span>
            </p>
          </Col>
          <Col span={8}>
            <p
              style={{
                fontSize: isMobile ? '14px' : '16px',
                color: 'black',
                fontWeight: 400,
              }}
            >
              Dificuldade
              <br />
              <span
                style={{
                  color: colorPrimary,
                  textAlign: 'center',
                  fontWeight: 600,
                }}
              >
                {practice.dificulty}
              </span>
            </p>
          </Col>
        </Row>
        {isPracticeNew && (
          <Flex justify="flex-end">
            <div
              style={{
                borderRadius: '5px 0px 0px 5px',
                fontSize: isMobile ? '12px' : '16px',
                position: 'absolute',
                paddingLeft: '8px',
                paddingRight: '8px',
                background: colorSecondary,
                color: 'white',
                right: -16,
              }}
            >
              NOVO
            </div>
          </Flex>
        )}
        <Button
          size="sm"
          style={{
            justifySelf: 'center',
            alignSelf: 'center',
            marginTop: '10px',
          }}
          onClick={()=> history.push(`/practices/${practice.id}`)}
        >
          LEIA MAIS
        </Button>
      </Flex>
    </GenericCard>
  );
}

export default PracticeCard;
