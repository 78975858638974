import { useMediaQuery } from '@chakra-ui/react';
import { SliderSingleProps } from 'antd';
import { useSavePracticeFeedback } from 'core/features/practices/hooks/useSavePracticeFeedback';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { PracticeFeedbackForm } from 'core/features/practices/typings';
import { notificateError } from 'infra/helpers/notifications';
import Feedback from 'components/feedback';

function PracticeFeedback() {
  const params = useParams<{ id: string }>();
  const [submited, setSubmited] = useState(false);
  const { mutate: handleSave, isLoading } = useSavePracticeFeedback();

  const onSubmit = (values: PracticeFeedbackForm) => {
    handleSave(
      { ...values, practiceId: params.id },
      {
        onSuccess: () => {
          setSubmited(true);
        },
        onError: (err: any) => {
          notificateError(
            'Ops, aconteceu algum problema ao salvar seu feedback, tente novamente!'
          );
        },
      }
    );
  };
  return (
    <Feedback
      onSubmit={onSubmit}
      askMessage="Como você avalia essa prática pedagógica?"
      submited={submited}
      isLoadingSubmit={isLoading}
    />
  );
}

export default PracticeFeedback;
