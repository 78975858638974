import { ApexOptions } from 'apexcharts';
import ReactApexChart from 'react-apexcharts';

function LineGraph({
  categories,
  series,
}: {
  categories: string[];
  series: ApexAxisChartSeries;
}) {
  const options: ApexOptions = {
    chart: {
      height: 350,
      type: 'line',
      toolbar:{
        show:false
      },
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'straight',
    },
    grid: {
      row: {
        colors: ['#f3f3f3', 'transparent'],
        opacity: 0.5,
      },
    },
    xaxis: {
      categories: categories,
    },
  };
  return (
    <ReactApexChart
      options={options}
      series={series}
      type="line"
      height={350}
    />
  );
}

export default LineGraph;
