import { useMediaQuery } from '@chakra-ui/media-query';
import { Divider, Flex, Pagination, Progress, Spin } from 'antd';
import { HoverItem } from 'components/hover-item/hover';
import SubTitle from 'components/typography/subTitle';
import Text from 'components/typography/text';
import Tag from 'components/V2/tag';
import useActivitiesByGame from 'core/features/activity/hooks/useActivitiesByGame';
import { ActivityGame } from 'core/features/activity/types';
import { GameDetails } from 'core/features/games/typings';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import moment from 'moment';
import { useState } from 'react';
import { useHistory } from 'react-router';

function GameActivities({ id, name, coverImageUrl }: GameDetails) {
  const { colorGreyBorderAndFont, colorPrimary } = useU4heroColors();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const { data, isLoading } = useActivitiesByGame({
    page: page,
    limit: pageSize,
    gameId: id,
    publishDate: moment().startOf('year').format('YYYY-MM-DD'),
    endDate: moment().endOf('year').format('YYYY-MM-DD'),
  });

  const [isMobile] = useMediaQuery('(max-width: 768px)');

  return (
    <Flex vertical gap={24}>
      <Flex vertical gap={16}>
        <img
          src={coverImageUrl}
          alt={name}
          style={{
            maxHeight: '600px',
            borderRadius: '20px',
            aspectRatio: '16/9',
            objectFit: 'cover',
            objectPosition: 'top',
          }}
        />
        <Flex justify="space-between">
          <SubTitle primary>Histórico</SubTitle>{' '}
          <Text size="md" color={colorGreyBorderAndFont}>
            {moment().year()}
          </Text>
        </Flex>
        <Flex vertical gap={24}>
          {isLoading && <Spin size="large" />}
          {data?.data.map((item) => (
            <ActivityHistory key={item.id} {...item} />
          ))}

          <Flex
            justify="center"
            style={{
              marginTop: isMobile ? '18px' : '26px',
              marginBottom: isMobile ? '10px' : '18px',
            }}
          >
            <Pagination
              showSizeChanger
              defaultCurrent={1}
              current={page}
              pageSize={pageSize}
              onChange={(v) => setPage(v)}
              onShowSizeChange={(v, size) => setPageSize(size)}
              total={data?.total}
            />
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}

export default GameActivities;

function ActivityHistory({
  id,
  typeId,
  classes,
  students,
  publishDate,
  endDate,
  tag,
  progressOverall,
}: ActivityGame) {
  const { colorPrimary, colorPrimaryLight } = useU4heroColors();
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const history = useHistory();

  const redirectToActivities = () => {
    if (typeId === 0) {
      history.push(`/activities/class/?id=${id}`);
    } else {
      history.push(`/activities/individual/?id=${id}`);
    }
  };

  if (isMobile) {
    return (
      <Flex
        style={{
          padding: '8px',
          borderRadius: '20px',
          boxShadow:
            '0px 0.5px 4px 0px rgba(0, 0, 0, 0.25), 0px -0.5px 4px 0px rgba(0, 0, 0, 0.25)',
        }}
        vertical
        gap={8}
        onClick={redirectToActivities}
      >
        <Text size="md">
          <strong>Trilha: </strong>
          {tag.name['pt-br']}
        </Text>

        {classes.length ? (
          <Flex gap={5}>
            <Text size="md" bold>
              Turmas:
            </Text>
            <Flex wrap gap={8}>
              {classes.map((classe) => (
                <Tag text={`${classe.serie.name} - ${classe.name}`} />
              ))}
            </Flex>
          </Flex>
        ) : (
          <Flex gap={5}>
            <Text size="md" bold>
              Alunos:
            </Text>
            <Flex wrap gap={8}>
              {students.map((student) => (
                <Tag text={student.name} />
              ))}
            </Flex>
          </Flex>
        )}

        <Text size="md">
          <strong>Inicio: </strong>
          {moment(publishDate).format('DD/MM/YYYY')}
        </Text>

        <Text size="md">
          <strong>Término: </strong>
          {moment(endDate).format('DD/MM/YYYY')}
        </Text>
        <Flex gap={10}>
          <Text size={'md'} bold>
            Progresso:
          </Text>
          <Progress
            status="normal"
            strokeColor={colorPrimary}
            percent={parseInt(progressOverall, 10)}
            style={{ fontSize: '14px' }}
          />
        </Flex>
      </Flex>
    );
  }
  return (
    <HoverItem
      hover={colorPrimaryLight}
      style={{ borderRadius: '20px', width: '100%' }}
    >
      <Flex
        justify="space-between"
        style={{
          padding: '16px',
          borderRadius: '20px',
          boxShadow:
            '0px 0.5px 4px 0px rgba(0, 0, 0, 0.25), 0px -0.5px 4px 0px rgba(0, 0, 0, 0.25)',
        }}
        onClick={redirectToActivities}
      >
        <Text size="md" align="center">
          <strong>Trilha:</strong>
          <br /> {tag.name['pt-br']}
        </Text>
        <Divider
          type="vertical"
          style={{
            height: '62px',
            borderColor: 'rgba(38, 38, 38, 0.30)',
          }}
        />
        {classes.length ? (
          <Flex vertical gap={5}>
            <Text size="md" bold align="center">
              Turmas:
            </Text>
            <Flex wrap gap={8}>
              {classes.map((classe) => (
                <Tag text={`${classe.serie.name} - ${classe.name}`} />
              ))}
            </Flex>
          </Flex>
        ) : (
          <Flex vertical gap={5}>
            <Text size="md" bold align="center">
              Alunos:
            </Text>
            <Flex wrap gap={8}>
              {students.map((student) => (
                <Tag text={student.name} />
              ))}
            </Flex>
          </Flex>
        )}

        <Divider
          type="vertical"
          style={{
            height: '62px',
            borderColor: 'rgba(38, 38, 38, 0.30)',
          }}
        />
        <Text size="md" align="center">
          <strong>Inicio:</strong>
          <br /> {moment(publishDate).format('DD/MM/YYYY')}
        </Text>
        <Divider
          type="vertical"
          style={{
            height: '62px',
            borderColor: 'rgba(38, 38, 38, 0.30)',
          }}
        />
        <Text size="md" align="center">
          <strong>Término::</strong>
          <br /> {moment(endDate).format('DD/MM/YYYY')}
        </Text>
        <Divider
          type="vertical"
          style={{
            height: '62px',
            borderColor: 'rgba(38, 38, 38, 0.30)',
          }}
        />
        <Flex vertical gap={10}>
          <Text size={'md'} bold align="center">
            Progresso:
          </Text>
          <Progress
            status="normal"
            strokeColor={colorPrimary}
            percent={parseInt(progressOverall, 10)}
            style={{ fontSize: '14px' }}
          />
        </Flex>
      </Flex>
    </HoverItem>
  );
}
