import { useMediaQuery } from '@chakra-ui/react';
import { Flex, Modal, Spin } from 'antd';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import { IoClose } from 'react-icons/io5';
import SubTitle from 'components/typography/subTitle';
import Button from 'components/buttons/button';
import { PiHeart, PiHeartFill } from 'react-icons/pi';
import { useGameFavorite } from 'core/features/games/hooks/useGameFavorite';
import { GameDetails } from 'core/features/games/typings';
import { Unity, useUnityContext } from 'react-unity-webgl';
import ReactGodot from 'react-godot';
import { useEffect, useState } from 'react';
import Text from 'components/typography/text';
import lumiPlaying from 'assets/images/lumi-playing.png';
import { useQueryClient } from 'react-query';

interface ModalGameProps extends GameDetails {
  onClose: () => void;
  isOpen: boolean;
}

function ModalGamePreview({
  onClose,
  isOpen,
  id,
  isFavorite,
  versions,
  versionId,
  ...rest
}: ModalGameProps) {
  const { colorPrimary } = useU4heroColors();
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const { mutate: handleToggleFavorite, isLoading: isLoadingFavorite } =
    useGameFavorite();
  const version = versions?.find((item) => item.id === versionId);
  const [unloadGame, setUnloadGame] = useState(false);
  const [isPreviewEnded, setIsPreviewEnded] = useState(false);

  const queryClient = useQueryClient();

  const [gameKey, setGameKey] = useState(0);

  const {
    unityProvider,
    addEventListener,
    sendMessage,
    loadingProgression,
    isLoaded,
    unload,
  } = useUnityContext({
    codeUrl: version?.playDatas?.codeUrl!,
    dataUrl: version?.playDatas?.dataUrl!,
    frameworkUrl: version?.playDatas?.frameworkUrl!,
    loaderUrl: version?.playDatas?.loaderUrl!,
    streamingAssetsUrl: version?.playDatas?.streamingAssetsUrl,
  });

  useEffect(() => {
    if (isOpen) {
      setGameKey((prevKey) => prevKey + 1);
      setIsPreviewEnded(false);
      setUnloadGame(false);
    }
  }, [isOpen]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsPreviewEnded(true);
      unload();
      setUnloadGame(true);
    }, 120000);

    return () => clearTimeout(timer);
  }, []);

  const closeGame = () => {
    unload();
    setUnloadGame(true);
    setTimeout(() => {
      onClose();
    }, 500);
  };

  var screenWidth = window.screen.width;
  var screenHeight = window.screen.height;

  return (
    <Modal
      title={<SubTitle primary>Prévia do Jogo</SubTitle>}
      open={isOpen}
      onCancel={closeGame}
      footer={null}
      width={isMobile ? '95%' : 1047}
      closeIcon={
        <div
          style={{
            background: colorPrimary,
            borderRadius: '50%',
            width: '20px',
            height: '20px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <IoClose size={16} color="white" />
        </div>
      }
    >
      <Flex vertical gap={8}>
        <Flex justify="flex-end">
          <Button
            size="md"
            variant="outline"
            loading={isLoadingFavorite}
            icon={
              isFavorite ? (
                <PiHeartFill color={colorPrimary} size={20} />
              ) : (
                <PiHeart color={colorPrimary} size={20} />
              )
            }
            onClick={() =>
              handleToggleFavorite(id as string, {
                onSuccess: () => {
                  queryClient.invalidateQueries(`/v2/game/${id}`);
                },
              })
            }
          >
            {isFavorite ? 'DESFAVORITAR' : 'Favoritar'}
          </Button>
        </Flex>

        {version?.playDatas.engine === 0 && (
          <Spin spinning={!isLoaded} tip="Carregando jogo...">
            <Unity
              key={gameKey}
              unityProvider={unityProvider}
              style={{
                width: '100%',
                height: isMobile ? '60vh' : '100%',
              }}
            />
          </Spin>
        )}

        {version?.playDatas.engine === 1 && (
          <ReactGodot
            key={gameKey}
            width={isMobile ? screenWidth * 0.9 : 1015}
            height={isMobile ? screenHeight * 0.7 : 552}
            unload={unloadGame}
            script={version?.playDatas?.frameworkUrl}
            pck={version?.playDatas?.dataUrl}
            wasm={version?.playDatas?.codeUrl}
          />
        )}
        {isPreviewEnded && (
          <Flex
            style={{
              padding: isMobile ? '8px' : '16px',
              background: '#FFF',
              borderRadius: '0px 0px 20px 20px',
              position: 'absolute',
              bottom: isMobile ? 65 : 0,
              width: isMobile ? '89%' : undefined,
            }}
            gap={12}
            align="center"
          >
            {!isMobile && <img src={lumiPlaying} />}

            <Text size="md" style={{ width: 'fit-content' }}>
              Esperamos que você tenha gostado desta amostra! Quando estiver
              pronto, clique em "Concluir" para encerrar a prévia.
            </Text>
          </Flex>
        )}
        <Button
          size="md"
          style={{ alignSelf: 'center' }}
          onClick={closeGame}
          disabled={!isPreviewEnded}
        >
          Concluir
        </Button>
      </Flex>
    </Modal>
  );
}

export default ModalGamePreview;
