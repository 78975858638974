import { Box, Flex, useMediaQuery } from '@chakra-ui/react';
import { MENUS } from 'core/features/menu';
import useCheckPermission from 'core/features/user/hooks/useCheckPermission';
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Image } from '@chakra-ui/image';
import logo from 'assets/images/logo.png';
import defaultProfile from 'assets/images/default-profile.png';
import { Badge, Dropdown, Tooltip } from 'antd';
import NotificationBox from 'components/notificationBox';
import useCountNotifications from 'core/features/notification/hooks/useCountNotifications';
import Helper from 'ui/pages/app/helpers-u4hero/components/helper';
import { useCurrentUser } from 'core/features/user/store';
import { useLogout } from 'core/features/auth/hooks/useLogout';
import { MdAccountCircle, MdExpandMore } from 'react-icons/md';
import './styles.css';
import Tour from 'components/tour';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { Step } from 'core/features/tour/typings';
import InitialTour from 'components/initial-tour';
import { PiBellLight, PiDoorOpenLight, PiQuestionLight } from 'react-icons/pi';

function SideMenu({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: (v: boolean) => void;
}) {
  const history = useHistory();
  const [currentPathname, setCurrentPathname] = useState(
    history.location.pathname
  );
  const [check] = useCheckPermission();
  const [isOpen, setIsOpen] = useState(false);
  const [isInitialTourOpen, setIsInitialTourOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isUserDropdownOpen, setIsUserDropdownOpen] = useState(false);
  const [isOpenTour, setIsOpenTour] = useState(false);
  const { data, refetch } = useCountNotifications();
  const [tourId, setTourId] = useState<string>();
  const [initialTourStep, setInitialTourStep] = useState<number | undefined>();
  const [tourSteps, setTourSteps] = useState<Step[] | undefined>();
  const { user } = useCurrentUser();
  const { handleLogout } = useLogout();
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const [isSidebarExpanded, setIsSidebarExpanded] = useState(false);
  const [isOverlayVisible, setIsOverlayVisible] = useState(false);
  const [showHamburgerMenu, setShowHamburgerMenu] = useState(true);
  let location = useLocation();
  const { colorPrimary, colorPrimaryMedium, colorSecondary, colorGreyCard } =
    useU4heroColors();

  const toggleSidebar = () => {
    setOpen(!open);
  };

  useEffect(() => {
    const unsubscribe = history.listen((listener) => {
      setCurrentPathname(listener.pathname);
    });

    return () => unsubscribe();
  }, [history]);

  useEffect(() => {
    if (tourId !== '') {
      setIsOpen(false);
      setIsOpenTour(true);
    }
  }, [tourId, setTourId]);

  useEffect(() => {
    if (open) {
      setIsSidebarExpanded(true);
      setShowHamburgerMenu(false);
      setIsOverlayVisible(true);
    } else {
      setIsSidebarExpanded(false);
      setShowHamburgerMenu(true);
      setIsOverlayVisible(false);
    }
  }, [open]);

  useEffect(() => {
    const baseUrl = window.location.origin;
    const handler = (ev: MessageEvent<{ type: string; message: any }>) => {
      if (ev.origin !== baseUrl) return;
      if (ev.data.type !== 'tour-steps') return;
      setTourSteps(ev.data.message);
      setIsOpenTour(true);
    };
    window.addEventListener('message', handler);

    return () => {
      window.removeEventListener('message', handler);
    };
  }, []);

  useEffect(() => {
    if (location.search.includes('initialtour')) {
      const searchParams = new URLSearchParams(location.search);
      const step = searchParams.get('step');
      if (step) {
        setInitialTourStep(Number(step));
      }
      setIsInitialTourOpen(true);
    }
  }, [location]);

  const renderMenuItems = () => {
    return MENUS.filter((item) => check(item.permission)).map((item, key) => {
      const isActive = item.isActive(currentPathname);
      const handleClick = () => {
        history.push(item.action);
        if (isSidebarExpanded) {
          toggleSidebar();
        }
        if (isMobile) {
          setShowHamburgerMenu(true);
        }
      };

      return (
        <Tooltip
          key={key}
          title={item.title}
          color={colorPrimary}
          placement="right"
        >
          <Box
            id={`id-tour-menu-icon-${key}`}
            onClick={handleClick}
            cursor="pointer"
            w="100%"
            display="flex"
            alignItems="center"
            justifyContent="center"
            _hover={{
              boxShadow: `inset 5px 0px 0px 0px ${colorSecondary}`,
            }}
            boxShadow={
              isActive ? `inset 5px 0px 0px 0px ${colorSecondary}` : undefined
            }
          >
            {item.icon()}
          </Box>
        </Tooltip>
      );
    });
  };

  return (
    <>
      {isInitialTourOpen ? (
        <InitialTour
          onClose={() => setIsInitialTourOpen(false)}
          step={initialTourStep}
        />
      ) : null}
      {isOpen ? (
        <Helper
          onClose={() => {
            setIsOpen(false);
          }}
          setTourId={setTourId}
        />
      ) : null}
      <div
        className={isMobile && showHamburgerMenu ? 'leftMobile' : 'rightMobile'}
        style={{
          width: '62px',
          height: '100%',
          backgroundColor: colorPrimary,
          zIndex: 1010,
          position: 'fixed',
          display: 'flex',
          flexDirection: 'column',
          borderRadius: '0px 15px 15px 0px',
          alignItems: 'center',
          justifyContent: 'space-around',
        }}
      >
        {isMobile && !isSidebarExpanded ? (
          <button
            onClick={() => {
              if (!isSidebarExpanded && showHamburgerMenu) {
                toggleSidebar();
              }
              setShowHamburgerMenu(!showHamburgerMenu);
            }}
            style={{
              backgroundColor: colorPrimary,
              position: 'absolute',
              right: '-20px',
              bottom: '30px',
              borderRadius: '0px 50px 50px 0px',
              height: '40px',
              width: '26px',
            }}
          >
            <BsThreeDotsVertical size={24} color="white" />
          </button>
        ) : (
          <button
            onClick={toggleSidebar}
            style={{
              backgroundColor: colorPrimary,
              position: 'absolute',
              right: '-10px',
              top: '100px',
              borderRadius: '50%',
            }}
          >
            {isSidebarExpanded ? null : (
              <MdExpandMore
                style={{
                  transform: 'rotate(270deg)',
                  cursor: 'pointer',
                }}
                color="white"
                size={20}
              />
            )}
          </button>
        )}

        {isOpenTour ? (
          <Tour
            id={tourId}
            stepsPreview={tourSteps}
            onClose={() => {
              setTourId('');
              setTourSteps(undefined);
            }}
          />
        ) : null}
        <div
          style={{
            height: isMobile ? '51px' : '90px',
            marginTop: isMobile ? '8px' : '16px',
          }}
        >
          &nbsp;
          {!isSidebarExpanded ? (
            <Image
              src={
                user?.whiteLabel && user?.tenantImage ? user?.tenantImage : logo
              }
              px="5px"
            />
          ) : null}
        </div>

        <Flex
          direction="column"
          gridGap="3vh"
          alignItems="center"
          justifyContent="center"
          w="100%"
          style={{ overflowY: 'auto' }}
        >
          {renderMenuItems()}
        </Flex>
        <Flex
          direction="column"
          justify="center"
          align="center"
          gridGap={'1.5vh'}
          w="100%"
          borderY={'solid 2px white'}
          py={'2.2vh'}
        >
          <Tooltip
            title={'Notificações'}
            color={colorPrimary}
            placement="right"
          >
            <Flex
              id={`id-tour-menu-icon-notifications`}
              alignItems="center"
              w="100%"
              justifyContent="center"
              style={{ cursor: 'pointer' }}
              _hover={{
                boxShadow: `inset 5px 0px 0px 0px ${colorSecondary}`,
              }}
              boxShadow={
                isDropdownOpen
                  ? `inset 5px 0px 0px 0px ${colorSecondary}`
                  : undefined
              }
              onClick={() => {
                if (isMobile && isSidebarExpanded) {
                  toggleSidebar();
                  setTimeout(() => {
                    setIsDropdownOpen(true);
                  }, 700);
                } else {
                  setIsDropdownOpen(true);
                }
              }}
            >
              <Badge count={data?.data?.total} offset={[-5, 20]} size={'small'}>
                <PiBellLight
                  color="white"
                  style={{ width: '3vh', height: '3vh' }}
                />
              </Badge>
            </Flex>
          </Tooltip>
          <Tooltip title={'Ajuda'} color={colorPrimary} placement="right">
            <Flex
              id={`id-tour-menu-icon-helpers`}
              alignItems={'center'}
              w="100%"
              justifyContent="center"
              cursor="pointer"
              _hover={{
                boxShadow: `inset 5px 0px 0px 0px ${colorSecondary}`,
              }}
            >
              <button onClick={() => setIsOpen(true)}>
                <PiQuestionLight
                  color={'white'}
                  style={{ width: '3vh', height: '3vh' }}
                />
              </button>
            </Flex>
          </Tooltip>
          <Tooltip title={'Sair'} color={colorPrimary} placement="right">
            <Flex
              id={`id-tour-menu-icon-logout`}
              alignItems={'center'}
              w="100%"
              justifyContent="center"
              cursor="pointer"
              _hover={{
                boxShadow: `inset 5px 0px 0px 0px ${colorSecondary}`,
              }}
            >
              <PiDoorOpenLight
                onClick={() => handleLogout()}
                color={'white'}
                style={{ width: '3vh', height: '3vh', cursor: 'pointer' }}
              />
            </Flex>
          </Tooltip>
        </Flex>
        <Flex>
          <Image
            src={user?.imageUrl ?? defaultProfile}
            w="36px"
            h="36px"
            rounded="10px"
            cursor="pointer"
            style={{ background: user?.imageUrl ? undefined : colorGreyCard }}
            onClick={() => {
              if (isMobile && isSidebarExpanded) {
                toggleSidebar();
                setTimeout(() => {
                  setIsUserDropdownOpen(true);
                }, 700);
              } else {
                setIsUserDropdownOpen(true);
              }
            }}
          />
        </Flex>
      </div>

      <div
        className={isSidebarExpanded ? 'right' : 'left'}
        style={{
          width: '254px',
          height: '100%',
          backgroundColor: colorPrimaryMedium,
          left: '-300px',
          zIndex: 1009,
          position: 'fixed',
          transition: '1s',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-around',
          borderRadius: '0px 20px 20px 0px',
          alignItems: 'center',
          paddingLeft: '72px',
          paddingRight: '10px',
          fontFamily: 'Lato',
        }}
      >
        <div
          style={{
            height: isMobile ? '51px' : '90px',
            marginTop: isMobile ? '8px' : '16px',
          }}
        >
          <Image
            src={
              user?.whiteLabel && user?.tenantImage ? user?.tenantImage : logo
            }
            maxW={150}
          />
        </div>

        <button
          onClick={() => {
            toggleSidebar();
            setShowHamburgerMenu(true);
          }}
          style={{
            backgroundColor: colorPrimaryMedium,
            position: 'absolute',
            right: '-10px',
            top: '100px',
            borderRadius: '50%',
          }}
        >
          {isSidebarExpanded ? (
            <MdExpandMore
              style={{
                transform: 'rotate(90deg)',
                cursor: 'pointer',
              }}
              color="white"
              size={20}
            />
          ) : null}
        </button>
        <Flex
          direction="column"
          gridGap={'3vh'}
          alignItems="center"
          justifyContent="center"
          w="100%"
        >
          {MENUS.map((item, key) =>
            !check(item.permission) ? null : (
              <Box
                key={key}
                id={`id-tour-menu-title-${key}`}
                onClick={() => {
                  history.push(item.action);
                  toggleSidebar();
                  if (isMobile) {
                    setShowHamburgerMenu(true);
                  }
                }}
                cursor="pointer"
                w="100%"
                display="flex"
                alignItems="center"
                justifyContent="start"
                pl={'10px'}
                fontSize={isMobile ? '16px' : '20px'}
                color={
                  item.isActive(currentPathname) ? colorPrimaryMedium : 'white'
                }
                fontWeight={item.isActive(currentPathname) ? '700' : '400'}
                bg={item.isActive(currentPathname) ? 'white' : undefined}
                borderRadius="20px"
                h={'3vh'}
                _hover={{
                  bg: 'white',
                  color: colorPrimaryMedium,
                  fontWeight: '700',
                }}
              >
                {item.title}
              </Box>
            )
          )}
        </Flex>

        <Flex
          direction="column"
          justify="center"
          align="center"
          gridGap={'1.5vh'}
          fontSize={isMobile ? '16px' : '20px'}
          color="white"
          fontWeight="400"
          w="100%"
          borderY={'solid 2px'}
          py={'2.2vh'}
        >
          <Box
            id={`id-tour-menu-notifications`}
            w="100%"
            display="flex"
            h={'3vh'}
            alignItems="center"
            justifyContent="start"
            pl={'10px'}
            cursor="pointer"
            bg={isDropdownOpen ? 'white' : undefined}
            color={isDropdownOpen ? colorPrimaryMedium : undefined}
            borderRadius={isDropdownOpen ? '20px' : undefined}
            fontWeight={isDropdownOpen ? 700 : 400}
            _hover={{
              bg: 'white',
              color: colorPrimaryMedium,
              borderRadius: '20px',
              fontWeight: '700',
            }}
          >
            <Dropdown
              open={isDropdownOpen}
              onOpenChange={(visible) => {
                if (isMobile) {
                  toggleSidebar();
                  setTimeout(() => {
                    setIsDropdownOpen(visible);
                  }, 700);
                } else {
                  setIsDropdownOpen(visible);
                }
              }}
              overlay={
                <NotificationBox
                  onClose={() => {
                    setIsDropdownOpen(false);
                    if (isSidebarExpanded) {
                      toggleSidebar();
                    }
                  }}
                  refetchCount={refetch}
                  isSideBarExpanded={isSidebarExpanded}
                />
              }
              trigger={['click']}
              placement="bottomRight"
            >
              <p>Notificações</p>
            </Dropdown>
          </Box>
          <Box
            onClick={() => setIsOpen(true)}
            id={`id-tour-menu-helper`}
            cursor="pointer"
            display="flex"
            alignItems="center"
            justifyContent="start"
            h={'3vh'}
            pl={'10px'}
            w="100%"
            _hover={{
              bg: 'white',
              color: colorPrimaryMedium,
              borderRadius: '20px',
              fontWeight: '700',
            }}
          >
            Ajuda
          </Box>
          <Box
            cursor="pointer"
            onClick={() => handleLogout()}
            id={`id-tour-menu-logout`}
            display="flex"
            alignItems="center"
            justifyContent="start"
            pl={'10px'}
            h={'3vh'}
            w="100%"
            _hover={{
              bg: 'white',
              color: colorPrimaryMedium,
              borderRadius: '20px',
              fontWeight: '700',
            }}
          >
            Sair
          </Box>
        </Flex>
        <Flex
          gridGap="5px"
          fontSize={isMobile ? '16px' : '20px'}
          alignItems="center"
          h="36px"
          cursor="pointer"
          bg={isUserDropdownOpen ? 'white' : undefined}
          color={isUserDropdownOpen ? colorPrimaryMedium : 'white'}
          borderRadius={isUserDropdownOpen ? '20px' : undefined}
          fontWeight={isUserDropdownOpen ? 700 : 400}
          _hover={{
            bg: 'white',
            color: colorPrimaryMedium,
            borderRadius: '20px',
            fontWeight: '700',
          }}
        >
          <Dropdown
            open={isUserDropdownOpen}
            onOpenChange={(visible) => {
              if (isMobile && isSidebarExpanded) {
                toggleSidebar();
                setTimeout(() => {
                  setIsUserDropdownOpen(visible);
                }, 700);
              } else {
                setIsUserDropdownOpen(visible);
              }
            }}
            overlay={
              <Box
                width={'194px'}
                borderRadius={'20px'}
                p={'8px'}
                bg={colorPrimaryMedium}
                display="flex"
                flexDirection="column"
                gridGap="16px"
                style={{
                  position: 'relative',
                  left:
                    !isSidebarExpanded && isMobile
                      ? '280px'
                      : isSidebarExpanded
                      ? '220px'
                      : '300px',
                  top: '40px',
                  fontFamily: 'Lato',
                  color: 'white',
                }}
              >
                <Flex
                  gridGap="16px"
                  fontSize={isMobile ? '16px' : '20px'}
                  align="center"
                  cursor="pointer"
                  _hover={{
                    borderRadius: '20px',
                    background: 'rgba(237, 129, 15, 0.15)',
                  }}
                  _active={{
                    border: `2px solid ${colorSecondary}`,
                  }}
                  onClick={() => {
                    history.push('/profile');
                    if (isSidebarExpanded) {
                      toggleSidebar();
                    }
                    if (isMobile) {
                      setShowHamburgerMenu(true);
                    }
                    setIsUserDropdownOpen(false);
                  }}
                >
                  <MdAccountCircle fontSize="25px" />
                  <p>Meu perfil</p>
                </Flex>
              </Box>
            }
            trigger={['click']}
            placement="bottomRight"
          >
            <Flex
              padding="4px"
              gridGap="5px"
              justify="center"
              align="center"
              minWidth="168px"
            >
              <p>
                {user && user?.name.length > 15
                  ? user?.name.split(' ')[0]
                  : user?.name}
              </p>
              <MdExpandMore
                style={{
                  transform: 'rotate(270deg)',
                  cursor: 'pointer',
                }}
                className="icon"
                color={isUserDropdownOpen ? colorPrimaryMedium : 'white'}
                size={20}
              />
            </Flex>
          </Dropdown>
        </Flex>
      </div>
      <div className={isOverlayVisible ? 'overlay active' : 'overlay'}></div>
    </>
  );
}

export default SideMenu;
