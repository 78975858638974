import useSeries from 'core/features/series/hooks/useSeries';
import moment from 'moment';
import GenericFilterComponent from 'components/generic-filter';
import { FilterProps } from 'infra/helpers/types';

function ClassesFilter({filters,onChangeFilters}:FilterProps) {
  const { data } = useSeries();
  let uniqueYears: any = [];
  const currentYear = moment().year();
  for (let i = 0; i < 3; i++) {
    uniqueYears.push({
      label: String(currentYear - i),
      value: String(currentYear - i),
    });
  }

  return (
    <GenericFilterComponent
      onChangeFilters={onChangeFilters}
      filters={filters}
      filterGroups={[
        { title: 'Ano Letivo', options: uniqueYears, filterName: 'years' },
        {
          title: 'Série',
          filterName: 'series',
          options: data?.map((item) => {
            return { label: item.name, value: item.id };
          }),
        },
      ]}
    />
  );
}

export default ClassesFilter;
