import { Flex, Collapse } from 'antd';
import { GenericCard } from 'components/V2/generic-card-styled-component';
import dayjs from 'dayjs';
import { useHistory } from 'react-router';
import { QuestionLog } from 'core/features/questions/types';
import React from 'react';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import Button from 'components/buttons/button';
import { useMediaQuery } from '@chakra-ui/media-query';

export function QuestionInfo({
  label,
  content,
  isChanged,
}: {
  label: string;
  content: string;
  isChanged?: boolean;
}) {
  const { colorSecondary } = useU4heroColors();
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  return (
    <Flex vertical>
      <p style={{ fontSize: isMobile ? '14px':'18px', color: 'black', fontWeight: 400 }}>
        {label}
      </p>
      <Flex
        align="center"
        style={{
          backgroundColor: '#E9E9E9',
          borderRadius: '8px',
          minHeight: '40px',
          border: isChanged ? `2px solid ${colorSecondary}` : undefined,
          padding: '8px 16px',
        }}
      >
        <p style={{ fontSize: '16px' }}>{content}</p>
      </Flex>
    </Flex>
  );
}

function HistoricCard({
  data,
  previousData,
}: {
  data: QuestionLog;
  previousData?: QuestionLog;
}) {
  const getComprehensionLevel = (value: number) => {
    let response;
    switch (true) {
      case value === 0:
        response = 'Muito Baixa';
        break;
      case value <= 25:
        response = 'Baixa';
        break;
      case value <= 50:
        response = 'Moderada';
        break;
      case value <= 75:
        response = 'Boa';
        break;
      case value <= 100:
        response = 'Muito Boa';
        break;
      default:
        response = 'Inválido';
    }

    return response;
  };
  const history = useHistory();

  const allScopes: { [key: number]: string } = {
    0: 'Privada',
    1: 'Pública',
    2: 'Global',
  };
  const questionTypes: { [key: number]: string } = {
    0: 'Atividade',
    1: 'Avaliação inicial',
    2: 'Avaliação',
    3: 'Situacional',
  };
  const analysisTypes: { [key: number]: string } = {
    0: 'Conhecimento',
    1: 'Comportamento',
    2: 'Percepção',
  };
  const environments: { [key: number]: string } = {
    0: 'Familiar',
    1: 'Escolar',
    2: 'Digital',
    3: 'Social',
  };

  const getItems = (data: QuestionLog) => {
    const ordinal = ['Primeira', 'Segunda', 'Terceira', 'Quarta', 'Quinta'];
    return [
      {
        key: '1',
        label: (
          <Flex style={{ width: '100%' }} align={'start'} vertical>
            <p
              style={{
                fontWeight: 600,
                color: data.type === 'Edit' ? '#ED810F' : '#336732',
                fontSize: '18px',
              }}
            >
              {data.userName}
            </p>
            <p
              style={{
                fontWeight: 600,
                color: data.type === 'Edit' ? '#ED810F' : '#336732',
                fontSize: '18px',
              }}
            >{`${data.type === 'Edit' ? 'Editou' : 'Criou'} em ${dayjs(
              data.createdAt
            ).format('DD/MM/YYYY HH[h]mm')}`}</p>
          </Flex>
        ),
        children: (
          <Flex gap={5} style={{ padding: '10px' }} vertical>
            <QuestionInfo
              label="Competência"
              content={data.log.competence.name}
              isChanged={
                previousData !== undefined &&
                data.log.competence.id !== previousData?.log?.competence?.id
              }
            />
            <QuestionInfo
              label="Habilidade"
              content={data.log.skill.name}
              isChanged={
                previousData !== undefined &&
                data.log.skill.id !== previousData?.log.skill.id
              }
            />
            <QuestionInfo
              label="Tipo de Análise"
              content={analysisTypes[data.log.question.analysisTypeId]}
              isChanged={
                previousData !== undefined &&
                data.log.question.analysisTypeId !==
                  previousData?.log.question.analysisTypeId
              }
            />
            <QuestionInfo
              label="Ambiente de Análise"
              content={environments[data.log.question.analysisEnvironmentId]}
              isChanged={
                previousData !== undefined &&
                data.log.question.analysisEnvironmentId !==
                  previousData?.log.question.analysisEnvironmentId
              }
            />
            <QuestionInfo
              label="Faixa Etária"
              content={`${data.log.question.minAge} - ${data.log.question.maxAge}`}
              isChanged={
                previousData !== undefined &&
                (data.log.question.minAge !==
                  previousData?.log.question.minAge ||
                  data.log.question.maxAge !==
                    previousData?.log.question.maxAge)
              }
            />
            <QuestionInfo
              label="Enunciado"
              content={data.log.question.text}
              isChanged={
                previousData !== undefined &&
                data.log.question.text !== previousData?.log.question.text
              }
            />
            {data.log.options.map((option, index: number) => (
              <React.Fragment key={option.id}>
                <QuestionInfo
                  label={`${ordinal[index]} alternativa`}
                  content={option.text}
                  isChanged={
                    previousData !== undefined &&
                    option.text !== previousData?.log.options?.[index]?.text
                  }
                />
                <QuestionInfo
                  label="Grau de Compreensão"
                  content={getComprehensionLevel(option.value)}
                  isChanged={
                    previousData !== undefined &&
                    option.value !== previousData?.log.options?.[index]?.value
                  }
                />
                <QuestionInfo
                  label="Hipótese"
                  content={option.hypothesis}
                  isChanged={
                    previousData !== undefined &&
                    option.hypothesis !==
                      previousData?.log.options?.[index]?.hypothesis
                  }
                />
                <QuestionInfo
                  label="Sugestão"
                  content={option.orientation}
                  isChanged={
                    previousData !== undefined &&
                    option.orientation !==
                      previousData?.log.options?.[index]?.orientation
                  }
                />
              </React.Fragment>
            ))}
            <QuestionInfo
              label="Tipo de Pergunta"
              content={questionTypes[data.log.question.typeId]}
              isChanged={
                previousData !== undefined &&
                data.log.question.typeId !== previousData?.log.question.typeId
              }
            />
            <QuestionInfo
              label="Escopo"
              content={allScopes[data.log.question.privacyId]}
              isChanged={
                previousData !== undefined &&
                data.log.question.privacyId !==
                  previousData?.log.question.privacyId
              }
            />
            <Button
              variant="outline"
              size='md'
              style={{ alignSelf: 'center' }}
              onClick={() => history.push(`/question/edit/${data.questionId}`)}
            >
              Editar
            </Button>
          </Flex>
        ),
      },
    ];
  };

  return (
    <Flex style={{ width: '100%' }}>
      {data.type !== 'Disable' && data.type !== 'Enable' ? (
        <GenericCard width={'100%'} padding={'none'}>
          <Collapse
            style={{ width: '100%' }}
            expandIconPosition={'end'}
            ghost
            items={getItems(data)}
          />
        </GenericCard>
      ) : (
        <GenericCard width={'100%'}>
          <Flex vertical>
            <p
              style={{
                fontWeight: 600,
                color: data.type === 'Disable' ? '#BB191E' : '#336732',
              }}
            >
              {data.userName}
            </p>
            <p
              style={{
                fontWeight: 600,
                color: data.type === 'Disable' ? '#BB191E' : '#336732',
              }}
            >{`${data.type === 'Disable' ? 'Desativou' : 'Ativou'} em ${dayjs(
              data.createdAt
            ).format('DD/MM/YYYY HH[h]mm')}`}</p>
          </Flex>
        </GenericCard>
      )}
    </Flex>
  );
}

export default HistoricCard;
