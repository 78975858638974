import { Image } from '@chakra-ui/image';
import safeSpace from 'assets/images/icons/MeuCantinhoBrancoContorno.svg';
import { Permissions } from '../../../core/resources/enums/permissions';
import {
  MdOutlineBarChart,
  MdOutlineDashboard,
  MdOutlinePieChart,
} from 'react-icons/md';
import {
  PiHouseLineLight,
  PiPaletteLight,
  PiStudentLight,
  PiSpeedometerLight,
  PiLetterCirclePLight,
  PiQuestionLight,
  PiBrainLight,
  PiGameControllerLight,
  PiChartBarLight,
  PiBuildingApartmentLight,
  PiLightbulbFilamentLight,
  PiClipboardTextLight,
  PiProjectorScreenChartLight,
  PiTreasureChestLight,
  PiGearLight,
} from 'react-icons/pi';

export interface Menu {
  title: string;
  icon: () => JSX.Element;
  action: string;
  isActive: (pathname: string) => boolean;
  permission: string;
  tabs?: any;
  feature?: string;
}



export const MENUS: Menu[] = [
  {
    title: 'Início',
    icon: () => (
      <PiHouseLineLight color="white" style={{ width: '3vh', height: '3vh' }} />
    ),
    action: '/',
    isActive: (pathname) => pathname === '/',
    permission: Permissions.U4hero.Dashboard.View,
  },
  {
    title: 'Início',
    icon: () => (
      <PiHouseLineLight color="white" style={{ width: '3vh', height: '3vh' }} />
    ),
    action: '/',
    isActive: (pathname) => pathname === '/',
    permission: Permissions.Dashboard,
  },
  {
    title: 'Início',
    icon: () => (
      <PiHouseLineLight color="white" style={{ width: '3vh', height: '3vh' }} />
    ),
    action: '/',
    isActive: (pathname) => pathname === '/',
    permission: Permissions.Org.Dashboard.View,
  },
  {
    title: 'Métricas',
    icon: () => (
      <PiSpeedometerLight
        color="white"
        style={{ width: '3vh', height: '3vh' }}
      />
    ),
    action: '/metrics',
    isActive: (pathname) => pathname.includes('/metrics'),
    permission: Permissions.U4hero.Metrics.View,
  },
  {
    title: 'Perguntas',
    icon: () => (
      <PiLetterCirclePLight
        color="white"
        style={{ width: '3vh', height: '3vh' }}
      />
    ),
    action: '/question',
    isActive: (pathname) => pathname.includes('/questions'),
    permission: Permissions.U4hero.Questions.View,
  },
  {
    title: 'Minha escola',
    icon: () => (
      <PiStudentLight color="white" style={{ width: '3vh', height: '3vh' }} />
    ),
    action: '/my-school',
    isActive: (pathname) => pathname.includes('/my-school'),
    permission: Permissions.Tenant.View,
  },
  {
    title: 'Organização',
    icon: () => (
      <PiStudentLight color="white" style={{ width: '3vh', height: '3vh' }} />
    ),
    action: '/my-school',
    isActive: (pathname) => pathname.includes('/my-school'),
    permission: Permissions.Org.Tenant.View,
  },
  {
    title: 'Jogos',
    icon: () => (
      <PiGameControllerLight
        color="white"
        style={{ width: '3vh', height: '3vh' }}
      />
    ),
    action: '/games',
    isActive: (pathname) => pathname.includes('/games'),
    permission: Permissions.Games.View,
  },
  {
    title: 'Jogos',
    icon: () => (
      <PiGameControllerLight
        color="white"
        style={{ width: '3vh', height: '3vh' }}
      />
    ),
    action: '/games-u4hero',
    isActive: (pathname) => pathname.includes('/games-u4hero'),
    permission: Permissions.U4hero.Games.View,
  },
  {
    title: 'Perguntas',
    icon: () => (
      <PiLetterCirclePLight
        color="white"
        style={{ width: '3vh', height: '3vh' }}
      />
    ),
    action: '/question',
    isActive: (pathname) => pathname.includes('/question'),
    permission: Permissions.CustomQuestion.View,
  },
  {
    title: 'Atividades',
    icon: () => (
      <PiClipboardTextLight
        color="white"
        style={{ width: '3vh', height: '3vh' }}
      />
    ),
    action: '/activities',
    isActive: (pathname) => pathname.includes('/activities'),
    permission: Permissions.Activity.View,
  },
  {
    title: 'Visão Geral',
    icon: () => (
      <MdOutlineDashboard
        color="white"
        style={{ width: '3vh', height: '3vh' }}
      />
    ),
    action: '/inkluzen/macro-view',
    isActive: (pathname) => pathname.includes('/inkluzen/macro-view'),
    permission: Permissions.Inkluzen.Macro.View,
  },
  {
    title: 'Explorar',
    icon: () => (
      <MdOutlineBarChart
        color="white"
        style={{ width: '3vh', height: '3vh' }}
      />
    ),
    action: '/inkluzen/exploration-view',
    isActive: (pathname) => pathname.includes('/inkluzen/exploration-view'),
    permission: Permissions.Inkluzen.Geographic.View,
  },
  {
    title: 'Detalhes',
    icon: () => (
      <MdOutlinePieChart
        color="white"
        style={{ width: '3vh', height: '3vh' }}
      />
    ),
    action: '/inkluzen/students',
    isActive: (pathname) => pathname.includes('/inkluzen/students'),
    permission: Permissions.Inkluzen.Student.View,
  },
  {
    title: 'Meu cantinho',
    icon: () => <Image w="3vh" src={safeSpace} />,
    action: '/safe-space',
    isActive: (pathname) => pathname.includes('/safe-space'),
    permission: Permissions.SafeSpace.View,
    feature: 'Meu cantinho',
  },
  {
    title: 'Indicadores',
    icon: () => (
      <PiChartBarLight color="white" style={{ width: '3vh', height: '3vh' }} />
    ),
    action: '/indicators',
    isActive: (pathname) => pathname.includes('/indicators'),
    permission: Permissions.Indicators.View,
  },
  {
    title: 'Indicadores',
    icon: () => (
      <PiChartBarLight color="white" style={{ width: '3vh', height: '3vh' }} />
    ),
    action: '/indicators',
    isActive: (pathname) => pathname.includes('/indicators'),
    permission: Permissions.Org.Indicators.View,
  },
  {
    title: 'Relatórios',
    icon: () => (
      <PiProjectorScreenChartLight
        color="white"
        style={{ width: '3vh', height: '3vh' }}
      />
    ),
    action: '/reports',
    isActive: (pathname) => pathname.includes('/reports'),
    permission: Permissions.Report.View,
  },
  {
    title: 'Ajuda',
    icon: () => (
      <PiQuestionLight color="white" style={{ width: '3vh', height: '3vh' }} />
    ),
    action: '/helpers-u4hero',
    isActive: (pathname) => pathname.includes('/helpers-u4hero'),
    permission: Permissions.U4hero.Helpers.Edit,
  },
  {
    title: 'Competências',
    icon: () => (
      <PiBrainLight color="white" style={{ width: '3vh', height: '3vh' }} />
    ),
    action: '/competences',
    isActive: (pathname) => pathname.includes('/competences'),
    permission: Permissions.U4hero.Competences.Edit,
  },
  {
    title: 'Instituições',
    icon: () => (
      <PiBuildingApartmentLight
        color="white"
        style={{ width: '3vh', height: '3vh' }}
      />
    ),
    action: '/schools?tab=0',
    isActive: (pathname) => pathname.includes('/schools'),
    permission: Permissions.U4hero.Tenant.Edit,
  },
  {
    title: 'Instituições',
    icon: () => (
      <PiBuildingApartmentLight
        color="white"
        style={{ width: '3vh', height: '3vh' }}
      />
    ),
    action: '/schools?tab=0',
    isActive: (pathname) => pathname.includes('/schools'),
    permission: Permissions.Org.Tenant.Edit,
  },
  {
    title: 'Inspirações',
    icon: () => (
      <PiLightbulbFilamentLight
        color="white"
        style={{ width: '3vh', height: '3vh' }}
      />
    ),
    action: '/practices',
    isActive: (pathname) => pathname.includes('/practices'),
    permission: Permissions.Practices.View,
  },
  {
    title: 'Inspirações',
    icon: () => (
      <PiLightbulbFilamentLight
        color="white"
        style={{ width: '3vh', height: '3vh' }}
      />
    ),
    action: '/practices',
    isActive: (pathname) => pathname.includes('/practices'),
    permission: Permissions.U4hero.Practices.View,
  },
  {
    title: 'Publicações',
    icon: () => <PiPaletteLight color="white" size={'3vh'} />,
    action: '/posts-hub',
    isActive: (pathname) => pathname.includes('posts'),
    permission: Permissions.U4hero.Posts.View,
  },
  {
    title: 'Loja',
    icon: () => (
      <PiTreasureChestLight
        color="white"
        style={{ width: '3vh', height: '3vh' }}
      />
    ),
    action: '/manager-store',
    isActive: (pathname) => pathname.includes('/manager-store'),
    permission: Permissions.U4hero.Posts.View,
  },
  {
    title: 'Configurações',
    icon: () => (
      <PiGearLight color="white" style={{ width: '3vh', height: '3vh' }} />
    ),
    action: '/settings-u4hero?tab=1',
    isActive: (pathname) => pathname.includes('/settings-u4hero'),
    permission: Permissions.U4hero.Tenant.Edit,
  },
  /*
  {
    title: "Configurações",
    icon: () => <Image w="25px" src={jogosImage} />,
    action: "/settings",
    isActive: (pathname) => pathname.includes("/settings"),
    permission: Permissions.Settings
  },*/
];
