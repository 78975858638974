import React, { useState } from 'react';
import { FloatButton, Dropdown, Flex } from 'antd';
import {
  PlusOutlined,
  FileTextOutlined,
  BookOutlined,
  UsergroupAddOutlined,
  ReadOutlined,
} from '@ant-design/icons';
import type { MenuProps } from 'antd';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import { useHistory } from 'react-router';
import { useCurrentUser } from 'core/features/user/store';
import competenceIcon from 'assets/images/icons/competence.svg';

import './style.css';

function FloatButtonComponent() {
  const { colorPrimary } = useU4heroColors();

  const currentUser = useCurrentUser();

  const history = useHistory();
  const [clicked, setClicked] = useState<boolean>(false);

  const handleClick = () => {
    setClicked(!clicked);
  };

  const handlePageChange = (url: string) => {
    setClicked(false);
    history.push(url);
  };

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <Flex
          onClick={() => handlePageChange('/reports?tab=1')}
          style={{ padding: 8 }}
          className="menuItem"
          gap={8}
        >
          <FileTextOutlined className="icon" />
          <p>Relatórios</p>
        </Flex>
      ),
    },
    {
      key: '2',
      label: (
        <Flex
          onClick={() => handlePageChange('/my-school/students?new')}
          style={{ padding: 8 }}
          className="menuItem"
          gap={8}
        >
          <BookOutlined className="icon" />
          <p>Criar estudante</p>
        </Flex>
      ),
    },
    {
      key: '3',
      label: (
        <Flex
          onClick={() => handlePageChange('/my-school/classes/create')}
          style={{ padding: 8 }}
          className="menuItem"
          gap={8}
        >
          <UsergroupAddOutlined />
          <p>Criar turma</p>
        </Flex>
      ),
    },
    {
      key: '4',
      label: (
        <Flex
          onClick={() => handlePageChange('/activities/create/class')}
          style={{ padding: 8 }}
          className="menuItem"
          gap={8}
        >
          <ReadOutlined className="icon" />
          <p>Criar atividade</p>
        </Flex>
      ),
    },
    {
      key: '5',
      label: (
        <Flex
          onClick={() => handlePageChange('/tags')}
          style={{ padding: 8 }}
          className="menuItem"
          gap={8}
        >
          <img src={competenceIcon} width={'16px'} className="icon" />
          <p style={{wordBreak:'break-word',whiteSpace:'break-spaces'}}>Campos de Conhecimentos e Habilidades</p>
        </Flex>
      ),
    },
  ];

  return (
    <div>
      {currentUser.user?.tenantName !== 'U4HERO' &&
        currentUser.user?.tenantName !== 'Inkluzen' && (
          <div>
            <Dropdown
              onOpenChange={() => setClicked(false)}
              trigger={['click']}
              menu={{ items: items }}
              placement="top"
              dropdownRender={(menu) => (
                <div style={{ backgroundColor: 'transparent', width: '200px' }}>
                  {React.cloneElement(menu as React.ReactElement, {
                    style: { backgroundColor: colorPrimary },
                  })}
                </div>
              )}
            >
              <FloatButton
                className={`floatButton ${clicked && 'rotate'}`}
                onClick={handleClick}
                icon={<PlusOutlined />}
                type="primary"
              />
            </Dropdown>
          </div>
        )}
    </div>
  );
}

export default FloatButtonComponent;
