import { useMediaQuery } from '@chakra-ui/media-query';
import { Col, Flex, Row, Typography } from 'antd';
import ClockIcon from 'components/icons/ClockIcon';
import ResourcesIcon from 'components/icons/ResoucesIcon';
import Star from 'components/star';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import { IoClose } from 'react-icons/io5';
const moment = require('moment');
require('moment/locale/pt-br');

const { Title } = Typography;

function PracticePreview({
  banner,
  duration,
  author,
  resources,
  title,
  maxAge,
  goal,
  content,
  skillName,
  competenceName,
  subject,
  modality,
  environment,
  target,
  onClose,
}: {
  banner?: string;
  duration?: number;
  maxAge?: number;
  author?: string;
  resources?: string;
  title?: string;
  goal?: string;
  content: string;
  skillName: string;
  competenceName: string;
  subject: string;
  modality: string;
  environment: string;
  target: string;
  onClose: () => void;
}) {
  const { colorPrimary, colorGreyBorderAndFont, colorSecondary } =
    useU4heroColors();
  const [isMobile] = useMediaQuery('(max-width: 768px)');

  const currentDate = moment();
  currentDate.locale('pt-br');

  return (
    <div style={{ padding: isMobile ? '0px' : '16px' }}>
      {!isMobile ? (
        <>
          <Flex justify="flex-end" style={{ cursor: 'pointer' }}>
            <IoClose
              size={24}
              color={colorGreyBorderAndFont}
              onClick={onClose}
            />
          </Flex>
          <Title
            level={1}
            style={{
              color: colorPrimary,
              fontSize: isMobile ? '24px' : '28px',
              fontWeight: 700,
              margin: '0px',
              marginBottom: '16px',
            }}
          >
            Prévia
          </Title>
        </>
      ) : null}

      <div style={{ position: 'relative' }}>
        <img
          alt={`banner ${title}`}
          src={banner}
          style={{
            borderRadius: '10px',
            width: '100%',
            height: isMobile ? '156px' : '275px',
            objectFit: 'cover',
          }}
        />
        <Flex
          style={{
            width: '100%',
            position: 'relative',
            bottom: isMobile ? 18 : 10,
          }}
          justify="center"
        >
          <div
            style={{
              borderRadius: '5px 5px 0px 0px',
              fontSize: isMobile ? '12px' : '16px',
              paddingLeft: isMobile ? '14px' : '16px',
              paddingRight: isMobile ? '14px' : '16px',
              background: colorSecondary,
              fontWeight: 700,
              color: 'white',
              textTransform: 'uppercase',
            }}
          >
            {target} - {modality}
          </div>
        </Flex>
        {!isMobile ? (
          <div style={{ position: 'absolute', bottom: -10, left: -10 }}>
            <Star color={colorPrimary} size={'70px'} fontSize="16px">
              {environment}
            </Star>
          </div>
        ) : (
          <div
            style={{
              borderRadius: '10px 5px 5px 0px',
              fontSize: isMobile ? '12px' : '16px',
              position: 'absolute',
              paddingLeft: isMobile ? '14px' : '16px',
              paddingRight: isMobile ? '14px' : '16px',
              background: colorPrimary,
              color: 'white',
              top: 0,
            }}
          >
            {environment}
          </div>
        )}
      </div>

      <p
        style={{
          fontSize: isMobile ? '14px' : '18px',
          color: colorGreyBorderAndFont,
          textAlign: 'end',
        }}
      >
        {currentDate.format('DD [de] MMMM [de] YYYY')}
        <br />
        Autor: {author}
      </p>
      {!isMobile ? (
        <Title
          level={2}
          style={{
            color: colorPrimary,
            fontSize: isMobile ? '18px' : '24px',
            fontWeight: 700,
            textAlign: 'center',
            margin: '0px',
            marginBottom: '8px',
          }}
        >
          {title}
        </Title>
      ) : null}

      <Row gutter={[24, 0]}>
        <Col span={isMobile ? 24 : 4}>
          <Flex
            vertical={!isMobile}
            justify={isMobile ? 'flex-start' : 'center'}
            align="center"
            gap="8px"
            style={{ marginBottom: isMobile ? '8px' : '' }}
          >
            <ClockIcon size={isMobile ? 20 : 36} color={colorPrimary} />
            <p
              style={{
                fontSize: isMobile ? 14 : 16,
                color: colorPrimary,
                fontWeight: 600,
                textAlign: 'center',
              }}
            >
              Duração:{' '}
              <span
                style={{
                  color: 'black',
                  fontWeight: 400,
                }}
              >
                {duration} minutos
              </span>
            </p>
          </Flex>
          <Flex
            vertical={!isMobile}
            justify={isMobile ? 'flex-start' : 'center'}
            align="center"
            gap="8px"
            style={{ marginBottom: isMobile ? '4px' : '' }}
          >
            <ResourcesIcon size={isMobile ? 20 : 36} color={colorPrimary} />
            <p
              style={{
                fontSize: isMobile ? 14 : 16,
                color: colorPrimary,
                fontWeight: 600,
                textAlign: isMobile ? 'start' : 'center',
              }}
            >
              Recursos:
              <span
                style={{
                  color: 'black',
                  fontWeight: 400,
                }}
              >
                {' '}
                {resources}
              </span>
            </p>
          </Flex>
          {isMobile ? (
            <Title
              level={2}
              style={{
                color: colorPrimary,
                fontSize: isMobile ? '18px' : '24px',
                fontWeight: 700,
                textAlign: 'center',
                margin: '0px',
                marginBottom: '8px',
                marginTop: '4px',
              }}
            >
              {title}
            </Title>
          ) : null}
        </Col>
        <Col span={isMobile ? 24 : 20}>
          <Flex
            vertical
            gap="8px"
            style={{ marginBottom: isMobile ? '16px' : '24px' }}
          >
            <Flex>
              <p
                style={{
                  fontSize: isMobile ? 14 : 18,
                  color: colorPrimary,
                  fontWeight: 600,
                }}
              >
                Competência:{' '}
                <span
                  style={{
                    color: 'black',
                    fontWeight: 400,
                  }}
                >
                  {competenceName}
                </span>
              </p>
            </Flex>
            {skillName ? (
              <Flex>
                <p
                  style={{
                    fontSize: isMobile ? 14 : 18,
                    color: colorPrimary,
                    fontWeight: 600,
                  }}
                >
                  Habilidade:{' '}
                  <span
                    style={{
                      color: 'black',
                      fontWeight: 400,
                    }}
                  >
                    {skillName}
                  </span>
                </p>
              </Flex>
            ) : null}

            <Flex>
              <p
                style={{
                  fontSize: isMobile ? 14 : 18,
                  color: colorPrimary,
                  fontWeight: 600,
                }}
              >
                Faixa Etária:{' '}
                <span
                  style={{
                    color: 'black',
                    fontWeight: 400,
                  }}
                >
                  {maxAge} anos +
                </span>
              </p>
            </Flex>
            <Flex>
              <p
                style={{
                  fontSize: isMobile ? 14 : 18,
                  color: colorPrimary,
                  fontWeight: 600,
                }}
              >
                Disciplina Relacionada:{' '}
                <span
                  style={{
                    color: 'black',
                    fontWeight: 400,
                  }}
                >
                  {subject}
                </span>
              </p>
            </Flex>
            <Flex>
              <p
                style={{
                  fontSize: isMobile ? 14 : 18,
                  color: colorPrimary,
                  fontWeight: 600,
                }}
              >
                Objetivo{' '}
                <span
                  style={{
                    color: 'black',
                    fontWeight: 400,
                  }}
                >
                  {goal}
                </span>
              </p>
            </Flex>
          </Flex>
          <div
            dangerouslySetInnerHTML={{
              __html: content ?? '',
            }}
          />
        </Col>
      </Row>
    </div>
  );
}

export default PracticePreview;
