import { useMediaQuery } from '@chakra-ui/react';
import { Flex } from 'antd';
import Layout from 'components/layout';
import useCheckPermissions from 'core/features/user/hooks/useCheckPermissions';
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Permissions } from 'core/resources/enums/permissions';
import ActivitiesTable from './components/ActivitiesTable';
import ModalProgress from 'components/modals/modal-progress';
import BackButton from 'components/buttons/back-button';
import Button from 'components/buttons/button';
import Title from 'components/typography/title';

function Activities() {
  const history = useHistory();
  const [check] = useCheckPermissions();
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  let location = useLocation();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [id, setId] = useState('');

  const types: {
    [key: string]: {
      label: string;
      permission: string;
      link: string;
    };
  } = {
    '/activities/individual': {
      label: 'Atividade individual',
      permission: Permissions.Activity.Create,
      link: '/activities/create/individual',
    },
    '/activities/class': {
      label: 'Atividade da turma',
      permission: Permissions.Activity.Create,
      link: '/activities/create/class',
    },
    '/activities/self-avaliation': {
      label: 'Avaliação inicial',
      permission: Permissions.SelfAvaliation.Create,
      link: '/activities/create/self-avaliation',
    },
    '/activities/avaliation': {
      label: 'Avaliação da turma',
      permission: Permissions.Avaliation.Create,
      link: '/activities/create/avaliation',
    },
    '/activities/individual-avaliation': {
      label: 'Avaliação individual',
      permission: Permissions.Avaliation.Create,
      link: '/activities/create/individual-avaliation',
    },
  };

  let pathname = location.pathname.endsWith('/')
    ? location.pathname.slice(0, -1)
    : location.pathname;
  const name = types[pathname]?.label;

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    if (location.search.includes('id')) {
      setId(queryParams.get('id') as string);
      setIsOpenModal(true);
    }
  }, [location]);

  return (
    <Layout.Container>
      {isOpenModal ? (
        <ModalProgress
          activityId={id}
          onClose={() => {
            setIsOpenModal(false);
            setId('');
          }}
        />
      ) : null}
      <Flex gap={isMobile ? '12px' : '24px'} align="center">
        <BackButton onClick={() => history.push('/activities')} />
        <Title primary>{name}</Title>
      </Flex>
      <Flex
        gap="24px"
        justify="end"
        style={{ marginBottom: isMobile ? '8px' : '16px' }}
      >
        {check([types[pathname].permission]) ? (
          <Button
            size="md"
            onClick={() => history.push(types[pathname].link)}
            id="id-tour-activities-button-add"
          >
            CRIAR {name.includes('Atividade') ? 'ATIVIDADE' : 'AVALIAÇÃO'}
          </Button>
        ) : null}
      </Flex>

      <ActivitiesTable
        onEdit={(v) => {
          history.push(
            `/activities/edit/${
              v.typeId === 0
                ? 'class'
                : v.typeId === 1
                ? 'self-avaliation'
                : v.typeId === 2
                ? 'avaliation'
                : v.typeId === 3
                ? 'individual'
                : 'individual-avaliation'
            }/${v.id}`
          );
        }}
        onProgress={(v) => {
          setId(v.id);
          setIsOpenModal(true);
        }}
      />
    </Layout.Container>
  );
}

export default Activities;
