import { translate } from '../polyglot';

export default translate('es', {
  app: {
    idadeMinima: 'Edad mínima',
    idadeMaxima: 'Edad máxima',
    genero: 'Género',
    tempoMedio: 'Tiempo medio',
    objetivoLudico: 'Propósito Lúdico',
    objetivoPedagogico: 'Objetivo pedagógico',
    competencias: 'Habilidades',
    questoes: 'Preguntas',
    conteudos: 'Contenido',
    conteudo: 'Contenido',
    remover: 'Para eliminar',
    emocionometro: 'Emocionómetro',
    indicadores_gerais: 'Indicadores Gerais',
    indicadores_avancados: 'Indicadores avanzados',
    rankJogadores: 'Rango de jugador',
    detalhes: 'Detalles',
    modelo: 'Modelo',
    cancelar: 'Cancelar',
    importar: 'Importar',
    importacao: 'Importar',
    ver_mais: 'Ver más',
    historico: 'Histórico',
    recomendacao: 'Recomendación',
    adicionar: 'Para agregar',
    maximo: 'Máximo',
    minimo: 'Mínimo',
    escola: 'Colegio',
    escolas: 'Escuelas',
    alunos: 'Alumnos',
    aluno: 'Alumno',
    serie: 'Serie',
    series: 'Series',
    turma: 'Clase',
    turmas: 'Clases',
    imagem: 'Imagem',
    filtro: 'Filtrar',
    filtrar: 'Filtrar',
    imprimir: 'Imprimir',
    enviarEmail: 'Enviar',
    comentarios: 'Comentarios',
    comentar: 'Comentario',
    colaboradores: 'Colaboradores',
    colaborador: 'Colaborator',
    grafico: 'Gráfico',
    grafico_geral: 'Gráficos generales',
    sair: 'Fuera',
    configuracoes: 'Ajustes',
    nome: 'Nombre',
    opcoes: 'Opciones',
    editar: 'Para editar',
    desativar: 'Desactivar',
    ativar: 'Activar',
    ativo: 'Activo',
    inativo: 'Inactivo',
    aviso: 'Advertencia',
    sucesso: 'Éxito',
    erro: 'Error',
    portugues: 'Portugués',
    ingles: 'Inglés',
    espanhol: 'Español',
    criar: 'Crear',
    entrar: 'iniciar sesión',
    facaBusca: 'Buscar',
    voltar: 'Vuelve',
    salvar: 'Guardar',
    documento: 'Documento',
    telefone: 'Teléfono',
    site: 'Site',
    emailAdmin: 'Correo electrónico del director',
    nomeAdmin: 'Nombre del director',
    pais: 'País',
    endereco: 'Direccíon',
    adicionarTurma: 'Agregar clase',
    cidade: 'Ciudad',
    bairro: 'Barrio',
    estado: 'Estado',
    rua: 'Calle',
    complemento: 'Complemento',
    numero: 'Número',
    cep: 'Código Postal',
    matutino: 'Mañana',
    vespertino: 'Noche',
    noturno: 'Nocturno',
    periodo: 'Período',
    excluir: 'Eliminar',
    perfil: 'Perfil',
    usuario: 'Usuário',
    senha: 'Contraseña',
    acessoTurmas: 'Acceso a clases',
    idade: 'Años',
    anos: 'Años',
    dataNascimento: 'Fecha de nacimiento',
    nomePai: 'Nombre del Padre',
    nomeMae: 'Nombre de la madre',
    limpar: 'Limpiar',
    aplicar: 'Aplicar',
    relatorio: 'Reporte',
    relatorios: 'Reportes',
    relatorioCompleto: 'Reporte completo',
    sexo: 'Sexo',
    feminino: 'Feminino',
    masculino: 'Masculino',
    esqueciSenha: 'Olvide mi contraseña',
    voltarParaLogin: 'Atrás para iniciar sesión',
    recuperarSenha: 'Recuperar contraseña',
    jogos: 'Juegos',
    jogo: 'Juego',
    aventura: 'Aventuras',
    exploracao: 'Exploración',
    miniquest: 'Miniquest',
    rpg: 'RPG',
    capitulo: 'Capítulo',
    capitulos: 'Capítulos',
    tipo: 'Tipo',
    perguntas: 'Preguntas',
    licenca: 'Licencia',
    descricao: 'Descripción',
    hipotese: 'hipótesis',
    orientacao: 'Orientación',
    resposta: 'Responder',
    pergunta: 'Pregunta',
    tag: 'Tag',
    tag_pai: 'Tag padre',
    jogoEmocoes: 'Juego de emociones',
    graficoEmocoes: 'Gráfico de emociones',

    autoGestao: 'Autogestion',
    autoconsciencia: 'Autoconciencia',
    habilidadesRelacionamento: 'Habilidades de relación',
    conscienciaSocial: 'Conciencia Social',
    tomadaDecisaoResponsavel: 'Decisión Responsable',
    graficoSocioemocional: 'Gráfico Socioemocional',
    socioemocional: 'socioemocional',

    nivemCompreensao: 'Nivel de comprensión',
    otimaCompreensao: 'Gran entendimiento',
    boaCompreensao: 'Buena comprensión',
    razoavelCompreensao: 'Comprensión razonable',
    poucaCompreensao: 'Poca comprensión',
    graficoBullying: 'Bullying gráfico',

    memoriaTrabalho: 'Memoria de trabajo',
    flexibilidadeCognitiva: 'Flexibilidad cognitiva',
    controleInibitorio: 'Control inhibitorio',
    legenda: 'Subtitular',
    baixoControleInibitorio: 'Control inhibitorio bajo',
    medioControleInibitorio: 'Control inhibitorio medio',
    altoControleInibitorio: 'Alto control inhibitorio',

    entendimentoTurma: 'Comprensión de clase',
    entendimentoSerie: 'Entendiendo la serie',
    entendimentoAluno: 'Comprensión del estudiante',

    graficoFuncoesExecutivas: 'Gráfico de Funciones Ejecutivas',
    mediaCliques10Segundos: 'Clics promedio cada 10 segundos',

    acimaDe20: 'Por encima de 20',
    de15a20: 'de 15 a 20',
    ate10: 'Hasta 10',

    graficoHumor: 'Gráfico de estado de ánimo',
    nivelCompreensao: 'Nivel de comprensión',

    'Estou feliz': 'Estoy feliz',
    'Estou com medo': 'Estoy asustado',
    'Estou triste': 'Estoy triste',
    'Estou com raiva': 'Estoy enojado',
    'Estou com nojo': 'Estoy disgustado',

    configuracao: 'Configuración',
    editar_perfil: 'Editar perfil',
    inicial: 'Inicial',
    encerramento: 'Clausura',
    investigacao: 'Investigación',

    Admin: 'Administrador(a)',
    Teacher: 'Profesor',

    morning: 'Mañana',
    evening: 'Noche',
    afternoon: 'Vespertino',
    nocturnal: 'Nocturno',
    organizacao: 'Organización',
  },
});
