import useSeries from 'core/features/series/hooks/useSeries';
import { TfiInfo } from 'react-icons/tfi';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import useSerie from 'core/features/series/hooks/useSerie';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import useQuestions from 'core/features/questions/hooks/useQuestions';
import { Pluralizar } from 'infra/helpers/plural';
import { useEffect, useState } from 'react';
import useAllClassesDetails from 'core/features/classes/hooks/useAllClassesDetails';
import { useMediaQuery } from '@chakra-ui/react';
import { GoAlertFill } from 'react-icons/go';
import './style.css';
import { Flex, Form, Input, Popover, Radio, Select } from 'antd';

function GeneralDataTab() {
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const form = useFormInstance();
  const [isExpired, setIsExpired] = useState(false);
  const params = useParams<{ id: string; mode: string }>();
  const { data: allSeries } = useSeries();
  const { colorPrimary } = useU4heroColors();
  const { id } = useParams<{ id: string }>();
  const { data: serie } = useSerie(Form.useWatch('serieId', form));
  const { data: allClassesDetails } = useAllClassesDetails(id);
  const { data } = useQuestions({
    page: 1,
    types: ['1'],
    active: true,
    tenantId: params.id,
    minAge: serie ? serie.minAge : undefined,
    maxAge: serie ? serie.maxAge : undefined,
  });

  useEffect(() => {
    const createdDate = moment(allClassesDetails?.createdAt);
    if (moment().diff(createdDate, 'days') >= 30 || params.mode) {
      setIsExpired(true);
    }
  }, [allClassesDetails?.createdAt, params.mode]);

  const groupByCompetence = (arr: any[] | undefined) => {
    if (!arr) {
      return;
    }
    const result: any = [];
    for (const item of arr) {
      const index = result.findIndex(
        (i: any) => i.competence === item.competence.name
      );
      if (index !== -1) {
        result[index].quantity += 1;
      } else {
        result.push({
          competence: item?.competence?.name,
          quantity: 1,
        });
      }
    }
    return result;
  };

  const content = (
    <div style={{ color: 'white', marginTop: '8px' }}>
      A avaliação inicial é composta por {data?.data?.length} perguntas, sendo
      elas:
      <div style={{ marginTop: '8px' }}>
        {groupByCompetence(data?.data)?.map((item: any) => (
          <p>
            {`${item.quantity} ${Pluralizar(
              'pergunta',
              item?.quantity > 1
            )} da competência ${item?.competence} `}
          </p>
        ))}
      </div>
    </div>
  );

  const year = moment().year();
  let options = [];
  for (let i = 0; i < 4; i++) {
    options.push({ value: year - i, label: year - i });
  }

  return (
    <Flex style={{ width: '100%', height: '100%' }} vertical>
      <Flex
        style={{ width: '100%', height: '100%' }}
        vertical
        justify="space-between"
        align="space-between"
      >
        <Flex
          style={{ height: '100%' }}
          vertical={isMobile}
          gap={isMobile ? '2px' : '90px'}
        >
          <Flex
            style={{ width: '100%', height: '100%' }}
            vertical={isMobile && true}
          >
            <Flex
              style={{ width: '100%', height: '100%' }}
              vertical
              justify="space-between"
              align="space-between"
            >
              <Flex vertical>
                <Flex
                  style={{ height: '100%' }}
                  vertical={isMobile}
                  gap={isMobile ? '2px' : '90px'}
                >
                  <Form.Item
                    label="Ano Letivo"
                    name="year"
                    rules={[
                      {
                        required: true,
                        message: 'Por favor, informe o ano letivo!',
                      },
                    ]}
                  >
                    <Select
                      id="id-tour-create-class-input-year"
                      style={
                        isMobile
                          ? { width: '130px', height: '30px' }
                          : { width: '160px', height: '40px' }
                      }
                      options={options}
                      disabled={isExpired}
                    />
                  </Form.Item>
                  <Form.Item
                    name="serieId"
                    label="Série"
                    rules={[
                      {
                        required: true,
                        message: 'Por favor, informe a série!',
                      },
                    ]}
                  >
                    <Select
                      id="id-tour-create-class-input-serie"
                      style={
                        isMobile
                          ? { width: '130px', height: '30px' }
                          : { width: '160px', height: '40px' }
                      }
                      options={allSeries?.map((serie) => ({
                        value: serie.id,
                        label: serie.name,
                      }))}
                      disabled={isExpired}
                    />
                  </Form.Item>
                  <Form.Item
                    name="period"
                    label="Turno"
                    rules={[
                      {
                        required: true,
                        message: 'Por favor, informe o turno!',
                      },
                    ]}
                  >
                    <Select
                      id="id-tour-create-class-input-period"
                      style={
                        isMobile
                          ? { width: '130px', height: '30px' }
                          : { width: '160px', height: '40px' }
                      }
                      disabled={isExpired}
                      options={[
                        { value: 'morning', label: 'Matutino' },
                        { value: 'afternoon', label: 'Vespertino' },
                        { value: 'fulltime', label: 'Integral' },
                      ]}
                    />
                  </Form.Item>
                  <Form.Item
                    name="name"
                    label="Nome"
                    rules={[
                      {
                        required: true,
                        message: 'Por favor, escreva o nome da turma!',
                      },
                    ]}
                  >
                    <Input
                      id="id-tour-create-class-input-name"
                      style={
                        isMobile
                          ? { width: '284px', height: '30px' }
                          : { width: '390px', height: '40px' }
                      }
                    />
                  </Form.Item>
                </Flex>
                {id ? null : (
                  <Form.Item
                    name="initialAvaliation"
                    label={
                      <Flex gap={17}>
                        <p>Deseja criar uma avaliação inicial?</p>
                        <Popover content={content}>
                          <Flex
                            align="center"
                            justify="center"
                            style={{
                              backgroundColor: colorPrimary,
                              borderRadius: '50px',
                              width: '18px',
                              height: '18px',
                              padding: '2px',
                            }}
                          >
                            <TfiInfo
                              style={{ color: 'white', fontWeight: 900 }}
                            />
                          </Flex>
                        </Popover>
                      </Flex>
                    }
                    rules={[
                      {
                        required: true,
                        message:
                          'Por favor, escolha se deseja criar uma avaliação inicial ou não!',
                      },
                    ]}
                  >
                    <Flex align="center">
                      <Radio.Group id="id-tour-create-class-input-initialAvaliation">
                        <Radio value={true}>Sim</Radio>
                        <Radio value={false}>Não</Radio>
                      </Radio.Group>
                    </Flex>
                  </Form.Item>
                )}
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      {id && (
        <Flex gap={8} align="center">
          <GoAlertFill style={{ color: '#9D9D9D', fontSize: '17px' }} />
          <p style={{ fontSize: '16px', fontWeight: 400, color: '#9D9D9D' }}>
            Atenção: Após 30 dias da criação da turma, não será possível editar
            os campos "Ano letivo", "Série" e "Turno".
          </p>
        </Flex>
      )}
    </Flex>
  );
}
export default GeneralDataTab;
