import { Permissions } from 'core/resources/enums/permissions';
import { useCurrentUser } from 'core/features/user/store';
import Layout from 'components/layout';
import { Col, Row } from 'antd';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import { useMediaQuery } from '@chakra-ui/react';
import ClassRoomIcon from 'assets/images/icons/ClassRoomIcon';
import SelfAvaliationIcon from 'assets/images/icons/SelfAvaliationIcon';
import AvaliationIcon from 'assets/images/icons/AvaliationIcon';
import AutomationIcon from 'assets/images/icons/AutomationIcon';
import IndividualIcon from 'assets/images/icons/IndividualIcon';
import ManageCard from 'components/ManageCard';
import { Link } from 'react-router-dom';
import Title from 'components/typography/title';
import IndividualAvaliationIcon from 'assets/images/icons/IndividualAvaliationIcon';

const permissionsForTab = [
  {
    permission: Permissions.Activity.View,
    label: 'Atividade da Turma',
    icon: ClassRoomIcon,
    link: '/activities/class',
    id: 'id-tour-activities-header-tab-class',
  },
  {
    permission: Permissions.Activity.View,
    label: 'Atividade Individual',
    icon: IndividualIcon,
    link: '/activities/individual',
    id: 'id-tour-activities-header-tab-individual',
  },
  {
    permission: Permissions.SelfAvaliation.View,
    label: 'Avaliação inicial',
    icon: SelfAvaliationIcon,
    link: '/activities/self-avaliation',
    order: 3,
    id: 'id-tour-activities-header-tab-initial-avaliation',
  },
  {
    permission: Permissions.Avaliation.View,
    label: 'Avaliação da Turma',
    icon: AvaliationIcon,
    link: '/activities/avaliation',
    id: 'id-tour-activities-header-tab-avaliation',
  },
  {
    permission: Permissions.Avaliation.View,
    label: 'Avaliação Individual',
    icon: IndividualAvaliationIcon,
    link: '/activities/individual-avaliation',
    id: 'id-tour-activities-header-tab-individual-avaliation',
  },
  // {
  //   permission: Permissions.Activity.View,
  //   label: 'Automatizar atividade',
  //   link: '/activities/automate',
  //   icon: AutomationIcon,
  //   id: 'id-tour-activities-header-tab-automate-activity',
  // },
];

function ActivitiesPages() {
  const { user } = useCurrentUser();
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  let cards: { label: string; icon: any; link: string; id: string }[] = [];
  permissionsForTab.forEach((element) =>
    user?.resources.includes(element.permission)
      ? cards.push({
          label: element.label,
          icon: element.icon,
          link: element.link,
          id: element.id,
        })
      : null
  );

  return (
    <Layout.Container>
      <Title
        primary
        style={{
          marginBottom: isMobile ? '16px' : '24px',
        }}
      >
        Atividades
      </Title>

      <Row gutter={isMobile ? [0, 18] : [42, 48]}>
        {cards.map((item) => (
          <Col sm={8} style={{ width: '100%' }} key={item.id}>
            <Link to={item.link}>
              <ManageCard
                isMobile={isMobile}
                icon={item.icon}
                title={item.label}
                id={item.id}
              />
            </Link>
          </Col>
        ))}
      </Row>
    </Layout.Container>
  );
}

export default ActivitiesPages;
