import { Button, Flex } from 'antd';
import { Modal } from 'antd';
import { useEffect, useState } from 'react';
import { useMediaQuery } from '@chakra-ui/react';
import { Upload } from 'antd';
import readXlsxFile from 'read-excel-file';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import { useSaveUsers } from 'core/features/user/hooks/useSaveUsers';
import {
  notificateError,
  notificateSucess,
  openNotification,
} from 'infra/helpers/notifications';
import { useCurrentUser } from 'core/features/user/store';
import Text from 'components/typography/text';
import { IoClose } from 'react-icons/io5';
import { FiDownload, FiUpload } from 'react-icons/fi';

const { Dragger } = Upload;
const schema = {
  Email: {
    prop: 'email',
    type: String,
    required: true,
  },
  Perfil: {
    prop: 'profile',
    type: String,
    oneOf: ['admin', 'teacher'],
  },
};

function ModalImportCollaborator({ onClose , tenantId }: { onClose: () => void ; tenantId?:string}) {
  const [file, setFile] = useState();
  const [data, setData] = useState<any>([]);
  const { mutate: handleSave } = useSaveUsers();
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const { colorPrimary, colorGreyBorderAndFont } = useU4heroColors();
  const { user } = useCurrentUser();

  useEffect(() => {
    (async () => {
      if (file) {
        try {
          const rows = await readXlsxFile(file, { schema });
          setData(rows.rows);
        } catch (error) {
          console.log(error);
        }
      }
    })();
  }, [file]);

  const uploadProps = {
    beforeUpload: (file: any) => {
      setFile(file);
      return false;
    },
  };

  const handleImport = () => {
    const items = data
      ?.map((item: any) => {
        const requiredProperties = ['email'];
        if (requiredProperties.every((prop) => item.hasOwnProperty(prop))) {
          return item;
        }
        return null;
      })
      .filter(Boolean);

    handleSave(
      { items, tenantId: tenantId ??  user?.tenantId as string},
      {
        onSuccess: () => {
          notificateSucess(
            'Importação de colaboradores concluída com sucesso!'
          );
          onClose();
        },
        onError: (err: any) => {
          notificateError(
            'Houve um problema ao importar os colaboradores. Verifique sua conexão e tente novamente.'
          );
        },
      }
    );
  };

  return (
    <Modal
      title={
        <p style={{ fontSize: '24px', fontWeight: 700, color: colorPrimary }}>
          Importação
        </p>
      }
      open={true}
      onCancel={() => {
        if (data.length > 0) {
          openNotification({ onClose, colorPrimary, isMobile });
        } else {
          onClose();
        }
      }}
      closeIcon={
        <div
          style={{
            background: colorPrimary,
            borderRadius: '50%',
            width: '20px',
            height: '20px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <IoClose size={16} color="white" />
        </div>
      }
      footer={null}
      width={850}
    >
      <Text>
        Se desejar cadastrar vários colaboradores de uma vez, oferecemos a opção
        de importar dados através de uma tabela. Para isso, a planilha deverá
        seguir o exemplo do modelo fornecido. Caso algum colaborador não tenha o
        campo "perfil" preenchido, será automaticamente considerado como
        "professor".
      </Text>
      <a
        href="/modelo-colaborador.xlsx"
        download="modelo planilha"
        target="_blank"
        rel="noreferrer"
        id="id-tour-my-school-import-collaborator-modal-button-download-model"
      >
        {' '}
        <Flex
          gap="8px"
          justify="center"
          align="center"
          style={{ marginTop: '4px' }}
        >
          <FiDownload
            color={colorGreyBorderAndFont}
            size={isMobile ? 16 : 24}
          />
          <p
            style={{
              color: colorGreyBorderAndFont,
              fontSize: isMobile ? '14px' : '16px',
              fontWeight: 400,
            }}
          >
            Baixar modelo
          </p>
        </Flex>
      </a>
      <Dragger
        {...uploadProps}
        style={{ marginTop: '20px', marginBottom: '20px' }}
        accept=".xlsx"
        id="id-tour-my-school-import-collaborator-modal-upload"
      >
        <Flex
          justify="center"
          align="center"
          gap={isMobile ? '8px' : '16px'}
          vertical
        >
          <FiUpload color={colorGreyBorderAndFont} size={40} />
          <p
            style={{
              color: colorGreyBorderAndFont,
              fontSize: isMobile ? '14px' : '16px',
              fontWeight: 400,
              maxWidth: isMobile ? '181px' : '265px',
            }}
          >
            Clique aqui ou arraste a planilha
          </p>{' '}
        </Flex>
      </Dragger>

      <Flex justify="center" gap={isMobile ? '12px' : '24px'}>
        <Button
          style={{
            color: colorPrimary,
            fontWeight: 600,
            border: `3px solid ${colorPrimary}`,
            fontSize: isMobile ? '12px' : '16px',
          }}
          size="large"
          onClick={() => {
            if (file) {
              openNotification({
                message: '',
                onClose,
                colorPrimary,
                isMobile,
              });
            } else {
              onClose();
            }
          }}
          id="id-tour-my-school-import-collaborator-modal-button-cancel"
        >
          CANCELAR
        </Button>

        <Button
          type="primary"
          size={'large'}
          onClick={handleImport}
          style={{
            fontWeight: 600,
            fontSize: isMobile ? '12px' : '16px',
          }}
          id="id-tour-my-school-import-collaborator-modal-button-import"
        >
          IMPORTAR
        </Button>
      </Flex>
    </Modal>
  );
}

export default ModalImportCollaborator;
