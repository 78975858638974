import GraduationCapIcon from 'assets/images/icons/GraduationCapIcon';
import useTotalStudents from 'core/features/indicators/hooks/useTotalStudents';
import StatCard from '../statCard';

function TotalStudents({onRemove}:{onRemove?:()=> void}) {
  const { data } = useTotalStudents();
  return (
    <StatCard
      title="Estudante"
      total={data?.total ?? 0}
      Icon={GraduationCapIcon}
      onRemove={onRemove}
    />
  );
}

export default TotalStudents;
