import { useState } from 'react';
import { notificateError } from 'infra/helpers/notifications';
import { PostFeedbackForm } from 'core/features/posts/typings';
import Feedback from 'components/feedback';
import { useSavePostFeedback } from 'core/features/posts/hooks/useSavePostFeedback';

function PostFeedback({ id }: { id: string }) {
  const [submited, setSubmited] = useState(false);
  const { mutate: handleSave, isLoading } = useSavePostFeedback();

  const onSubmit = (values: PostFeedbackForm) => {
    handleSave(
      { ...values, postId: id },
      {
        onSuccess: () => {
          setSubmited(true);
        },
        onError: (err: any) => {
          notificateError(
            'Ops, aconteceu algum problema ao salvar seu feedback, tente novamente!'
          );
        },
      }
    );
  };
  return (
    <Feedback
      onSubmit={onSubmit}
      askMessage="O que achou das novidades?"
      askForTextMessage="Para nos ajudar a melhorar, por favor, explique o motivo da sua avaliação:"
      placeholderTextArea="Explique o motivo da sua avaliação com o máximo de detalhes possível"
      submited={submited}
      isLoadingSubmit={isLoading}
      requireText
    />
  );
}

export default PostFeedback;
