import { useHistory, useParams } from 'react-router-dom';
import bgMobile from 'assets/images/bg-mobile.png';
import bg from 'assets/images/bg.png';
import logo from 'assets/images/logo.png';
import expired from 'assets/images/expired.png';
import questionMark from 'assets/images/cosmo-question-mark.png';
import useValidate from 'core/features/auth/hooks/useValidate';
import { useRegisterUser } from 'core/features/user/hooks/useRegisterUser';
import { useMediaQuery } from '@chakra-ui/react';
import { UserRegisterForm } from 'core/features/user/typings/form';
import { useCurrentUser } from 'core/features/user/store';
import PasswordRequirements from 'components/password-requirements';
import {  Flex, Form, Typography, Input, Modal } from 'antd';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import { notificateError, notificateSucess } from 'infra/helpers/notifications';
import { IoClose } from 'react-icons/io5';
import { useEffect, useState } from 'react';
import Button from 'components/buttons/button';

const { Title } = Typography;
function Register() {
  const params = useParams<{ id: string }>();
  const { data} = useValidate({
    id: params.id,
  });
  const [isOpenModal, setIsOpenModal] = useState(false);
  const { colorPrimary } = useU4heroColors();
  const { mutate: handleSave, isLoading } = useRegisterUser();
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const [form] = Form.useForm();
  const password = Form.useWatch('password', form);
  const [isExpired, setIsExpired] = useState(false);
  const history = useHistory();
  const { user } = useCurrentUser();
  if (user) {
    history.push('/');
  }

  const _handleSubmit = (values: UserRegisterForm) => {
    delete values.confirmPassword;
    handleSave(
      { ...values, code: params.id },
      {
        onSuccess: () => {
          notificateSucess('Seu cadastro foi realizado com sucesso!');
          form.resetFields();
          history.push('/auth/login');
        },
        onError: (err: any) => {
          notificateError(err?.response?.data?.error);
        },
      }
    );
  };
  useEffect(() => {
    if (data?.data === 'expired') {
      setIsOpenModal(true);
      setIsExpired(true);
    }
    if (data?.data === 'used') {
      setIsOpenModal(true);
    }
  }, [data]);
  return (
    <Flex
      style={{
        width: '100%',
        height: '100vh',
        backgroundImage: isMobile ? '' : `url(${bg})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'bottom',
      }}
    >
      {isOpenModal ? (
        <Modal
          title={
            <p
              style={{
                fontSize: isMobile ? '18px' : '24px',
                color: colorPrimary,
                fontWeight: 700,
              }}
            >
              Link Expirado
            </p>
          }
          open={true}
          centered
          onCancel={() => {
            setIsOpenModal(false);
          }}
          closeIcon={
            <div
              style={{
                background: colorPrimary,
                borderRadius: '50%',
                width: '20px',
                height: '20px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <IoClose size={16} color="white" />
            </div>
          }
          footer={null}
          width={450}
        >
          <Flex vertical justify="center" align="center">
            <img
              alt="código expirado"
              src={isExpired ? expired : questionMark}
            />
            <p
              style={{
                fontSize: isMobile ? '14px' : '18px',
                textAlign: 'justify',
                fontWeight: 400,
                marginBottom: '16px',
              }}
            >
              {isExpired
                ? 'O tempo para ativar seu cadastro expirou. Se você ainda deseja completá-lo, por favor, entre em contato com o administrador da sua escola para gerar um novo link de ativação.'
                : 'O link para finalizar seu cadastro expirou, parece que você já concluiu o cadastro anteriormente.Por favor, entre em contato conosco se precisar de ajuda para acessar sua conta.'}
            </p>
            <Button
              size='md'
              onClick={() => setIsOpenModal(false)}
            >
              ENTENDI
            </Button>
          </Flex>
        </Modal>
      ) : null}
      <Form
        onFinish={(ev) => _handleSubmit(ev)}
        validateTrigger="onSubmit"
        layout="vertical"
        form={form}
        requiredMark="optional"
        initialValues={undefined}
      >
        {isMobile ? (
          <img alt="background-mobile" src={bgMobile} width="536px" />
        ) : null}
        <Flex
          style={{
            borderRadius: isMobile ? ' 20px 20px 0px 0px' : '0px 20px 20px 0px',
            background: 'white',
            width: isMobile ? '100vw' : '50vw',
            height: isMobile ? '' : '100vh',
            position: isMobile ? 'relative' : '-moz-initial',
            top: isMobile ? -20 : undefined,
          }}
          vertical
          justify="center"
          align="center"
        >
          <Flex
            vertical
            justify="center"
            style={{
              width: isMobile ? '100%' : '508px',
              padding: isMobile ? '0px 8px' : '',
            }}
          >
            <img
              alt="logo"
              src={logo}
              width={isMobile ? '100px' : '212px'}
              style={{ alignSelf: 'center' }}
            />
            <Title
              level={2}
              style={{
                maxWidth: '508px',
                color: colorPrimary,
                fontSize: isMobile ? '18px' : '28px',
                fontWeight: 700,
                marginTop: isMobile ? '' : '16px',
                textAlign: 'center',
                marginBottom: '0px',
              }}
            >
              Você está quase pronto para começar a sua jornada com a U4hero!
            </Title>
            <Form.Item
              label="Nome"
              rules={[
                {
                  required: true,
                  message: 'Por favor, digite seu nome!',
                },
              ]}
              name="name"
            >
              <Input
                id="id-tour-register-collaborator-input-name"
                placeholder="Digite seu nome aqui"
                size="large"
                style={{ width: '100%' }}
              />
            </Form.Item>

            <Form.Item
              label="Data de nascimento"
              rules={[
                {
                  required: true,
                  message: 'Por favor, informe sua data de nascimento!',
                },
              ]}
              name="birthday"
            >
              <Input
                id="id-tour-register-collaborator-input-date-of-birth"
                type="date"
                size="large"
                style={{ width: '100%' }}
              />
            </Form.Item>
            <Form.Item
              label="Senha"
              rules={[
                {
                  required: true,
                  message: 'Por favor, digite sua senha!',
                },
                {
                  min: 12,
                  message: 'Senha deve ter pelo menos 12 caracteres',
                },
                {
                  pattern:
                    /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*()])[\w!@#$%^&*()]+$/,
                  message:
                    'Senha deve conter pelo menos 1 letra maiúscula, 1 letra minúscula, 1 número e 1 caractere especial',
                },
              ]}
              name="password"
              initialValue=""
            >
              <Input.Password
                id="id-tour-register-password"
                size="large"
                style={{ width: '100%' }}
              />
            </Form.Item>
            <PasswordRequirements password={password} />
            <Form.Item
              label="Confirmar senha"
              rules={[
                {
                  required: true,
                  message: 'Por favor, confirme sua senha!',
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error('Ops, as senhas não são iguais!')
                    );
                  },
                }),
              ]}
              name="confirmPassword"
            >
              <Input.Password
                id="id-tour-register-confirm-password"
                size="large"
                style={{ width: '100%' }}
              />
            </Form.Item>

            <Button
              size="lg"
              htmlType="submit"
              style={{
                alignSelf: 'center',
                marginTop: isMobile ? '16px' : '',
              }}
              loading={isLoading}
            >
              CONFIRMAR
            </Button>
          </Flex>
        </Flex>
      </Form>
    </Flex>
  );
}

export default Register;
