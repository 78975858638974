import { useMediaQuery } from "@chakra-ui/react";
import { Col, Divider, Flex, Row, Button, Form } from "antd";
import SearchBar from "components/V2/SearchBar";
import { GenericCard } from "components/V2/generic-card-styled-component";
import useStudentsV2 from "core/features/students/hooks/useStudentsV2";
import { StudentItem } from "core/features/students/typings";
import { useCurrentUser } from "core/features/user/store";
import useU4heroColors from "infra/packages/antd/u4heroTokens";
import { useEffect, useState } from "react";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import {  MdKeyboardDoubleArrowLeft, MdKeyboardDoubleArrowRight } from "react-icons/md";
import { useParams } from "react-router-dom";

function DoubleList({ value, onChange }: { value: string[], onChange: (v: string[]) => void }) {

  const [search, setSearch] = useState<string>();
  const currentUser = useCurrentUser();
  const [selectedSearch, setSelectedSearch] = useState<string>();
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const { colorPrimary } = useU4heroColors();
  const [selected, setSelected] = useState<StudentItem[]>([]);
  const [allStudents, setAllStudents] = useState<StudentItem[]>([]);
  const { tenantId} = useParams<{ tenantId: string }>();
  const { data } = useStudentsV2({
    search,
    all: true,
    page: 1,
    tenantId,
  });

  useEffect(() => {
    if (data) {
      setAllStudents(data.data.filter((item) => !value.includes(item.id)));
      setSelected(data?.data.filter((item) => value.includes(item.id)));
    }
  }, [data]);

  const handleAddToSelected = (item: StudentItem) => {
    setAllStudents((prevStudents) =>
      prevStudents?.filter((q) => q.id !== item.id)
    );
    const added = [...selected, item]
    setSelected(added);
    onChange(added.map((item) => item.id));
  };

  const handleRemoveFromSelected = (item: StudentItem) => {
    const filtered = selected.filter((q) => q.id !== item.id);
    setSelected(filtered);
    onChange(filtered.map((item) => item.id));

    setAllStudents((prevAllStudents) => [...prevAllStudents, item]);
  };

  const moveAllToSelected = () => {

    setSelected([...selected, ...allStudents]);
    onChange([...selected, ...allStudents].map((item) => item.id));
    setAllStudents([]);
  };

  const moveAllToQuestionBank = () => {
    setAllStudents((prevAllStudents) => [...prevAllStudents, ...selected]);
    setSelected([]);
    onChange([]);
  };


  return (
    <Row gutter={[24, isMobile ? 8 : 0]} align="stretch">
      <Col span={isMobile ? 24 : 11}>
        <Flex vertical gap="8px" style={{ height: '100%' }}>
          <p
            style={{
              fontSize: isMobile ? '18px' : '24px',
              color: colorPrimary,
              fontWeight: 700,
            }}
          >
            Todos os Estudantes
          </p>
          <GenericCard height="100%">
            <Flex vertical gap="16px" style={{ width: '100%' }}>
              <Flex justify="space-between">
                <SearchBar id='id-tour-reenroll-class-search-all-students-list' onSearch={setSearch} />
              </Flex>
              {allStudents.length ? (
                <Flex
                  vertical
                  style={{
                    height: isMobile ? '40vh' : '60vh',
                    overflowY: 'scroll',
                    paddingRight: '16px',
                  }}
                  id='id-tour-reenroll-class-all-students-list'
                >
                  {allStudents?.map((item) => (
                    <Flex vertical>
                      <Flex justify="space-between" align="center">
                        <p
                          style={{
                            color: 'black',
                            fontSize: isMobile ? '14px' : '18px',
                            fontWeight: 400,
                          }}
                        >
                          {item.name}
                        </p>
                        <Button
                          type="primary"
                          size="small"
                          style={{ borderRadius: '8px' }}
                          onClick={() => handleAddToSelected(item)}
                        >
                          <IoIosArrowForward style={{ transform: isMobile ? 'rotate(90deg)' : '' }} size={16} />
                        </Button>
                      </Flex>
                      <Divider />
                    </Flex>
                  ))}
                </Flex>
              ) : (
                <Flex
                  vertical
                  justify="center"
                  align="center"
                  style={{ width: '100%' }}
                >
                  <p
                    style={{
                      fontSize: '14px',
                      color: '#7B7B7B',
                      textAlign: 'center',
                      fontWeight: 400,
                      maxWidth: '313px',
                    }}
                  >
                    Não existem mais estudantes para exibição. Todos os estudantes ja foram escolhidos.
                  </p>
                </Flex>
              )}
            </Flex>
          </GenericCard>
        </Flex>
      </Col>
      <Col span={isMobile ? 24 : 2} style={{ alignSelf: 'center' }}>
        <Flex vertical={!isMobile} align="center" justify="center" gap={isMobile ? '32px' : '48px'}>
          <Button
            type="default"
            style={{
              borderRadius: '8px',
              border: `2px solid ${colorPrimary}`,
              padding: '8px',
              height: '100%'
            }}
            onClick={moveAllToSelected}
            id='id-tour-reenroll-class-move-all-to-selected-button'
            disabled={allStudents?.length === 0}
          >
              <MdKeyboardDoubleArrowRight style={{ transform: isMobile ? 'rotate(90deg)' : '' }} color={colorPrimary} size={20} />
          </Button>
          <Button
            type="default"
            style={{
              borderRadius: '8px',
              border: `2px solid ${colorPrimary}`,
              padding: '8px',
              height: '100%'
            }}
            disabled={selected?.length === 0}
            onClick={moveAllToQuestionBank}
            id='id-tour-reenroll-class-move-all-to-unselected-button'
          >
              <MdKeyboardDoubleArrowLeft style={{ transform: isMobile ? 'rotate(90deg)' : '' }} color={colorPrimary} size={20} />
          </Button>
        </Flex>
      </Col>
      <Col span={isMobile ? 24 : 11}>
        <Flex vertical gap="8px" style={{ height: '100%' }}>
          <p
            style={{
              fontSize: isMobile ? '18px' : '24px',
              color: colorPrimary,
              fontWeight: 700,
            }}
          >
            Selecionados
          </p>
          <GenericCard height="100%">
            <Flex vertical gap="16px" style={{ width: '100%' }}>
              <Flex justify="space-between">
                <SearchBar id='id-tour-reenroll-class-search-selected-students-list' onSearch={setSelectedSearch} />
              </Flex>
              {selected?.length ? (
                <Flex
                  vertical
                  style={{
                    height: isMobile ? '' : '60vh',
                    overflowY: 'scroll',
                    paddingRight: '16px',
                  }}
                  id='id-tour-reenroll-class-selected-students-list'
                >
                  {selectedSearch ? selected?.filter((item) => item.name.toLowerCase().includes(selectedSearch.toLowerCase())).map((item: any) => (
                    <Flex vertical>
                      <Flex justify="space-between" align="center" gap="35px">
                        <Button
                          type="primary"
                          size="small"
                          style={{ borderRadius: '8px' }}
                          onClick={() => handleRemoveFromSelected(item)}
                        >
                          <IoIosArrowBack style={{ transform: isMobile ? 'rotate(90deg)' : '' }} size={16} />
                        </Button>
                        <p
                          style={{
                            color: 'black',
                            fontSize: isMobile ? '14px' : '18px',
                            fontWeight: 400,
                          }}
                        >
                          {item.name}
                        </p>
                      </Flex>
                      <Divider />
                    </Flex>
                  )) : selected?.map((item: any) => (
                    <>
                      <Flex justify="space-between" align="center" gap="35px">
                        <Button
                          type="primary"
                          size="small"
                          style={{ borderRadius: '8px' }}
                          onClick={() => handleRemoveFromSelected(item)}
                        >
                          <IoIosArrowBack style={{ transform: isMobile ? 'rotate(90deg)' : '' }} size={16} />
                        </Button>
                        <p
                          style={{
                            color: 'black',
                            fontSize: isMobile ? '14px' : '18px',
                            fontWeight: 400,
                          }}
                        >
                          {item.name}
                        </p>
                      </Flex>
                      <Divider />
                    </>
                  ))}
                </Flex>
              ) : (
                <Flex
                  vertical
                  justify="center"
                  align="center"
                  style={{ width: '100%' }}
                >
                  <p
                    style={{
                      fontSize: '14px',
                      color: '#7B7B7B',
                      textAlign: 'center',
                      fontWeight: 400,
                      maxWidth: '313px',
                    }}
                  >
                    Até agora, nenhum estudante foi escolhido. Por favor,
                    selecione os estudantes para visualizá-los
                    aqui.
                  </p>
                </Flex>
              )}
            </Flex>
          </GenericCard>
        </Flex>
      </Col>
    </Row>
  )
}
export default DoubleList