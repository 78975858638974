import { useMediaQuery } from '@chakra-ui/react';
import { Button, Flex } from 'antd';
import useStudentsActive from 'core/features/students/hooks/useStudentsActive';
import { useCurrentUser } from 'core/features/user/store';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import { FaArrowRightLong } from 'react-icons/fa6';
import { useHistory } from 'react-router';

function WelcomeMessage() {
  const { user } = useCurrentUser();
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const { data } = useStudentsActive();
  const { colorPrimary } = useU4heroColors();
  const history = useHistory();
  return (
    <div
      id="id-tour-home-section-welcome-message"
      style={{
        backgroundImage: "url('/estrelas_1.png')",
        backgroundColor: colorPrimary,
        fontFamily: 'Poppins',
        padding: isMobile ? '16px 8px' : '16px',
        height: isMobile ? '' : '100%',
        borderRadius: '20px',
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
        width: '100%',
      }}
    >
      <p
        style={{
          color: '#fff',
          fontSize: isMobile ? '18px' : '24px',
          fontWeight: 700,
        }}
      >
        Olá, {user?.name}!
      </p>
      <p
        style={{
          color: '#fff',
          fontSize: isMobile ? '14px' : '18px',
          fontWeight: 400,
        }}
      >
        Escola: {user?.tenantName}
      </p>
      <Flex justify="space-between">
        <p
          style={{
            color: '#fff',
            fontSize: isMobile ? '14px' : '18px',
            fontWeight: 400,
          }}
        >
          Estudantes ativos essa semana:{' '}
          <span style={{ textDecoration: 'underline' }}>{data}</span>
        </p>
        <Button
          style={{ background: '#FFF', padding: '5px',display:'flex',alignItems:'center' }}
          id='id-tour-button-students-active'
          onClick={() => {
            history.push('/my-school/students?active');
          }}
        >
          <FaArrowRightLong color={colorPrimary} size={25} />
        </Button>
      </Flex>
    </div>
  );
}

export default WelcomeMessage;
