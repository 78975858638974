import { useMediaQuery } from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';
import { Col, Empty, Flex, Pagination, Row, Spin, Typography } from 'antd';
import Layout from 'components/layout';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import { useHistory } from 'react-router';
import usePractices from 'core/features/practices/hooks/usePractices';
import { useEffect, useState } from 'react';
import SearchBar from 'components/V2/SearchBar';
import PracticeFilter from './components/PracticeFilter';
import { GenericCard } from 'components/V2/generic-card-styled-component';
import moment from 'moment';
import Star from 'components/star';
import { t } from 'core/resources/strings';
import { FilterType } from 'infra/helpers/types';
import AppliedFilters from 'components/applied-filters';
import Button from 'components/buttons/button';

const { Title } = Typography;
function PracticesSuggestions() {
  const history = useHistory();
  const location = useLocation();
  const { colorPrimary, colorGreyBorderAndFont, colorSecondary } =
    useU4heroColors();
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [search, setSearch] = useState('');
  const [filters, setFilters] = useState<FilterType>({
    environments: [],
    modalities: [],
    durations: [],
    age: [],
    dificulties: [],
    tags: [],
    subjects: [],
  });

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const tagId = searchParams.get('tagId');
    if (tagId) {
      setFilters((prevFilters) => ({
        ...prevFilters,
        tags: [{ value: tagId, label: 'Competência' }],
      }));
    }
  }, [location.search]);

  const { data, isLoading } = usePractices({
    page,
    limit: pageSize,
    published: true,
    search,
    environments: filters.environments.map((item) => item.value) ?? undefined,
    durations: filters.durations.map((item) => item.value) ?? undefined,
    dificulties: filters.dificulties.map((item) => item.value) ?? undefined,
    tags: filters.tags.map((item) => item.value) ?? undefined,
    subjects: filters.subjects.map((item) => item.value) ?? undefined,
    modalities: filters.modalities.map((item) => item.value) ?? undefined,
    age: filters?.age?.map((item) => item.value)[0] ?? undefined,
  });

  useEffect(() => {
    setPage(1);
  }, [search, filters]);

  return (
    <Layout.Container>
      <Title
        level={1}
        style={{
          color: colorPrimary,
          marginBottom: isMobile ? '16px' : '24px',
          fontSize: isMobile ? '24px' : '28px',
          fontWeight: 700,
        }}
      >
        Sugestões de Inspirações Pedagógicas
      </Title>

      <Flex
        vertical
        gap="8px"
        style={{ marginBottom: isMobile ? '20px' : '40px' }}
      >
        <Flex gap="16px" align="center">
          <SearchBar
            onSearch={setSearch}
            width="100%"
            placeholder="Pesquisar por nome da inspiração"
          />
          <PracticeFilter filters={filters} onChangeFilters={setFilters} />
        </Flex>

        <AppliedFilters appliedFilters={filters} onChangeFilters={setFilters} />
      </Flex>
      {!isLoading ? (
        <Flex vertical gap={isMobile ? '16px' : '34px'} justify="center">
          {data?.data.map((item, index) => (
            <Flex
              style={{
                flexDirection: isMobile
                  ? 'column-reverse'
                  : index % 2 === 0
                  ? 'row-reverse'
                  : 'row',
              }}
              vertical={isMobile}
            >
              <div
                style={{
                  minWidth: '60%',
                }}
              >
                <GenericCard
                  minHeight={isMobile ? '356px' : '333px'}
                  height="100%"
                >
                  <Flex
                    vertical
                    justify="space-between"
                    style={{
                      width: '100%',
                      height: isMobile ? undefined : '100%',
                      paddingLeft: isMobile
                        ? undefined
                        : index % 2 === 0
                        ? '60px'
                        : undefined,
                      paddingRight: isMobile
                        ? undefined
                        : index % 2 !== 0
                        ? '60px'
                        : undefined,
                      paddingTop: isMobile ? '20px' : undefined,
                    }}
                  >
                    <p
                      style={{
                        fontSize: '12px',
                        color: colorGreyBorderAndFont,
                        fontWeight: 400,
                        textAlign: isMobile
                          ? 'end'
                          : index % 2 === 0
                          ? 'end'
                          : 'left',
                      }}
                    >
                      {moment(item.createdAt).format('DD/MM/YYYY')}
                    </p>
                    <p
                      style={{
                        fontSize: isMobile ? '16px' : '20px',
                        color: colorPrimary,
                        fontWeight: 700,
                        textAlign: 'center',
                      }}
                    >
                      {item.title}
                    </p>
                    <p
                      style={{
                        fontSize: isMobile ? '14px' : '18px',
                        color: 'black',
                        fontWeight: 400,
                        textAlign: 'justify',
                      }}
                    >
                      {item.goal}
                    </p>
                    <p
                      style={{
                        fontSize: isMobile ? '14px' : '18px',
                        color: 'black',
                        fontWeight: 600,
                        textAlign: 'justify',
                      }}
                    >
                      Recursos:{' '}
                      <span
                        style={{
                          fontWeight: 400,
                        }}
                      >
                        {item.resources}
                      </span>
                    </p>
                    <p
                      style={{
                        fontSize: isMobile ? '14px' : '18px',
                        color: 'black',
                        fontWeight: 600,
                        textAlign: 'justify',
                      }}
                    >
                      Disciplina Relacionada:{' '}
                      <span
                        style={{
                          fontWeight: 400,
                        }}
                      >
                        {item.subject}
                      </span>
                    </p>
                    <Row>
                      <Col span={8}>
                        <p
                          style={{
                            fontSize: isMobile ? '14px' : '18px',
                            color: 'black',
                            fontWeight: 400,
                            textAlign: 'left',
                          }}
                        >
                          Faixa Etária:
                          <br />
                          <span
                            style={{
                              fontWeight: 600,
                              color: colorPrimary,
                            }}
                          >
                            {item.maxAge} anos +
                          </span>
                        </p>
                      </Col>
                      <Col span={8}>
                        <p
                          style={{
                            fontSize: isMobile ? '14px' : '18px',
                            color: 'black',
                            fontWeight: 400,
                            textAlign: 'left',
                          }}
                        >
                          Duração:
                          <br />
                          <span
                            style={{
                              fontWeight: 600,
                              color: colorPrimary,
                            }}
                          >
                            {item.duration} minutos
                          </span>
                        </p>
                      </Col>
                      <Col span={8}>
                        <p
                          style={{
                            fontSize: isMobile ? '14px' : '18px',
                            color: 'black',
                            fontWeight: 400,
                            textAlign: 'left',
                          }}
                        >
                          Dificuldade:
                          <br />
                          <span
                            style={{
                              fontWeight: 600,
                              color: colorPrimary,
                            }}
                          >
                            {item.dificulty}
                          </span>
                        </p>
                      </Col>
                    </Row>
                    <Button
                      size="md"
                      style={{
                        justifySelf: 'center',
                        alignSelf: 'center',
                      }}
                      onClick={() => history.push(`/practices/${item.id}`)}
                    >
                      LEIA MAIS
                    </Button>
                  </Flex>
                </GenericCard>
              </div>

              <Flex justify={'center'}>
                <div
                  style={{
                    position: 'relative',
                    left: isMobile
                      ? undefined
                      : index % 2 === 0
                      ? 60
                      : undefined,
                    right: isMobile
                      ? undefined
                      : index % 2 !== 0
                      ? 60
                      : undefined,
                    bottom: isMobile ? -50 : -25,
                  }}
                >
                  <img
                    alt={`banner ${item.title}`}
                    src={item.banner}
                    style={{
                      borderRadius: '10px',
                      width: isMobile ? '80vw' : '50vw',
                      height: isMobile ? '180px' : '280px',
                      objectFit: 'cover',
                    }}
                  />
                  <Flex
                    style={{
                      width: '100%',
                      position: 'relative',
                      bottom: isMobile ? 18 : 10,
                    }}
                    justify="center"
                  >
                    <div
                      style={{
                        borderRadius: '5px 5px 0px 0px',
                        fontSize: isMobile ? '12px' : '16px',
                        paddingLeft: isMobile ? '14px' : '16px',
                        paddingRight: isMobile ? '14px' : '16px',
                        background: colorSecondary,
                        fontWeight: 700,
                        color: 'white',
                        textTransform: 'uppercase',
                      }}
                    >
                      {item.target} - {t(`app.${item.practiceModality.name}`)}
                    </div>
                  </Flex>
                  <div
                    style={{
                      position: 'absolute',
                      bottom: isMobile ? undefined : 20,
                      left: isMobile
                        ? undefined
                        : index % 2 === 0
                        ? -30
                        : undefined,
                      right: isMobile ? -20 : index % 2 !== 0 ? -30 : undefined,
                      top: isMobile ? -30 : undefined,
                    }}
                  >
                    <Star
                      color={colorPrimary}
                      size={isMobile ? '60px' : '70px'}
                      fontSize="16px"
                    >
                      {t(`app.${item.analysisEnvironment.name}`)}
                    </Star>
                  </div>
                </div>
              </Flex>
            </Flex>
          ))}
        </Flex>
      ) : (
        <Spin size="large" />
      )}
      {!isLoading && !data?.data.length ? <Empty /> : null}
      <Flex
        justify="center"
        style={{
          marginTop: isMobile ? '18px' : '26px',
          marginBottom: isMobile ? '10px' : '18px',
        }}
      >
        <Pagination
          showSizeChanger
          defaultCurrent={1}
          current={page}
          pageSize={pageSize}
          onChange={(v) => setPage(v)}
          onShowSizeChange={(v, size) => setPageSize(size)}
          total={data?.total}
        />
      </Flex>
    </Layout.Container>
  );
}

export default PracticesSuggestions;
