import useHelperByUrl from 'core/features/helpers/hooks/useHelperByUrl';
import { useHistory, useLocation } from 'react-router-dom';
import { Button, Drawer, Flex, Skeleton, Spin, Tooltip } from 'antd';
import { HelperContainer } from './styles';
import Title from 'components/typography/title';
import Text from 'components/typography/text';
import { useMediaQuery } from '@chakra-ui/media-query';
import SubTitle from 'components/typography/subTitle';
import YouTube from 'react-youtube';
import { HiArrowNarrowRight } from 'react-icons/hi';
import { HoverItem } from 'components/hover-item/hover';
import { hex2rgba } from 'infra/helpers/hex2Rgba';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import { FiSettings } from 'react-icons/fi';
import useCheckPermissions from 'core/features/user/hooks/useCheckPermissions';
import { Permissions } from 'core/resources/enums/permissions';
import HelperFeedback from 'ui/pages/app/helpers/pages/components/HelperFeedback';
import { PiBinoculars } from 'react-icons/pi';
import TourMap from '../TourMap';
import { useState } from 'react';

export function Helper({
  title,
  onClose,
  setTourId,
}: {
  title?: string;
  onClose: () => any;
  setTourId: (v: string) => void;
}) {
  let location = useLocation();
  let { data: dataUrl, isLoading: isLoadingUrl } = useHelperByUrl(
    location.pathname + location.search
  );
  const { colorPrimary } = useU4heroColors();
  const history = useHistory();
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const loading = isLoadingUrl;
  const re =
    /(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/\s]{11})/gi;
  const videoUrl = dataUrl?.videoUrl;
  const videoId = videoUrl ? re.exec(videoUrl) : undefined;
  const [check] = useCheckPermissions();
  const isU4Hero = check([Permissions.U4hero.Helpers.View]);
  const [isMapOpen, setIsMapOpen] = useState(false);
  return (
    <>
      <Drawer
        title={
          <Flex justify="space-between">
            <Title primary>Guia Rápido</Title>
            {isU4Hero ? (
              <Button
                type="primary"
                style={{ position: 'absolute', top: 0, right: 46 }}
                icon={<FiSettings color="white" />}
                onClick={() => {
                  history.push('/helpers-u4hero');
                  onClose();
                }}
              />
            ) : (
              <Tooltip title={'Explorar Tours'}>
                <Button
                  type="primary"
                  style={{ position: 'absolute', top: 0, right: 46 }}
                  icon={<PiBinoculars color="white" />}
                  onClick={() => {
                    setIsMapOpen(true);
                  }}
                />
              </Tooltip>
            )}
          </Flex>
        }
        onClose={onClose}
        open={true}
        width={isMobile ? '100%' : '470px'}
      >
        {loading ? (
          <Flex justify="center" align="center">
            <Skeleton active />
          </Flex>
        ) : null}

        <HelperContainer>
          <Flex vertical gap="8px">
            <Text
              size="md"
              style={{ textDecoration: 'underline', cursor: 'pointer' }}
              align="end"
              onClick={() => {
                history.push('/helpers');
                onClose();
              }}
            >
              Ir para a Central de Ajuda
            </Text>
            <SubTitle primary>{dataUrl?.title}</SubTitle>
            {videoId && (
              <YouTube
                style={{ width: '100%' }}
                videoId={videoId[1]}
                opts={{ width: '100%', height: isMobile ? 160 : 275 }}
              />
            )}
            {dataUrl?.tourId && (
              <HoverItem
                style={{
                  bordeRadius: isMobile ? '10px' : '16px',
                }}
                background="#E9E9E9"
                hover={hex2rgba(colorPrimary, 0.3)}
              >
                <Flex
                  justify="space-between"
                  style={{
                    padding: isMobile ? '8px' : '16px',
                  }}
                  onClick={() => setTourId(dataUrl?.tourId as string)}
                >
                  <Text color="black" size="lg">
                    Inicie o tour explicativo dessa tela
                  </Text>{' '}
                  <HiArrowNarrowRight
                    color={'black'}
                    size={isMobile ? 20 : 28}
                  />
                </Flex>
              </HoverItem>
            )}

            <div
              dangerouslySetInnerHTML={{
                __html: dataUrl?.md ?? '',
              }}
            />
          </Flex>
        </HelperContainer>
        {dataUrl?.id ? <HelperFeedback id={dataUrl.id} /> : null}
      </Drawer>

      {isMapOpen ? (
        <TourMap
          onClose={() => {
            setIsMapOpen(false);
            onClose();
          }}
        />
      ) : null}
    </>
  );
}

export default Helper;
