import { useMediaQuery } from '@chakra-ui/react';
import { Col, Flex, Row } from 'antd';
import Button from 'components/buttons/button';
import { Graph } from 'core/features/indicators-profile/typings';
import { notificateSucess, showConfirm } from 'infra/helpers/notifications';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import { useState } from 'react';
import ModalAddIndicators from '../../components/modal-add-indicators';
import CustomGraph from '../components/customGraph';
import EmotionalGraph from '../components/emotionalGraph';
import StudentFrequencyGraph from '../components/studentFrequencyGraph';
import TotalClasses from '../components/totalClasses';
import TotalStudents from '../components/totalStudents';
import { v4 as uuidv4 } from 'uuid';

function EducatorView() {
  const [isOpen, setIsOpen] = useState(false);
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const [graphs, setGraphs] = useState<Graph[]>([]);
  const { colorPrimary } = useU4heroColors();
  const [graphIndex, setGraphIndex] = useState<number>();

  const onRemoveIndicator = ({
    label,
    value,
  }: {
    label: string;
    value: any;
  }) => {
    showConfirm({
      message: `Tem certeza que deseja excluir o indicador “${label}”?`,
      colorPrimary,
      isMobile,
    }).then(({ isConfirmed }) => {
      if (isConfirmed) {
        setGraphs(graphs.filter((item) => item.id !== value));
        notificateSucess(`O indicador foi excluído com sucesso!`);
      }
    });
  };

  return (
    <Flex vertical gap={16}>
      {isOpen ? (
        <ModalAddIndicators
          mode="educator"
          graphs={graphs}
          setGraphs={setGraphs}
          graphIndex={graphIndex}
          onClose={() => {
            setIsOpen(false);
            setGraphIndex(undefined);
          }}
        />
      ) : null}
      <Row gutter={[isMobile ? 0 : 40, isMobile ? 16 : 0]}>
        <Col sm={24} md={12} style={{ width: '100%' }}>
          <Flex vertical gap={isMobile ? 16 : 32}>
            <Row gutter={[32, 16]}>
              <Col span={12}>
                <TotalStudents />
              </Col>
              <Col span={12}>
                <TotalClasses />
              </Col>
            </Row>
            <StudentFrequencyGraph />
          </Flex>
        </Col>

        <Col sm={24} md={12} style={{ width: '100%' }}>
          <EmotionalGraph />
        </Col>
      </Row>
      <Row align="stretch" gutter={[40, isMobile ? 16 : 20]}>
        {graphs.map((graph, index) => (
          <Col key={index} span={isMobile ? 24 : 12}>
            <CustomGraph
              graph={graph}
              onDuplicate={() => {
                setIsOpen(true);
                const newGraphId = uuidv4();
                const newIndex = graphs.length;
                setGraphs([...graphs, { ...graph, id: newGraphId }]);
                setGraphIndex(newIndex);
                notificateSucess(
                  'Gráfico duplicado com sucesso! Você agora está editando uma cópia. Faça as alterações necessárias e salve para criar um novo gráfico.'
                );
              }}
              onEdit={() => {
                setIsOpen(true);
                setGraphIndex(index);
              }}
              onRemove={() => {
                onRemoveIndicator({
                  label: graph.name,
                  value: graph.id,
                });
              }}
            />
          </Col>
        ))}
      </Row>
      <Button
        size="md"
        style={{ alignSelf: 'center' }}
        onClick={() => setIsOpen(true)}
      >
        + GRÁFICOS
      </Button>
    </Flex>
  );
}

export default EducatorView;
