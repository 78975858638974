import {  Col, Flex, Row } from 'antd';
import Button from 'components/buttons/button';
import ModalCharacterLore from 'components/modals/modal-character-lore';
import { GenericCard } from 'components/V2/generic-card-styled-component';
import { useState } from 'react';

const characters = [
  {
    name: 'Rotori',
    image: 'https://u4hero-files.s3.sa-east-1.amazonaws.com/lores/Rotori.png',
    lore: 'Em uma floresta mágica chamada Floresta Cintila, havia uma criatura extraordinária conhecida como Rotori. Ele parecia um urso, mas suas características especiais o tornavam único em todo o vale. Tinha uma pelagem macia, castanho claro e cheia de pequenas manchinhas brancas, seus olhos eram como duas pérolas cintilantes, porém não possuía nenhuma boca.\
      O que tornava Rotori verdadeiramente especial era a sua habilidade de controlar o clima, trazendo sol nos dias nublados, fazendo nevar em pleno verão ou provocar uma brisa suave sempre que quisesse. Vive na Floresta Mágica, onde todas as criaturas habitam em harmonia. Conhecido por seu coração bondoso e sua amizade com todos.\
     Em Senáquia, existiam inúmeros seres mágicos, cada um com sua própria personalidade e aparência. No entanto, havia uma criatura malvada chamada Tagon, que sempre tentava perturbar o equilíbrio da Floresta Mágica, Tagon estava constantemente tramando planos para criar confusão e caos, afetando a paz da floresta.\
     Um dia, quando uma tempestade terrível ameaçou destruir todo o Reino de Senáquia, as criaturas da floresta se voltaram para Rotori em busca de ajuda. Com determinação ele enfrentou a tempestade e usou seus poderes para acalmar os ventos furiosos e as águas revoltas. Ele impediu que o Tagon causasse mais danos ao equilíbrio do reino.\
     Depois de sua vitória sobre Tagon, Rotori foi aclamado como o guardião da Floresta de Cintila, e continuou trazendo alegria e equilíbrio para o reino, garantindo que todas as estações do ano fossem mágicas e felizes.\
      A lenda de Rotori, inspirou as crianças a cuidarem da natureza e acreditarem na magia que pode ser encontrada em todos os lugares, se você souber onde procurar.',
  },
  {
    name: 'Na e Bo',
    image: 'https://u4hero-files.s3.sa-east-1.amazonaws.com/lores/Na+e+Bo.png',
    lore: 'Em Senáquia, há diversas criaturas mágicas de todas as formas, até mesmo parecidas com vegetais, como Na e seu irmão Bo. Eles são uma espécie de nabo, porém nascidos como gêmeos siameses que vivem grudados um ao outro.\
      Eles viviam em um terreno aconchegante no Vale das Raízes, onde as casas eram subterrâneas e os moradores eram vegetais e legumes. Sempre que alguém precisava de uma boa risada ou de um amigo para brincar, lá estava Na e Bo com suas brincadeiras e sua capacidade de se esconder entre as vegetação para alegrar.',
  },
  {
    name: 'Ibá',
    image: 'https://u4hero-files.s3.sa-east-1.amazonaws.com/lores/Ib%C3%A1.png',
    lore: 'Onde todas as criaturas eram inspiradas na natureza, havia um muito especial chamado Ibá. Este ser mágico se assemelhava a um tronco de árvore, mas tinha uma expressão amigável em seu rosto de madeira. Era conhecido por sua sabedoria e sua capacidade de se conectar com a natureza de uma maneira única.\
      Ibá vivia no coração da Grande Floresta, um lugar onde as árvores eram altas e majestosas, e os riachos cantavam entre as pedras. Ele era o filho da floresta e era amado por todas as criaturas que habitavam ali. Ibá costumava sentar-se no pé da  antiga árvore, onde ouvia muitas histórias sábias que ela lhe contava.\
       Seus poderes eram essenciais para o crescimento das flores e das árvores, ele mantinha tudo correndo em seu ritmo e trazia harmonia para a Floresta Cintila.',
  },
  {
    name: 'Coepi',
    image: 'https://u4hero-files.s3.sa-east-1.amazonaws.com/lores/Coepi.png',
    lore: 'Uma criatura muito especial e travessa chamada Coepi, com a aparência de uma coruja, mas com orelhas peludas de coelho que espreitavam por entre suas penas macias e brancas. Era o que o tornava único dentre os outros seres animalescos.\
      Adorava pregar peças e fazer travessuras pelo Vale das Raízes. Ele era conhecido por sua risada contagiante e seu espírito brincalhão. Coepi vivia na Floresta Cintila, um lugar repleto de árvores altas, riachos borbulhantes e flores coloridas.\
      Os moradores de Senáquia viviam preocupados em qual seria a próxima armadilha de Coepi. Pois com a sua capacidade de andar silenciosamente como um coelho, ele sempre passava despercebido pelo guardião da floresta e surpreendia a todos com seus truques.',
  },
  {
    name: 'Tagon',
    image: 'https://u4hero-files.s3.sa-east-1.amazonaws.com/lores/Tagon.png',
    lore: 'Onde todas as criaturas eram conhecidas por sua felicidade e bondade, havia uma criatura que se destacava por sua atitude mal-humorada. Seu nome era Tagon, e ele se assemelhava a um emaranhado de pelos escuros, suas sobrancelhas com uma expressão carrancuda e sua voz era resmungona.\
      Tagon vivia nas profundezas do Pântano de Grumblor, um lugar onde as árvores não tinham folhas, e o silêncio predominava. Ele preferia a solidão e raramente interagia com outras criaturas da floresta. Sua casinha estava escondida entre as sombras das árvores, e Tagon passava seus dias cochilando e resmungando sobre o barulho constante do mundo exterior.\
      No entanto, ele nutria muita inveja pela harmonia e felicidade da Floresta Cintila onde moravam a maioria das criaturas, lugar que era sempre alegre e cheio de vida. Muitas vezes ele era visto pisoteando flores e destruindo a floresta.',
  },
  {
    name: 'Tery',
    image: 'https://u4hero-files.s3.sa-east-1.amazonaws.com/lores/Tery.png',
    lore: 'Tery, nascido no Vale das Raízes, tinha a forma de uma berinjela, mas possuía patas e uma folha no topo de sua cabeça, além de olhos pequenos brilhantes. Sua personalidade se assemelhava a de um cãozinho filhote.\
      Era conhecido por sempre estar correndo no Campo Ensolarado. Ele era um companheiro leal e sempre estava disposto a brincar e explorar as vastas paisagens do reino. Porém havia um lugar que ele nunca se arriscava em ir, o Pântano de Grumblor, aquele lugar lhe dava arrepios e além disso lá vivia o problemático Tagon, que odiava ser incomodado.',
  },
];

function CharacterLore() {
  const [currentCharacter] = useState(
    characters[Math.floor(Math.random() * characters.length)]
  );
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      {isOpen ? (
        <ModalCharacterLore
          name={currentCharacter.name}
          image={currentCharacter.image}
          lore={currentCharacter.lore}
          onClose={() => {
            setIsOpen(false);
          }}
        />
      ) : null}
      <GenericCard padding="2px" width="100%" height="100%" id='id-tour-section-check-character-lore'>
        <Row justify="space-between" style={{ width: '100%' }}>
          <Col span={12}>
            <img
              alt="imagem do personagem"
              src={currentCharacter.image}
              style={{
                height: '140px',
                borderRadius: '20px',
                objectFit: 'cover',
                aspectRatio: '16/9',
              }}
            />
          </Col>
          <Col span={12}>
            <Flex
              vertical
              gap="12px"
              style={{ height: '100%' }}
              justify="center"
              align="center"
            >
              <p
                style={{
                  fontSize: '18px',
                  color: 'black',
                  textAlign: 'center',
                  maxWidth: '170px',
                  fontWeight: 400,
                }}
              >
                Conheça a história de {currentCharacter.name}
              </p>

              <Button
                size="sm"
                id='id-tour-button-read-now-character-lore'
                onClick={() => {
                  setIsOpen(true);
                }}
              >
                LEIA AGORA
              </Button>
            </Flex>
          </Col>
        </Row>
      </GenericCard>
    </>
  );
}

export default CharacterLore;
