import { useMediaQuery } from '@chakra-ui/react';
import { Form, Radio, Space } from 'antd';
import useEnvironments from 'core/features/questions/hooks/useEnvironments';
import { t } from 'core/resources/strings';

function AnalysisEnvironmentInput({ index }: { index: number }) {
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const form = Form.useFormInstance();
  const graph = Form.useWatch(['graphs', index], form);
  const environments = useEnvironments();

  if (graph?.category !== 'activity') {
    return null;
  }

  return (
    <Form.Item
      rules={[
        {
          required: true,
          message: 'Por favor, selecione o ambiente de análise!',
        },
      ]}
      label="Ambiente de Análise"
      name={[index, 'analysisEnvironmentId']}
    >
      <Radio.Group id="id-tour-question-creation-input-analysisEnvironment">
        <Space direction={isMobile ? 'vertical' : 'horizontal'}>
          {environments?.data?.map((env) => (
            <Radio value={env.id} key={env.id}>
              {t(`app.${env.name}`)}
            </Radio>
          ))}
          <Radio value={4} key={4}>
              Todos
            </Radio>
        </Space>
      </Radio.Group>
    </Form.Item>
  );
}

export default AnalysisEnvironmentInput;
