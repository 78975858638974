import useSeries from 'core/features/series/hooks/useSeries';
import useAllClasses from 'core/features/classes/hooks/useAllClasses';
import GenericFilterComponent from 'components/generic-filter';
import { FilterProps } from 'infra/helpers/types';

function AutomationFilter({filters,onChangeFilters}:FilterProps) {
  const { data } = useSeries();
  const { data: dataClasses } = useAllClasses({});

  return (
    <GenericFilterComponent
      filters={filters}
      onChangeFilters={onChangeFilters}
      filterGroups={[
        {
          title: 'Série',
          filterName: 'series',
          options:
            data?.map((item) => {
              return { label: item.name, value: item.id };
            }) ?? [],
        },
        {
          title: 'Turma',
          filterName: 'classes',
          options:
            dataClasses?.data.map((item) => {
              return { label: item.name, value: item.id };
            }) ?? [],
        },
        {
          title: 'Data de Inicio',
          filterName: 'initialDate',
          options: [],
          type: 'date',
          open: true,
        },
        {
          title: 'Data de Término',
          filterName: 'endDate',
          options: [],
          type: 'date',
          open: true,
        },
      ]}
    />
  );
}

export default AutomationFilter;
