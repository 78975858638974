import { useMediaQuery } from '@chakra-ui/media-query';
import { Col, Flex, Row } from 'antd';
import Button from 'components/buttons/button';
import SubTitle from 'components/typography/subTitle';
import Tag from 'components/V2/tag';
import { PracticeDetails } from 'core/features/practices/typings';
import { t } from 'core/resources/strings';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import moment from 'moment';
import { useHistory } from 'react-router';
import './style.css';

function PracticeCard({ practice }: { practice: PracticeDetails }) {
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const history = useHistory();
  const { colorSecondary,colorGreyBorderAndFont } =
    useU4heroColors();

  return (
    <Flex
      vertical
      style={{
        borderRadius: '20px 100px',
        background: 'white',
        padding: '16px',
        maxWidth: '352px',
        height:'100%',
        boxShadow:
          '0px 3px 4px 0px rgba(0, 0, 0, 0.25), 0px -0.5px 4px 0px rgba(0, 0, 0, 0.25)',
      }}
    >
      <Flex
        vertical
        justify="space-between"
        style={{ width: 'fit-content', position: 'relative' }}
      >
        <div>
          <img
            alt={`banner ${practice.title}`}
            src={practice.banner}
            style={{ borderRadius: '10px 100px 10px 10px', width: '320px' }}
          />
          <div
            style={{
              borderRadius: '10px 5px 5px 0px',
              fontSize: isMobile ? '12px' : '16px',
              position: 'absolute',
              paddingLeft: isMobile ? '14px' : '16px',
              paddingRight: isMobile ? '14px' : '16px',
              background: colorSecondary,
              color: 'white',
              top: 0,
            }}
          >
            {t(`app.${practice.analysisEnvironment.name}`)}
          </div>
          <Flex
            style={{
              width: '100%',
              position: 'relative',
              bottom: isMobile ? 18 : 24,
            }}
            justify="center"
          >
            <div
              style={{
                borderRadius: '5px 5px 0px 0px',
                fontSize: isMobile ? '12px' : '16px',
                paddingLeft: isMobile ? '14px' : '16px',
                paddingRight: isMobile ? '14px' : '16px',
                background: colorSecondary,
                color: 'white',
                textTransform: 'uppercase',
              }}
            >
              {practice.target} - {t(`app.${practice.practiceModality.name}`)}
            </div>
          </Flex>

          <p
            style={{
              fontSize: isMobile ? '10px' : '14px',
              color: colorGreyBorderAndFont,
              textAlign: 'end',
              marginBottom: '4px',
            }}
          >
            {moment(practice.createdAt).format('DD/MM/YYYY')}
          </p>
        </div>
        <Flex gap="8px" justify="flex-end" align="center">
          <Tag color="primary" text={practice.competence1.name['pt-br']} />
          {practice?.competence2 ? (
            <Tag color="primary" text={practice.competence2.name['pt-br']} />
          ) : null}
          {practice?.skill ? (
            <Tag color="primary" text={practice.skill.name['pt-br']} />
          ) : null}
        </Flex>
        <SubTitle primary align="center">
          {practice.title}
        </SubTitle>
        <Button
          size="md"
          style={{
            justifySelf: 'center',
            alignSelf: 'center',
            marginTop: '10px',
          }}
          onClick={() => history.push(`/practices/${practice.id}`)}
        >
          CONFERIR
        </Button>
      </Flex>
    </Flex>
  );
}

export default PracticeCard;
