import { useMediaQuery } from "@chakra-ui/react";
import { Flex } from "antd";
import { Pluralizar } from "infra/helpers/plural";
import useU4heroColors from "infra/packages/antd/u4heroTokens";

function MovementsCard({ numberOfMovements }: { numberOfMovements: number }) {
  const [isMobile] = useMediaQuery("(max-width: 768px)");
  const { colorPrimary } = useU4heroColors();

  return (
    <Flex
      style={{
        justifyContent: "space-between",
        marginTop: isMobile ? "8px" : "24px",
        borderRadius: "10px",
        width: "100%",
        background: colorPrimary,
        position: "relative",
      }}
    >
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background:
            "url('https://u4hero-files.s3.sa-east-1.amazonaws.com/helpers/faa8a4a9-76fe-4211-8c68-5c3744f5cd5b.png')",
          backgroundSize: "cover",
          backgroundPosition: "center",
          borderRadius: "10px",
        }}
      ></div>
      <Flex justify={!isMobile ? "center" : ""} style={{ width: "100%" }}>
        <Flex
          style={{
            width: isMobile ? "100%" : "40%",
            paddingLeft: "18px",
            paddingBottom: "16px",
            paddingTop: "16px",
            padding: "10px",
            zIndex: 0,
          }}
          justify="center"
          vertical
        >
          <Flex gap={16} align={"center"}>
            <p
              style={{
                color: "white",
                lineHeight: "21px",
                fontSize: isMobile ? "16px" : "20px",
                fontWeight: 600,
              }}
            >
              {numberOfMovements}
            </p>
            <p
              style={{
                color: "white",
                lineHeight: "14px",
                fontSize: isMobile ? "14px" : "16px",
                fontWeight: 400,
              }}
            >
              {Pluralizar("Movimentação", numberOfMovements !== 1)}
            </p>
          </Flex>
        </Flex>
        <Flex style={{ height: "100%" }} align="end">
          <img
            style={{ zIndex: 0 }}
            width={"81px"}
            height={"79px"}
            src="https://u4hero-files.s3.sa-east-1.amazonaws.com/helpers/636d6839-60b5-4bd4-8b9e-7e51f8e75e10.png"
            alt="Lumi no canto montado em um lapis"
          />
        </Flex>
      </Flex>
    </Flex>
  );
}

export default MovementsCard;
