import UseCountTagsByResponsibleId from 'core/features/tags/hooks/useCountTagsByResponsibleId';
import useTagsWithoutParent from 'core/features/tags/hooks/useTagsWithoutParent';
import { useCurrentUser } from 'core/features/user/store';
import { useMediaQuery } from '@chakra-ui/media-query';
import {
  Col,
  Collapse,
  CollapseProps,
  Flex,
  Form,
  Radio,
  Row,
  Space,
  Spin,
} from 'antd';
import SvgIcon from 'components/SvgIcon';
import { GenericCard } from 'components/V2/generic-card-styled-component';
import { hex2rgba } from 'infra/helpers/hex2Rgba';
import { Pluralizar } from 'infra/helpers/plural';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import Text from 'components/typography/text';
import './style.css';

function SelectActivityTag({
  value,
  onChange,
}: {
  value: string[];
  onChange: (v: string[]) => void;
}) {
  const user = useCurrentUser();
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const form = Form.useFormInstance();
  const classes = Form.useWatch('classes', form);
  const students = Form.useWatch('students', form);
  const tagType = Form.useWatch('tagType', form);

  const { colorSecondary } = useU4heroColors();

  const { data, isLoading } = useTagsWithoutParent({
    disabled: tagType === undefined,
    type: tagType,
  });

  const { data: countTags } = UseCountTagsByResponsibleId({
    responsibleId: user?.user?.id,
    classes,
    students,
    enabled:
      ((classes !== undefined && classes?.length !== 0) ||
        (students !== undefined && students.length !== 0)) &&
      tagType !== undefined,
  });

  const items: CollapseProps['items'] = data?.map((item, index) => ({
    key: item.id,
    label: (
      <Flex gap="16px" align="center">
        {item.icon ? <SvgIcon url={item.icon} color="black" size={40} /> : null}
        <p
          style={{
            fontSize: '18px',
            color: 'black',
            fontWeight: 400,
          }}
        >
          {item.name}
        </p>
      </Flex>
    ),
    children: (
      <Radio.Group
        value={value[1]}
        onChange={(e) => {
          onChange([item.id, e.target.value]);
        }}
        style={{ width: '100%', marginTop: '5px' }}
      >
        <Space direction="vertical" style={{ width: '100%' }}>
          {item.children.map((child, index) => (
            <Radio
              style={{ paddingLeft: '8px', width: '100%' }}
              value={child.id}
            >
              <Flex gap="16px" align='center'>
                {child.icon ? (
                  <SvgIcon url={child.icon} color="black" size={40} />
                ) : null}
                <p
                  style={{
                    fontSize: '16px',
                    color: 'black',
                    fontWeight: 400,
                  }}
                >
                  {child.name}
                </p>
              </Flex>

              <Flex style={{ width: '100%' }} justify="end">
                <div
                  style={{
                    borderRadius: '10px 0px 0px 10px',
                    background: hex2rgba(colorSecondary, 0.8),
                    width: 'fit-content',
                    padding: '1px 8px',
                  }}
                >
                  <p
                    style={{
                      fontSize: '10px',
                      fontWeight: 600,
                      color: 'white',
                      textAlign: 'center',
                    }}
                  >
                    {child?.count
                      ? `Utilizado ${child.count} ${Pluralizar(
                          'vez',
                          child.count > 1
                        )}`
                      : 'Não utilizado'}
                  </p>
                </div>
              </Flex>
            </Radio>
          ))}
        </Space>
      </Radio.Group>
    ),
  }));

  if (data) {
    for (const competence of data) {
      for (const skill of competence.children) {
        skill.count = countTags?.find((t) => t.tagId === skill.id)?.count ?? 0;
      }
    }
  }
  return (
    <>
    {
      isLoading && <Spin size='large' />
    }
      {isMobile ? (
        <Collapse
          items={items}
          expandIconPosition="end"
          defaultActiveKey={value.length ? value[0] : undefined}
        />
      ) : (
        <Row id="id-tour-tag-step-row" gutter={[67, 20]} align="stretch">
          {data?.map((tag, key) => (
            <Col span={6} key={key}>
              <GenericCard padding="0px 0px 20px 0px" height="100%">
                <Flex vertical style={{ width: '100%' }} gap="8px">
                  <Flex
                    style={{
                      borderRadius: '20px 20px 0px 0px',
                      background: '#F5F5F5',
                      padding: '24px 8px',
                    }}
                    gap="16px"
                    align="center"
                  >
                    {tag.icon ? (
                      <SvgIcon url={tag.icon} color="black" size={40} />
                    ) : null}
                    <Text size="md">{tag.name}</Text>
                  </Flex>
                  <Radio.Group
                    value={value[1]}
                    onChange={(e) => {
                      onChange([tag.id, e.target.value]);
                    }}
                    style={{ width: '100%' }}
                  >
                    <Space direction="vertical" style={{ width: '100%' }}>
                      {tag.children.map((child, index) => (
                        <Radio
                          style={{ paddingLeft: '8px', width: '100%' }}
                          value={child.id}
                          key={child.id}
                        >
                          <Flex gap="16px" align="center">
                            {child.icon ? (
                              <SvgIcon
                                url={child.icon}
                                color="black"
                                size={40}
                              />
                            ) : null}

                            <p
                              style={{
                                fontSize: '16px',
                                color: 'black',
                                fontWeight: 400,
                              }}
                            >
                              {child.name}
                            </p>
                          </Flex>

                          <Flex style={{ width: '100%' }} justify="end">
                            <div
                              style={{
                                borderRadius: '10px 0px 0px 10px',
                                background: hex2rgba(colorSecondary, 0.8),
                                width: 'fit-content',
                                padding: '1px 8px',
                              }}
                            >
                              <p
                                style={{
                                  fontSize: '10px',
                                  fontWeight: 600,
                                  color: 'white',
                                  textAlign: 'center',
                                }}
                              >
                                {child?.count
                                  ? `Utilizado ${child.count} ${Pluralizar(
                                      'vez',
                                      child.count > 1
                                    )}`
                                  : 'Não utilizado'}
                              </p>
                            </div>
                          </Flex>
                        </Radio>
                      ))}
                    </Space>
                  </Radio.Group>
                </Flex>
              </GenericCard>
            </Col>
          ))}
        </Row>
      )}
    </>
  );
}

export default SelectActivityTag;
