import { useMediaQuery } from '@chakra-ui/react';
import { Col, Flex, Form, Input, Row, Select } from 'antd';
import InputMask from 'components/inputs/input-mask';
import SubTitle from 'components/typography/subTitle';
import { GenericCard } from 'components/V2/generic-card-styled-component';
import useAddress from 'core/features/address/hooks';
import { useCurrentUser } from 'core/features/user/store';
import { useEffect, useState } from 'react';

function AdressInfo() {
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const [zipcode, setZipcode] = useState<string>();
  const { data } = useAddress(zipcode);
  const form = Form.useFormInstance();
  const cep = Form.useWatch('addressZipCode', form);
  const { user } = useCurrentUser();
  const [error, setError] = useState<string>();
  useEffect(() => {
    if (cep === user?.addressZipCode) {
      return;
    }
    if (cep) {
      const formattedCep = cep.replace(/\D/g, '');
      if (formattedCep.length === 8) {
        setZipcode(formattedCep);
      } else {
        setZipcode(undefined);
      }
    }
  }, [cep]);

  useEffect(() => {
    if (data && !('error' in data)) {
      form.setFieldsValue({
        addressCity: data.city,
        addressDistrict: data.neighborhood,
        addressState: data.state,
        addressStreet: data.street,
      });
      setError(undefined);
    }
    if (data && 'error' in data) {
      setError(data.error);
    }
  }, [data]);

  const isDataValid = data && !('error' in data);
  const isNeighborhoodEmpty = isDataValid && data.neighborhood === '';
  const isStreetEmpty = isDataValid && data.street === '';

  return (
    <GenericCard>
      <Flex vertical style={{ width: '100%' }}>
        <SubTitle primary>Endereço</SubTitle>
        <Row gutter={[24, 0]}>
          <Col span={isMobile ? 24 : 6}>
            <Form.Item
              label="CEP"
              validateStatus={error ? 'error' : ''}
              help={error ?? ''}
              rules={[
                {
                  validator: async (rule, value) => {
                    if (value) {
                      const formattedValue = value.replace(/\D/g, '');
                      if (formattedValue.length !== 8) {
                        throw new Error('O CEP deve ter 8 digitos');
                      }
                    }
                  },
                },
              ]}
              name="addressZipCode"
            >
              <InputMask mask="99999-999" autoComplete="off">
                {() => (
                  <Input
                    id="id-tour-my-profile-input-phone"
                    style={{ maxWidth: '345px' }}
                  />
                )}
              </InputMask>
            </Form.Item>
          </Col>
          <Col span={isMobile ? 24 : 18}>
            <Form.Item label="Logradouro" name="addressStreet">
              <Input
                disabled={!isDataValid || !isStreetEmpty}
                placeholder="Rua, Avenida, Travessa..."
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[24, 0]}>
          <Col span={isMobile ? 10 : 6} order={1}>
            <Form.Item label="Número" name="addressNumber">
              <Input placeholder="Número" />
            </Form.Item>
          </Col>
          <Col span={isMobile ? 24 : 11} order={isMobile ? 3 : 2}>
            <Form.Item label="Bairro" name="addressDistrict">
              <Input
                disabled={!isDataValid || !isNeighborhoodEmpty}
                placeholder="Nome do bairro"
              />
            </Form.Item>
          </Col>
          <Col span={isMobile ? 14 : 7} order={isMobile ? 2 : 3}>
            <Form.Item label="Complemento" name="addressComplement">
              <Input placeholder="Apto, casa..." />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[24, 0]}>
          <Col span={12}>
            <Form.Item label="Estado" name="addressState">
              <Input disabled placeholder="Estado" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Cidade" name="addressCity">
              <Input disabled placeholder="Cidade" />
            </Form.Item>
          </Col>
        </Row>
      </Flex>
    </GenericCard>
  );
}

export default AdressInfo;
