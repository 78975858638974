import { useMediaQuery } from '@chakra-ui/react';
import { Button, Dropdown, Flex, Menu } from 'antd';
import useStudentsV2 from 'core/features/students/hooks/useStudentsV2';
import useCheckPermissions from 'core/features/user/hooks/useCheckPermissions';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Permissions } from 'core/resources/enums/permissions';
import { StudentItem } from 'core/features/students/typings';
import moment from 'moment';
import { MdExpandLess, MdExpandMore } from 'react-icons/md';
import DynamicTable from 'components/V2/dinamic-table';
import { FaEllipsisVertical } from 'react-icons/fa6';
import StudentFilter from '../StudentFilter';
import { SiGoogleclassroom } from 'react-icons/si';
import { useToggleStudent } from 'core/features/students/hooks/useToggleStudent';
import { calcAge } from 'infra/helpers/date';
import {
  notificateError,
  notificateSucess,
  showConfirm,
} from 'infra/helpers/notifications';
import { FilterType } from 'infra/helpers/types';

function StudentsTable({
  onEdit,
  onCreateNewKey,
  onSelect,
  value,
  callback,
  hideColumns = false,
}: {
  onEdit?: (item: StudentItem) => void;
  onCreateNewKey?: (item: StudentItem) => void;
  onSelect?: (rowKeys: string[]) => void;
  value?: string[];
  callback?: (rows: StudentItem[]) => void;
  hideColumns?: boolean;
}) {
  const history = useHistory();
  const [check] = useCheckPermissions();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState('');
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  let location = useLocation();
  const { colorPrimary } = useU4heroColors();
  const { tenantId } = useParams<{ tenantId: string }>();
  const [filters, setFilters] = useState<FilterType>({
    types: [],
    series: [],
    classes: [],
    lastAccess: [],
  });

  useEffect(() => {
    if (location.search.includes('active')) {
      setFilters({
        ...filters,
        types: [{ label: 'Estudantes com chave ativa', value: 'active' }],
      });
    }
  }, []);

  const { data, isLoading, isFetching, refetch } = useStudentsV2({
    page,
    search,
    series: filters?.series?.map((item: any) => item.value) ?? undefined,
    classes: filters?.classes?.map((item: any) => item.value) ?? undefined,
    disableConcat: true,
    deleted: filters.types.map((item) => item.value).includes('deleted'),
    active: filters.types.map((item) => item.value).includes('active'),
    classId: filters.types.map((item) => item.value).includes('without-class')
      ? 'null'
      : undefined,
    tenantId: tenantId ?? undefined,
    limit: pageSize,
    lastAccess: filters?.lastAccess.map((item) => item.value)[0] ?? undefined,
  });

  const { mutate: handleToggle } = useToggleStudent();

  const isEachLoading = isLoading || isFetching;

  const tableHeaderStyle = {
    fontSize: isMobile ? '14px' : '18px',
    fontWeight: 400,
  };
  const tableRowStyle = {
    fontSize: isMobile ? '14px' : '16px',
    fontWeight: 400,
  };
  const [selectedStudents, setSelectedStudents] = useState<StudentItem[]>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>(value ?? []);

  const redirectToDashboard = (userId: string) => {
    if (tenantId) {
      history.push(`/schools/${tenantId}/student/${userId}`);
    } else {
      history.push(`/my-school/student/${userId}`);
    }
  };

  const columns = [
    {
      title: <p style={tableHeaderStyle}>Nome</p>,
      name: 'Nome',
      dataIndex: 'name',
      key: 'name',
      mobile: true,
      render: (name: string, record: StudentItem) => (
        <p style={tableRowStyle}>{record.name}</p>
      ),
      sorter: (a: any, b: any) => a.name.localeCompare(b.name),
      sortIcon: (sortOrder: any) =>
        sortOrder.sortOrder === 'descend' ? (
          <MdExpandLess size={25} />
        ) : (
          <MdExpandMore size={25} />
        ),
    },
    {
      title: <p style={tableHeaderStyle}>Série/Turma</p>,
      name: 'Série/Turma',
      dataIndex: 'serie.name',
      key: 'serie.name',
      mobile: true,
      render: (_: string, record: StudentItem) => (
        <p style={tableRowStyle}>{`${
          record?.serie?.name ? record.serie.name : '-'
        } ${record?.class?.name ? record.class.name : '-'}`}</p>
      ),
      sorter: (a: any, b: any) => {
        const nameA = a?.serie?.name || '';
        const nameB = b?.serie?.name || '';
        return nameA.localeCompare(nameB);
      },
      sortIcon: (sortOrder: any) =>
        sortOrder.sortOrder === 'descend' ? (
          <MdExpandLess size={25} />
        ) : (
          <MdExpandMore size={25} />
        ),
    },
    {
      title: <p style={tableHeaderStyle}>Chave</p>,
      name: 'Chave',
      dataIndex: 'license',
      key: 'license',
      render: (_: string, record: StudentItem) => (
        <p style={tableRowStyle}>{record.license}</p>
      ),
      sorter: (a: any, b: any) => a.license.localeCompare(b.license),
      sortIcon: (sortOrder: any) =>
        sortOrder.sortOrder === 'descend' ? (
          <MdExpandLess size={25} />
        ) : (
          <MdExpandMore size={25} />
        ),
    },
    {
      title: <p style={tableHeaderStyle}>Último acesso</p>,
      dataIndex: 'activationDate',
      key: 'activationDate',
      name: 'Último acesso',
      render: (_: string, record: StudentItem) => (
        <p style={tableRowStyle}>
          {record.activationDate
            ? moment(record.activationDate).format('DD/MM/YYYY')
            : '-'}
        </p>
      ),
      sorter: (a: any, b: any) => {
        const nameA = a?.activationDate || '';
        const nameB = b?.activationDate || '';
        return nameA.localeCompare(nameB);
      },
      sortIcon: (sortOrder: any) =>
        sortOrder.sortOrder === 'descend' ? (
          <MdExpandLess size={25} />
        ) : (
          <MdExpandMore size={25} />
        ),
    },
    {
      title: <p style={tableHeaderStyle}>Última resposta</p>,
      key: 'lastSync',
      dataIndex: 'lastSync',
      name: 'Última resposta',
      render: (_: string, record: StudentItem) => (
        <p style={tableRowStyle}>
          {' '}
          {record.lastSync ? moment(record.lastSync).format('DD/MM/YYYY') : '-'}
        </p>
      ),
      sorter: (a: any, b: any) => {
        const nameA = a?.lastSync || '';
        const nameB = b?.lastSync || '';
        return nameA.localeCompare(nameB);
      },
      sortIcon: (sortOrder: any) =>
        sortOrder.sortOrder === 'descend' ? (
          <MdExpandLess size={25} />
        ) : (
          <MdExpandMore size={25} />
        ),
    },
    {
      title: <p style={tableHeaderStyle}>Painel do estudante</p>,
      key: 'studentPanel',
      dataIndex: 'studentPanel',
      name: 'Painel do estudante',
      render: (_: string, record: StudentItem) => (
        <Button
          type="primary"
          size="large"
          onClick={(e) => {
            e.stopPropagation();
            redirectToDashboard(record.userId);
          }}
          style={{ padding: '8px' }}
        >
          <Flex>
            <SiGoogleclassroom size={20} color="white" />
          </Flex>
        </Button>
      ),
    },
    {
      title: <p style={tableHeaderStyle}>Ações</p>,
      key: 'action',
      mobile: true,
      dataIndex: 'action',
      name: 'Ações',
      render: (_: string, record: StudentItem) => (
        <Dropdown
          overlay={
            <Menu
              id="id-tour-action-student-table"
              onClick={(e) => {
                e.domEvent.stopPropagation();
                if (e.key === 'edit') {
                  onEdit && onEdit(record);
                }

                if (e.key === 'new-key') {
                  onCreateNewKey && onCreateNewKey(record);
                }
                if (e.key === 'delete') {
                  showConfirm({
                    message: `Tem certeza que deseja
                  ${record.active ? 'exluir' : 'restaurar'} o cadastro do
                  estudante?`,
                    colorPrimary,
                    isMobile,
                  }).then(({ isConfirmed }) => {
                    if (isConfirmed) {
                      handleToggle(
                        {
                          id: record.id,
                          isActive: record.active,
                        },
                        {
                          onSuccess: () => {
                            notificateSucess(
                              `Cadastro ${
                                record.active ? 'excluido' : 'restaurado'
                              } com sucesso!`
                            );
                            refetch();
                          },
                          onError: () => {
                            notificateError(
                              `Ops, aconteceu algum problema ao tentar ${
                                record.active ? 'excluir' : 'restaurar'
                              } o cadastro, tente novamente!`
                            );
                          },
                        }
                      );
                    }
                  });
                }
                if (e.key === 'panel') {
                  redirectToDashboard(record.userId);
                }
              }}
            >
              {check([
                Permissions.Students.Edit,
                Permissions.U4hero.Students.Edit,
                Permissions.Org.Students.Edit,
              ]) ? (
                <Menu.Item id="edit-button" key={'edit'}>
                  Editar
                </Menu.Item>
              ) : null}

              {check([
                Permissions.Students.Edit,
                Permissions.U4hero.Students.Edit,
                Permissions.Org.Students.Edit,
              ]) ? (
                <Menu.Item id="create-new-key-button" key={'new-key'}>
                  Gerar Nova Chave
                </Menu.Item>
              ) : null}

              {check([
                Permissions.Students.Activate,
                Permissions.U4hero.Students.Activate,
                Permissions.Org.Students.Activate,
              ]) ? (
                <Menu.Item id="delete-button" key={'delete'}>
                  {record.active ? 'Desativar' : 'Restaurar'}
                </Menu.Item>
              ) : null}

              {isMobile ? (
                <Menu.Item key={'panel'}>Ver painel</Menu.Item>
              ) : null}
            </Menu>
          }
          trigger={['click']}
        >
          <FaEllipsisVertical
            onClick={(e) => e.stopPropagation()}
            id="id-tour-action-student-table"
            size={24}
            style={{ cursor: 'pointer' }}
          />
        </Dropdown>
      ),
    },
  ];

  useEffect(() => {
    setPage(1);
  }, [search, filters]);

  if (hideColumns) {
    columns.splice(1);
    columns.push({
      title: <p style={tableHeaderStyle}>Idade</p>,
      name: 'Idade',
      dataIndex: 'birthday',
      key: 'birthday',
      mobile: true,
      render: (name: string, record: StudentItem) => (
        <p style={tableRowStyle}>{calcAge(record.birthday)}</p>
      ),
      sorter: (a: any, b: any) => a.birthday.localeCompare(b.birthday),
      sortIcon: (sortOrder: any) =>
        sortOrder.sortOrder === 'descend' ? (
          <MdExpandLess size={25} />
        ) : (
          <MdExpandMore size={25} />
        ),
    });
  }
  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys: string[], selectedRows: StudentItem[]) => {
      setSelectedRowKeys(selectedRowKeys);
      setSelectedStudents(selectedRows);
      onSelect && onSelect(selectedRowKeys);
    },
  };

  const onClickRow = (key: string) => {
    if (selectedRowKeys.includes(key)) {
      const rowKeys = [...selectedRowKeys.filter((item) => item !== key)];
      setSelectedStudents((prev) => [
        ...prev.filter((item) => item.id !== key),
      ]);
      setSelectedRowKeys(rowKeys);
      onSelect && onSelect(rowKeys);
    } else {
      const rowKeys = [...selectedRowKeys, key];
      setSelectedRowKeys(rowKeys);
      onSelect && onSelect(rowKeys);
      const student = data?.data.find((item) => item.id === key);
      if (student) {
        setSelectedStudents((prev) => [...prev, student]);
      }
    }
  };

  useEffect(() => {
    if (value && data) {
      setSelectedStudents((prev) =>
        prev.length
          ? [
              ...prev,
              ...data?.data
                .filter((v) => !prev.map((item) => item.id).includes(v.id))
                .filter((item) => value.includes(item.id)),
            ]
          : data?.data.filter((item) => value.includes(item.id))
      );
    }
  }, [data]);

  useEffect(() => {
    if (callback) {
      callback(selectedStudents.filter((item) => item !== undefined));
    }
  }, [selectedStudents]);

  return (
    <DynamicTable
      id="id-tour-my-school-section-students-table"
      itemName="estudante"
      data={data?.data ?? []}
      onChangePage={setPage}
      onChangePageSize={setPageSize}
      title=""
      total={data?.total ?? 0}
      page={page}
      pageSize={pageSize}
      isLoading={isEachLoading}
      defaultColumns={
        isMobile ? columns.filter((item) => item.mobile) : columns
      }
      allColumns={columns}
      rowSelection={
        onSelect
          ? { preserveSelectedRowKeys: true, ...rowSelection }
          : undefined
      }
      onClickRow={onSelect ? (v) => onClickRow(v.id) : undefined}
      onSearch={setSearch}
      appliedFilters={filters}
      onChangeFilters={setFilters}
      filters={<StudentFilter filters={filters} onChangeFilters={setFilters} />}
    />
  );
}

export default StudentsTable;
