import { Flex, Progress, Spin } from 'antd';
import useStudentInfo from 'core/features/students/hooks/useStudentInfo';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import dayjs from 'dayjs';
import { GenericCard } from 'components/V2/generic-card-styled-component';
import { useEffect, useState } from 'react';
import { useMediaQuery } from '@chakra-ui/media-query';
import { ClockCircleOutlined } from '@ant-design/icons';
import defaultProfile from 'assets/images/default-profile.png';
import Button from 'components/buttons/button';
import { t } from 'core/resources/strings';
import { useParams } from 'react-router-dom';
import ModalStudentLogins from 'components/modals/modal-student-logins';

function StudentInformation({ showDrawer }: { showDrawer: () => void }) {
  const { id } = useParams<{ id: string }>();
  const { data: userInfo } = useStudentInfo(id);
  const { colorPrimary } = useU4heroColors();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [emotionColor, setEmotionColor] = useState('');
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const [emotionName, setEmotionName] = useState('');

  useEffect(() => {
    const predominantEmotion = userInfo?.predominantEmotion;

    if (predominantEmotion) {
      return userInfo?.predominantEmotion.includes('feliz')
        ? (setEmotionColor('#FCBE03'), setEmotionName('Feliz'))
        : userInfo?.predominantEmotion.includes('triste')
        ? (setEmotionColor('#4377B9'), setEmotionName('Triste'))
        : userInfo?.predominantEmotion.includes('nojo')
        ? (setEmotionColor('#77B943'), setEmotionName('Nojo'))
        : userInfo?.predominantEmotion.includes('raiva')
        ? (setEmotionColor('#B94143'), setEmotionName('Raiva'))
        : (setEmotionColor('#7743B9'), setEmotionName('Medo'));
    }
  }, [userInfo?.predominantEmotion]);

  return (
    <GenericCard width={'100%'} heigth={'189px'}>
      {isOpenModal && (
        <ModalStudentLogins userId={id} onClose={() => setIsOpenModal(false)} />
      )}
      {isMobile ? (
        <Flex gap={16} align="center" style={{ width: '100%' }} vertical>
          <Flex justify="center" align="center" gap={29}>
            <Flex
              align="center"
              justify="start"
              style={{ marginRight: '24px' }}
              vertical
            >
              <img
                loading={'lazy'}
                src={userInfo?.imageUrl ?? defaultProfile}
                alt="Avatar do aluno"
                style={{
                  width: '80px',
                  height: '80px',
                  zIndex: 1,
                  borderRadius: '50%',
                  border: `4px solid ${emotionColor}`,
                  objectFit: 'cover',
                }}
              />
              <Flex style={{ width: '100%' }} vertical>
                <Progress
                  style={{ marginTop: '-8px', zIndex: 99 }}
                  strokeWidth={20}
                  strokeColor={emotionColor}
                  trailColor="#F5F5F5"
                  status="normal"
                  percent={userInfo?.emotionPercentage}
                />
              </Flex>
              <p
                style={{
                  fontWeight: 600,
                  fontSize: '18px',
                  color: emotionColor,
                }}
              >
                {emotionName}
              </p>
            </Flex>
            <Flex gap={12} vertical>
              <p style={{ color: 'black', fontSize: '14px' }}>
                {userInfo?.name}
              </p>
              <p
                style={{ color: 'black', fontSize: '14px' }}
              >{`${userInfo?.age} anos`}</p>
              <p style={{ color: 'black', fontSize: '14px' }}>
                {userInfo?.className
                  ? `${userInfo?.className} - ${t(`app.${userInfo?.period}`)}`
                  : '-'}
              </p>
              <p style={{ color: 'black', fontSize: '14px' }}>
              {userInfo?.lastSync
                    ? dayjs(userInfo?.lastSync).format('DD/MM/YYYY HH[h]mm')
                    : '-'}
                <ClockCircleOutlined style={{ marginLeft: '15.79px' }} />
              </p>
            </Flex>
          </Flex>

          <Flex style={{ width: '100%' }} justify="space-around">
            <Button onClick={showDrawer} size="lg">
              OBSERVAÇÃO
            </Button>
            <Button
              variant="outline"
              size="lg"
              onClick={() => setIsOpenModal(true)}
            >
              HISTÓRICO DE ACESSOS
            </Button>
          </Flex>
        </Flex>
      ) : (
        <Flex style={{ width: '100%' }} gap={16} justify="space-between">
          <Flex justify="center" align="center" gap={58}>
            <Flex
              align="center"
              justify="start"
              style={{ marginRight: '24px' }}
              vertical
            >
              <img
                loading={'lazy'}
                src={userInfo?.imageUrl ?? defaultProfile}
                alt="Avatar do aluno"
                style={{
                  width: '100px',
                  height: '100px',
                  zIndex: 1,
                  borderRadius: '50%',
                  border: `4px solid ${emotionColor}`,
                  objectFit: 'cover',
                }}
              />
              <Flex style={{ width: '100%' }} vertical>
                <Progress
                  style={{ marginTop: '-8px', zIndex: 99 }}
                  strokeWidth={20}
                  status="normal"
                  strokeColor={emotionColor}
                  trailColor="#F5F5F5"
                  percent={userInfo?.emotionPercentage}
                />
              </Flex>
              <p
                style={{
                  fontWeight: 600,
                  fontSize: '18px',
                  color: emotionColor,
                }}
              >
                {emotionName}
              </p>
            </Flex>
            {userInfo ? (
              <Flex gap={12} vertical>
                <p style={{ color: 'black', fontSize: '18px' }}>
                  <span style={{ color: colorPrimary, fontWeight: 700 }}>
                    Nome:{' '}
                  </span>
                  {userInfo?.name}
                </p>
                <p style={{ color: 'black', fontSize: '18px' }}>
                  <span style={{ color: colorPrimary, fontWeight: 700 }}>
                    Idade:{' '}
                  </span>
                  {`${userInfo?.age} anos`}
                </p>
                <p style={{ color: 'black', fontSize: '18px' }}>
                  <span style={{ color: colorPrimary, fontWeight: 700 }}>
                    Chave:{' '}
                  </span>
                  {userInfo?.license}
                </p>
                <p style={{ color: 'black', fontSize: '18px' }}>
                  <span style={{ color: colorPrimary, fontWeight: 700 }}>
                    Matriculado:{' '}
                  </span>
                  {userInfo.className
                    ? `${userInfo?.className} - ${t(`app.${userInfo?.period}`)}`
                    : '-'}
                </p>
                <p style={{ color: 'black', fontSize: '18px' }}>
                  <span style={{ color: colorPrimary, fontWeight: 700 }}>
                    Último Acesso:{' '}
                  </span>
                  {userInfo.lastSync
                    ? dayjs(userInfo?.lastSync).format('DD/MM/YYYY HH[h]mm')
                    : '-'}
                  <ClockCircleOutlined style={{ marginLeft: '15px' }} />
                </p>
              </Flex>
            ) : (
              <Spin />
            )}
          </Flex>
          <Flex vertical style={{ maxWidth: '284px' }} justify="space-around">
            <Button onClick={showDrawer} size="lg">
              OBSERVAÇÃO
            </Button>
            <Button
              variant="outline"
              size="lg"
              onClick={() => setIsOpenModal(true)}
            >
              HISTÓRICO DE ACESSOS
            </Button>
          </Flex>
        </Flex>
      )}
    </GenericCard>
  );
}

export default StudentInformation;
