import { useQuery } from "react-query";
import axios from "infra/http";
import { ResourceItem } from "../types";


function useResources() {
  return useQuery(`/v1/resource`, async (): Promise<ResourceItem[]> => {
    const { data } = await axios.get(`/v1/resource`);

    return data;
  });
}

export default useResources;