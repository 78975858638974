import { useMediaQuery } from '@chakra-ui/react';
import { Flex, Form, Modal } from 'antd';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import { IoClose } from 'react-icons/io5';
import {
  notificateError,
  notificateSucess,
  openNotification,
} from 'infra/helpers/notifications';
import Button from 'components/buttons/button';
import SubTitle from 'components/typography/subTitle';
import CustomizedRequiredMark from 'components/required-mark';
import PreMadeOptions from './components/preMadeOptions';
import CustomizeGraphs from './components/customizeGraphs';
import { useSaveIndicatorsProfile } from 'core/features/indicators-profile/hooks/useSaveIndicatorsProfile';
import { useEffect } from 'react';
import { useQueryClient } from 'react-query';
import { v4 as uuidv4 } from 'uuid';
import { IndicatorsProfileForm } from 'core/features/indicators-profile/typings';

function ModalCustomizePanel({
  data,
  onClose,
  graphIndex,
}: {
  data?: IndicatorsProfileForm;
  onClose: () => void;
  graphIndex?: number;
}) {
  const [form] = Form.useForm();
  const { colorPrimary, colorGreyBorderAndFont } = useU4heroColors();
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const { mutate: handleSave, isLoading: isLoadingSave } =
    useSaveIndicatorsProfile();

  const queryClient = useQueryClient();

  useEffect(() => {
    form.resetFields();
  }, [data, form]);

  const onSubmit = (values: any) => {
    handleSave(
      { profile: values, id: data?.id },
      {
        onSuccess: () => {
          notificateSucess(
            `${
              data?.id ? 'Indicadores atualizados' : 'Indicadores incluidos'
            } com sucesso!`
          );
          queryClient.invalidateQueries(`/v2/indicators-profile/`);
          onClose();
        },
        onError: (err: any) => {
          notificateError(
            `Houve um problema ao ${
              data?.id ? 'atualizar' : 'incluir'
            } os indicadores. Verifique sua conexão e tente novamente.`
          );
        },
      }
    );
  };

  return (
    <Modal
      title={<SubTitle primary>Adicionar Indicadores</SubTitle>}
      open={true}
      onCancel={() => {
        if (form.isFieldsTouched()) {
          openNotification({ onClose, colorPrimary, isMobile });
        } else {
          onClose();
        }
      }}
      footer={null}
      width={isMobile ? '100%' : 750}
      closeIcon={
        <div
          style={{
            background: colorPrimary,
            borderRadius: '50%',
            width: '20px',
            height: '20px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <IoClose size={16} color="white" />
        </div>
      }
    >
      <Form
        id="id-tour-indicators-custom-panel-form"
        onFinish={(ev) => onSubmit(ev)}
        validateTrigger="onSubmit"
        layout="vertical"
        requiredMark={CustomizedRequiredMark}
        form={form}
        initialValues={
          data
            ? {
                options: data.profile.options,
                graphs: data.profile.graphs,
              }
            : {
                options: {
                  educatorFrequency: false,
                  emotionometer: false,
                  studentFrequency: false,
                  totalClasses: false,
                  totalCollaborators: false,
                  totalEducators: false,
                  totalStudents: false,
                },
                graphs: [
                  {
                    id: uuidv4(),
                    tags: [],
                  },
                ],
              }
        }
      >
        <Flex vertical gap={16}>
          <PreMadeOptions />
          <CustomizeGraphs graphIndex={graphIndex} />

          <Flex justify="center" gap={isMobile ? '12px' : '24px'}>
            <Button
              variant="outline"
              size="md"
              onClick={() => {
                if (form.isFieldsTouched()) {
                  openNotification({ onClose, colorPrimary, isMobile });
                } else {
                  onClose();
                }
              }}
              id="id-tour-indicators-custom-panel-button-cancel"
            >
              CANCELAR
            </Button>

            <Button
              htmlType="submit"
              size="md"
              id="id-tour-indicators-custom-panel-button-save"
              loading={isLoadingSave}
            >
              SALVAR
            </Button>
          </Flex>
        </Flex>
      </Form>
    </Modal>
  );
}

export default ModalCustomizePanel;
