import { ApexOptions } from 'apexcharts';
import ReactApexChart from 'react-apexcharts';

function RadarGraph({
  categories,
  series,
}: {
  categories: string[];
  series: ApexAxisChartSeries;
}) {
  const options: ApexOptions = {
    chart: {
      height: 350,
      type: 'radar',
      toolbar:{
        show:false,
      },
      dropShadow: {
        enabled: true,
        blur: 1,
        left: 1,
        top: 1,
      },
    },
    stroke: {
      width: 2,
    },
    fill: {
      opacity: 0.1,
    },
    markers: {
      size: 0,
    },
    yaxis: {
      stepSize: 20,
    },
    xaxis: {
      categories: categories,
    },
  };

  return (
    <ReactApexChart options={options} series={series} type="radar" height={350} />
  );
}

export default RadarGraph;
